import React, { useState } from "react";
import Header from "components/header";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AuthenticationService from "services/AuthenticationService";
import { useNavigate } from "react-router-dom";

function ForgotUsername() {
  const [identity, set_identity] = useState("");
  const [msg, set_msg] = useState("");
  const [err, set_err] = useState([]);

  const navigate = useNavigate();

  const send_id = async (e) => {
    e.preventDefault();

    set_err([]);
    set_msg("");

    try {
      const res = await AuthenticationService.send_user_no({ identity });

      set_msg(res.data.message);
    } catch (error) {
      const msg = error.response?.data?.message;
      if (msg) {
        if (msg instanceof String || typeof msg === "string") set_err([msg]);
        else {
          const arr = [];
          Object.keys(msg).map((key) => {
            for (const emsg of msg[key]) arr.push(emsg);
          });

          set_err(arr);
        }
      } else {
        set_err(["Something went wrong, check your internet"]);
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="loginBg">
          <h1>Lost Your Number</h1>
          <form id="loginForm" onSubmit={send_id}>
            <div className="mb-4">
              <label className="form-label">
                Phone Number/Email <em className="required">*</em>
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                required
                value={identity}
                onChange={(e) => set_identity(e.target.value)}
                placeholder="Phone Number/Email"
              />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-yellow">
                Submit
              </button>
              <div className="mt-4">
                <a className="link" onClick={() => navigate("/auth/signin")}>
                  Login Account
                </a>
              </div>
            </div>

            {msg && <div className="success_message">{msg}</div>}
            {err.map((text) => (
              <div className="error_message">{text}</div>
            ))}
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotUsername;
