import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  initializeBrandList,
  selectBrandsData,
  setCustomBrandList,
  addBrandToList,
} from "redux-store/features/brandsSlice";
import BrandService from "services/BrandService";
import { logger } from "shared/helpers";

export const useBrands = (filter = undefined) => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { brand_list, initialized_brand_list, custom_brand_list } =
    useSelector(selectBrandsData);

  const fetchBrandList = async () => {
    setLoading(true);
    try {
      const res = await BrandService.getAllBrands(token, dispatch);

      if (res.data.data) {
        dispatch(initializeBrandList(res.data.data));
      }
    } catch (error) {
      logger("[ERROR]", error);
    }
    setLoading(false);
  };

  const fetchCategoryBrands = async (category_id) => {
    if (!Boolean(category_id)) return;

    setLoading(true);
    try {
      const res = await BrandService.getBrandsForCategory(
        token,
        dispatch,
        category_id
      );

      if (res.data.data) {
        dispatch(setCustomBrandList(res.data.data));
      }
    } catch (error) {
      logger("ERROR", error);
    }
    setLoading(false);
  };

  const addBrand = (data) => {
    dispatch(addBrandToList(data));
  };

  useEffect(() => {
    if (!initialized_brand_list && token) {
      fetchBrandList();
    }
  }, [initialized_brand_list, token]);

  useEffect(() => {
    if (filter && filter.category && token) {
      fetchCategoryBrands(filter.category);
    }
  }, [token, filter?.category]);

  const refresh = () => {
    if (token) {
      fetchBrandList();
    }
  };

  return {
    all: brand_list,
    custom: custom_brand_list,
    refresh,
    loading,
    addBrand,
  };
};
