const { axiosClient } = require("shared/axiosInstance");

class AssetService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async getAssetUrl(location) {
    return this.client.get(`/assets?location=${location}`);
  }
}

export default AssetService;
