import { useMessageCases } from "hooks/useMessageCases";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectMessageCasesBugTypes } from "redux-store/features/messageCasesSlice";
import styles from "./style.module.css";
import AutocompleteV2 from "components/AutocompleteV2";
import { useForm } from "shared/helpers";
import LoadIndicator from "components/LoadIndicator";
import ErrorsComponent from "components/Errors";

function CreateNewCase() {
  const navigate = useNavigate();
  const controller = useMessageCases();

  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);
  const form = useForm({
    type: "",
  });

  const handleChange = (name) => (e) => form.onChange(name, e.target.value);

  const bugTypes = useSelector(selectMessageCasesBugTypes);

  const selectedMessageType = useMemo(() => {
    if (!form.form.type || bugTypes.length === 0) return null;

    return (
      bugTypes.filter((type) => type.type_id === form.form.type)[0] || null
    );
  }, [form.form.type, bugTypes.length]);

  const disableSubmit = useMemo(
    () =>
      !selectedMessageType ||
      !form.form.message ||
      !form.form.title ||
      saving,
    [selectedMessageType?.type_id, form.form.title, saving, form.form.message]
  );

  const goBack = () => navigate(-1);
  const cleanUp = () => {
    form.reset();
    setErrors([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (disableSubmit) {
      return;
    }

    setErrors([]);
    setSaving(true);

    const { errors, newCase } = await controller.httpCreateCase(form.form);

    if (errors) setErrors(errors);
    else if (newCase)
      navigate(`/messages/case/${newCase.id}`, { replace: true });

    setSaving(false);
  };

  useEffect(() => {
    return cleanUp;
  }, []);

  useEffect(() => {
    if (bugTypes.length === 0) {
      controller.httpGetBugTypes();
    }
  }, [bugTypes.length]);

  return (
    <div id="main-container">
      <div className="container">
        <div className={`${styles.arrow} mb-4`} onClick={goBack}>
          <img src="/assets/images/ArrowLeft.png" alt="" />
          <span>Go Back</span>
        </div>

        <h2 className="mb-4">New Case</h2>

        <div className="row">
          <div className="col-12 col-xl-8">
            <form className={`${styles.form} mb-4`} onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <div>
                  <label>Type:</label>
                </div>

                <AutocompleteV2
                  suggestions={bugTypes}
                  labels={["name"]}
                  value={form.type}
                  setValue={(type) => form.onChange("type", type.type_id)}
                  sortKey="name"
                  placeholder="Please select case type"
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <div>
                  <label>Summary:</label>
                </div>

                <input
                  className="form-control"
                  type="text"
                  value={form.form.title || ""}
                  onChange={handleChange("title")}
                  placeholder=""
                  maxLength={40}
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <div>
                  <label>Guidance:</label>
                </div>

                <p>{selectedMessageType?.guidance || "Null"}</p>
              </div>

              <div className={styles.formGroup}>
                <textarea
                  rows="5"
                  className="form-control"
                  value={form.form.message}
                  onChange={handleChange("message")}
                />
              </div>

              {errors.length > 0 && <ErrorsComponent errors={errors} />}

              <div
                className={`d-flex flex-row justify-content-center align-items-center ${styles.actions}`}
              >
                <button
                  type="submit"
                  className="btn btn-yellow"
                  disabled={disableSubmit}
                >
                  {saving ? <LoadIndicator /> : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewCase;
