import configs from "configs";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppLoading } from "redux-store/features/appSlice";
import { selectToken } from "redux-store/features/authSlice";
import {
  populateReportList,
  selectReportData,
} from "redux-store/features/reportSlice";
import AssetService from "services/AssetService";
import ReportService from "services/ReportService";
import { APP_LOADING_TRIGGER } from "shared/constants";
import { logger } from "shared/helpers";

export const useReportController = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const service = new ReportService(token, dispatch);

  const assetsService = new AssetService(dispatch, token);

  const { initialized, data } = useSelector(selectReportData);

  const fetchReports = useCallback(async () => {
    try {
      dispatch(
        setAppLoading({
          info: "Hang on... Fetching your data",
          canClose: false,
          trigger: APP_LOADING_TRIGGER.BUY_SINGLE_PRODUCT_QUALITY,
        })
      );
      const res = await service.getUserReports();
      if (Array.isArray(res.data)) {
        dispatch(populateReportList(res.data));
      }
    } catch (error) {
      logger("[ERROR]", error);
    } finally {
      dispatch(setAppLoading(null));
    }
  }, [token, service]);

  const onClick = useCallback(async (data) => {
    logger(data);
    if (data.pdf) {
      try {
        dispatch(
          setAppLoading({
            info: "Fetching report document...",
          })
        );
        const res = await assetsService.getAssetUrl(data.pdf);
        if (typeof res.data.data === "string") {
          const url = res.data.data;
          const a = document.createElement("a");
          a.href = url;
          a.target = "_blank";
          a.download = data.pdf.split("/")[1];
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      } catch (error) {
        logger("ERROR DOWNLOADING PDF");
      } finally {
        dispatch(setAppLoading(null));
      }
    }
  }, []);

  useEffect(() => {
    if (token && !initialized) {
      fetchReports();
    }
  }, [token, initialized]);

  return { reports: data, trigger: fetchReports, onClick };
};
