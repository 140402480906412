import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
  aberrations: [],
};

export const aberrationManagement = createSlice({
  name: "aberration_management",
  initialState,
  reducers: {
    initializeAberrationList: (state, action) => {
      state.aberrations = action.payload;
      state.initialized = true;
    },

    addAberration: (state, action) => {
      state.aberrations = [action.payload, ...state.aberrations];
    },

    updateAberration: (state, action) => {
      state.aberrations = state.aberrations.map((item) => {
        if (item.aberration_id === action.payload.aberration_id) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },

    removeAberration: (state, action) => {
      state.aberrations = state.aberrations.filter((item) => {
        return item.aberration_id !== action.payload;
      });
    },
  },
});

export const {
  initializeAberrationList,
  addAberration,
  updateAberration,
  removeAberration,
} = aberrationManagement.actions;

export const selectAberrationManagementData = (state) => ({
  ...state.aberration_management,
});

export default aberrationManagement.reducer;
