import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  variables: null,
};

export const dbVariables = createSlice({
  name: "db_variables",
  initialState,
  reducers: {
    setDbVariables: (state, action) => {
      state.variables = action.payload;
    },

    updateDbVariable: (state, action) => {
      console.log("Update DB Variable", action.payload);
      state.variables = state.variables
        ? { ...state.variables, ...action.payload }
        : action.payload;
    },
  },
});

export const { setDbVariables, updateDbVariable } = dbVariables.actions;

export const selectDbVariablesData = (state) => ({
  ...state.db_variables,
});

export default dbVariables.reducer;
