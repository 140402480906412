import styles from "./styles.module.css";

const Hypnotic = () => {
  return (
    <div className={`${styles.hypnotic_loader} ${styles.loader_all}`}></div>
  );
};

export default {
  Hypnotic,
};
