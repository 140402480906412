const { axiosClient } = require("shared/axiosInstance");

class ServiceBrandComparisonService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async getReport(query) {
    return this.client.get(`/service-brand-comparison-report?${query}`);
  }

  async buyReport(ids, qc, category) {
    return this.client.post("/service-brand-comparison-report/buy-quality-report", { ids, qc, category })
  }

  async downloadReport(report_index) {
    return this.client.get(`/service-brand-comparison-report/download?report_index=${report_index}`);
  }
}

export default ServiceBrandComparisonService;
