import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, updateUserMobile } from "redux-store/features/authSlice";
import UserService from "services/UserService";
import { handleError } from "shared/helpers";
import ErrorsComponent from "components/Errors";
import LoadIndicator from "components/LoadIndicator";

const ConfirmVerifyPhoneModal = ({ isOpen, handleClose, number, message }) => {
  const [code, setCode] = useState("");
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(number);

    if (loading || !number) return;

    setLoading(true);
    setErrors([]);

    try {
      const res = await UserService.verifyChangeMobile(token, dispatch, {
        mobile: number,
        code,
      });

      if (res.data?.success) {
        dispatch(updateUserMobile(number));
      }

      handleClose();
    } catch (error) {
      handleError(error, setErrors);
    }

    setLoading(false);
  };

  useEffect(() => {
    return () => {
      setCode("");
      setErrors([]);
      setLoading(false);
    };
  }, []);

  return (
    <Modal
      show={isOpen}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header style={{ borderBottom: "none" }}>
        <Modal.Title>Confirm Phone Number</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form id="codeForm-ConfirmVerifyPhoneModal" onSubmit={handleSubmit}>
          <p>{message}</p>

          <input
            type="text"
            className="form-control"
            required
            value={code}
            onChange={(e) => setCode(e.target.value)}
            minLength={6}
          />

          {errors.length > 0 && (
            <div className="mt-4">
              <ErrorsComponent errors={errors} />
            </div>
          )}
        </form>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button
          type="submit"
          variant="primary"
          form="codeForm-ConfirmVerifyPhoneModal"
          disabled={loading}
        >
          {loading ? <LoadIndicator /> : "Submit"}
        </Button>

        <Button variant="secondary" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmVerifyPhoneModal;
