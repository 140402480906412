const HomeIcon = ({ color = "#fff" }) => {

    return (
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.3986 13.7752L16.0387 1.14624L15.2102 0.299293C15.0214 0.107591 14.7661 0 14.5001 0C14.234 0 13.9787 0.107591 13.7899 0.299293L0.601497 13.7752C0.408072 13.9722 0.255206 14.2068 0.15192 14.4651C0.0486344 14.7235 -0.00297684 15.0004 0.000132587 15.2795C0.0129276 16.4305 0.950161 17.3494 2.07612 17.3494H3.13559C3.30127 17.3494 3.43559 17.4837 3.43559 17.6494V27.7C3.43559 27.8657 3.5699 28 3.73559 28H12.7088C12.7088 28 13.8005 28 14.5001 28C15.1996 28 16.2914 28 16.2914 28H25.2645C25.4302 28 25.5645 27.8657 25.5645 27.7V17.6494C25.5645 17.4837 25.6988 17.3494 25.8645 17.3494H26.9528C27.4998 17.3494 28.0148 17.1303 28.4018 16.7346C28.5924 16.5404 28.7434 16.3095 28.8461 16.0552C28.9488 15.801 29.0011 15.5284 29 15.2533C29 14.6974 28.7857 14.1709 28.3986 13.7752ZM23.5614 14.995C23.3957 14.995 23.2614 15.1293 23.2614 15.295V25.3456C23.2614 25.5112 23.1271 25.6456 22.9614 25.6456H18.6386C18.4729 25.6456 18.3386 25.5112 18.3386 25.3456V18.1898C18.3386 17.4671 17.766 16.8818 17.0591 16.8818H11.9411C11.2341 16.8818 10.6616 17.4671 10.6616 18.1898V25.3456C10.6616 25.5112 10.5272 25.6456 10.3616 25.6456H6.03869C5.873 25.6456 5.73869 25.5112 5.73869 25.3456V15.295C5.73869 15.1293 5.60437 14.995 5.43869 14.995H3.38141C3.11568 14.995 2.98114 14.675 3.16704 14.4851L14.2888 3.1246C14.4065 3.00439 14.6 3.00443 14.7176 3.12468L15.2422 3.66092L25.8364 14.4851C26.0223 14.675 25.8877 14.995 25.622 14.995H23.5614Z" fill={color}/>
        </svg>
    );
}

export {
    HomeIcon
}