const views = {
  open_supporter: "open_supporter",
  open_request: "open_request",
  closed: "closed_case",
};

const processMailWithReplies = (mail) => {
  const replies = mail.replies?.sort((a, b) => a.time - b.time);

  if (!replies || replies.length === 0) return mail;

  mail.display = replies[replies.length - 1];

  return mail;
};

export { views, processMailWithReplies };
