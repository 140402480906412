import { createSlice } from "@reduxjs/toolkit";
import { AddUnitStep } from "shared/constants";

const initialState = {
  step: AddUnitStep.STEP_ONE,
  selectedBrandModel: null,
  selectedBrandModelAttribute: null,
  selectedUnit: null,
  form: {},
};

export const addUnitSliceV2 = createSlice({
  name: "addUnitV2",
  initialState,
  reducers: {
    resetStore: (state) => {
      state.step = initialState.step;
      state.selectedBrandModel = initialState.selectedBrandModel;
      state.selectedBrandModelAttribute =
        initialState.selectedBrandModelAttribute;
      state.selectedUnit = initialState.selectedUnit;
      state.form = initialState.form;
    },
    updateForm: (state, { payload }) => {
      state.form = { ...state.form, [payload.key]: payload.value };
    },
    clearForm: (state) => {
      state.form = {};
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    setSelectedBrandModel: (state, { payload }) => {
      state.selectedBrandModel = payload || null;
      state.selectedBrandModelAttribute = null;
    },
    setSelectedUnit: (state, { payload }) => {
      state.selectedUnit = payload || null;
    },
    setSelectedBrandModelAttribute: (state, { payload }) => {
      state.selectedBrandModelAttribute = payload || null;
    },
  },
});

export const {
  resetStore,
  updateForm,
  clearForm,
  setStep,
  setSelectedBrandModel,
  setSelectedUnit,
  setSelectedBrandModelAttribute,
} = addUnitSliceV2.actions;

export const selectAddUnitV2Data = (state) => ({
  ...state.addUnitV2,
});

export default addUnitSliceV2.reducer;
