import { Link } from "react-router-dom";
import { USER_TYPE } from "shared/constants";

export const Commercial = () => {
  return (
    <>
      <h2 className="title3 mb-3 mt-5">Professionals Needed!</h2>

      <p>Qualy Relies on Professionals to maintain its database.</p>

      <strong className="d-block mb-3 mt-5">
        There are 2 types of Commercial Sign up
      </strong>

      <ul className="infolist">
        <li>Professional Contractors</li>
        <li>Company Representatives</li>
      </ul>

      <strong className="d-block mb-3 mt-5">Professional Contractors</strong>

      <p className="mb-0 pb-0">
        If you have expert knowledge about a professional category and can
        verify customer database entries or make your own, then you can sign up
        using a Professional Contractor role. You can then chose categories or
        specific records to verify. This includes new Brands, companies, models,
        categories, and symptoms. When you verify records or assist users you
        get a royalty for every report that record appears in.
      </p>

      <a
        href="#"
        className="infolink"
        data-bs-toggle="modal"
        data-bs-target="#professionalContractorTermsModal"
      >
        Professional Contractor Terms and Conditions
      </a>

      <strong className="d-block mb-3 mt-5">Company representatives</strong>

      <p className="mb-0 pb-0">
        If you wish to help ensure the best accuracy about your companies
        products or your competitors we provide tools for you to do this. We
        want companies to participate and engage in quality improvment for the
        benefit of the environment and users, and will be developing advanced
        features with this in mind.
      </p>

      <a
        href="#"
        className="infolink"
        // data-bs-toggle="modal"
        // data-bs-target="#companyRepTermsModal"
      >
        Company rep Terms and Conditions
      </a>

      <p className="mt-5">
        Note that commercial users are expected to use desktop system rather
        than phones, so phone screen formats are less supported.
      </p>

      <p>
        Sign up for commercial use and choose your role after basic sign up:
      </p>

      <div className="d-flex justify-content-center mt-5">
        <a
          href="#"
          data-bs-target="#commercialSignUpModal"
          data-bs-toggle="modal"
        >
          <div className="btn btn-yellow">Sign Up as Commercial</div>
        </a>
      </div>

      <strong className="d-block mb-3 mt-5">
        Conflicts with other signups.
      </strong>

      <p>
        Note that the same person can have a private identity and a commercial
        identitty, however certain things like emails and phones cannot overlap.
        Multiple private indentities are specifically prevented. Your use of the
        site for your private identity should relate only to your private life,
        and likewise for your commercial life. You may have 2 identities as
        submitting users 1 for private use and one for commercial use.
      </p>

      <div className="modal fade" id="professionalContractorTermsModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                Professional Contractor Terms and Conditions (contract)
              </h4>

              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <p>
                A Professional Contractor (hereafter "the contractor") to Qualy
                Data ltd (Hereafter "Qualy") is entering a casual professional
                business agreement the amount of work being mainly at the
                discretion of the contractor. The contractor will provide
                services using their expertise to create or verifiy new database
                records in a specific cataegory chosen at the time of
                application. The Contractor may chose the records for which they
                work on.
              </p>

              <h2 className="title3 mb-3">Commitment</h2>

              <p>
                When the contractor applies and accepts conditions with Qualy
                they agree to make an ongoing commitment to a specific amount of
                work in terms of numbers of records they wish to commit to
                creating / verifying on a regular basis (monthly or weekly).
                <br />
                This commitment is non-binding either by Qualy or the contractor
                but the contractor will be assessed on how well they keep to
                this commitment figure, the system may change the commitment
                automatically if the contractors performance is different.
                <br />
                The contractor may change their commitment via the web interface
                as they decide or withdraw at any time, given reasonable notice.
                The system will indicate the minimum notice period, normally
                less than 2 weeks.
                <br />A contractor may have any number of commitments for
                different categories. The contractor must make a seperate
                application for each commitment.
              </p>

              <h2 className="title3 mb-3">Record Ownership and rewards</h2>

              <p>
                When creating verified records the contractor will retain
                temporary ownership of those records for a fixed number of
                records (currently 50) after which the contractors efforts will
                be considered paid. When reports are purchased by customers that
                use those records the contractor will be credited an amount
                depending on the market price for that type of record at the
                time. <br />
                The market price for the records are set by a supply and demand
                althorithim controlled by Qualy, the contractor can view this at
                any time and decide on their future commitment. The contractor
                will be paid in credits, which depending on Qualy profits, may
                be converted back to the currency of their resident country. The
                web interface will indicate if this is allowed.
              </p>

              <h2 className="title3 mb-3">Liabilities</h2>

              <p>
                The contractor retains no ongoing liability on the quality of
                their entries however Qualy reserves the right to cancel or ban
                a contractor at their discretion thereby removing the
                contractor's ownership of records <br />
                Qualy's aim is to improve the contents of its database and will
                operate a correction and performance review system where other
                contractors or Qualy could correct entries and take over
                ownership of specific record. The contractor agrees to abid by
                these judgements.
              </p>

              <p>
                This contract does not cover any illegal or unreasonable actions
                by Qualy or the contractor. Any disagreements will be settled in
                a UK court under UK law.
              </p>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-yellow"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="commercialSignUpModal">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <p>
                Thank you for your interest, but we are not currently signing up
                Commercial users please try again later
              </p>
            </div>

            <div className="modal-footer">
              <Link to={"/"}>
                <button
                  type="button"
                  className="btn btn-yellow"
                  data-bs-dismiss="modal"
                >
                  Ok
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
