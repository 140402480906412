const { axiosClient } = require("shared/axiosInstance");

class SingleBrandProductService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async getBrands(category) {
    return this.client.get(
      `/single-brand-product-report/brands?category=${category}`
    );
  }

  async getReport(query) {
    return this.client.get(`/single-brand-product-report?${query}`);
  }

  async buyReport(ids, category) {
    return this.client.post("/single-brand-product-report/buy-quality-report", {
      ids,
      category,
    });
  }

  async downloadReport(report_index) {
    return this.client.get(
      `/single-brand-product-report/download?report_index=${report_index}`
    );
  }
}

export default SingleBrandProductService;
