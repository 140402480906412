import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
}

export const unreadMessagesSlice = createSlice({
    name: 'unreadMessages',
    initialState,
    reducers: {
        setUnreadMesssages: (state, { payload }) => {
            state.data = payload;
        },
        markAsRead: (state, { payload }) => {
            state.data = state.data.filter(e => e.message_id != payload);
        }
    }
});

export const { setUnreadMesssages, markAsRead } = unreadMessagesSlice.actions;

export const selectUnreadMessages = (state) => state.unreadMessages.data

export default unreadMessagesSlice.reducer;