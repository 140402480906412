const ArrowDownIcon = ({ color = "#fff" }) => {

    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L8 17L15 10M8 17V1V17Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export {
    ArrowDownIcon
}