import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  selectSoftwareVersionManagementData,
  initializeSWVersionList,
} from "redux-store/features/softwareVersionManagement";
import SWVersionService from "services/SWVersionService";
import { logger } from "shared/helpers";

export const useSwVersions = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const service = new SWVersionService(dispatch, token);

  const { sw_versions, initialized } = useSelector(
    selectSoftwareVersionManagementData
  );

  const fetchVersionList = async () => {
    setLoading(true);

    try {
      const res = await service.fetchAll();

      if (res.data.data) {
        dispatch(initializeSWVersionList(res.data.data));
      }
    } catch (error) {
      logger("[ERROR]", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!initialized && token) {
      fetchVersionList();
    }
  }, [initialized, token]);

  const refresh = () => {
    if (token) {
      fetchVersionList();
    }
  };

  return { all: sw_versions, refresh, loading };
};
