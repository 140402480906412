import React from "react";
import { useTransition, animated } from "react-spring";

function AnimatedContaner({ show = false, children }) {
  const transition = useTransition(show, {
    from: { x: -100, y: 0, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1 },
    leave: { x: -100, y: 0, opacity: 0 },
  });

  return transition(
    (style, item) =>
      item && <animated.div style={style}>{children}</animated.div>
  );
}

export default AnimatedContaner;
