const { axiosClient } = require("shared/axiosInstance");

class PublicService {
  constructor() {}

  static getStoredTextById(id) {
    return axiosClient().get(`/stored-texts/${id}`);
  }
}

export default PublicService;
