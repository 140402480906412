import { useMemo } from "react";
import styles from "./styles.module.css";
import { Link, useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const NavItem = ({ Icon, location, relatedRoutes = [], badge, tooltip = "" }) => {
    const { pathname } = useLocation();

    const isActive = useMemo(() => {
        console.log(location, pathname, relatedRoutes);
        if (location && pathname) {
            return [...relatedRoutes, location].includes(pathname);
        }

        return false;
    }, [relatedRoutes, location, pathname]);

    const key = "nav-item-" + location.replace(/\//gi, "_");

    const renderTooltip = (props) => (
        <Tooltip {...props} id={`tooltip-${key}`}>
            <strong>
                {tooltip}
            </strong>
        </Tooltip>
    );

    return (
        <OverlayTrigger
            key={key}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <Link 
                className={`${styles.nav_item} ${isActive ? styles.active : ''} d-inline-flex flex-column align-items-center justify-content-center position-relative`} 
                to={location}
            >
                <span className="position-relative">
                    {typeof badge !== "undefined" && (
                        <span className={`${styles.badge} position-absolute`}>
                            {badge}
                        </span>
                    )}

                    <Icon color={isActive ? "#fc3" : "#fff"} />
                </span>

                <div></div>
            </Link>
        </OverlayTrigger>
    );
}

export default NavItem;