import { axiosClient } from "shared/axiosInstance";

class InviteUserService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  userCanInvite() {
    return axiosClient(this.dispatch, this.token).get("/invite/can-invite");
  }

  createUserInvite(data) {
    return axiosClient(this.dispatch, this.token).post("/invite", data);
  }

  getMyInvites() {
    return axiosClient(this.dispatch, this.token).get("/invite");
  }

  deleteUserInvite(id) {
    return axiosClient(this.dispatch, this.token).delete(
      `/invite?invite_id=${id}`
    );
  }

  resend(invite_id) {
    return axiosClient(this.dispatch, this.token).post("/invite/resend", {
      invite_id,
    });
  }

  rejectUserInvite(invite_id) {
    return axiosClient(this.dispatch, this.token).post("/auth/invite/reject", {
      invite_id,
    });
  }

  wasUserInvited() {
    return axiosClient(this.dispatch, this.token).get(
      "/invite/was-user-invited"
    );
  }

  fetchInviteDetails(code) {
    return axiosClient(this.dispatch, this.token).get(
      "/auth/invite?code=" + code
    );
  }

  fetchInviteDetailsV2(token) {
    return axiosClient(this.dispatch, this.token).get(
      "/invite/details?token=" + token
    )
  }
}

export default InviteUserService;
