const UserAdminIcon = ({ color = "#fff" }) => {

    return (
        <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1816 0C10.4833 0.045479 6.94965 1.48235 4.3343 4.00422C1.71894 6.52609 0.228806 9.93339 0.181641 13.4996C0.198929 15.577 0.713249 17.6226 1.68456 19.4771C2.65586 21.3315 4.05795 22.9448 5.78164 24.1912V24.2992H5.92164C8.2921 26.0508 11.1952 27 14.1816 27C17.1681 27 20.0712 26.0508 22.4416 24.2992H22.5816V24.1912C24.3053 22.9448 25.7074 21.3315 26.6787 19.4771C27.65 17.6226 28.1644 15.577 28.1816 13.4996C28.1345 9.93339 26.6443 6.52609 24.029 4.00422C21.4136 1.48235 17.88 0.045479 14.1816 0V0ZM8.67964 22.8548C8.88402 21.9522 9.40225 21.1442 10.1484 20.5647C10.8945 19.9853 11.8236 19.6692 12.7816 19.6689H15.5816C16.5397 19.6692 17.4688 19.9853 18.2149 20.5647C18.961 21.1442 19.4793 21.9522 19.6836 22.8548C18.0161 23.8008 16.1164 24.2997 14.1816 24.2997C12.2468 24.2997 10.3472 23.8008 8.67964 22.8548V22.8548ZM22.0356 21.1133C21.5031 19.8861 20.6064 18.838 19.4581 18.1007C18.3099 17.3633 16.9612 16.9696 15.5816 16.9689H12.7816C11.402 16.9696 10.0534 17.3633 8.90515 18.1007C7.75688 18.838 6.86016 19.8861 6.32764 21.1133C5.27857 20.1184 4.4431 18.9339 3.86898 17.6275C3.29486 16.3211 2.99333 14.9184 2.98164 13.4996C3.01795 10.6462 4.20961 7.91956 6.3022 5.90177C8.39478 3.88399 11.2225 2.73493 14.1816 2.69991C17.1408 2.73493 19.9685 3.88399 22.0611 5.90177C24.1537 7.91956 25.3453 10.6462 25.3816 13.4996C25.3699 14.9184 25.0684 16.3211 24.4943 17.6275C23.9202 18.9339 23.0847 20.1184 22.0356 21.1133V21.1133Z" fill={color}/>
            <path d="M14.1816 5.39792C13.4414 5.38129 12.7055 5.5096 12.0183 5.77508C11.3311 6.04056 10.7069 6.4377 10.1834 6.94247C9.65989 7.44724 9.24803 8.04916 8.97271 8.71181C8.69739 9.37446 8.56432 10.0841 8.58156 10.7977C8.56432 11.5114 8.69739 12.221 8.97271 12.8837C9.24803 13.5463 9.65989 14.1482 10.1834 14.653C10.7069 15.1578 11.3311 15.5549 12.0183 15.8204C12.7055 16.0859 13.4414 16.2142 14.1816 16.1976C14.9217 16.2142 15.6576 16.0859 16.3448 15.8204C17.032 15.5549 17.6563 15.1578 18.1798 14.653C18.7032 14.1482 19.1151 13.5463 19.3904 12.8837C19.6657 12.221 19.7988 11.5114 19.7816 10.7977C19.7988 10.0841 19.6657 9.37446 19.3904 8.71181C19.1151 8.04916 18.7032 7.44724 18.1798 6.94247C17.6563 6.4377 17.032 6.04056 16.3448 5.77508C15.6576 5.5096 14.9217 5.38129 14.1816 5.39792V5.39792ZM14.1816 13.4977C13.8091 13.515 13.437 13.4571 13.0891 13.3275C12.7412 13.198 12.4253 12.9997 12.1616 12.7455C11.898 12.4912 11.6923 12.1866 11.558 11.8511C11.4237 11.5157 11.3636 11.1569 11.3816 10.7977C11.3636 10.4386 11.4237 10.0798 11.558 9.74434C11.6923 9.40891 11.898 9.10428 12.1616 8.85002C12.4253 8.59576 12.7412 8.3975 13.0891 8.26796C13.437 8.13842 13.8091 8.08047 14.1816 8.09783C14.554 8.08047 14.9262 8.13842 15.274 8.26796C15.6219 8.3975 15.9378 8.59576 16.2015 8.85002C16.4652 9.10428 16.6708 9.40891 16.8051 9.74434C16.9395 10.0798 16.9996 10.4386 16.9816 10.7977C16.9996 11.1569 16.9395 11.5157 16.8051 11.8511C16.6708 12.1866 16.4652 12.4912 16.2015 12.7455C15.9378 12.9997 15.6219 13.198 15.274 13.3275C14.9262 13.4571 14.554 13.515 14.1816 13.4977Z" fill={color}/>
        </svg>
    );
}

export {
    UserAdminIcon
}