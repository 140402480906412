import { TableModelSelector } from "components/TableModelSelector";
import styles from "./styles.module.css";
import { forwardRef, useImperativeHandle, useRef } from "react";

const StepThree = forwardRef(({ brand, category, previousStep, onModelChange }, ref) => {
    const tableModelSelectorRef = useRef(null);

    const updateModelList = (model) => tableModelSelectorRef.current?.addModel(model);

    useImperativeHandle(ref, () => {
        return {
            updateModelList
        }
    }, []);

    return (
        <>
            <div className="mb-2">
                <b>Selected brand: </b>

                <span>{brand.brand}</span>
            </div>

            <button
                className={`mb-4 btn btn-green ${styles.category_back}`} 
                type="button"
                onClick={previousStep}
            >
                <i className="bi bi-arrow-left"></i>
            </button>

            <p className="mb-4">
                Find the Model or service below, use search to reduce the results
            </p>

            <TableModelSelector
                ref={tableModelSelectorRef}
                category={category}
                brand={brand}
                onModelChange={onModelChange}
            />
        </>
    )
});

export default StepThree;