import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  initializePastUseUnits,
  initializePendingUnits,
  initializeVerifiedUnits,
  selectUnitsData,
} from "redux-store/features/unitsSlice";
import UnitService from "services/UnitService";

export const useUnits = () => {
  const [loading, setLoading] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const {
    initialzedPending,
    pendingUnits,
    initialzedVerified,
    verifiedUnits,
    initialzedPastUse,
    pastUseUnits,
  } = useSelector(selectUnitsData);

  const fetchVerifiedUnits = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const res = await UnitService.getVerifiedUnits(token, dispatch);

      dispatch(initializeVerifiedUnits(res.data.data));
    } catch (error) {}
    setLoading(false);
  };

  const httpConfirmNoSymptoms = async () => {
    try {
      setConfirming(true);
      await UnitService.confirmNoSymptoms(token, dispatch);
    } catch (error) {
      console.log("[error]", error);
    } finally {
      setConfirming(false);
    }
  }

  const fetchPendingUnits = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const res = await UnitService.getPendingUnits(token, dispatch);
      dispatch(initializePendingUnits(res.data.data));
    } catch (error) {}
    setLoading(false);
  };

  const fetchPastUseUnits = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const res = await UnitService.getPastUseUnits(token, dispatch);
      console.log(res.data);
      dispatch(initializePastUseUnits(res.data.data));
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    if (!initialzedVerified) fetchVerifiedUnits();
  }, [initialzedVerified]);

  useEffect(() => {
    if (!initialzedPending) fetchPendingUnits();
  }, [initialzedPending]);

  useEffect(() => {
    if (!initialzedPastUse) fetchPastUseUnits();
  }, [initialzedPastUse]);

  const refresh = async () => {
    try {
      if (loading) return;

      await Promise.all([
        fetchPastUseUnits(),
        fetchPendingUnits(),
        fetchVerifiedUnits(),
      ]);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    verified: verifiedUnits,
    pending: pendingUnits,
    past_use: pastUseUnits,
    loading,
    confirming,
    refresh,
    httpConfirmNoSymptoms
  };
};
