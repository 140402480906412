import React, { useEffect, useMemo, useState } from "react";
import Header from "components/header";
import { COUNTRIES } from "shared/constants";
import AuthenticationService from "services/AuthenticationService";
import DescModal from "../../../components/DescModal";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { login, selectToken } from "redux-store/features/authSlice";
import { useNavigate } from "react-router-dom";
import Autocomplete from "components/Autocomplete";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import { logger } from "shared/helpers";
import { setFirst } from "redux-store/features/submittingRoleDetails";
import InviteUserService from "services/InviteUserService";
import LoadIndicator from "components/LoadIndicator";
import AccountExistModal from "./AccountExistModal";
import WarningModal from "components/WarningModal";

const Signup = () => {
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [err, set_err] = useState([]);
  const [success, set_success] = useState(null);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isInvite, setIsInvite] = React.useState(false);
  const [fetchingInviteDetails, setFetchingInviteDetails] = useState(false);
  const [inviteDetails, setInviteDetails] = useState(null);

  //
  const [form, set_form] = useState({
    language: "English",
    accessability: "Normal",
    user_type: "personal",
    isfirstaccount: true,
    first_name: "",
    last_name: "",
    middle_name: "",
    postcode: "",
    road_name: "",
    houseno: "",
    city: "",
    state: "",
    country: "United Kingdom",
    day: "",
    month: "",
    year: "",
    gender: "Male",
    mobile: "",
    email: "",
    password: "",
    password_confirm: "",
    roles: [],
  });

  const onChange = (name, value) => {
    if (name === "roles") {
      if (form.roles.includes(value))
        set_form((prev) => ({
          ...prev,
          roles: prev.roles.filter((i) => i !== value),
        }));
      else set_form((prev) => ({ ...prev, roles: [...prev.roles, value] }));
    } else set_form((prev) => ({ ...prev, [name]: value }));
  };

  const inviteService = new InviteUserService(token, dispatch);

  const [existModalOpen, setExistModalOpen] = useState(false);
  const [showNoInviteModal, setShowNoInviteModal] = useState(false);

  const fetchInviteDetails = async (code) => {
    setFetchingInviteDetails(true);
    try {
      const res = await inviteService.fetchInviteDetails(code);

      if (!res.data.data) {
        setShowNoInviteModal(true);
      } else if (
        res.data.data?.status?.toLowerCase() === "rejected"
      ) {
        navigate("/auth/signup");
      } else if (res.data.data.user_id !== null) {
        setExistModalOpen(true);
      } else setInviteDetails(res.data.data);
    } catch (e) {
      logger("Error fetch invite details", e);

      navigate("/auth/signup");
    }

    setFetchingInviteDetails(false);
  };

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("c");
    
    setIsInvite(Boolean(code));

    if (code) {
      fetchInviteDetails(code);
    }
  }, []);

  useEffect(() => {
    if (form.country) {
      const country = COUNTRIES.filter(
        (country) => country.name === form.country
      )[0];

      if (country) onChange("countryCode", country.code2);
    }
  }, [form.country]);

  const getPlaceDetails = async () => {
    if (address_suggestions.length === 0) return;

    const selected = address_suggestions.filter(
      (e) => e.description === form.address
    )[0];

    if (!selected) return;

    if (!placeDetailsService) return;

    try {
      const request = {
        placeId: selected.place_id,
        fields: ["address_component"],
      };

      function callback(place, status) {
        if (status == window.google.maps.places.PlacesServiceStatus.OK) {
          console.log("PLACE", place);
          if (place.address_components) {
            for (const component of place.address_components) {
              if (component.types?.includes("administrative_area_level_2"))
                onChange("state", component.long_name);
            }
          }
        }
      }

      placeDetailsService.getDetails(request, callback);
    } catch (e) {
      console.log(e, e.response);
    }
  };

  /**
   * Submit data
   *
   * @param {*} event
   * @returns void
   */
  const handleSubmit = async (
    event,
    showOptions,
    loginUser = true,
    no = false
  ) => {
    event?.preventDefault();

    set_err([]);

    if (form.roles.length === 0 && form.phone_verified && showOptions && !no)
      return set_err(["Please select role"]);

    set_success(null);
    setFormSubmitting(true);

    try {
      const payload = {};
      payload.email = form.email;
      payload.roles = form.roles;
      // payload.mobile = form.phone;
      payload.password = form.password;

      const res = await AuthenticationService.complete_signup(payload);

      // set_success(res.data.message);

      if (loginUser) {
        dispatch(login({ user: res.data.user, token: res.data.token }));
        dispatch(setFirst(true));
      }
    } catch (error) {
      const msg = error.response?.data?.message;
      if (msg) {
        if (msg instanceof String || typeof msg === "string") set_err([msg]);
        else {
          const arr = [];
          Object.keys(msg).map((key) => {
            for (const emsg of msg[key]) arr.push(emsg);
          });

          set_err(arr);
        }
      } else {
        set_err(["Something went wrong, check your internet"]);
      }
    }

    setFormSubmitting(false);
  };

  /**
   * Step Handler
   */
  const [step, set_step] = useState(0);

  const goToStepFive = (e, phoneVerified) => {
    onChange("phone_verified", phoneVerified);
    moveToNextStep(e);
  };

  const moveToNextStep = (e) => {
    e?.preventDefault();
    if (step < stepComponents.length - 1) set_step(step + 1);
  };
  const moveToPrevious = (e) => {
    e.preventDefault();
    if (step > 0) set_step(step - 1);
  };

  const [open_dets, set_open_dets] = useState(false);

  const [text, set_text] = useState("");

  const closeDesc = () => {
    set_open_dets(false);
    set_text("");
  };

  const openDesc = (text) => {
    set_text(text);
    set_open_dets(true);
  };

  const stepComponents = [
    <StepOne
      next={moveToNextStep}
      onChange={onChange}
      data={{
        language: form.language,
        accessability: form.accessability,
        accounttype: form.accounttype,
        isfirstaccount: form.isfirstaccount,
        first_name: form.first_name,
        last_name: form.last_name,
        middle_name: form.middle_name,
        country: form.country,
      }}
      isInvite={isInvite}
      inviteDetails={inviteDetails}
    />,
    <StepTwo
      next={moveToNextStep}
      previous={moveToPrevious}
      data={{
        postcode: form.postcode,
        streetname: form.streetname,
        city: form.city,
        houseno: form.houseno,
        state: form.state,
        country: form.country,
      }}
      openDesc={openDesc}
      getPlaceDetails={getPlaceDetails}
      onChange={onChange}
      isInvite={isInvite}
      inviteDetails={inviteDetails}
    />,
    <StepThree
      next={moveToNextStep}
      previous={moveToPrevious}
      data={{
        day: form.day,
        month: form.month,
        year: form.year,
        gender: form.gender,
      }}
      onChange={onChange}
      isInvite={isInvite}
      inviteDetails={inviteDetails}
    />,
    <StepFour
      next={goToStepFive}
      previous={moveToPrevious}
      onChange={onChange}
      form={form}
      isInvite={isInvite}
      inviteDetails={inviteDetails}
    />,
    <StepFive
      onSubmit={handleSubmit}
      roles={form.roles}
      onChange={onChange}
      loading={formSubmitting}
      err={err}
      success={success}
      form={form}
      isInvite={isInvite}
      inviteDetails={inviteDetails}
    />,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  if (fetchingInviteDetails) return <LoadIndicator />;

  return (
    <>
      <AccountExistModal
        show={existModalOpen}
        handleClose={() => setExistModalOpen(false)}
      />

      <DescModal show={open_dets} handleClose={closeDesc} text={text} />

      <WarningModal
        show={showNoInviteModal}
        text={'Sorry, this invite is no longer valid, you may sign up as a basic user'}
        handleOk={() => {
          setShowNoInviteModal(false);
          navigate("/");
        }}
        handleClose={() => {}}
        hideCancel
      />

      <div className="registerPage">
        {step !== 5 ? (
          <section className="mt-5 mb-5">
            <div className="container">
              <h1 className="title1 text-center centerlabel">{`Signup Step ${
                step + 1
              }/${stepComponents.length}`}</h1>
              {stepComponents[step]}
            </div>

            <div hidden id="map" />
          </section>
        ) : (
          stepComponents[5]
        )}

        {success && <div className="success_message">{success}</div>}
      </div>
    </>
  );
};

const StepOne = ({ next, data, onChange }) => {
  const navigate = useNavigate();

  const nextOrLogin = (e) => {
    e.preventDefault();

    if (data.isfirstaccount) next(e);
    else navigate("/auth/signin?from=sgup");
  };

  return (
    <form className="registerForm" onSubmit={nextOrLogin}>
      <div className="row">
        <div className="col-md-12 mb-2">
          <h3 className="title3">
            Please enter your preferred Language, country and accessibility
            requirements
          </h3>
        </div>

        <div className="col-md-6 mb-4">
          <label className="form-label">
            Language <em className="required">*</em>
          </label>
          <select
            className="form-select"
            value={data.language}
            onChange={(e) => onChange("language", e.target.value)}
          >
            {["English"].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6 mb-4">
          <label className="form-label" htmlFor="country">
            Country <em className="required">*</em>
          </label>
          <select
            id="country"
            className="form-select"
            value={data.country}
            onChange={(e) => onChange("country", e.target.value)}
            required
          >
            {COUNTRIES.map((item) => (
              <option key={`country ${Math.random()}`} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>


      <div className="row">
        <div className="col-md-12 mt-3 mb-2">
          <h3 className="title3">Please enter your names</h3>
        </div>

        <div className="col-md-4 mb-4">
          <label className="form-label" htmlFor="firstName">
            First Name <em className="required">*</em>
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            placeholder="First Name"
            name="name"
            maxLength={20}
            value={data.first_name}
            onChange={(e) => onChange("first_name", e.target.value)}
            required={data.isfirstaccount}
          />
        </div>

        <div className="col-md-4 mb-4">
          <label className="form-label" htmlFor="mName">
            Middle Name
          </label>
          <input
            type="text"
            className="form-control"
            id="mName"
            placeholder="Middle Name"
            maxLength={20}
            name="name"
            value={data.middle_name}
            onChange={(e) => onChange("middle_name", e.target.value)}
          />
        </div>

        <div className="col-md-4 mb-4">
          <label className="form-label" htmlFor="lastName">
            Last Name <em className="required">*</em>
          </label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            placeholder="Last Name"
            name="name"
            maxLength={20}
            value={data.last_name}
            onChange={(e) => onChange("last_name", e.target.value)}
            required={data.isfirstaccount}
          />
        </div>

        <div className="col-md-12 mt-3 mb-2">
          <h3 className="title3">This is a private account for personal use only.</h3>
        </div>
      </div>

      <div className="mt-4 text-center">
        {/*<a href="register3.html" className="btn btn-black me-2">Back</a>*/}
        <button type="submit" className="btn btn-yellow">
          Next
        </button>
      </div>

      {/* <div>{formMessage.message}</div> */}
    </form>
  );
};

// Region
const StepTwo = ({ next, previous, data, onChange, openDesc }) => {
  const openDescription = (text) => {
    return (e) => openDesc(text);
  };

  const states = useMemo(() => {
    if (!data.country) return [];

    return (
      COUNTRIES[
        COUNTRIES.findIndex(
          (item) => item.name.toLowerCase() == data.country?.toLowerCase()
        )
      ].states?.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }) || []
    );
  }, [data.country]);

  return (
    <form className="registerForm" onSubmit={next}>
      <div className="row">
        <div className="col-md-12 mt-3 mb-2">
          <h3 className="title3">Please enter your address.</h3>
        </div>

        <div className="col-md-6 mb-4">
          <label className="form-label" htmlFor="propertynumber">
            House/dwelling Number <em className="required">*</em>
          </label>
          <input
            type="text"
            className="form-control"
            id="propertynumber"
            placeholder="6C"
            name="name"
            maxLength={30}
            value={data.property_number}
            onChange={(e) => onChange("property_number", e.target.value)}
            required
          />
        </div>

        <div className="col-md-6 mb-4">
          <label className="form-label" htmlFor="road_name">
            Street Name <em className="required">*</em>
          </label>
          <input
            type="text"
            className="form-control"
            id="road_name"
            value={data.road_name}
            onChange={(e) => onChange("road_name", e.target.value)}
            placeholder="Street Name"
            name="name"
            maxLength="30"
            required
          />
        </div>

        <div className="col-md-6 mb-4">
          <label className="form-label" htmlFor="towncity">
            Town/City <em className="required">*</em>
          </label>
          <input
            type="text"
            className="form-control"
            id="towncity"
            placeholder="Town/City"
            value={data.city}
            onChange={(e) => onChange("city", e.target.value)}
            name="name"
            required
            maxLength="30"
          />
        </div>

        <div className="col-md-6 mb-4">
          <label className="form-label">County/State/District</label>

          <Autocomplete
            suggestions={states}
            input={data.state}
            setInput={(input) => onChange("state", input)}
            setValue={(value) => {}}
            placeholder="Please select state"
            labelKey={"name"}
            primaryKey="code"
            required={false}
          />
        </div>

        <div className="col-md-6 mb-4">
          <label className="form-label">
            Postal Reference
            <i
              className="bi bi-info-circle"
              onClick={openDescription("Zip code")}
            ></i>
          </label>
          <input
            type="text"
            className="form-control"
            id="postcode"
            value={data.postcode}
            onChange={(e) => onChange("postcode", e.target.value)}
            placeholder="Post Code"
            name="name"
            maxLength="10"
          />
        </div>
      </div>

      <div className="mt-4 text-center">
        <div onClick={previous} className="btn btn-black me-2">
          Back
        </div>
        <button type="submit" className="btn btn-yellow">
          Next
        </button>
      </div>
    </form>
  );
};

// Age
const StepThree = ({ next, previous, data, onChange }) => {
  function getAge(birthDate) {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const [err, set_err] = useState(null);

  const validateBeforeNext = (e) => {
    e.preventDefault();

    set_err(null);

    const birthDate = new Date();
    birthDate.setMonth(data.month);
    birthDate.setDate(data.day);
    birthDate.setFullYear(data.year);

    const age = getAge(birthDate);

    if (age < 16) return set_err("Minimum age is 16");

    next(e);
  };

  return (
    <form className="registerForm" onSubmit={validateBeforeNext}>
      <div className="row">
        <div className="col-md-12 mt-3 mb-2">
          <h3 className="title3">Please enter you date of Birth</h3>
        </div>
        <div className="col-md-4 mb-4">
          <label className="form-label" htmlFor="day">
            Day <em className="required">*</em>
          </label>
          <input
            type="number"
            className="form-control"
            id="day"
            placeholder="Day"
            min={1}
            max={31}
            name="name"
            value={data.day}
            onChange={(e) => {
              if (e.target.value.length <= 2) onChange("day", e.target.value);
            }}
            required
          />
        </div>
        <div className="col-md-4 mb-4">
          <label className="form-label" htmlFor="month">
            Month <em className="required">*</em>
          </label>
          <input
            type="number"
            className="form-control"
            id="month"
            placeholder="Month"
            min={1}
            max={12}
            name="name"
            value={data.month}
            onChange={(e) => {
              if (e.target.value.length <= 2) onChange("month", e.target.value);
            }}
            required
          />
        </div>
        <div className="col-md-4 mb-4">
          <label className="form-label" htmlFor="year">
            Year <em className="required">*</em>
          </label>
          <input
            type="number"
            className="form-control"
            id="year"
            placeholder="Year"
            min={1850}
            max={2999}
            name="name"
            value={data.year}
            onChange={(e) => {
              if (e.target.value.length <= 4) onChange("year", e.target.value);
            }}
            required
          />
        </div>

        <div className="col-md-6 mb-4">
          <label className="form-label">
            Gender <em className="required">*</em>
          </label>
          <select
            className="form-select"
            value={data.gender}
            onChange={(e) => onChange("gender", e.target.value)}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>

      <div className="mt-4 text-center">
        <div onClick={previous} className="btn btn-black me-2">
          Back
        </div>
        <button type="submit" className="btn btn-yellow">
          Next
        </button>
      </div>

      {err && <div className="error_message">{err}</div>}
    </form>
  );
};

export default Signup;
