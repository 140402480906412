import React, { useState } from "react";
import { StepFour, StepOne, StepThree, StepTwo } from "./Steps";
import styles from "./styles.module.css";
import { handleErrorV2, useDescriptionModal } from "shared/helpers";
import DescModal from "components/DescModal";
import ModelService from "services/ModelService";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { WARNING_TYPE } from "./constants";
import WarningModal from "components/WarningModal";
import ExistingModelModal from "components/ExistingModelModal";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const AddModelStep = {
  ONE: 'ONE',
  TWO: 'TWO',
  THREE: 'THREE',
  FOUR: 'FOUR'
}

const DEFAULT_DATA_STATE = {
  modelType: null,
  item_class: null,
  personalized: null,
  model_name_des: null,
  model_code: null,
  generic_model: null,
  serial_type: "None"
};

function AddModel({ brand, category, closeModal }) {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const [step, setStep] = useState(AddModelStep.ONE);
  const [data, setData] = useState(DEFAULT_DATA_STATE);

  const [isExistingModelOpen, setIsExistingModelOpen] = useState(false);
  const [warning, setWarning] = useState("");
  const [warningType, setWarningType] = useState(WARNING_TYPE.normal);
  const openWarningModal = (msgData, type) => {
    setWarningType(type);
    setWarning(msgData);
  };

  const {
    showDescriptionModal,
    description,
    closeDescriptionModal,
    openDescriptionModal,
  } = useDescriptionModal();

  const handleChange = (key) => (value) => {
    setData(prev => ({
      ...prev,
      [key]: value
    }));
  }

  const onSelectModelType = (type) => {
    handleChange('modelType')(type);
    setStep(AddModelStep.TWO);
  }

  const onSelectItemClass = (item_class) => {
    handleChange("item_class")(item_class);
    setStep(AddModelStep.THREE);
  }

  const onSelectPersonalizedMode = (personalized) => {
    handleChange("personalized")(personalized);
    setStep(AddModelStep.FOUR);
  }

  const handleUseExisting = (data) => {
    setWarning("");
    closeModal(data);
  };

  const submitModelForm = async () => {
    try {
      if (!data.model_name_des || !data.item_class || typeof data.generic_model !== "number" || !data.serial_type || typeof data.personalized !== "boolean") {
        return;
      };

      const payload = {
        ...data,
        generic_model: Boolean(data.generic_model),
        brand: brand.brand_id,
        category: category.category_id
      };

      const res = await ModelService.addModelToDb(token, dispatch, payload);

      if (res.status === 201)
        closeModal(res.data.data);
    } catch (error) {
      const errorObj = handleErrorV2(error);
      if (errorObj.code && errorObj.code === "REQUIRES_ACTION_USE_EXISTING") {
        openWarningModal(
          {
            text: errorObj.message,
            ok: () => handleUseExisting(errorObj.data),
            okText: "Use Existing",
          },
          WARNING_TYPE.custom
        );
      }
    }
  }

  const openExistingModelModal = () => {
    setIsExistingModelOpen(true);
  };

  return (
    <RequireSubmittingUser>
      <ExistingModelModal 
        categoryId={category.category_id}
        show={isExistingModelOpen}
        handleClose={() => setIsExistingModelOpen(false)}
        onChange={handleChange}
      />

      <WarningModal
        show={Boolean(warning)}
        text={warning}
        handleOk={() => setWarning("")}
        handleClose={() => setWarning("")}
        hideCancel={warningType === WARNING_TYPE.normal}
      />

      <DescModal
        show={showDescriptionModal}
        handleClose={closeDescriptionModal}
        text={description}
      />

      <div id="main-container" className={styles.wrapper}>
        <div className="container">
          <h1 className="page-title mb-4">Add New Model</h1>

          {step === AddModelStep.ONE && (
            <StepOne 
              onSelectModelType={onSelectModelType}
              openExistingModelModal={openExistingModelModal}
            />
          )}

          {step === AddModelStep.TWO && (
            <StepTwo 
              modelType={data.modelType}
              onSelectModelType={onSelectItemClass}
            />
          )}

          {step === AddModelStep.THREE && (
            <StepThree 
              onSelectPersonalizedMode={onSelectPersonalizedMode}
            />
          )}

          {step === AddModelStep.FOUR && (
            <StepFour 
              data={data}
              handleChange={handleChange}
              openDescriptionModal={openDescriptionModal}
              submitModelForm={submitModelForm}
              openExistingModelModal={openExistingModelModal}
            />
          )}
        </div>
      </div>
    </RequireSubmittingUser>
  );
}

export default AddModel;
