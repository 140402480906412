import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAppLoadingState,
  setAppLoading,
} from "redux-store/features/appSlice";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import SingleBrandProductService from "services/SingleBrandProductServices";
import { APP_LOADING_TRIGGER } from "shared/constants";
import { handleErrorV2, logger, parseQueryString } from "shared/helpers";
import { useMultiSelect } from "shared/useMultiSelect";
import { socket } from "socket";

const DEFAULT_SCREEN_STATE = {
  bidPrice: "No filter",
  bidPriceVal: 0,
  date_seen_from: null,
  date_seen_to: null,
  date_of_production_from: null,
  date_of_production_to: null,
  evidence_available: "No filter",
  country: "No filter",
  openInsufficientFunds: false,
  openPurchaseConfirmation: false,
  reportDownloadResponse: null,
};

const lastday = function (y, m) {
  return new Date(y, m + 1, 0).getDate();
};

export const useSingleBrandProductController = () => {
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const loadingState = useSelector(selectAppLoadingState);
  const dispatch = useDispatch();

  const service = new SingleBrandProductService(dispatch, token);

  const [fetching, setFetching] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [report, setReport] = useState([]);
  const [price, setPrice] = useState(0);
  const [disableBrandSearch, setDisableBrandSearch] = useState(true);

  const [queryData, setQueryData] = useState({
    category: null,
    brand: null,
    modelTexts: [],
    modelTextOperator: "AND",
  });

  const [screenState, setScreenState] = useState(DEFAULT_SCREEN_STATE);

  useEffect(() => {
    function onTrigger(message) {
      if (message.user === user?.user_id) {
        handleScreenStateChange("reportDownloadResponse", message);
        if (
          loadingState &&
          loadingState.trigger ===
            APP_LOADING_TRIGGER.BUY_SINGLE_PRODUCT_QUALITY
        ) {
          dispatch(setAppLoading(null));
        }
      }
    }

    socket.on("single_product_report_download_ready", onTrigger);

    return () => {
      socket.off("single_product_report_download_ready", onTrigger);
    };
  }, [socket.connected, user?.user_id, loadingState]);

  const handleScreenStateChange = (key, val) => {
    setScreenState((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const handleQueryDataChange = (key, val) => {
    setQueryData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const handleCategorySelect = (val) => {
    handleQueryDataChange("category", val);
  };

  const getFromDate = (input) => {
    if (!input) {
      return undefined;
    }

    const date = new Date(input);
    date.setDate(1);
    return date;
  };

  const getToDate = (input) => {
    if (!input) {
      return undefined;
    }
    const date = new Date(input);
    const lastDay = lastday(date.getFullYear(), date.getMonth());
    date.setDate(lastDay);
    return date;
  };

  const categorySelect = useMultiSelect({
    value: queryData.category?.category_id || "",
    setValue: handleCategorySelect,
  });

  const httpFetchBrands = useCallback(async () => {
    if (!queryData.category || !queryData.category.category_id) {
      return;
    }

    try {
      const res = await service.getBrands(queryData.category.category_id);

      if (res.data && Array.isArray(res.data)) {
        setBrands(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [queryData.category?.category_id]);

  const httpFetchReport = async () => {
    if (!queryData.brand || !queryData.category || fetching) {
      return;
    }

    const data = {
      category: queryData.category.category_id,
      brand: queryData.brand.brand_id,
      country:
        screenState.country == "No filter" ? undefined : screenState.country,
      bid_price:
        screenState.bidPrice == "No filter"
          ? undefined
          : screenState.bidPriceVal,
      evidence:
        screenState.evidence_available == "No filter"
          ? undefined
          : screenState.evidence_available == "Yes"
          ? 1
          : 0,
      date_seen_to: getToDate(screenState.date_seen_to),
      date_seen_from: getFromDate(screenState.date_seen_from),
      date_of_production_from: getFromDate(screenState.date_of_production_from),
      date_of_production_to: getToDate(screenState.date_of_production_to),
    };

    if (queryData.modelTexts.length > 0) {
      data.model_texts = queryData.modelTexts.join(",");
      data.model_texts_op = queryData.modelTextOperator;
    }

    setFetching(true);

    try {
      const res = await service.getReport(parseQueryString(data));

      if (res.data.data && Array.isArray(res.data.data)) {
        setReport(res.data.data);
      }

      if (!isNaN(res.data.price)) {
        setPrice(res.data.price);
      }
    } catch (e) {
      logger("[ERROR]", e);
    }
    setFetching(false);
  };

  const httpBuyQuality = useCallback(async () => {
    const qualityIds = report.map((e) => e.quality_id);

    if (qualityIds.length === 0 || !queryData.category) return;

    try {
      await service.buyReport(qualityIds, queryData.category.category_id);
      dispatch(
        setAppLoading({
          info: "Hang on... Qualy is building your report",
          canClose: true,
          trigger: APP_LOADING_TRIGGER.BUY_SINGLE_PRODUCT_QUALITY,
        })
      );
    } catch (error) {
      logger("[ERROR]", error);
      const parsedError = handleErrorV2(error);
      if (parsedError.code === "InsufficientFunds") {
        handleScreenStateChange("openPurchaseConfirmation", false);
        handleScreenStateChange("openInsufficientFunds", true);
      }
    }
  }, [report]);

  const httpDownloadReport = useCallback(
    async (report_id) => {
      setDownloading(true);
      try {
        const { data } = await service.downloadReport(report_id);

        if (data.url) {
          window.open(data.url, "_blank");
        }
        return true;
      } catch (error) {
        console.log(error);
      } finally {
        setDownloading(false);
      }
    },
    [downloading]
  );

  useEffect(() => {
    setDisableBrandSearch(
      !(queryData.category && Boolean(queryData.category.end))
    );
    httpFetchBrands();
  }, [queryData.category?.category_id]);

  useEffect(() => {
    const getData = setTimeout(httpFetchReport, 1000);
    return () => clearTimeout(getData);
  }, [
    queryData.category?.category_id,
    queryData.brand?.brand_id,
    queryData.modelTexts,
    queryData.modelTextOperator,
    screenState.bidPrice,
    screenState.bidPriceVal,
    screenState.country,
    screenState.date_of_production_from,
    screenState.date_of_production_to,
    screenState.date_seen_from,
    screenState.date_seen_to,
    screenState.evidence_available,
  ]);

  const reset = () => {
    setDisableBrandSearch(true);
    setReport([]);
    setBrands([]);
    setModels([]);
    setScreenState(DEFAULT_SCREEN_STATE);
    dispatch(setAppLoading(null));
  };

  return {
    models,
    brands,
    queryData,
    disableBrandSearch,
    categorySelect,
    report,
    fetching,
    downloading,
    screenState,
    price,
    reset,
    handleCategorySelect,
    handleQueryDataChange,
    httpBuyQuality,
    httpDownloadReport,
    handleScreenStateChange,
    getToDate,
    getFromDate,
    httpFetchReport,
  };
};
