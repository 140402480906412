import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import CategoryService from "services/CategoryService";
import { handleError, useForm } from "shared/helpers";
import DescModal from "components/DescModal";
import ErrorsComponent from "components/Errors";
import LoadIndicator from "components/LoadIndicator";
import styles from "./styles.module.css";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

function AddCategory({ closeModal, parent }) {

  // Description Modal Props
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const openDescriptionModal = (msg) => {
    setDescription(msg);
    setShowDescriptionModal(true);
  };

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const { form, onChange, reset } = useForm();

  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);

  const saveCategoryToDatabase = async (e) => {
    e.preventDefault();

    if (!form.name || !form.description || !form.category) {
      return setErrors(["Please fill all fields"]);
    }

    setSaving(true);
    setErrors([]);

    try {
      const res = await CategoryService.addCategory(token, dispatch, {
        ...form,
        parent_category: form.category,
      });

      closeModal(res.data.data);
    } catch (error) {
      handleError(error, setErrors);
    }

    setSaving(false);
  };

  useEffect(() => {
    if (Boolean(parent?.category_id)) {
      onChange("category", parent.category_id);
    }
  }, [parent?.category_id]);

  useEffect(() => {
    return () => {
      setSaving(false);
      setErrors([]);
      reset();
      setDescription("");
      setShowDescriptionModal(false);
    };
  }, []);

  return (
    <RequireSubmittingUser>
      <DescModal
        show={showDescriptionModal}
        handleClose={() => setShowDescriptionModal(false)}
        text={description}
      />

      <div id="main-container">
        <div className="container">
          <h1 className="page-title mb-4">Add New Category</h1>

          <form className="" onSubmit={saveCategoryToDatabase}>
            <div className="registerForm ms-0 me-0">
              <ErrorsComponent errors={errors} />

              <h5 className="mb-4">Parent category: <span className={styles.category_parent_name}>{parent.name}</span></h5>

              <h5 className="mb-4">New End Category Details</h5>

              <div className="mb-4">
                <label className="form-label">
                  Category Name{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        "Only add a new category if existing categories are unsuitable"
                      )
                    }
                  ></i>{" "}
                  <em className="required">*</em>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.name || ""}
                  onChange={(e) => onChange("name", e.target.value)}
                  id="cat-name"
                  placeholder="Category Name"
                  name="cname"
                  required
                  maxLength="50"
                />
              </div>
              <div className="mb-4">
                <label className="form-label">
                  Category Description{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        "Include a longer description especially if industry or marketing terms are used that are not obvious to their meaning"
                      )
                    }
                  ></i>{" "}
                  <em className="required">*</em>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cat-description"
                  value={form.description || ""}
                  onChange={(e) => onChange("description", e.target.value)}
                  placeholder="Category Description"
                  name="cdescription"
                  required
                  maxLength="150"
                />
              </div>
              <div className="mt-4 text-center">
                <button
                  type="button"
                  className="btn btn-black me-2"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-yellow">
                  {saving ? <LoadIndicator /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </RequireSubmittingUser>
  );
}

export default AddCategory;
