import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadIndicator from "components/LoadIndicator";
import DataTable from "../data-table";
import { unit_types } from "./helper";
import useWindowWidth from "hooks/useWindowWidth";
import { addLeadingZeros } from "shared/helpers";

const defaultUnitColumns = [
  {
    text: "QUN",
    key: "unit_id",
    padded: true,
  },
  {
    text: "Item",
    data: (item) => `${item.brand} - ${item.model_name_des} - ${item.model_code}`,
    sortable: true
  },
  {
    text: "Defect",
    add: true,
    disabled: false,
    onClick: () => {},
  },
];

function Verified({ verified, loading }) {
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  const unitColumns = useMemo(() => {
    if (windowWidth <= 768) {
      const columns = [...defaultUnitColumns];
      columns[0] = { ...defaultUnitColumns[0], key: undefined, data: (item) => `...${addLeadingZeros(item.unit_id, 8).substring(6)}` };
      return columns;
    }

    return defaultUnitColumns;
  }, [defaultUnitColumns, windowWidth])

  const {
    multiple_use_products,
    // consumable_products,
    continuous_use_services,
    single_use_products,
    single_use_services,
  } = verified;

  const onRowClick = (rowData) => {
    navigate(`/submit-quality-information/item-detail/${rowData.unit_id}`);
  };

  const onAddClick = (rowData) => {
    navigate("/submit-quality-information/select-symptom", {
      state: {
        unit: rowData,
      },
    });
  };

  return (
    <div>
      {loading ? (
        <LoadIndicator size="lg" />
      ) : (
        <div>
          {multiple_use_products?.length > 0 && <div className="card mb-4">
            <div className="card-body">
              <h3 className="card-title mb-4">Multiple Use Products</h3>

              <DataTable
                data={multiple_use_products}
                columns={unitColumns}
                clickAble
                onRowClick={onRowClick}
                onAddClick={onAddClick}
                hideOnNoData
              />
            </div>
          </div>}

          {single_use_services?.length > 0 && <div className="card mb-4">
            <div className="card-body">
              <h3 className="card-title mb-4">Single use services</h3>

              <DataTable
                data={single_use_services}
                columns={unitColumns}
                clickAble
                onRowClick={(data) => onRowClick(data, unit_types.single)}
                onAddClick={onAddClick}
              />
            </div>
          </div>}

          {continuous_use_services?.length > 0 && <div className="card mb-4">
            <div className="card-body">
              <h3 className="card-title mb-4">
                Continuous use / subscription services
              </h3>

              <DataTable
                data={continuous_use_services}
                columns={unitColumns}
                clickAble
                onRowClick={onRowClick}
                onAddClick={onAddClick}
              />
            </div>
          </div>}

          {single_use_products?.length > 0 && <div className="card mb-4">
            <div className="card-body">
              <h3 className="card-title mb-4">
                Single use / Consumable Products
              </h3>

              <DataTable
                data={single_use_products}
                columns={unitColumns}
                clickAble
                onRowClick={(data) => onRowClick(data, unit_types.single)}
                onAddClick={onAddClick}
              />
            </div>
          </div>}
        </div>
      )}
    </div>
  );
}

export default Verified;
