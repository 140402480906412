import styles from "./styles.module.css";

const WhatIsQuality = () => {
  return (
    <div id="main-container">
      <div className="container">
        <h2 className="mb-5 userLevel-Title">What is Quality</h2>

        <div className="mb-4">
          <h5 className={`${styles.title} mb-3`}>Definition:</h5>

          <p className={styles.p}>
            Quality in this context is the ability of the product or service to
            meet its intended functions. When a product fails to meet its
            intended functions we call this a defect or a symptom.
          </p>
        </div>

        <br />

        <div className="mb-4">
          <h5 className={`${styles.title} mb-3`}>
            What Information is available?
          </h5>

          <p className={styles.p}>
            People who use the products or service enter any defect they observe
            into the database. This includes a number of other pieces of
            information such as the amount of use, the date, if evidence is
            available, the severity, if its repeatable, whether its repaired, if
            there is a relavent software version and the date or age it was
            first noticed. Qualy then provides this information as a statistic
            based on all the records we have. This is different to a review site
            where people enter their opinions and a star rating. At Qualy we ony
            deal with specific defects and we use strong verification methods to
            ensure the information is honest and accurate.
          </p>
          <br />
          <p className={styles.p}>
            The quality output provided here comes out in the form of reports.
            When you buy a report you can decide what products and services you
            want in there, and filter by a number of charactertistics including
            the selling price of the record. In this way market prices are
            decided. The more you pay the more records you get, or you can
            reduce the number of records and get the ones you are specifically
            interested in. In the report we will provide a basic summary as well
            as all the details for each record you purchase, this can include
            the name and approximate date of the symptom, the Model name. In
            some cases the records are free, and once the site has been
            operational for a few years you will be able to get free older
            information, however there is always a small fixed fee for the
            report.
          </p>
        </div>

        <br />

        <div className="mb-4">
          <h5 className={`${styles.title} mb-3`}>What is not available?</h5>

          <p className={styles.p}>
            We do not provide personal information about the users that submit
            information or certain information that can be used to identify them
            in these reports.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhatIsQuality;
