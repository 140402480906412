import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAddUnitV2Data,
  updateForm,
  setSelectedBrandModel,
  setSelectedBrandModelAttribute,
  setSelectedUnit,
  setStep as setStepInStore,
  clearForm as clearFormStore,
  resetStore,
} from "redux-store/features/addUnitSliceV2";
import { selectToken } from "redux-store/features/authSlice";
import BrandModelService from "services/BrandModelService";
import CategoryService from "services/CategoryService";
import ModelService from "services/ModelService";
import SWApplicationService from "services/SWApplicationService";
import SWVersionService from "services/SWVersionService";
import UnitService from "services/UnitService";
import { AddUnitStep } from "shared/constants";
import { logger } from "shared/helpers";

export const useAddUnit = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const brandModelService = new BrandModelService(token, dispatch);
  const swVersionService = new SWVersionService(dispatch, token);
  const swApplicationService = new SWApplicationService(dispatch, token);

  const { form, selectedBrandModel } = useSelector(selectAddUnitV2Data);

  const httpFetchQuickSearchData = async () => {
    try {
      const res = await UnitService.quickSearch(token, dispatch);
      return res.data.data;
    } catch (error) {
      logger("ERROR", error);
      return null;
    }
  };

  const httpGetModels = async (brand, category) => {
    try {
      const res = await ModelService.getFilteredModels(
        token,
        dispatch,
        brand,
        category
      );

      return res.data.data;
    } catch (e) {
      logger("[error]", e);
      return [];
    }
  };

  const httpGetSelectBrandModel = async (brand, model) => {
    try {
      const res = await brandModelService.getBMByBrandAndModel(brand, model);

      return res.data.data;
    } catch (error) {
      logger("Brand model", error);
      return null;
    }
  };

  const httpGetApplicationVersions = async (application_id) => {
    try {
      const { data } = await swVersionService.fetchByApplicationForDropdown(
        application_id
      );
      return data?.data || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const httpGetSelectAttribute = async (attribute) => {
    try {
      const { data } = await brandModelService.getAttribute(attribute);

      if (data) {
        return data.data;
      }
    } catch (error) {
      logger("[error]", error);
    }

    return null;
  };

  const httpCreateUnit = async (form) => {
    try {
      const payload = new FormData();
      Object.keys(form).map((key) => {
        if (key !== "rating_label") {
          const item = form[key];
          if (typeof item !== "undefined") payload.append(key, item);
        }
      });

      if (Boolean(form.rating_label)) {
        payload.append("rating_label", form.rating_label);
      }

      const { data } = await UnitService.saveUnit(token, dispatch, payload);

      // dispatch(setSelectedUnit(data.data));

      // setStep(AddUnitStep.STEP_TWO);

      return data.data;
    } catch (error) {
      throw error;
    }
  };

  const httpCreateControllerUse = async (data) => {
    try {
      await UnitService.addControllerUseAndPrice(token, dispatch, data);
    } catch (error) {
      throw error;
    }
  };

  const httpGetEndCategoryAttribute = async (category_id) => {
    try {
      const { data } = await CategoryService.getAttributes(
        token,
        dispatch,
        category_id
      );

      return data.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const httpFindPrivateUnit = async (brand_model, id, identifier) => {
    try {
      const { data } = await UnitService.findPrivateUnit(
        token,
        dispatch,
        brand_model,
        id,
        identifier?.trim()
      );

      if (data.data) {
        dispatch(setSelectedUnit(data.data));
      }

      return data.data || null;
    } catch (error) {
      return null;
    }
  };

  const httpFetchBrandModelUnits = async (brand_model_id) => {
    const getControllerTypeDisplay = (type) => {
      switch (type) {
        case "current_controller":
          return "In Use";

        case "pending_new_controller":
          return "Pending";

        default:
          return "Available";
      }
    };

    try {
      const { data } = await UnitService.getUnitsByModelAndModel(
        token,
        dispatch,
        brand_model_id
      );

      if (data.data) {
        const units = data.data.map((item) => {
          item.controller_type_display = getControllerTypeDisplay(
            item.controller_type
          );
          return item;
        });

        return units || [];
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  const httpGetApplication = async (application_id) => {
    if (!application_id) return null;

    try {
      const { data } = await swApplicationService.find(application_id);

      return data?.data;
    } catch (error) {
      logger("[error]", error);
    }
  };

  const setStep = (step) => dispatch(setStepInStore(step));

  const handleFormChange = (key, value) => dispatch(updateForm({ key, value }));

  const clearForm = () => dispatch(clearFormStore());

  const clearStore = () => dispatch(resetStore());

  const selectUnit = (data) => dispatch(setSelectedUnit(data));
  const clearSelectUnit = () => dispatch(setSelectedUnit(null));

  useEffect(() => {
    if (form.model_id && form.brand_id)
      httpGetSelectBrandModel(form.brand_id, form.model_id).then((data) =>
        dispatch(setSelectedBrandModel(data))
      );
    else dispatch(setSelectedBrandModel(null));
  }, [form.model_id, form.brand_id]);

  useEffect(() => {
    if (Boolean(selectedBrandModel?.attributes)) {
      httpGetSelectAttribute(selectedBrandModel.attributes).then((data) =>
        dispatch(setSelectedBrandModelAttribute(data))
      );
    } else {
      dispatch(setSelectedBrandModelAttribute(null));
    }
  }, [selectedBrandModel?.brand_model_id]);

  return {
    fetchSearchData: httpFetchQuickSearchData,
    fetchModels: httpGetModels,
    handleFormChange,
    getApplicationVersions: httpGetApplicationVersions,
    httpCreateUnit,
    clearForm,
    clearSelectUnit,
    httpGetEndCategoryAttribute,
    clearStore,
    httpFindPrivateUnit,
    httpCreateControllerUse,
    httpFetchBrandModelUnits,
    selectUnit,
    httpGetApplication,
    setStep,
  };
};
