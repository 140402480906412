import React from "react";
import { Modal } from "react-bootstrap";

function DescModal({ show, handleClose, text, children }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
        ></Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          {text && <h5 className="mod-detail">{text}</h5>}
          {children && <div>{children}</div>}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DescModal;
