import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Reasons = () => {
  return (
    <div>
      <section className="homeBanner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 leftHomeBanner">
              <h1>Reasons to Use Qualy before you buy anything</h1>

              <ol className={`${styles.list} ps-4 my-4`}>
                <li>
                  <p>
                    Find out if your new “want” is going to last before you buy
                  </p>
                </li>

                <li>
                  <p>Find out more than “first impressions”</p>
                </li>

                <li>
                  <p>To avoid fake reviews purchased from a bot farm</p>
                </li>

                <li>
                  <p>To highlight sellers who sell fake products</p>
                </li>

                <li>
                  <p>
                    To find quality local skilled trades who haven't paid for
                    membership
                  </p>
                </li>

                <li>
                  <p>To get a focus on negative aspects</p>
                </li>

                <li>
                  <p>
                    To get guaranteed data, your money back if the information
                    is false.
                  </p>
                </li>

                <li>
                  <p>Because you pay, you are the customer</p>
                </li>
              </ol>

              <Link to="/" className="btn btn-yellow">
                Home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Reasons;
