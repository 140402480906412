const { axiosClient } = require("shared/axiosInstance");

class QualityService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async insertQuality(data) {
    return this.client.post("/quality", data);
  }

  async updateQuality(data) {
    return this.client.patch('/quality', data);
  }

  async getControllerUseQualityRecords(id) {
    return this.client.get(`/quality/by-controller-use?id=${id}`);
  }


  async fetchQualityData(id) {
    return this.client.get(`/quality/view?quality_id=${id}`);
  }

  async deleteQuality(id) {
    return this.client.delete(`/quality?quality_id=${id}`);
  }
}

export default QualityService;
