import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUser } from "redux-store/features/authSlice";
import Countdown from "../countdown";
import styles from "./LockTimer.module.css";

const LockTimer = ({ onComplete = () => {}, data }) => {
  const user = useSelector(selectUser);

  return data && (data.locked_by || data.locked_by_2) && user ? (
    (data.locked_by && data.locked_by == user.user_id) ||
    (data.locked_by_2 && data.locked_by_2 == user.user_id) ? (
      <div className={styles.alNum}>
        <OverlayTrigger
          key={"unlock-user"}
          placement="top"
          overlay={
            <Tooltip id="tooltip-unlock-user">
              Record is locked for others editing
            </Tooltip>
          }
        >
          <div>
            <i className="bi bi-unlock-fill" style={{ color: "green" }}></i>
            <Countdown initialValue={data.seconds_left} onFinish={onComplete} />
          </div>
        </OverlayTrigger>
      </div>
    ) : (
      <div className={styles.alNum}>
        <OverlayTrigger
          key={"locked-all"}
          placement="top"
          overlay={
            <Tooltip id="tooltip-locked-all">
              Record is unavailable for editing
            </Tooltip>
          }
        >
          <i className="bi bi-lock-fill" style={{ color: "red" }}></i>
        </OverlayTrigger>
      </div>
    )
  ) : (
    <div className={styles.alNum}>
      <OverlayTrigger
        key={"unlock-all"}
        placement="top"
        overlay={
          <Tooltip id="tooltip-unlock-all">Record is ready for editing</Tooltip>
        }
      >
        <i className="bi bi-lock-fill" style={{ color: "green" }}></i>
      </OverlayTrigger>
    </div>
  );
};

export default LockTimer;
