import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectToken,
  selectUser,
  updateProfilePic,
} from "redux-store/features/authSlice";
import UserService from "services/UserService";
import { MAX_FILE_SIZE } from "shared/constants";
import { formatMMDDYYYY, logger } from "shared/helpers";
import FileDisplayThumb from "components/FileDisplay";
import LoadIndicator from "components/LoadIndicator";
import EnabledMethodModal from "./EnabledMethodModal";
import "./index.css";
import { usePreferences } from "hooks/usePrefrences";

function SubmitRoleDetails() {
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const { formatDate } = usePreferences();

  const [data, set_data] = useState(null);

  const fetchSubmittingRoleDetails = async () => {
    try {
      const res = await UserService.fetchSubmittingUserDetails(token);

      set_data(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchSubmittingRoleDetails();
  }, []);

  const getStatus = () => {
    logger("Submitting user", user?.submitting);

    if (user?.submitting) {
      if (user.submitting.status === "normal") {
        return "Normal (Selling enabled)";
      }

      return "On-Hold (Selling blocked)";
    }

    return "";
  };

  const navigate = useNavigate();

  const [open_details, set_open_details] = useState(false);

  const closeDetails = () => {
    set_open_details(false);
  };

  const openDetails = () => {
    set_open_details(true);
  };

  const dispatch = useDispatch();
  const [uploading, set_uploading] = useState(false);

  const onFileChange = async (event) => {
    const file = event.target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      return alert("File size cannot be more than 5 mb.");
    }

    set_uploading(true);

    try {
      const payload = new FormData();
      payload.append("avatar", file);

      const res = await UserService.updateProfilePic(token, dispatch, payload);

      dispatch(updateProfilePic(res.data.data));
    } catch (e) {}
    set_uploading(false);
  };

  const [hideStartVerification, setIsNewVerificationDisabled] = useState(false);

  useEffect(() => {
    let disabled = false;

    if (data?.docs) {
      for (const doc of data.docs) {
        if (doc.result === "open") {
          disabled = true;
          break;
        }
      }
    }

    if (user?.submitting && !user.submitting.selfi_photo) {
      disabled = true;
    }

    setIsNewVerificationDisabled(disabled);
  }, [data?.docs, user]);

  const getVerificationResult = (result) => {
    if (typeof result === "string" || result instanceof String) {
      switch (result) {
        case "open":
          return "Open";

        case "accepted":
          return "Accepted";

        case "rejected":
          return "Rejected";

        case "failed_to_provide_improvement":
          return "Failed to provide improvement";

        default:
          return "";
      }
    }

    return "";
  };

  const getVerificationStatus = (
    process_flow_position,
    uploaded_date,
    initiated_by
  ) => {
    if (
      (!uploaded_date && initiated_by === "requestor") ||
      process_flow_position === "improvement_requested"
    ) {
      return (
        <div>
          <span>Action Required</span>

          <button className="btn btn-yellow mx-2">Update</button>
        </div>
      );
    }

    switch (process_flow_position) {
      case "open":
        return "Open";

      case "improvement_requested":
        return "Improvement requested";

      case "closed":
        return "Closed";

      default:
        return "";
    }
  };

  const handleRowClick = (history_id) =>
    history_id && navigate("/user-verification?history=" + history_id);

  return (
    <>
      <EnabledMethodModal
        show={open_details}
        handleClose={closeDetails}
        data={data}
      />

      <div className="container">
        <div id="main-container">
          <h1 className="title1 mb-4">Submitting Role Details</h1>

          <div className="row">
            <div className="col-md-4 mb-3">
              <p>
                <b>User Name: </b>
                {`${user?.first_name} ${user?.last_name}`}
              </p>
            </div>

            <div className="col-md-4 mb-3">
              <p>
                <b>User ID: </b>
                {user?.number}
              </p>
            </div>

            <div className="col-md-4 mb-3">
              <p>
                <b className="d-block mb-2">Unique Selfi:</b>

                <input
                  type="file"
                  id="imageUpload"
                  onChange={onFileChange}
                  accept=".png, .jpg, .jpeg"
                  hidden
                />

                {!user?.submitting?.selfi_photo ? (
                  <div>
                    <em>Waiting for upload</em>

                    <label
                      for="imageUpload"
                      className="btn btn-yellow mx-2"
                      style={{ cursor: "pointer" }}
                    >
                      {uploading ? <LoadIndicator /> : "Upload"}
                    </label>
                  </div>
                ) : (
                  <div className="row col-5">
                    <div className="col-6">
                      <FileDisplayThumb
                        location={user?.submitting.selfi_photo}
                      />
                    </div>

                    <div className="col-6">
                      <label
                        for="imageUpload"
                        className="btn btn-yellow mx-2"
                        style={{ cursor: "pointer" }}
                      >
                        {uploading ? <LoadIndicator /> : "Edit"}
                      </label>
                    </div>
                  </div>
                )}
              </p>
            </div>

            <div className="col-md-4 mb-3">
              <p>
                <b>Verifications available: </b>
                {data?.methods_available
                  ? data.methods_available.length
                  : 0}{" "}
                <a onClick={openDetails} className="infolink">
                  See details
                </a>
              </p>
            </div>

            <div className="col-md-4 mb-3">
              <p>
                <b>Role started: </b>
                {formatDate(user?.submitting?.created_at)}
              </p>
            </div>

            <div className="col-md-4 mb-3">
              <p>
                <b>Verification Level: </b>
                {user?.submitting?.verification_level}
              </p>
            </div>

            <div className="col-md-4 mb-3">
              <p>
                <b>Status: </b>
                {getStatus()}
              </p>
            </div>
          </div>

          <hr />

          <h2 className="title3 mb-3 mt-5">Verification History</h2>

          <div className="table-responsive">
            <table className="table table-responsive table-bordered table-dark-theme">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Result</th>
                  <th>Verification Status</th>
                </tr>
              </thead>

              <tbody>
                {data?.docs &&
                  data.docs instanceof Array &&
                  data.docs.map((item, idx) => (
                    <tr
                      key={"available-user-verif-" + idx}
                      onClick={() => handleRowClick(item.history_id)}
                      className="user-verification-row"
                    >
                      <td>{item.type_name}</td>

                      <td>{getVerificationResult(item.result)}</td>

                      <td>
                        {getVerificationStatus(
                          item.process_flow_position,
                          item.uploaded_date,
                          item.initiated_by
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {!user?.submitting?.selfi_photo && (
            <div className="mb-5">
              *Further verification progression cannot start until a Unique
              Selfi is uploaded
            </div>
          )}

          <div className="mt-4 text-center">
            {!hideStartVerification && (
              <button
                type="button"
                className="btn btn-yellow me-3"
                onClick={() => navigate("/user-verification")}
              >
                Start a new verification
              </button>
            )}

            <button
              type="button"
              className="btn btn-black"
              onClick={() => navigate("/identity")}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubmitRoleDetails;
