import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AuthenticationService from "services/AuthenticationService";
import ErrorsComponent from "components/Errors";
import LoadIndicator from "components/LoadIndicator";

const StepFive = ({
  onSubmit,
  roles,
  onChange,
  loading,
  err,
  form,
  isInvite,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const shouldOptionsShow = async () => {
    try {
      if (!form.phone_verified) {
        setShowOptions(false);
        return;
      }

      const res = await AuthenticationService.getDbVariables();

      const invite_only = Boolean(res.data?.data.invite_only);

      if (!(isInvite || form.user_type === "commercial" || !invite_only)) {
        setShowOptions(false);
        return;
      }

      setShowOptions(true);
    } catch (e) {
      console.log("Error", e);
      setShowOptions(false);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    onSubmit(e, showOptions);
  };

  useEffect(() => {
    shouldOptionsShow();
  }, [form.phone_verified, isInvite, form.user_type]);

  return (
    <>
      <form onSubmit={submit} className="registerForm">
        <h3 className="title3">
          Your user number is: <em>{form?.user?.number}</em>
        </h3>
        <h3 className="title3">
          Please take a note of this now as you will need it for login.
        </h3>

        {!showOptions && (
          <h3 className="title3">
            Please use the identification Information menu in your user profile
            to complete phone verification and start a role
          </h3>
        )}

        <>
          {showOptions && (
            <h3 className="title3">
              Do you wish to setup a user role now(you can create a role at any
              time)?
            </h3>
          )}

          {showOptions && (
            <div className="row">
              <div className="col-md-4 mb-4">
                <OverlayTrigger
                  key={"submitting-user"}
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-submitting-user">
                      You wish to submit your user experience about products and
                      services you have used
                    </Tooltip>
                  }
                >
                  <a
                    href="#"
                    style={{
                      backgroundColor: roles.includes("submitting")
                        ? "#ffff0033"
                        : "white",
                    }}
                    className={`roleCol ${
                      roles.includes("submitting") && "active"
                    }`}
                    onClick={() => {
                      if (showOptions) onChange("roles", "submitting");
                    }}
                  >
                    <i className="far fa-user"></i>
                    <span>Submitting User</span>
                  </a>
                </OverlayTrigger>
              </div>
              <div className="col-md-4 mb-4">
                <OverlayTrigger
                  key={"professional-comitment"}
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-professional-comitment">
                      you wish to contribute your expertise
                    </Tooltip>
                  }
                >
                  <a
                    href="#"
                    style={{
                      backgroundColor: roles.includes("representative")
                        ? "#ffff0033"
                        : "white",
                      cursor: "not-allowed",
                    }}
                    className={`roleCol ${
                      roles.includes("representative") && "active"
                    }`}
                    // onClick={() => onChange("roles", "representative")}
                  >
                    <i className="far fa-user"></i>
                    <span>Professional commitment</span>
                  </a>
                </OverlayTrigger>
              </div>
              <div className="col-md-4 mb-4">
                <OverlayTrigger
                  key={"company-representative"}
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-company-representative">
                      you want to represent a brand on which data is held
                    </Tooltip>
                  }
                >
                  <a
                    href="#"
                    style={{
                      backgroundColor: roles.includes("contractor")
                        ? "#ffff0033"
                        : "white",
                      cursor: "not-allowed",
                    }}
                    className={`roleCol ${
                      roles.includes("contractor") && "active"
                    }`}
                    // onClick={() => onChange("roles", "contractor")}
                  >
                    <i className="far fa-user"></i>
                    <span>Company Representative</span>
                  </a>
                </OverlayTrigger>
              </div>
            </div>
          )}
        </>

        <div className="mt-4 text-center">
          {showOptions && (
            <a
              onClick={() => onSubmit(null, true, true)}
              className="btn btn-black me-2"
            >
              No Thanks
            </a>
          )}

          <button type="submit" className="btn btn-yellow">
            {loading ? <LoadIndicator /> : showOptions ? "Submit" : "Continue"}
          </button>
        </div>
        <div className="mt-4" />

        {err.length > 0 && <ErrorsComponent errors={err} />}
      </form>
    </>
  );
};

export default StepFive;
