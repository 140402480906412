import { axiosClient } from "shared/axiosInstance";

class PaymentService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }
  
  async initiateCheckout(data) {
    return axiosClient(this.dispatch, this.token).post('/payment/initiate', data);
  }

  async getCreditStoredText() {
    return axiosClient(this.dispatch, this.token).get('/stored-texts/add-credit');
  }

  async getIsCountryEnabled() {
    return axiosClient(this.dispatch, this.token).get('/payment/country-enabled');
  }

  async getCreditLastBuyAttempt() {
    return axiosClient(this.dispatch, this.token).get('/payment/last-attempt')
  }
}

export default PaymentService;
