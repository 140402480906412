import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
  sw_versions: [],
};

export const softwareVersionManagement = createSlice({
  name: "software_version_management",
  initialState,
  reducers: {
    initializeSWVersionList: (state, action) => {
      state.sw_versions = action.payload;
      state.initialized = true;
    },

    addSWVersion: (state, action) => {
      state.sw_versions = [...state.sw_versions, action.payload];
    },
  },
});

export const { initializeSWVersionList, addSWVersion } =
  softwareVersionManagement.actions;

export const selectSoftwareVersionManagementData = (state) => ({
  ...state.software_version_management,
});

export default softwareVersionManagement.reducer;
