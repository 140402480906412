import { forwardRef, useCallback, useRef } from "react";

import styles from "./styles.module.css";
import { TableBrandSelector } from "components/TableBrandSelector";
import { useImperativeHandle } from "react";

const StepTwo = forwardRef(({ categoryTree, category, previousStep, onBrandChange }, ref) => {
    const tableBrandSelectorRef = useRef(null);

    const updateBrandList = (brand) => tableBrandSelectorRef.current?.addBrand(brand);

    useImperativeHandle(ref, () => {
        return {
            updateBrandList
        };
    }, []);

    const renderCategoryName = useCallback(() => {
        let names = [];

        const tree = [...categoryTree(), category];

        tree.forEach((category) => {
            if (category.category_id !== 1) {
                names.push(category.name);
            }
        });

        return (
            <>
                { names.map((name, index) => (
                    <>
                        {name}
                        {index !== tree.length - 2 && <i className="bi bi-arrow-right mx-1"></i>}
                    </>
                )) }
            </>
        );
    }, [categoryTree, category]);

    return (
        <>
            <div className="mb-2">
                <b>Selected End Category: </b>

                <span>{renderCategoryName()}</span>
            </div>

            <button
                className={`mb-4 btn btn-green ${styles.category_back}`} 
                type="button"
                onClick={previousStep}
            >
                <i className="bi bi-arrow-left"></i>
            </button>

            <p className="mb-4">
                If a product does not have a clear brand and it is brand new, then you may use the name of the seller as the Brand. 
                
                <br />

                If sole trader is providing a service then use their business name, which in some cases maybe their own name.
            </p>

            <p className="mb-2">
                Find the brand or service provider below, or use search to reduce the results
            </p>

            <TableBrandSelector 
                onBrandChange={onBrandChange}
                ref={tableBrandSelectorRef}
            />
        </>
    );
});

export default StepTwo;