import { axiosClient } from "shared/axiosInstance";

class UnitService {
  constructor() {}

  static confirmNoSymptoms (token, dispatch) {
    return axiosClient(dispatch, token).post('/unit/confirm-no-symptoms');
  }

  static quickSearch(token, dispatch) {
    return axiosClient(dispatch, token).get("/unit/quick-search");
  }

  static saveUnit(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/unit", data);
  }

  static updateUnit(token, dispatch, data) {
    // MANIPULATE START/END DATE & TIME
    if (data.rating_label) {
      const payload = new FormData();

      Object.keys(data).forEach((key) => payload.append(key, data[key]));

      return axiosClient(dispatch, token).post("/unit/update", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      return axiosClient(dispatch, token).post("/unit/update", data);
    }
  }

  static getUnit(token, dispatch, unit_id) {
    return axiosClient(dispatch, token).get(`/unit/${unit_id}`);
  }

  static getPendingUnits(token, dispatch) {
    return axiosClient(dispatch, token).get("/unit/pending");
  }

  static getVerifiedUnits(token, dispatch) {
    return axiosClient(dispatch, token).get("/unit/verified");
  }

  static getPastUseUnits(token, dispatch) {
    return axiosClient(dispatch, token).get("/unit/past-use");
  }

  static getUnits(token, dispatch) {
    return axiosClient(dispatch, token).get("/unit/all");
  }

  static getUnitsByModelAndModel(token, dispatch, brand_model_id) {
    return axiosClient(dispatch, token).get(
      "/unit/search?brand_model_id=" + brand_model_id
    );
  }

  static addControllerUseAndPrice(token, dispatch, data) {
    return axiosClient(dispatch, token).post(
      "/unit/create_use_and_price",
      data
    );
  }

  static updateUnitRecord(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/unit/update", data);
  }

  static getAttributes(token, dispatch, brand, model) {
    return axiosClient(dispatch, token).get(
      `/unit/attribute?brand=${brand}&model=${model}`
    );
  }

  static getReviewSalesData (token, dispatch, brand_model) {
    return axiosClient(dispatch, token).get(
      `/unit/review-sales?brand_model=${brand_model}`
    );
  }

  static getUnitUses(token, dispatch, unit_id) {
    return axiosClient(dispatch, token).get(`/unit/${unit_id}/uses`);
  }

  static findPrivateUnit(token, dispatch, brand_model, unit_id, identifier) {
    let url = `/unit/private?brand_model=${brand_model}`;

    if (unit_id) url += `&unit_id=${unit_id}`;
    if (identifier) url += `&identifier=${identifier}`;

    return axiosClient(dispatch, token).get(url);
  }

  static deleteUnit(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/unit/delete", data);
  }
}

export default UnitService;
