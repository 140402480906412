import { usePreferences } from "hooks/usePrefrences";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, useShortDisplay } from "shared/helpers";
import LoadIndicator from "components/LoadIndicator";

function Preferences() {
  const { preferences, updatePreferences } = usePreferences();

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const { form, onChange, err, set_err } = useForm({
    language: preferences?.language,
    distance: preferences?.distance,
    date_format: preferences?.date_format,
    sales_opp: preferences?.sales_opp,
  });

  useShortDisplay(() => set_err([]), 5000, err.length > 0);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await updatePreferences(form, set_err, setLoading);

    setLoading(false);
  };

  const handleCancel = () => goBack();

  return (
    <>
      <div id="main-container">
        <div className="container">
          <h3 className="mb-4 fadeAnim">Preferences</h3>

          <form onSubmit={handleSubmit}>
            <div className="card mb-4 fadeAnim">
              <div className="card-body">
                {/** LANGUAGE */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Display Language</label>
                  </div>

                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={form.language}
                      onChange={(e) => onChange("language", e.target.value)}
                      required
                    >
                      <option disabled selected value="">
                        Select language
                      </option>
                      {["English"].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/** Distance Units */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Distance Unit</label>
                  </div>

                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={form.distance}
                      onChange={(e) => onChange("distance", e.target.value)}
                      required
                    >
                      <option disabled selected value="">
                        Select unit
                      </option>
                      {["Metric", "Imperial"].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/** Date Format */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Date Format</label>
                  </div>

                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={form.date_format}
                      onChange={(e) => onChange("date_format", e.target.value)}
                      required
                    >
                      <option disabled selected value="">
                        Select date format
                      </option>
                      {["yyyy/mm/dd", "dd/mm/yyyy", "mm/dd/yyyy"].map(
                        (item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>

                {/** NOTIFICATIONS SWITCH */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Product suggestions</label>
                  </div>

                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={form.sales_opp ? Number(form.sales_opp) : 0}
                      onChange={(e) =>
                        onChange("sales_opp", Number(e.target.value))
                      }
                      required
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center fadeAnim">
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-black me-2"
                disabled={loading}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-yellow"
                disabled={loading}
              >
                {loading ? <LoadIndicator size="sm" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Preferences;
