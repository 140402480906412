const SettingsIcon = ({ color = "#fff" }) => {

    return (
        <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.853 2.756C12.4683 0.414667 16.0766 0.414667 16.6919 2.756C16.7842 3.10773 16.9652 3.43437 17.2202 3.70933C17.4751 3.98429 17.7967 4.19981 18.159 4.33833C18.5212 4.47685 18.9137 4.53447 19.3046 4.50649C19.6955 4.47852 20.0737 4.36574 20.4085 4.17733C22.6372 2.924 25.1896 5.27867 23.8318 7.33733C23.628 7.64621 23.506 7.99512 23.4758 8.3557C23.4455 8.71628 23.5079 9.07836 23.6578 9.41251C23.8077 9.74665 24.0409 10.0434 24.3384 10.2787C24.6359 10.514 24.9894 10.6812 25.3701 10.7667C27.9066 11.3347 27.9066 14.6653 25.3701 15.2333C24.9891 15.3186 24.6352 15.4856 24.3373 15.7209C24.0395 15.9563 23.806 16.2532 23.6559 16.5875C23.5059 16.9219 23.4435 17.2842 23.4738 17.645C23.5041 18.0059 23.6262 18.355 23.8304 18.664C25.1881 20.7213 22.6372 23.0773 20.407 21.824C20.0724 21.6359 19.6944 21.5233 19.3038 21.4954C18.9132 21.4674 18.5209 21.525 18.1589 21.6634C17.7969 21.8017 17.4754 22.017 17.2205 22.2916C16.9656 22.5663 16.7845 22.8926 16.6919 23.244C16.0766 25.5853 12.4683 25.5853 11.853 23.244C11.7607 22.8923 11.5797 22.5656 11.3248 22.2907C11.0698 22.0157 10.7482 21.8002 10.386 21.6617C10.0238 21.5232 9.63122 21.4655 9.24033 21.4935C8.84943 21.5215 8.47121 21.6343 8.13646 21.8227C5.90768 23.076 3.35535 20.7213 4.71313 18.6627C4.91694 18.3538 5.03892 18.0049 5.06916 17.6443C5.0994 17.2837 5.03703 16.9216 4.88714 16.5875C4.73725 16.2533 4.50406 15.9566 4.20652 15.7213C3.90898 15.486 3.5555 15.3188 3.17479 15.2333C0.63835 14.6653 0.63835 11.3347 3.17479 10.7667C3.55584 10.6814 3.9097 10.5144 4.20757 10.2791C4.50545 10.0437 4.73892 9.74682 4.88898 9.41246C5.03905 9.07811 5.10147 8.71578 5.07116 8.35495C5.04085 7.99413 4.91868 7.645 4.71457 7.336C3.35679 5.27867 5.90768 2.92267 8.1379 4.176C9.57657 4.98667 11.4543 4.26933 11.853 2.756Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.6061 13C18.6061 14.0609 18.1496 15.0783 17.3369 15.8284C16.5243 16.5786 15.4221 17 14.2728 17C13.1235 17 12.0213 16.5786 11.2087 15.8284C10.396 15.0783 9.93945 14.0609 9.93945 13C9.93945 11.9391 10.396 10.9217 11.2087 10.1716C12.0213 9.42143 13.1235 9 14.2728 9C15.4221 9 16.5243 9.42143 17.3369 10.1716C18.1496 10.9217 18.6061 11.9391 18.6061 13V13Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export {
    SettingsIcon
}