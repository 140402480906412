import axios from "axios";
import configs from "configs";
import { logout } from "redux-store/features/authSlice";

export const axiosClient = (dispatch, token, version = "v1.0") => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (token) headers.Authorization = `Bearer ${token}`;

  const axiosInstance = axios.create({
    baseURL: configs.appAPI + `/${version}`,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 && dispatch) {
        dispatch(logout());
      } else if (
        error.response?.status === 500 &&
        error.response?.data?.message?.toLowerCase() === "unauthenticated." &&
        dispatch
      ) {
        dispatch(logout());
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
