import React from "react";
import { Button, Modal } from "react-bootstrap";

const InvalidLevelModal = ({ handleClose, show }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      centered
      backdrop="static"
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", paddingTop: 32 }}
      ></Modal.Header>

      <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
        <div>
          <h5>Only submitting users level 2 and above allowed</h5>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="primary" onClick={handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvalidLevelModal;
