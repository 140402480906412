import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import SwitchAnswer from "components/SwitchAnswer";
import DateRange from "components/DateRange";
import { useSingleBrandProductController } from "hooks/useSingleBrandProductController";
import MultiStepCategorySelect from "components/MultiStepCategorySelect";
import AutocompleteV2 from "components/AutocompleteV2";
import WordPillBox from "components/WordPillBox";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import LoadIndicator from "components/LoadIndicator";
import { logger, parseQueryString } from "shared/helpers";
import { COUNTRIES } from "shared/constants";
import { selectUser } from "redux-store/features/authSlice";
import WarningModal from "components/WarningModal";

const SingleBrandProduct = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const controller = useSingleBrandProductController();
  const user = useSelector(selectUser);

  const {
    brands,
    disableBrandSearch,
    queryData,
    categorySelect,
    report,
    fetching,
    downloading,
    screenState,
    price,
    handleCategorySelect,
    handleScreenStateChange,
    getFromDate,
    getToDate,
    httpFetchReport: trigger,
  } = controller;

  const modelCount = useMemo(() => {
    const models = [];

    report.forEach((e) =>
      !models.includes(e.brand_model) ? models.push(e.brand_model) : undefined
    );

    return models.length;
  }, [report]);

  const unitCount = useMemo(() => {
    const units = [];

    report.forEach((e) =>
      !units.includes(e.unit) ? units.push(e.unit) : undefined
    );

    return units.length;
  }, [report]);

  const getHighestPrice = () => {
    let max = 0;

    report.forEach((e) => {
      logger("e", e);
      if (Number(e.price) > max) {
        max = Number(e.price);
      }
    });

    logger("max", max);

    return max;
  };

  useEffect(() => {
    if (screenState.bidPrice === "Set price") {
      handleScreenStateChange("bidPriceVal", getHighestPrice());
    }
  }, [screenState.bidPrice]);

  useEffect(() => {
    if (user?.country) {
      handleScreenStateChange("country", user.country);
    }
  }, [user?.country]);

  const goToAddCredit = () => navigate("/add-credit");

  const goToModelList = () => {
    if (modelCount > 0 && queryData.brand) {
      const models = [];

      const data = (({
        country,
        date_seen_from,
        date_seen_to,
        date_of_production_from,
        date_of_production_to,
        evidence_available,
        bidPriceVal,
        bidPrice,
      }) => ({
        country,
        date_seen_from: getFromDate(date_seen_from),
        date_seen_to: getToDate(date_seen_to),
        date_of_production_from: getFromDate(date_of_production_from),
        date_of_production_to: getToDate(date_of_production_to),
        evidence_available,
        bidPriceVal,
        bidPrice,
      }))(screenState);

      report.forEach((e) =>
        !models.includes(e.brand_model) ? models.push(e.brand_model) : undefined
      );

      window.open(
        `/get-quality/single-brand-product/model-list?models=${models.join(
          ","
        )}&brand=${queryData.brand.brand}&${parseQueryString(data)}`,
        "_blank"
      );
    }
  };

  const goToUnitList = () => {
    if (unitCount > 0 && queryData.brand) {
      const models = [];

      const data = (({
        country,
        date_seen_from,
        date_seen_to,
        date_of_production_from,
        date_of_production_to,
        evidence_available,
        bidPriceVal,
        bidPrice,
      }) => ({
        country,
        date_seen_from: getFromDate(date_seen_from),
        date_seen_to: getToDate(date_seen_to),
        date_of_production_from: getFromDate(date_of_production_from),
        date_of_production_to: getToDate(date_of_production_to),
        evidence_available,
        bidPriceVal,
        bidPrice,
      }))(screenState);

      report.forEach((e) =>
        !models.includes(e.brand_model) ? models.push(e.brand_model) : undefined
      );

      window.open(
        `/get-quality/single-brand-product/unit-list?models=${models.join(
          ","
        )}&brand=${queryData.brand.brand}&${parseQueryString(data)}`,
        "_blank"
      );
    }
  };

  const buyQuality = async () => {
    if (!user) return;

    if (user.balance < price) {
      handleScreenStateChange("openInsufficientFunds", true);
    } else {
      handleScreenStateChange("openPurchaseConfirmation", true);
    }
  };

  const downloadReport = useCallback(async () => {
    if (screenState.reportDownloadResponse?.report_index) {
      const status = await controller.httpDownloadReport(
        screenState.reportDownloadResponse.report_index
      );
      if (status) {
        handleScreenStateChange("reportDownloadResponse", null);
      }
    }
  }, [screenState.reportDownloadResponse?.report_index]);

  useEffect(() => {
    return () => {
      controller.reset();
    };
  }, []);

  return (
    <>
      <WarningModal
        show={screenState.openInsufficientFunds}
        text={"You don't have enough credit"}
        positiveLabel="Add Credit"
        negativeLabel="Cancel"
        handleOk={goToAddCredit}
        handleClose={() =>
          handleScreenStateChange("openInsufficientFunds", false)
        }
      />

      <WarningModal
        show={screenState.openPurchaseConfirmation}
        text={`Purchase report with ${price} credit?`}
        positiveLabel="Confirm"
        negativeLabel="Cancel"
        handleOk={() => setTimeout(controller.httpBuyQuality, 500)}
        handleClose={() =>
          handleScreenStateChange("openPurchaseConfirmation", false)
        }
      />

      <div id="main-container" className={styles.singleBrandProduct}>
        <div className="container">
          <h2 className="mb-3 userLevel-Title">Single Brand Product</h2>

          <div className={`${styles.arrow} mb-5`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span>Get Quality Menu</span>
          </div>

          <div className="row">
            <div className="col-12 col-lg-9">
              <div className="mb-5">
                <div className={`${styles.subtitle} mb-3`}>
                  Single Brand, Multiple model report for Mutliple use Products
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Category</span>
                  </div>

                  <div className="col-7">
                    <MultiStepCategorySelect
                      id={"single-brand-product-category"}
                      categoryInput={categorySelect.categoryInput}
                      dropdownMatrix={categorySelect.dropdownMatrix}
                      setCategoyInput={categorySelect.setCategoyInput}
                      setDropdownMatrix={categorySelect.setDropdownMatrix}
                      setValue={(val) => handleCategorySelect(val)}
                      verifiedOnly
                    />
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Brand</span>
                  </div>

                  <div className="col-7">
                    <AutocompleteV2
                      id="single-brand-product-brand"
                      suggestions={brands}
                      placeholder="Select a brand"
                      disabled={disableBrandSearch}
                      labels={["brand"]}
                      primaryKey="brand_id"
                      setValue={(val) =>
                        controller.handleQueryDataChange("brand", val)
                      }
                      sortKey="brand"
                      value={queryData.brand?.brand_id || ""}
                    />
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Model Search</span>
                  </div>

                  <div className="d-none d-md-block col-7">
                    <WordPillBox
                      value={queryData.modelTexts}
                      setValue={(val) =>
                        controller.handleQueryDataChange("modelTexts", val)
                      }
                      placeHolder="No Filter"
                      disabled={disableBrandSearch || !queryData.brand}
                    />
                  </div>

                  <div className="d-none d-md-block col-2">
                    <SwitchAnswer
                      rightLabel="OR"
                      leftLabel="AND"
                      value={queryData.modelTextOperator}
                      handleChange={(val) =>
                        controller.handleQueryDataChange(
                          "modelTextOperator",
                          val
                        )
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3 d-flex d-md-none">
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter text search"
                    />
                  </div>

                  <div className="col-3">
                    <SwitchAnswer rightLabel="OR" leftLabel="AND" />
                  </div>
                </div>
              </div>

              <div className="mb-5">
                <div className={`${styles.subtitle} mb-2`}>Filters</div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Symtom bid Price</span>
                  </div>

                  <div className="col-7">
                    <select
                      className="form-select"
                      value={screenState.bidPrice}
                      onChange={(e) =>
                        handleScreenStateChange("bidPrice", e.target.value)
                      }
                    >
                      <option value="No filter">No filter</option>

                      <option value="Set price">Set price</option>
                    </select>

                    {screenState.bidPrice === "Set price" && (
                      <input
                        type="number"
                        className="form-control mt-2"
                        step={"0.01"}
                        min={0}
                        max={99.99}
                        value={screenState.bidPriceVal}
                        onChange={(e) =>
                          handleScreenStateChange(
                            "bidPriceVal",
                            e.target.valueAsNumber
                          )
                        }
                      />
                    )}
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Date Seen</span>
                  </div>

                  <div className="col-7">
                    <DateRange
                      type="month"
                      from={screenState.date_seen_from}
                      to={screenState.date_seen_to}
                      handleChangeFrom={(val) =>
                        handleScreenStateChange("date_seen_from", val)
                      }
                      handleChangeTo={(val) =>
                        handleScreenStateChange("date_seen_to", val)
                      }
                    />
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Date of production</span>
                  </div>

                  <div className="col-7">
                    <DateRange
                      type="month"
                      from={screenState.date_of_production_from}
                      to={screenState.date_of_production_to}
                      handleChangeFrom={(val) =>
                        handleScreenStateChange("date_of_production_from", val)
                      }
                      handleChangeTo={(val) =>
                        handleScreenStateChange("date_of_production_to", val)
                      }
                    />
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Evidence available</span>
                  </div>

                  <div className="col-7">
                    <select
                      className="form-select"
                      value={screenState.evidence_available}
                      onChange={(e) =>
                        handleScreenStateChange(
                          "evidence_available",
                          e.target.value
                        )
                      }
                    >
                      <option value="No filter">No filter</option>
                      <option value={"Yes"}>Yes</option>
                    </select>
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Country</span>
                  </div>

                  <div className="col-7">
                    <select
                      id="country"
                      className="form-select"
                      value={screenState.country}
                      onChange={(e) =>
                        handleScreenStateChange("country", e.target.value)
                      }
                    >
                      <option value="No filter">No filter</option>
                      {COUNTRIES.map((item, idx) => (
                        <option key={`country-${idx}`} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-auto">
                    <button
                      onClick={trigger}
                      className={`${styles.searchButton} m-0`}
                      disabled={fetching}
                      type="button"
                    >
                      Search Records
                    </button>
                  </div>
                </div>
              </div>

              <div className="mb-5">
                <div className={`${styles.subtitle} mb-3`}>
                  Records in Report
                </div>

                {fetching && <LoadIndicator size="md" />}

                {!fetching && (
                  <>
                    <div className={`mb-3`}>
                      <div className={`${styles.recordInReport} ps-3`}>
                        <div className="row">
                          <div className="col-3">
                            <span>Model</span>
                          </div>

                          <div className="col-1">
                            <span className={styles.seperator}></span>
                          </div>

                          <div className="col-8">
                            <span onClick={goToModelList}>{modelCount}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`mb-3`}>
                      <div className={`${styles.recordInReport} ps-3`}>
                        <div className="row">
                          <div className="col-3">
                            <span>Units</span>
                          </div>

                          <div className="col-1">
                            <span className={styles.seperator}></span>
                          </div>

                          <div className="col-8">
                            <span onClick={goToUnitList}>{unitCount}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`mb-3`}>
                      <div className={`${styles.recordInReport} ps-3`}>
                        <div className="row">
                          <div className="col-3">
                            <span>Quality</span>
                          </div>

                          <div className="col-1">
                            <span className={styles.seperator}></span>
                          </div>

                          <div className="col-8">
                            <span style={{ textDecoration: "none" }}>
                              {report.length}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`mb-3`}>
                      <div className={`${styles.recordInReport} ps-3`}>
                        <div className="row">
                          <div className="col-3">
                            <span>Cost Cr</span>
                          </div>

                          <div className="col-1">
                            <span className={styles.seperator}></span>
                          </div>

                          <div className="col-8">
                            <span style={{ textDecoration: "none" }}>
                              {price}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div
                className={`d-flex flex-row align-items-center flex-wrap ${styles.actions}`}
              >
                <button
                  type="button"
                  onClick={buyQuality}
                  disabled={report.length === 0 || downloading}
                >
                  Buy
                </button>

                <button disabled={downloading}>Cancel</button>

                <button
                  type="button"
                  disabled={
                    !Boolean(
                      screenState.reportDownloadResponse?.report_index
                    ) || downloading
                  }
                  onClick={downloadReport}
                >
                  Download Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBrandProduct;
