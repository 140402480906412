import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import UserService from "services/UserService";
import LoadIndicator from "components/LoadIndicator";
import VerficationtypesSelector from "components/VerificationTypesSelector";
import "./index.css";

function EnabledMethodModal({ show, handleClose, data }) {
  const [methods, setMethods] = useState([]);
  const [err, set_err] = useState([]);
  const token = useSelector(selectToken);

  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (data && data.methods_available instanceof Array) {
      setMethods(data.methods_available.map((e) => e.doc_id));
    }
  }, [data, edit]);

  const onSwitchClick = (doc_id) => {
    setMethods((prev) => {
      const d = [...prev];
      if (d.includes(doc_id)) {
        return d.filter((item) => item !== doc_id);
      } else {
        d.push(doc_id);
        return d;
      }
    });
  };

  const handleBack = () => {
    if (saving) return;

    if (edit) {
      setEdit(false);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    console.log("up");
    if (data && data.methods_available instanceof Array) {
      setMethods(data.methods_available.map((e) => e.doc_id));
    }

    return () => {
      setEdit(false);
      setMethods(data?.methods_available?.map((e) => e.doc_id) || []);
    };
  }, []);

  const save = async () => {
    if (methods.length < 2) {
      setSaving(false);
      return set_err(["Select at least 2 document type"]);
    }

    set_err([]);
    setSaving(true);

    try {
      await UserService.updateVerificationMethods(
        {
          verification_methods: methods,
        },
        token
      );

      setEdit(false);

      window.location.reload();
    } catch (error) {}

    setSaving(false);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} keyboard={false} centered>
        <Modal.Header
          closeButton
          style={{ borderBottom: "none", paddingTop: 32 }}
        ></Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          <div>
            <h5>Verification methods enabled</h5>

            {!edit ? (
              <ul>
                {data?.methods_available?.map((item) => (
                  <li key={"m-available-" + item.verif_id}>
                    <p className="enabled-methods-modal__veri_name">
                      {item.type_name}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <VerficationtypesSelector
                onSwitchClick={onSwitchClick}
                selected={methods}
              />
            )}
          </div>

          {err.map((text) => (
            <div className="error_message">{text}</div>
          ))}
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "none" }}>
          {!edit ? (
            <button className="btn btn-yellow" onClick={() => setEdit(true)}>
              Edit
            </button>
          ) : (
            <button className="btn btn-yellow" onClick={save} disabled={saving}>
              {saving ? <LoadIndicator /> : "Save"}
            </button>
          )}

          <button onClick={handleBack} className="btn btn-black">
            Back
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EnabledMethodModal;
