import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import QualityService from "services/QualityService";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { formatDDMMYYY } from "shared/helpers";
import { useMediaQuery } from "usehooks-ts";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const QualityRecords = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const matches = useMediaQuery("(max-width: 768px)");

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const { state } = useLocation();
  const service = new QualityService(dispatch, token);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (state && state.unit) {
      setSelectedUnit(state.unit);
    }
  }, [state]);

  const getRecords = async () => {
    if (!selectedUnit) return;

    try {
      const res = await service.getControllerUseQualityRecords(
        selectedUnit.controller_use_id
      );

      setRecords(res.data.data);
    } catch (error) {}
  };

  const goToUnitQualityRecord = ({ quality_id, symptom }) => () => {
    if (symptom === 1) return;
    
    navigate(`/submit-quality-information/unit-quality-record/${quality_id}`)
  }

  useEffect(getRecords, [selectedUnit]);

  const onAddClick = () => {
    if (selectedUnit)
      navigate("/submit-quality-information/select-symptom", {
        state: {
          unit: selectedUnit,
        },
      });
  };

  return (
    <RequireSubmittingUser>
      <div id="main-container">
        <div className="container">
          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          <h2 className="mb-4 userLevel-Title">Quality Records</h2>

          {selectedUnit && (
            <div className="mb-4">
              <div className="row mb-2">
                <div className="col-auto">
                  <div className={styles.topLabel}>
                    <span>QUN</span>
                  </div>
                </div>

                <div className={`${styles.topLabelVal} col-auto`}>
                  {selectedUnit.unit_id}
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-auto">
                  <div className={styles.topLabel}>
                    <span>Use Period</span>
                  </div>
                </div>

                <div className={`${styles.topLabelVal} col-auto`}>
                  {selectedUnit.start_time +
                    " - " +
                    (selectedUnit.end_time || "Ongoing")}
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-auto">
                  <div className={styles.topLabel}>
                    <span>Include All Symptoms</span>
                  </div>
                </div>

                <div className={`${styles.topLabelVal} col-auto`}>
                  <div className="h-100 d-flex flex-column justify-content-center">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={styles.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th>
                    <div>Symptoms</div>
                  </th>
                  <th>
                    <div>Severity</div>
                  </th>
                  <th>
                    <div>Seen</div>
                  </th>
                  {!matches && (
                    <th>
                      <div>Repaired</div>
                    </th>
                  )}
                  {!matches && (
                    <th>
                      <div>Sold</div>
                    </th>
                  )}
                  {!matches && (
                    <th>
                      <div>Missed</div>
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                {records.map((item) => (
                  <tr key={"quality-record-" + item.quality_id} onClick={goToUnitQualityRecord(item)}>
                    <td>
                      <div>{item.symptom_description}</div>
                    </td>
                    <td>
                      <div>{item.severity}</div>
                    </td>
                    <td>
                      <div>{formatDDMMYYY(item.date_seen, 2)}</div>
                    </td>
                    {!matches && (
                      <td>
                        <div>
                          {item.repaired ? formatDDMMYYY(item.repaired, 2) : "-"}
                        </div>
                      </td>
                    )}
                    {!matches && (
                      <td className="d-none d-md-table-cell">
                        <div>{item.status === 'Normal' ? item["sales(n)"] : item.status}</div>
                      </td>
                    )}
                    {!matches && (
                      <td className="d-none d-md-table-cell">
                        <div>-</div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 text-center">
            <button onClick={onAddClick} className="btn btn-yellow">Add Symptom</button>
          </div>
        </div>
      </div>
    </RequireSubmittingUser>
  );
};

export default QualityRecords;
