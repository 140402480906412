import { useEffect, useRef, useState } from "react";
import { TableCategorySelector } from "components/TableCategorySelector";

import styles from "./styles.module.css";
import AddCategoryModal from "components/AddCategoryModal";
import { useNavigate } from "react-router-dom";
import StepTwo from "./StepTwo";
import AddBrandModal from "../add-unit/components/forms/AddBrandModal";
import StepThree from "./StepThree";
import AddModelModal from "../add-unit/components/forms/AddModelModal";
import ModelService from "services/ModelService";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { FullScreenLoader } from "components/FullScreenLoader";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const RegisterItemScreenStep = {
    ONE: "ONE",
    TWO: "TWO",
    THREE: "THREE"
}

const RegisterItemScreen = () => {
    const navigate = useNavigate();
    const token = useSelector(selectToken);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(RegisterItemScreenStep.ONE);

    const tableCategorySelectorRef = useRef(null);
    const stepTwoRef = useRef(null);
    const stepThreeRef = useRef(null);

    const [data, setData] = useState({
        category: null,
        brand: null,
        model: null
    });

    const handleChange = (key) => (value) => {
        setData(prev => ({ ...prev, [key]: value }));
    }

    const previuosCategory = () => tableCategorySelectorRef.current?.goBack();
    const getCategoryTree = () => tableCategorySelectorRef.current?.tree || [];
    const onCancel = () => navigate(-1);
    const previousStep = () => {
        switch (step) {
            case RegisterItemScreenStep.ONE:
                break;

            case RegisterItemScreenStep.TWO:
                setStep(RegisterItemScreenStep.ONE);
                handleChange('category')(null);
                break;

            case RegisterItemScreenStep.THREE:
                setStep(RegisterItemScreenStep.TWO);
                handleChange('brand')(null);
                break;
        
            default:
                break;
        }
    }

    const [showAddCategory, setShowAddCategory] = useState(false);
    const openAddCategoryModal = () => {
        if (Boolean(data.category)) {
            setShowAddCategory(true);
        }
    };
    const closeAddCategoryModal = async (data) => {
        if (data && data.category_id) {
            tableCategorySelectorRef.current?.addChildCategory(data);
        }
        setShowAddCategory(false);
    };

    const [showAddBrand, setShowAddBrand] = useState(false);
    const openAddBrandModal = () => {
        setShowAddBrand(true);
    }
    const closeAddBrandModal = (data) => {
        if (data && data.brand_id) {
            stepTwoRef.current?.updateBrandList(data);
        }
    
        setShowAddBrand(false);
    };

    const [showAddModel, setShowAddModel] = useState(false);
    const openAddModelModal = () => {
        setShowAddModel(true);
    };
    const closeAddModelModal = (data) => {
        if (data && data.model_id) {
            stepThreeRef.current?.updateModelList(data);
        }
    
        setShowAddModel(false);
    };

    useEffect(() => {
        if (Boolean(data.category?.end)) {
            setStep(RegisterItemScreenStep.TWO);
        }
    }, [data.category?.end]);

    useEffect(() => {
        if (data.brand) {
            setStep(RegisterItemScreenStep.THREE);
        }
    }, [data.brand?.brand_id]);

    const goToStepFour = (model) => {
        const verified = model.brand_model_status === "verified";
        const allowselect = !Boolean(model.private) && (Boolean(model.transferable) || !Boolean(model.prevent_use_overlap));

        if (!verified || !allowselect) {
            // GO TO ADD UNIT
            console.log("GO TO ADD UNIT");
            navigate(`/submit-quality-information/add-unit`, {
                state: {
                    brand_model_id: model.brand_model_id
                }
            });
        } else {
            // GO TO SELECT UNIT
            navigate(`/submit-quality-information/select-unit`, {
                state: {
                    brand_model_id: model.brand_model_id
                }
            });
        }
    }

    useEffect(() => {
        const run = async (model) => {
            try {
                setLoading(true);
                if (model.brand_model_id) {
                    await new Promise(rs => setTimeout(rs, 1500));
                    goToStepFour(model);
                } else {
                    // Create Brand Model
                    const res = await ModelService
                        .createGenericModelBrandModel(token, dispatch, {model: model.model_id, brand: data.brand.brand_id});
    
                    goToStepFour(res.data);
                }
            } catch (error) {
                console.log('[ERROR]', error);
            } finally {
                setLoading(false);
            }
        }

        if (data.model?.model_id) {
            run(data.model);
        }
    }, [data.model?.model_id]);

    return (
        <RequireSubmittingUser>
            <AddModelModal
                show={showAddModel} 
                handleClose={closeAddModelModal} 
                brand={data.brand}
                category={data.category}
            />

            <AddCategoryModal
                handleClose={closeAddCategoryModal}
                show={showAddCategory}
                parent={data.category}
            />

            <AddBrandModal 
                show={showAddBrand} 
                handleClose={closeAddBrandModal} 
            />

            <FullScreenLoader isOpen={loading} />

            <div id="main-container" className={styles.wrapper}>
                <div className="container">
                    <h2 className="mb-4 userLevel-Title">Register Item</h2>


                    <div className="mt-4">
                        {step === RegisterItemScreenStep.ONE && (
                            <>
                                <p className={`${styles.subTitle}`}>Select category below</p>
                            
                                <TableCategorySelector 
                                    ref={tableCategorySelectorRef} 
                                    category={data.category} 
                                    setCategory={handleChange("category")} />
                            </>
                        )}

                        {step === RegisterItemScreenStep.TWO && (
                            <StepTwo
                                ref={stepTwoRef} 
                                categoryTree={getCategoryTree}
                                category={data.category}
                                previousStep={previousStep}
                                brand={data.brand}
                                onBrandChange={handleChange("brand")}
                            />
                        )}

                        {step === RegisterItemScreenStep.THREE && (
                            <StepThree
                                ref={stepThreeRef}
                                brand={data.brand}
                                category={data.category}
                                previousStep={previousStep}
                                onModelChange={handleChange("model")}
                            />
                        )}
                    </div>

                    {Boolean(data.category?.allow_end_category) && step === RegisterItemScreenStep.ONE && (
                        <div className="mb-4">
                            <a className="d-block link" onClick={openAddCategoryModal}>Add new category</a>
                        </div>
                    )}

                    {step === RegisterItemScreenStep.TWO && (
                        <div className="mb-4">
                            <a className="d-block link" onClick={openAddBrandModal}>Add new brand</a>
                        </div>
                    )}

                    {step === RegisterItemScreenStep.THREE && (
                        <div className="mb-4">
                            <a className="d-block link" onClick={openAddModelModal}>Add new model</a>
                        </div>
                    )}

                    <div className="d-flex flex-row align-items-center justify-content-start gap-4 flex-wrap">
                        {step === RegisterItemScreenStep.ONE && (
                            <button 
                                disabled={!Boolean(data.category)} 
                                className={`btn btn-green ${styles.category_back}`} 
                                type="button"
                                onClick={previuosCategory}
                            >
                                <i className="bi bi-arrow-left"></i>
                            </button>
                        )}

                        <button className="btn btn-danger" type="btn" onClick={onCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </RequireSubmittingUser>
    );
}

export default RegisterItemScreen;