import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function DeleteModal({ show, handleClose }) {
  const [options, setOptions] = useState({
    use_of_unit: false,
    unit: false,
  });

  useEffect(() => {
    if (!show) setOptions({ use_of_unit: false, unit: false });
  }, [show]);

  const updateOptions = (option, value) =>
    setOptions((prev) => ({ ...prev, [option]: value }));

  const handleOk = () => handleClose(options);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          <div className="container px-5">
            <div className="row mb-4">
              <div className="col-md-3">Delete use unit</div>
              <div className="col-md-9">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    checked={options.use_of_unit}
                    onChange={(e) =>
                      updateOptions("use_of_unit", e.target.checked)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">Delete unit</div>
              <div className="col-md-9">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    disabled={!options.use_of_unit}
                    checked={options.unit}
                    onChange={(e) => updateOptions("unit", e.target.checked)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>

          <Button
            variant="primary"
            disabled={!options.use_of_unit && !options.unit}
            onClick={handleOk}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
