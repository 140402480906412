import React, { useEffect, useRef, useState } from "react";
import { formatDDMMYYY, logger } from "shared/helpers";
// import "./styles.css";

const getLabel = (input, labels = []) => {
  if (labels.length === 0) {
    return "";
  }

  const labelValues = [];

  labels.forEach((label) => {
    if (typeof label === "string") {
      if (input[label]) labelValues.push(input[label]);
    } else {
      const val = input[label.label];
      if (val) {
        if (label.date) {
          labelValues.push(formatDDMMYYY(val));
        } else {
          labelValues.push(val);
        }
      }
    }
  });

  return labelValues.join(", ");
};

function AutocompleteV2({
  id,
  suggestions,
  disabled,
  setValue,
  placeholder,
  labels,
  sortKey,
  primaryKey,
  required,
  initialValue,
  value = null,
  addKeyToLabel,
  first = [],
  last,
  disabledTyping
}) {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");

  const onClick = (valueChange = false) => {
    valueChange = typeof valueChange === "boolean" && valueChange;

    let idx =
      typeof valueChange === "boolean" && valueChange
        ? suggestions.findIndex((e) => e[primaryKey] === value)
        : activeSuggestionIndex;

    if (idx === -1) return;

    if (first && first instanceof Array && first.length > 0) {
      idx = idx - first.length;
    }

    let currentSuggestion =
      typeof valueChange === "boolean" && valueChange
        ? suggestions[idx]
        : filteredSuggestions[idx];

    if (!currentSuggestion) {
      setFilteredSuggestions([]);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);

      if (idx < 0) {
        // setValue(first[idx + first?.length || 0]);
        currentSuggestion = first[idx + first?.length || 0];
      } else {
        // setValue(last);
        currentSuggestion = last;
      }
      // return;
    }

    let input = getLabel(currentSuggestion, labels);

    if (
      primaryKey === "category_id" &&
      !currentSuggestion["verified_date"] &&
      input !== "All"
    )
      input = input + " (p)";

    if (value === null || valueChange) setInput(input);
    if (value === null || !valueChange) setValue(currentSuggestion);
    setFilteredSuggestions([]);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {
    if (Number(e.keyCode) === 13) {
      e.preventDefault();
      onClick();
    } else if (Number(e.keyCode) == "38") {
      if (activeSuggestionIndex > 0)
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (Number(e.keyCode) == "40") {
      if (activeSuggestionIndex < filteredSuggestions.length)
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  const onHover = (index) => {
    setActiveSuggestionIndex(index);
  };

  const initialIsSet = useRef(false);
  // const [initialIsSet, setInitialIsSet] = useState(false);
  useEffect(() => {
    if (initialValue && suggestions.length > 0 && !initialIsSet.current) {
      const selected = suggestions.filter(
        (item) => item[primaryKey] === initialValue
      )[0];

      if (selected) {
        let input = getLabel(selected, labels);

        setInput(input);
      }

      initialIsSet.current = true;
    } else if (typeof initialValue === "undefined") {
      initialIsSet.current = true;
    }
  }, [initialValue, suggestions]);

  function sortList(suggestionA, suggestionB) {
    if (suggestionA[sortKey] < suggestionB[sortKey]) {
      return -1;
    }

    if (suggestionA[sortKey] > suggestionB[sortKey]) {
      return 1;
    }

    return 0;
  }

  const onChange = (query = "") => {
    if (!query) {
      if (initialIsSet.current === true) setInput("");
      setFilteredSuggestions([...suggestions].sort(sortList));
      setActiveSuggestionIndex(0);
      setShowSuggestions(true);
      return;
    }

    if (!initialIsSet.current && query) return setInitialIsSet(true);

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter((suggestion) => {
      let input = getLabel(suggestion, labels);

      if (
        primaryKey === "category_id" &&
        !suggestion.verified_date &&
        input !== "All"
      )
        input = input + " (p)";

      return input?.toLowerCase()?.includes(query.toLowerCase());
    });

    setInput(query);
    setFilteredSuggestions([...unLinked].sort(sortList));
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const toggleShowSuggestions = () => {
    if (disabled) {
      if (showSuggestions) {
        setShowSuggestions(false);
      }

      return;
    }

    if (!showSuggestions) {
      setFilteredSuggestions([...suggestions].sort(sortList));
    } else {
      setFilteredSuggestions([]);
    }

    setActiveSuggestionIndex(0);
    setShowSuggestions((prev) => !prev);
  };

  useEffect(() => {
    if (
      !value ||
      Array.isArray(suggestions) === false ||
      suggestions.length === 0
    ) {
      setInput("");
      setActiveSuggestionIndex(0);
    } else {
      onClick(true);
    }
  }, [value, suggestions]);

  return (
    <div className="qualy-autocomplete position-relative">
      <div
        className={`input-wrapper form-control ${
          disabled ? "input-wrapper-disabled" : ""
        }`}
      >
        <input
          id={id}
          type="text"
          onChange={(e) => !Boolean(disabledTyping) && onChange(e.target.value)}
          value={input || ""}
          disabled={disabled}
          className="form-control autocomplete"
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onBlur={() => {
            if (showSuggestions) {
              setFilteredSuggestions([]);
              setActiveSuggestionIndex(0);
              setShowSuggestions(false);
            }
          }}
          onFocus={() => {
            if (filteredSuggestions.length === 0) {
              setFilteredSuggestions([...suggestions].sort(sortList));
              setActiveSuggestionIndex(0);
              setShowSuggestions(true);
            }
          }}
          required={typeof required === "undefined" ? true : required}
          autoComplete="off"
        />
        <i
          className="bi bi-caret-down-fill"
          onClick={toggleShowSuggestions}
        ></i>
      </div>

      {showSuggestions ? (
        <SuggestionsListComponent
          filteredSuggestions={filteredSuggestions}
          labels={labels}
          onHover={onHover}
          activeSuggestionIndex={activeSuggestionIndex}
          primaryKey={primaryKey}
          onClick={onClick}
          last={last}
          first={first}
          addKeyToLabel={addKeyToLabel}
        />
      ) : null}
    </div>
  );
}

const SuggestionsListComponent = ({
  filteredSuggestions = [],
  labels,
  onHover,
  activeSuggestionIndex,
  primaryKey,
  last,
  first,
  onClick,
  addKeyToLabel,
}) => {
  return (
    <ul className="suggestions position-absolute">
      {[...first, ...filteredSuggestions, last].map((suggestion, index) => {
        if (!suggestion) return;

        let input = getLabel(suggestion, labels);

        if (addKeyToLabel && suggestion[primaryKey] !== "")
          input = suggestion[primaryKey] + " - " + input;

        if (
          primaryKey === "category_id" &&
          !suggestion.verified_date &&
          input !== "All"
        )
          input = input + " (p)";

        return (
          <li
            className={
              index === activeSuggestionIndex ? "suggestion-active" : ""
            }
            key={suggestion[primaryKey]}
            style={{ padding: 4, color: suggestion.color }}
            onMouseDown={onClick}
            onMouseEnter={() => onHover(index)}
          >
            {input}
          </li>
        );
      })}
    </ul>
  );
};

export default AutocompleteV2;
