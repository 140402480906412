const MessageIcon = ({ color = "#fff" }) => {

    return (    
        <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.2 0H2.8C1.2558 0 0 1.17985 0 2.63065V18.4145C0 19.8653 1.2558 21.0452 2.8 21.0452H7V26L15.7878 21.0452H25.2C26.7442 21.0452 28 19.8653 28 18.4145V2.63065C28 1.17985 26.7442 0 25.2 0ZM25.2 18.4145H15.0122L9.8 21.3516V18.4145H2.8V2.63065H25.2V18.4145Z" fill={color}/>
        </svg>        
    );
}

export {
    MessageIcon
}