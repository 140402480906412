const fullGuideanceText = `
    Images must be clear and be able to indentify the individual.
    Text relating to the user should be clearly readable. Image
    format should be jpeg (.jpg).
    new-line
    To use your phone to take these images make sure the phone is
    paralell to the document,the lighing is even and you are not
    blocking the light with shadow. Ideally use natural light from a
    window. Try to fill the screen with the document and don't use a
    very wide angle lens if you have a choice. After taking it,zoom
    in to check the text is clear and there are no severe
    reflections blocking the image.
    new-line
    If you have to use indoor lighting try turning the flash off and holding the phone with your elbows on the same surface the 
    document is on. This will steady the camera with a slightly longer exposure to deal with lower light levels.
`;

export {
    fullGuideanceText
}