import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import InviteUserService from "services/InviteUserService";
import { handleError, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "components/Errors";
import LoadIndicator from "components/LoadIndicator";
import "./index.css";
import InvalidLevelModal from "./InvalidLevelModal";
import DesktopVersion from "./desktop";
import MobileVersion from "./mobile";
import WarningModal from "components/WarningModal";
import { selectDbVariablesData } from "redux-store/features/dbVariableSlice";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const UserInvite = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { variables } = useSelector(selectDbVariablesData);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const mediaQuery = window.matchMedia("(max-width: 600px)");
  const [showMobileVersion, setShowMobileVersion] = useState(
    mediaQuery.matches
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowMobileVersion(mediaQuery.matches);
    });
  }, [mediaQuery.matches]);

  useEffect(() => {
    if (user) {
      if (!user?.submitting) {
        setIsModalOpen(true);
      }
    }
  }, [user]);

  const inviteService = new InviteUserService(token, dispatch);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const [inviting, setInviting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState("");

  const [invites, setInvites] = useState([]);

  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  const [canInvite, setCanInvite] = useState(false);

  const fetchCanInvite = async () => {
    try {
      const res = await inviteService.userCanInvite();

      setCanInvite(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const fetchInvites = async () => {
    try {
      const res = await inviteService.getMyInvites();

      setInvites(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const isEmail = useMemo(() => {
    return email.includes('@') || user?.submitting?.status === 'on-hold' || user?.submitting?.verification_level === 1 
  }, [email, user?.submitting]);

  const [warning, setWarning] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setInviting(true);

    try {
      const payload = isEmail ? {email} : {name: email}; 
      const res = await inviteService.createUserInvite(payload);

      if (res.status === 201) {
        // Show success message

        if (invites.filter((item) => item.email === email || item.name === email).length === 0) {
          setInvites((prev) => [res.data.data, ...prev]);
        }

        if (res.data.link) {
          navigator.clipboard.writeText(res.data.link);
          setWarning('Link copied to clipboard.');
        }

        setEmail("");
      }
    } catch (error) {
      handleError(error, setErrors);
    }

    setInviting(false);
  };

  const deleteUserInvite = async (id) => {
    try {
      const res = await inviteService.deleteUserInvite(id);

      if (res.status === 200) {
        setInvites((prev) => prev.filter((item) => item.invite_id !== id));
      }
    } catch (error) {
      handleError(error, setErrors);
    }
  };

  const resendUserInvite = async (invite_id) => {
    if (!invite_id) return;

    try {
      const res = await inviteService.resend(invite_id);

      if (res.status === 200) {
        setInvites((prev) =>
          prev.map((inv) => {
            if (inv.invite_id === res.data.data.invite_id) {
              return { ...inv, ...res.data.data };
            }
            return inv;
          })
        );
      }

      if (res.data.link) {
        navigator.clipboard.writeText(res.data.link);
        setWarning('Link copied to clipboard.');
      }
    } catch (error) {
      handleError(error, setErrors);
    }
  };

  useEffect(() => {
    fetchCanInvite();
    fetchInvites();
  }, []);

  return (
    <RequireSubmittingUser>
      <InvalidLevelModal
        show={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
          navigate("/");
        }}
      />

      <WarningModal 
        text={warning}
        show={Boolean(warning)}
        handleOk={() => setWarning('')}
        handleClose={() => setWarning('')}
        hideCancel
      />

      <div id="main-container">
        <div className="container">
          <span className="user-invite__title_span fadeAnim">
            Creating an Information Economy
          </span>

          <h3 className="mb-4 fadeAnim user-invite__title">
            Invite Private Submitting Users
          </h3>

          {errors.length > 0 && <ErrorsComponent errors={errors} />}

          {canInvite && (
            <form
              id="inviteForm"
              className="user-invite__new mb-5 fadeAnim"
              onSubmit={handleSubmit}
            >
              <label htmlFor="userEmail">
                <span>Enter the email or full name of the person you want to invite:</span>
              </label>

              <input
                id="userEmail"
                type="text"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <button
                type="submit"
                form="inviteForm"
                disabled={!canInvite || inviting}
              >
                {inviting ? <LoadIndicator /> : isEmail ? "Invite" : "Copy link"}
              </button>
            </form>
          )}

          {!canInvite && (
            <div className="mb-5">
              <h4> Max limit of unsigned users reached </h4>
            </div>
          )}

          {user && user.submitting && (user.submitting.verification_level === 1 || user.submitting.status == "on-hold") && (
            <div className="mb-4 user-invite__warning">
              *You need to <Link to={'/submit-role-details'}>verify your identity</Link> before invites are sent
            </div>
          )}

          {showMobileVersion ? (
            <MobileVersion
              invites={invites}
              deleteUserInvite={deleteUserInvite}
              resendUserInvite={resendUserInvite}
            />
          ) : (
            <DesktopVersion
              invites={invites}
              deleteUserInvite={deleteUserInvite}
              resendUserInvite={resendUserInvite}
            />
          )}

          {
            variables && variables.invite_incentive > 0 && (
              <span className="user-invite__extra_info d-block mb-2 fadeAnim">
                *Receive {variables.invite_incentive} credits when your invite verifies their address (Level 2)
              </span>
            )
          }

          <span className="user-invite__extra_info fadeAnim">
            *Note that the rate at which invites are sent out will vary
          </span>

          <div className="mt-4 text-center fadeAnim">
            <button
              type="button"
              onClick={goBack}
              className="btn btn-black"
              disabled={inviting}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </RequireSubmittingUser>
  );
};

export default UserInvite;
