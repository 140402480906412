import { Modal } from "react-bootstrap";
import AddBrand from "pages/dashboard/add-brand";

const AddBrandModal = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      fullscreen
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none", backgroundColor: "black" }}
      ></Modal.Header>

      <Modal.Body style={{ backgroundColor: "black", padding: 0 }}>
        <AddBrand closeModal={handleClose} />
      </Modal.Body>
    </Modal>
  );
};

export default AddBrandModal;
