import { axiosClient } from "shared/axiosInstance";

class DbVariablesService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  getDatabaseGlobalVariables() {
    return axiosClient(this.dispatch, this.token).get("/db-variables");
  }
}

export default DbVariablesService;
