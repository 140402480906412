import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import AuthenticationService from "services/AuthenticationService";
import LoadIndicator from "components/LoadIndicator";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import { handleError, logger, useShortDisplay } from "shared/helpers";
import { verifyPhone } from "redux-store/features/authSlice";
import { useDispatch } from "react-redux";
import CustomPhoneInput from "components/CustomPhoneInput";

// Mail, Password and Phonenumber
const StepFour = ({ next, onChange, form, isInvite, inviteDetails }) => {
  const dispatch = useDispatch();
  // STATE
  const [err, set_err] = useState([]);
  useShortDisplay(() => set_err([]), 6000, err.length > 0);

  const [success, set_success] = useState(null);
  const [step, setStep] = useState(1);

  const [email_verified, set_email_verified] = useState(false);
  const [phone_verified, set_phone_verified] = useState(false);
  const [loading, set_loading] = useState(false);

  function isAllPresent(str) {
    // Regex to check if a string
    // contains uppercase, lowercase
    // special character & numeric value
    var pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );

    // If the string is empty
    // then print No
    if (!str || str.length === 0) {
      return false;
    }

    // Print Yes If the string matches
    // with the Regex
    if (pattern.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  const isPasswordInvalid = () => {
    if (form.password?.length < 8)
      return "Password must be at least 8 characters in length";

    if (!isAllPresent(form.password))
      return "Password must contain at least 1 of each: uppercase, lowercase, number and special characters";

    return null;
  };

  const performCorrectAction = (e) => {
    e.preventDefault();

    if (email_verified) {
      set_err([]);
      next(e, phone_verified);
    } else {
      set_err(["Please verify your email"]);
    }
  };

  const [verifying, setVerifying] = useState(false);
  const [created, setCreated] = useState(false);

  const verifyEmail = async () => {
    set_success(null);

    set_loading(true);
    setVerifying(true);

    try {
      const payload = {};

      const mobile = form.phone === form.dialCode ? "" : form.phone;

      payload.email = form.email;
      payload.language = form.language;
      payload.accessability = form.accessability;
      payload.user_type = form.user_type;
      // payload.isfirstaccount = form.isfirstaccount;
      payload.first_name = form.first_name;
      payload.last_name = form.last_name;
      payload.postcode = form.postcode;
      payload.country = form.country;
      payload.road_name = form.road_name;
      payload.state = form.state;
      payload.mobile = mobile;
      payload.property_number = form.property_number;
      payload.town = form.city;
      payload.gender = form.gender;
      payload.date_of_birth = `${form.year}/${form.month}/${form.day}`;
      payload.password = form.password;
      payload.isInvite = isInvite;
      payload.inviteToken = new URLSearchParams(window.location.search).get(
        "ref-data"
      );

      const res = await AuthenticationService.signup(payload);

      setCreated(true);
      setStep(2);

      if (res.data.data) {
        onChange("user", res.data.data);
      }
    } catch (error) {
      const msg = error.response?.data?.message;
      if (msg) {
        if (msg instanceof String || typeof msg === "string") set_err([msg]);
        else {
          const arr = [];
          Object.keys(msg).map((key) => {
            for (const emsg of msg[key]) arr.push(emsg);
          });

          set_err(arr);
        }
      } else {
        set_err(["Something went wrong, check your internet"]);
      }
    }

    set_loading(false);
    setChecking(false);
    setVerifying(false);
  };

  const navigate = useNavigate();
  const cancelSignup = () => {
    // if (created) return;

    navigate("/");
  };

  const [agree, setAgree] = useState(false);
  const [code, setCode] = useState("");

  const verifyEmailCode = async () => {
    if (loading) return;

    if (!code) return set_err(["Please enter verification code"]);

    set_loading(true);
    try {
      const res = await AuthenticationService.verify_mail({
        email: form.email,
        code,
      });

      if (res.data.data) {
        onChange("user", res.data.data);
      }

      set_email_verified(true);
      setStep(3);
    } catch (error) {
      handleError(error, set_err);
    }
    set_loading(false);
  };

  const skipPhoneVerification = () => {
    next(null, phone_verified);
  };

  const verifyPhoneNow = async () => {
    set_loading(true);
    try {
      const mobile = form.phone === form.dialCode ? "" : form.phone;
      await AuthenticationService.verify_phone(mobile, form.email);

      setStep(4);
    } catch (error) {
      handleError(error, set_err);
    }
    set_loading(false);
  };

  const [phoneCode, setPhoneCode] = useState("");

  const confirmCode = async () => {
    set_loading(true);
    try {
      const mobile = form.phone === form.dialCode ? "" : form.phone;

      const res = await AuthenticationService.verify_code({
        email: form.email,
        phone_number: mobile,
        code: phoneCode,
      });

      set_phone_verified(true);

      dispatch(verifyPhone());

      setStep(5);
    } catch (error) {
      logger("ERROR", error);
      handleError(error, set_err);
    }
    set_loading(false);
  };

  const [checking, setChecking] = useState(false);
  const areDetailsUnique = async () => {
    setChecking(true);

    const mobile = form.phone === form.dialCode ? "" : form.phone;

    try {
      const res = await AuthenticationService.are_details_uniqe(
        form.email,
        mobile
      );

      if (!res.data.success) {
        set_err([res.data.message]);
        setChecking(false);
      }

      // setChecking(false);
      return res.data.success;
    } catch (error) {
      logger("Error ", error);
      setChecking(false);
      return false;
    }
  };

  const sendVerificationMail = async () => {
    if (verifying) return;
    if (!form.email) return set_err(["Please enter email"]);

    const isNotValid = isPasswordInvalid();
    if (isNotValid) return set_err([isNotValid]);

    const isUnique = await areDetailsUnique();

    if (isUnique) {
      const isMailDomainValid = (
        await AuthenticationService.isEmailDomainValid(form.email)
      ).data.data;

      if (!isMailDomainValid) {
        setChecking(false);
        return set_err(["Invalid email domain"]);
      }

      verifyEmail();
    }
  };

  const cancelEmailVerification = async () => {
    setStep(1);

    await AuthenticationService.cancelEmailVerification(form.email);
  };

  const [creating, setCreating] = useState(false);
  const goToStepThree = async () => {
    const isNotValid = isPasswordInvalid();
    if (isNotValid) return set_err([isNotValid]);

    const isUnique = await areDetailsUnique();

    if (isUnique) {
      const payload = {};

      payload.email = form.email;
      payload.language = form.language;
      payload.accessability = form.accessability;
      payload.user_type = form.user_type;
      // payload.isfirstaccount = form.isfirstaccount;
      payload.first_name = form.first_name;
      payload.last_name = form.last_name;
      payload.postcode = form.postcode;
      payload.country = form.country;
      payload.road_name = form.road_name;
      payload.state = form.state;
      payload.mobile = form.phone;
      payload.property_number = form.property_number;
      payload.town = form.city;
      payload.gender = form.gender;
      payload.date_of_birth = `${form.year}/${form.month}/${form.day}`;
      payload.password = form.password;
      payload.isInvite = isInvite;

      setCreating(true);
      const res = await AuthenticationService.signup(payload);

      setCreated(true);
      setStep(2);

      if (res.data.data) {
        onChange("user", res.data.data);
      }

      setCreating(false);
      setStep(3);
    }
  };

  useEffect(() => {
    if (isInvite && inviteDetails) {
      set_email_verified(true);
      onChange("email", inviteDetails.email);
    }
  }, [isInvite, inviteDetails]);

  return (
    <>
      <form
        className="registerForm"
        onSubmit={(e) => {
          e.preventDefault();

          if (step === 1) {
            if (isInvite) {
              goToStepThree();
            } else {
              sendVerificationMail();
            }
          }
        }}
      >
        {step === 1 && (
          <div className="mb-4">
            <h3 className="title3">
              Please create your password and enter all the details below
            </h3>
          </div>
        )}

        {step === 1 && (
          <VerifyEmailBody
            form={form}
            onChange={onChange}
            isInvite={isInvite}
            inviteDetails={inviteDetails}
          />
        )}

        {step === 2 && (
          <>
            <ConfirmCodeBody
              code={code}
              setCode={setCode}
              type={"email"}
              data={form.email}
            />

            <p>
              <input
                className="form-check-input"
                type="checkbox"
                checked={agree}
                onClick={() => setAgree((prev) => !prev)}
              />{" "}
              I accept{" "}
              <a
                href="/basic_user_t_c.pdf"
                target="_blank"
                className="infolink"
              >
                Terms and Conditions
              </a>{" "}
              &{" "}
              <a
                target="_blank"
                href="/non_commercial_privacy.pdf"
                className="infolink"
              >
                Privacy Policy
              </a>
            </p>
          </>
        )}

        {step === 3 && (
          <div className="mb-4">
            <h3 className="title3">
              Email verification successful, if you want to start a user role
              (such as a submitting role) now you need to verify your phone, but
              this can be done later.
            </h3>
          </div>
        )}

        {step === 4 && (
          <ConfirmCodeBody
            code={phoneCode}
            setCode={setPhoneCode}
            type={"phone"}
          />
        )}

        {step === 5 && (
          <div className="mb-4">
            <h3 className="title3">Phone verfication successful</h3>
          </div>
        )}

        {step === 1 && (
          <div className="mb-4">
            Password must be atleast 8 characters long and should contain 1
            uppercase, 1 lowercase, 1 special character, 1 number
          </div>
        )}

        {err && err.map((e) => <div className="error_message">{e}</div>)}
        {success && <div>{success}</div>}

        {step === 1 && (
          <VerifyEmailActions
            verifying={verifying}
            cancelSignup={cancelSignup}
            checking={checking}
            creating={creating}
            isInvite={isInvite}
          />
        )}

        {step === 2 && (
          <ConfirmEmailActions
            confirmCode={verifyEmailCode}
            loading={loading}
            disabled={!agree}
            isEmail
            onCancel={cancelEmailVerification}
          />
        )}

        {step === 3 && (
          <StepThreeActions
            form={form}
            loading={loading}
            skipPhoneVerification={skipPhoneVerification}
            verifyPhoneNow={verifyPhoneNow}
          />
        )}

        {step === 4 && (
          <ConfirmPhoneActions
            code={phoneCode}
            loading={loading}
            skipPhoneVerification={skipPhoneVerification}
            confirmCode={confirmCode}
          />
        )}

        {step === 5 && (
          <div className="mt-2 text-center">
            <button
              type="button"
              onClick={performCorrectAction}
              className="btn btn-yellow"
            >
              Continue
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default StepFour;

const VerifyEmailBody = ({ form, onChange, isInvite }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggle_password = () => setShowPassword(!showPassword);

  return (
    <>
      {/** PASSWORD */}
      <div className="row">
        <div className="col-md-12 mb-4">
          <label className="form-label" htmlFor="password">
            Password <em className="required">*</em>
          </label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="password"
              placeholder="Password"
              name="name"
              value={form?.password}
              maxLength={50}
              onChange={(e) => onChange("password", e.target.value)}
              required
            />
            <div
              onClick={toggle_password}
              style={{ marginLeft: 10, cursor: "pointer" }}
            >
              {!showPassword ? (
                <FontAwesomeIcon icon={regular("eye")} size="lg" />
              ) : (
                <FontAwesomeIcon icon={regular("eye-slash")} size="lg" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/** PHONE */}
      <div className="row">
        <div className="col-md-12 mb-4">
          <label className="form-label" htmlFor="phone">
            Phone
          </label>

          <CustomPhoneInput
            id="phone"
            country={form.countryCode?.toLowerCase()}
            value={form.phone}
            onChange={(phone) => {
              onChange("phone", phone);
            }}
            onDialCodeChange={(code) => onChange("dialCode", code)}
          />
        </div>
      </div>

      {/** EMAIL */}
      {!isInvite && (
        <div className="row">
          <div className="col-md-12 mb-4">
            <label className="form-label" htmlFor="email">
              Email <em className="required">*</em>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email Address"
              name="name"
              value={form.email}
              onChange={(e) => {
                onChange("email", e.target.value);
              }}
              required
            />
          </div>
        </div>
      )}
    </>
  );
};

const VerifyEmailActions = ({
  verifying,
  cancelSignup,
  isInvite,
  checking,
  creating,
}) => {
  return (
    <div className="mt-2 text-center">
      <button type="submit" className="btn btn-yellow me-2">
        {verifying || checking || creating ? (
          <LoadIndicator />
        ) : isInvite ? (
          "Next"
        ) : (
          "Verify email now"
        )}
      </button>

      <button className="btn btn-black" onClick={cancelSignup} type="button">
        Cancel signup
      </button>
    </div>
  );
};

const ConfirmCodeBody = ({ code, setCode, type, data }) => {
  const getLabel = () => {
    if (type === "email") {
      return `Enter email verification code for ${data}`;
    }

    return `Enter ${type} verification code`;
  };

  return (
    <>
      {/** CODE */}
      <div className="row">
        <div className="col-md-12 mb-4">
          <label className="form-label" htmlFor="code">
            {getLabel()} <em className="required">*</em>
          </label>
          <input
            type="text"
            className="form-control"
            id="code"
            placeholder="Code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            required
          />
        </div>
      </div>
    </>
  );
};

const ConfirmEmailActions = ({
  confirmCode,
  loading,
  isEmail,
  onCancel,
  disabled,
}) => {
  return (
    <div className="mt-2 text-center">
      <button
        onClick={confirmCode}
        className="btn btn-yellow me-3"
        type="button"
        disabled={disabled}
      >
        {loading ? <LoadIndicator /> : "Submit"}
      </button>

      {isEmail && (
        <button type="button" className="btn btn-black" onClick={onCancel}>
          Cancel
        </button>
      )}
    </div>
  );
};

const StepThreeActions = ({
  verifyPhoneNow,
  skipPhoneVerification,
  form,
  loading,
}) => {
  return (
    <div className="mt-2 text-center">
      <button
        onClick={verifyPhoneNow}
        className="btn btn-yellow me-2"
        disabled={!form.phone || form.phone === form.dialCode}
        type="button"
      >
        {loading ? <LoadIndicator /> : "Verify phone now"}
      </button>

      <button
        className="btn btn-black"
        onClick={skipPhoneVerification}
        type="button"
      >
        Skip phone verification
      </button>
    </div>
  );
};

const ConfirmPhoneActions = ({
  confirmCode,
  loading,
  code,
  skipPhoneVerification,
}) => {
  return (
    <div className="mt-2 text-center">
      <button
        onClick={confirmCode}
        className="btn btn-yellow  me-2"
        disabled={!code}
        type="button"
      >
        {loading ? <LoadIndicator /> : "Submit"}
      </button>

      <button
        className="btn btn-black"
        onClick={skipPhoneVerification}
        type="button"
      >
        Cancel
      </button>
    </div>
  );
};
