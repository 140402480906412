import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  originalCategoryAttributes: null,
  selectedUnit: null,
  selectedAberration: null,
  selectedSymptom: null,
  selectedQuality: null
};

export const unitQualityRecordSlice = createSlice({
  name: "unit_quality_record",
  initialState,
  reducers: {
    setSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload || null;
    },
    setSelectedAberration: (state, action) => {
      state.selectedAberration = action.payload || null;
    },
    setSelectedSymptom: (state, action) => {
      state.selectedSymptom = action.payload || null;
    },
    setOriginalCategoryAttributes: (state, action) => {
      state.originalCategoryAttributes = action.payload || null;
    },
    setSelectedQuality: (state, action) => {
      state.selectedQuality = action.payload || null;
    }
  },
});

export const {
  setSelectedUnit,
  setSelectedAberration,
  setSelectedSymptom,
  setOriginalCategoryAttributes,
  setSelectedQuality,
} = unitQualityRecordSlice.actions;

export const selectUnitQualityRecordData = (state) => ({
  ...state.unit_quality_record,
});

export default unitQualityRecordSlice.reducer;
