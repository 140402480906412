import React, { useState } from "react";
import "./styles.css";

function CustomSelect({ suggestions, value, onChange, disabled }) {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

  const onClick = () => {
    const currentSuggestion = suggestions[activeSuggestionIndex];

    onChange(currentSuggestion);
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {
    if (Number(e.keyCode) === 13) {
      e.preventDefault();
      onClick(e);
    } else if (Number(e.keyCode) == "38") {
      if (activeSuggestionIndex > 0)
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (Number(e.keyCode) == "40") {
      if (activeSuggestionIndex < filteredSuggestions.length)
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  const onHover = (index) => {
    setActiveSuggestionIndex(index);
  };

  const capitalizeFirst = (string = "") => {
    return string[0]?.toUpperCase() + string.substring(1);
  };

  const SuggestionsListComponent = () => {
    return (
      <ul className="custom-suggestions">
        {suggestions.map((input, index) => {
          return (
            <li
              className={
                index === activeSuggestionIndex ? "suggestion-active" : ""
              }
              key={input + Math.random()}
              style={{ padding: 4 }}
              onMouseDown={onClick}
              onMouseEnter={() => onHover(index)}
            >
              {capitalizeFirst(input)}
            </li>
          );
        })}
      </ul>
    );
  };

  const toggleShowSuggestions = () => {
    setActiveSuggestionIndex(0);
    setShowSuggestions((prev) => !prev);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className={`input-wrapper form-control ${
          disabled ? "input-wrapper-disabled" : ""
        }`}
        onClick={toggleShowSuggestions}
        onBlur={() => {
          setActiveSuggestionIndex(0);
          setShowSuggestions(false);
        }}
        onKeyDown={onKeyDown}
      >
        <div
          className="form-control autocomplete"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {capitalizeFirst(value)}
        </div>
        <i
          className="bi bi-caret-down-fill"
          onClick={toggleShowSuggestions}
        ></i>
      </div>
      {showSuggestions && suggestions.length > 0 ? (
        <SuggestionsListComponent />
      ) : null}
    </div>
  );
}

export default CustomSelect;
