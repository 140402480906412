import { useUnits } from "hooks/useUnits";
import DataTable from "../data-table";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const unitColumns = [
  {
    text: "QUN",
    key: "unit_id",
    padded: true,
  },
  {
    text: "Brand Model",
    key: [
      { key: "brand", suffix: " - " },
      { key: "model_name_des", suffix: ", " },
      { key: "model_code" },
    ],
    sortable: true,
  },
  {
    text: "Past (Yrs)",
    key: (item) => {
      if (!item || !item.end_time) {
        return;
      }

      return Number(
        (
          (Date.now() - new Date(item.end_time).getTime()) /
          31536000000
        ).toFixed(1)
      );
    },
    sortable: true,
  },
  {
    text: "Action",
    add: true,
    onClick: () => {},
  },
];

const PastUse = () => {
  const navigate = useNavigate();

  const onRowClick = (rowData) => {
    navigate(`/submit-quality-information/item-detail/${rowData.unit_id}`);
  };

  const onAddClick = (rowData) => {
    navigate("/submit-quality-information/select-symptom", {
      state: {
        unit: rowData,
      },
    });
  };

  const { past_use, loading } = useUnits();

  const {
    multiple_use_products,
    single_use_products,
    single_use_services,
    continuous_use_services,
  } = past_use;

  return (
    <RequireSubmittingUser>
      <div id="main-container">
        {loading ? (
          <Spinner
            as="span"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <div className="container">
            <h2 className="mb-4 userLevel-Title">Past Use Items</h2>

            <div className="card mb-4">
              <div className="card-body">
                <h3 className="card-title mb-4">Multiple Use Products</h3>

                <DataTable
                  data={multiple_use_products}
                  columns={unitColumns}
                  clickAble
                  onRowClick={onRowClick}
                  onAddClick={onAddClick}
                />
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h3 className="card-title mb-4">Single use services</h3>

                <DataTable
                  data={single_use_services}
                  columns={unitColumns}
                  clickAble
                  onRowClick={onRowClick}
                  onAddClick={onAddClick}
                />
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h3 className="card-title mb-4">
                  Continuous use / subscription services
                </h3>

                <DataTable
                  data={continuous_use_services}
                  columns={unitColumns}
                  clickAble
                  onRowClick={onRowClick}
                  onAddClick={onAddClick}
                />
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h3 className="card-title mb-4">
                  Single use / Consumable Products
                </h3>

                <DataTable
                  data={single_use_products}
                  columns={unitColumns}
                  clickAble
                  onRowClick={onRowClick}
                  onAddClick={onAddClick}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </RequireSubmittingUser>
  );
};

export default PastUse;
