import { useHasFocus } from "hooks/useHasFocus";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken } from "redux-store/features/authSlice";
import {
  clearPrefillCompany,
  selectPrefilledData,
  setPrefilledBrand,
} from "redux-store/features/prefillSlice";
import BrandService from "services/BrandService";
import CompanyService from "services/CompanyService";
import { handleError, useForm } from "shared/helpers";
import DescModal from "components/DescModal";
import ErrorsComponent from "components/Errors";
import AddCompanyModal from "./AddCompanyModal";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

function AddBrand({ closeModal }) {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  // Description modal props
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const openDescriptionModal = (msg) => {
    setDescription(msg);
    setShowDescriptionModal(true);
  };

  const [companies, setCompanies] = useState([]);
  const getCompanies = async () => {
    try {
      const res = await CompanyService.getAvailableCompanies(token, dispatch);

      setCompanies(res.data?.data);
    } catch (e) {}
  };

  const { form, onChange } = useForm();

  useEffect(() => {
    if (focus) getCompanies();
  }, []);

  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);

  const saveBrandToDb = async (e) => {
    e.preventDefault();

    setSaving(true);
    try {
      const res = await BrandService.addBrandToDb(token, dispatch, {
        ...form,
      });

      closeModal(res.data.data);
    } catch (error) {
      handleError(error, setErrors);
    }
    setSaving(false);
  };

  const [showAddCompany, setShowAddCompany] = useState(false);

  const openAddCompanyModal = () => {
    setShowAddCompany(true);
  };

  const closeAddCompanyModal = (data) => {
    if (data && data.company_id) {
      setCompanies((prev) => [data, ...prev]);

      onChange("company", data.company_id);
    }

    setShowAddCompany(false);
  };

  return (
    <RequireSubmittingUser>
      <AddCompanyModal
        show={showAddCompany}
        handleClose={closeAddCompanyModal}
      />

      <DescModal
        show={showDescriptionModal}
        handleClose={() => setShowDescriptionModal(false)}
        text={description}
      />

      <div id="main-container">
        <div className="container">
          <h1 className="page-title mb-4">Add New Brand</h1>

          <form className="" onSubmit={saveBrandToDb}>
            <div className="registerForm ms-0 me-0">
              <ErrorsComponent errors={errors} />

              <div className="mb-4">
                <label className="form-label">
                  New Brand Name{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        "Brand name is the name you asscoiate with the quality of item as being the provider of a service or manufacturer of an item, sometimes this is just a shortened version of the full company name for example the name you see on the shop. Note that a shop or vendor is not normally the manufacturer of the goods it provides, however if no brand is visible at the point of sale then the vendor becomes the responsible brand. For sole traders their brand is often their name"
                      )
                    }
                  ></i>{" "}
                  <em className="required">*</em>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="brand"
                  placeholder="Brand Name"
                  name="brand"
                  value={form.brand || ""}
                  onChange={(e) => onChange("brand", e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="form-label">
                  Owner Company{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        "The owner company is the actual legal company who owns the brand, if this is a sole trader the name may not be registered as a limited company, or could be the persons name and the same as the brand"
                      )
                    }
                  ></i>
                </label>
                <select
                  className="form-select"
                  value={form.company}
                  onChange={(e) => onChange("company", e.target.value)}
                >
                  {[-1, ...companies].map((company) => {
                    if (company === -1)
                      return (
                        <option key={"none-c-1"} value={null}>
                          None
                        </option>
                      );

                    return (
                      <option
                        key={"company-d " + company.company_id}
                        value={company.company_id}
                      >
                        {company.company_name}
                      </option>
                    );
                  })}
                </select>
                <a
                  onClick={openAddCompanyModal}
                  className="mt-2 d-inline-block link"
                >
                  Add New Company
                </a>
              </div>
              <div className="mt-4 text-center">
                <button type="submit" className="btn btn-yellow">
                  {saving ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </RequireSubmittingUser>
  );
}

export default AddBrand;
