import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { USER_TYPE } from "shared/constants";
import { Personal } from "./Personal";
import { Commercial } from "./Commercial";

const SignupStart = () => {
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const userType =
      new URLSearchParams(window.location.search).get("type") ??
      USER_TYPE.PERSONAL;
    setUserType(userType);

    return () => {
      setUserType(null);
    };
  }, []);

  return (
    <div className="registerPage">
      {userType && (
        <section className="mt-5 mb-5">
          <div className="container">
            <h1 className="title1">Join Qualy today.</h1>

            {userType === USER_TYPE.PERSONAL && <Personal />}

            {userType === USER_TYPE.COMMERCIAL && <Commercial />}
          </div>
        </section>
      )}
    </div>
  );
};

export default SignupStart;
