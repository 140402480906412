import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./SelectUnit.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFilter,
  selectSymptomSelectionData,
  setSelectedUnit,
  updateFilter,
} from "redux-store/features/symptomSelectionSlice";
import {
  initializeAberrationList,
  selectAberrationManagementData,
} from "redux-store/features/aberrationManagementSlice";
import AberrationService from "services/AberrationService";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import { convertDateTimeString, useForm } from "shared/helpers";
import {
  initializeSymptomList,
  selectSymptomManagementData,
} from "redux-store/features/symptomManagementSlice";
import SymptomService from "services/SymptomService";
import CollapsedCategoryTreeViewer from "components/CollapsedCategoryTreeViewer";
import WarningModal from "components/WarningModal";
import SwitchAnswer from "components/SwitchAnswer";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const SelectSymptomPage = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const expandRef = useRef(null);

  const aberrationService = new AberrationService(dispatch, token);
  const symptomService = new SymptomService(dispatch, token);

  const goBack = () => navigate(-1);

  const { initialized, aberrations } = useSelector(
    selectAberrationManagementData
  );
  const { symptoms } = useSelector(
    selectSymptomManagementData
  );
  const { selectedUnit, filter } = useSelector(selectSymptomSelectionData);
  const { form, onChange } = useForm();
  const [skipCategoryIndex, setSkipCategoryIndex] = useState(0);

  useEffect(() => {
    if (state?.unit) {
      console.log(state.unit);
      dispatch(setSelectedUnit(state.unit));
    }
  }, [state]);

  const fetchAberrations = async () => {
    try {
      const res = await aberrationService.fetchAll();

      dispatch(initializeAberrationList(res.data.data || []));
    } catch (error) {}
  };
  useEffect(() => {
    if (!initialized) fetchAberrations();
  }, [initialized]);

  const fetchSymptoms = async () => {
    try {
      const res = await symptomService.fetchAll();

      dispatch(initializeSymptomList(res.data.data || []));
    } catch (error) {}
  };

  useEffect(() => {
    fetchSymptoms();
  }, []);

  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    setFiltered(symptoms);
  }, [symptoms]);

  useEffect(() => {
    if (!filter.category && !filter.aberration) {
      setFiltered(symptoms);
    } else {
      console.log(filter);
      const result = symptoms.filter((item) => {
        if (
          (!filter.category ||
            (item.cat_symps instanceof Array &&
              item.cat_symps.filter((e) => e.end_category === filter.category)
                .length > 0)) &&
          (!filter.aberration ||
            item.aberration_id === filter.aberration) && 
          (!filter.search || filter.searchEnabled !== "filter" || 
            item.description?.toLowerCase().includes(filter.search.toLowerCase()) || 
            item.additional_info?.toLowerCase().includes(filter.search.toLowerCase())) 
        ) {
          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  }, [filter, symptoms]);

  const handleCategoryChange = (category) => {
    if (!category || category.category_id === "none" || category.category_id === 1) {
      dispatch(updateFilter({ key: "category" }));
    } else {
      dispatch(updateFilter({ key: "category", value: category.category_id }));
    }
  };

  const getSerialNumberTitle = () => {
    if (
      !selectedUnit ||
      selectedUnit.serial_type == "none"
    )
      return "";

    return selectedUnit.serial_type;
  }

  const getSerialNumber = () => {
    if (
      !selectedUnit ||
      selectedUnit.serial_type == "none" ||
      !selectedUnit.unique_identifier ||
      selectedUnit.unique_identifier == "null"
    )
      return "";

    return selectedUnit.unique_identifier;
  };
  
  const getUseTime = () => {
    const start = convertDateTimeString(selectedUnit.start_time);
    const end = convertDateTimeString(selectedUnit.end_time);

    let start_string = "";
    let end_string = "";

    if (end && start.getDate() === end.getDate()) {
      start_string = selectedUnit.start_time;
    }  else {
      start_string = selectedUnit.start_time.split(" ")[0];
    }

    if (!end) {
      end_string = "ongoing";
    } else if (start.getDate() === end.getDate()) {
      end_string = selectedUnit.end_time;
    } else {
      end_string = selectedUnit.end_time.split(" ")[0];
    }

    return `${start_string} - ${end_string}`;
  }

  const selectedAberrationData = useMemo(() => {
    if (!filter.aberration) return null;

    return (
      aberrations.filter((e) => e.aberration_id === filter.aberration)[0] ||
      null
    );
  }, [filter.aberration]);

  const onItemClick = (data) => {
    if (!selectedUnit || data.status === "Barred" || !selectedAberrationData) return;

    const original_category = selectedUnit.category_id;
    const filter_category = filter.category;

    navigate("/submit-quality-information/unit-quality-record", {
      state: {
        from: "select_symptom",
        data: {
          original_category,
          filter_category,
          symptom: data,
          unit: selectedUnit,
          aberration: selectedAberrationData,
          description: form?.description,
        },
      },
    });
  };

  const goToNewSymptom = () => {
    if (!selectedUnit) return;

    const original_category = selectedUnit.category_id;

    navigate("/submit-quality-information/new-symptom", {
      state: {
        aberration: selectedAberrationData,
        unit: selectedUnit,
        original_category,
        filter_category: original_category,
        description: form.description
      },
    });
  };

  const expandCategoryTree = () => {
    if (expandRef.current) {
      setSkipCategoryIndex(expandRef.current.expand());
    }
  }

  const goToSubmittingRoleScreen = () => 
    navigate('/submit-role-details');


  useEffect(() => {
    if (filter.aberration) {
      setSkipCategoryIndex(expandRef.current?.reset());
    }
  }, [filter.aberration]);

  const [openWarning, setOpenWarning] = useState(false);

  useEffect(() => {
    if (user && user.submitting && user.submitting.status === "on-hold") {
      setOpenWarning(true);
    }
  }, [user?.user_id]);

  useEffect(() => {
    return () => {
      setOpenWarning(false);
      dispatch(resetFilter());
    }
  }, []);

  return (
    <RequireSubmittingUser>
      <WarningModal 
        show={openWarning}
        text={'Your Submitting Role account is on-hold please check your verification status'}
        handleClose={() => {}}
        handleOk={goToSubmittingRoleScreen}
        hideCancel
      />

      <div id="main-container">
        <div className="container">
          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          <h2 className="mb-4 userLevel-Title">Symptom Selection</h2>

          {selectedUnit && (
            <>
              <div className={`mb-4`}>
                <p className={styles.filter}>Unit Details:</p>

                <div className="row mb-2">
                  <div className="col-auto">
                    <div className={styles.topLabel}>
                      <span>Model</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col-auto`}>
                    {`${selectedUnit.brand}, ${selectedUnit.model_name_des}, ${selectedUnit.model_code}`}
                  </div>
                </div>

                <div className="row mb-md-2">
                  <div className="col-12 col-md-7">
                    <div className="row">
                      <div className="col-12 col-md-6 mb-2 mb-md-0">
                        <div className="row">
                          <div className="col-auto">
                            <div className={styles.topLabel}>
                              <span>QUID</span>
                            </div>
                          </div>

                          <div className={`${styles.topLabelVal} col-auto`}>
                            {selectedUnit.unit_id}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mb-2 mb-md-0">
                        <div className="row">
                          <div className="col-auto">
                            <div className={styles.topLabel}>
                              <span>{getSerialNumberTitle()}</span>
                            </div>
                          </div>

                          <div className={`${styles.topLabelVal} col-auto`}>
                            {getSerialNumber()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-auto">
                    <div className={styles.topLabel}>
                      <span>Use period</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col-auto`}>
                    {getUseTime()}
                  </div>
                </div>
              </div>
            </>
          )}

          {/** SYMPTOM FILTER */}
          <div className="mb-4">
            <p className={styles.filter}>Symptom Filter:</p>

            {/** ABERRATION */}
            <div className="row mb-2">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Defect Class</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <select
                      name="aberration"
                      className="form-select w-full"
                      id="aberration"
                      value={filter.aberration || ""}
                      onChange={(e) =>
                        dispatch(
                          updateFilter({
                            key: "aberration",
                            value: +e.target.value,
                          })
                          )
                        }
                        >
                      <option value="" selected disabled>
                        Select Defect Class
                      </option>
                      {aberrations
                        .filter((item) => {
                          if (item.aberration == "No Aberration") {
                            return false;
                          }

                          if (
                            selectedUnit.item_class?.includes("product") &&
                            item.restriction !== "Services"
                          ) {
                            return true;
                          }

                          if (
                            selectedUnit.item_class?.includes("service") &&
                            item.restriction !== "Products"
                          ) {
                            return true;
                          }


                          return false;
                        })
                        .map((item) => (
                          <option
                            key={item.aberration_id + "-aberration-ss"}
                            value={item.aberration_id}
                          >
                            {item.aberration}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/** DESCRIPTION */}
            <div className="row mb-2">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Description</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedAberrationData?.description || "Null"}
                  </div>
                </div>
              </div>
            </div>

            {/** CATEGORY */}
            <div className={`${styles.categoryContainer} row mb-2`}>
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Category</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <CollapsedCategoryTreeViewer 
                      ref={expandRef} 
                      value={selectedUnit?.category_id} 
                      onChange={handleCategoryChange} 
                      onSkipChange={setSkipCategoryIndex}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/** ENTER SYMPTOM IN YOUR WORD */}
          <div className="mb-4">
            <p className={`${styles.filter} mb-2`}>
              Enter Symptom in your own words:
            </p>

            <textarea
              rows={1}
              value={form.description || ""}
              onChange={(e) => onChange("description", e.target.value)}
              className={`${styles.textareaCustom} form-control`}
            />
          </div>

          <div className="mb-4">
            <div className="mb-4 row">
              <div className="d-none d-md-block col-md-6">
                <div className={styles.filter}>
                  Select the best Symptom match
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div
                  className="d-flex flex-wrap flex-column justify-content-start flex-md-row align-items-md-center"
                  style={{ gap: 16 }}
                >
                  <div className={styles.filter}>Search filter:</div>

                  <div
                    className="d-flex align-items-center"
                    style={{ gap: 16, flex: 1 }}
                  >
                    <input
                      type="text"
                      className={`${styles.searchInput} form-control`}
                      value={filter.search || ""}
                      onChange={(e) => dispatch(updateFilter({
                        key: "search",
                        value: e.target.value
                      }))}
                    />

                    <SwitchAnswer 
                      leftLabel="no filter"
                      rightLabel="filter"
                      value={filter.searchEnabled || "no filter"}
                      handleChange={(value) => dispatch(updateFilter({
                        key: "searchEnabled",
                        value
                      }))}
                    />
                    {/* <div
                      className="d-flex flex-row align-items-center"
                      style={{ gap: 8 }}
                    >
                      <button 
                        className={styles.filterRadio}
                        onClick={() => dispatch(updateFilter({
                          key: "searchEnabled",
                          value: false
                        }))}
                      >no filter</button>

                      <button className={styles.filterRadio}>filter</button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4 row">
              <div className="col-6">
                <div className={styles.th}>Symptom Description</div>
              </div>

              <div className="col-6">
                <div className={styles.th}>Additional Information</div>
              </div>
            </div>

            {filtered.map((symptom) => (
              <div
                key={symptom.symptom_id + "-symptom-ss"}
                className={`${styles.rowCard} d-flex mb-2 ${symptom.status === "Barred" ? styles.disabled : ''}`}
                onClick={() => onItemClick(symptom)}
              >
                <div className={styles.val}>
                  {symptom.description}{" "}
                  {symptom.status === "Pending"
                    ? "(P)"
                    : symptom.status === "Barred"
                    ? "(Barred)"
                    : ""}
                </div>

                <div className={styles.spacer} />

                <div className={styles.val}>
                  {symptom.additional_info || "-"}
                </div>
              </div>
            ))}

            <p className={styles.info}>
              <i className="bi bi-question-circle"></i>{" "}
              <span>
                *Note that if you are not sure what aberration or symtom to use
                then just select anything. You can request assistance in the
                next screen.
              </span>
            </p>
          </div>

          <div className={`${styles.actions} d-flex flex-row`}>
            <button className={styles.cancel} type="button" onClick={goBack}>Cancel</button>

            {!filter.category ? (
              <button
                className={styles.new}
                onClick={goToNewSymptom}
                disabled={!selectedAberrationData || !selectedUnit || !form?.description}
              >
                New Symptom
              </button>
            ) : (
              <button 
                className={styles.expand} 
                disabled={skipCategoryIndex === 0 || !form?.description || !selectedAberrationData}
                onClick={expandCategoryTree}
              >
                Expand Search
              </button>
            )}
          </div>
        </div>
      </div>
    </RequireSubmittingUser>
  );
};

export default SelectSymptomPage;
