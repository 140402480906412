import { Modal } from "react-bootstrap";
import AddModel from "pages/dashboard/add-model";

const AddModelModal = ({ show, handleClose, brand, category }) => {
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      fullscreen
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none", backgroundColor: "black" }}
      ></Modal.Header>

      <Modal.Body style={{ backgroundColor: "black", padding: 0 }}>
        <AddModel brand={brand} closeModal={handleClose} category={category} />
      </Modal.Body>
    </Modal>
  );
};

export default AddModelModal;
