import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  initialized: false,
};

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    populateReportList: (state, action) => {
      state.data = action.payload;
      if (action.payload.length > 0) {
        state.initialized = true;
      }
    },
  },
});

export const { populateReportList } = reportSlice.actions;

export const selectReportData = (state) => state.reports;

export default reportSlice.reducer;
