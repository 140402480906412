import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { useEffect, useMemo, useState } from "react";
import { COUNTRIES, MAX_FILE_SIZE } from "shared/constants";
import BrandModelService from "services/BrandModelService";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { FullScreenLoader } from "components/FullScreenLoader";
import { convertDateToYearMonthString, handleError, useDescriptionModal } from "shared/helpers";
import DescModal from "components/DescModal";
import { useAddUnit } from "./hooks/useAddUnit";
import AutocompleteV2 from "components/AutocompleteV2";
import AddSWVersionModal from "./components/forms/AddSWVersionModal";
import ReactDatePicker from "react-datepicker";
import ErrorsComponent from "components/Errors";
import LoadIndicator from "components/LoadIndicator";
import FileDisplayThumb from "components/FileDisplay";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const AddUnit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const { getApplicationVersions, httpGetApplication, httpCreateUnit } = useAddUnit();

  const service = new BrandModelService(token, dispatch);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showAddSwVersion, setShowAddSwVersion] = useState(false);
  const [versions, setVersions] = useState([]);
  const [brand_model, setBrandModel] = useState(null);
  const [unit, setUnit] = useState(null);
  const [data, setData] = useState({
    rating_label: null,
    current_sw_version: null,
    unique_identifier: "",
    mfr_date: null,
    country: null
  });
  
  const openAddSwVersionModal = () => {
    setShowAddSwVersion(true);
  };

  const closeAddSwVersionModal = async (data) => {
    if (data && data.sw_version_id && data.application_id) {
      const versions = await getApplicationVersions(data.application_id);
      setVersions(versions);
      handleChange("current_sw_version")(data);
    }

    setShowAddSwVersion(false);
  };

  const handleChange = (key) => (value) => {
    setData(prev => ({...prev, [key]: value}));
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];

    const ext = file.name.split(".").pop();

    const allowedExtensions = ["jpeg", "jpg", "png"];

    if (!allowedExtensions.includes(ext.toLowerCase()))
      return alert("Invalid file type, Select PDF, JPEG, JPG or PNG");

    if (ext.toLowerCase() === "pdf" && file.size > MAX_FILE_SIZE)
      return alert(`Pdf size cannot be more than ${MAX_FILE_SIZE} mb.`);
    else if (file.size > MAX_FILE_SIZE)
      return alert(`Image cannot be greater than ${MAX_FILE_SIZE} mb.`);

    handleChange("rating_label")(file);
  };

  const handleSwVersionSelect = (data) => {
    if (data.sw_version_id) {
      handleChange("current_sw_version")(data.sw_version_id);
    } else if (brand_model?.sw_application) {
      openAddSwVersionModal();
    }
  };

  const setupBrandModel = async (brand_model_id) => {
    setLoading(true);
    try {
      const brand_model_response = await service.getByID(brand_model_id);
      const brand_model = brand_model_response.data.data;
      setBrandModel(brand_model);

      if (Boolean(brand_model.sw_application)) {
        const versions = await getApplicationVersions(brand_model.sw_application);
        setVersions(versions);
      }
    } catch (error) {
      console.log('[error]', error);
    } finally {
      setLoading(false);
    }
  };

  const userDefaultCountry = useMemo(() => {
    if (user?.country) {
      return (
        COUNTRIES.filter(
          (e) => e.name.toLowerCase() === user.country.toLowerCase()
        )[0] || null
      );
    }

    return null;
  }, [user?.country]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSubmitting(true);
      setErrors([]);

      const payload = {
        ...data,
        brand_model_id: brand_model?.brand_model_id
      }

      const unit = await httpCreateUnit(payload);
      setUnit(unit);
    } catch (error) {
      console.log('[error]', error);
      handleError(error, setErrors);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    if (userDefaultCountry)
      handleChange("country")(userDefaultCountry.name);
  }, [userDefaultCountry]);

  useEffect(() => {
    if (state?.brand_model_id) {
      setupBrandModel(state?.brand_model_id);
    }
  }, [state]);

  useEffect(() => {
    if (unit) {
      setTimeout(() => navigate('/submit-quality-information/add-use', {
        state: {
          unit_id: unit.unit_id
        }
      }), 2000);
    }
  }, [unit]);

  const {
    showDescriptionModal,
    description,
    closeDescriptionModal,
    openDescriptionModal,
  } = useDescriptionModal();

  return (
    <RequireSubmittingUser>
      <AddSWVersionModal
        show={showAddSwVersion}
        handleClose={closeAddSwVersionModal}
        httpGetApplication={httpGetApplication}
      />

      <FullScreenLoader isOpen={loading} />

      <DescModal
        show={showDescriptionModal}
        handleClose={closeDescriptionModal}
        text={description}
      />

      <div id="main-container" className={styles.wrapper}>
        <form className="container" onSubmit={handleSubmit}>
          <h2 className="mb-4 userLevel-Title">Add Unit</h2>

          <button
            className={`mb-4 btn btn-green`} 
            type="button"
            onClick={() => navigate(-1)}
          >
            <i className="bi bi-arrow-left"></i>
          </button>

          {brand_model?.status === "verified" && (
            <p className="mb-4">
              Check this document for details on where to find the information required;
              <br />
              {brand_model.serial_instructions_link ? (
                // <a href="#" className="link">Model Instructions</a>  
                <FileDisplayThumb
                  icon={
                    <a href={"#"} className="link" onClick={(e) => e.preventDefault()}>
                      Model Instructions
                    </a>
                  }
                  location={brand_model.serial_instructions_link}
                />
              ) : (
                <em>Model Instructions not available</em>
              )}
            </p>
          )}

          {(brand_model?.item_class === "Multiple use product" || brand_model?.item_class === "Single use product") && (
            <>
              <p className="mb-2">
                If you can, please upload a picture of the product information label that shows the serial number if applicable.  You can do this later if necessary, it helps us with auditing and creating new records.
              </p>

              <div className="mb-4">
                <label htmlFor="add-unit-info-label" className="form-label">
                  Add Information Label
                </label>

                <p className="form">
                  <input
                    type="file"
                    id="imageUploadInfoLabel"
                    onChange={onFileChange}
                    accept=".jpeg, .jpg, .png"
                    hidden
                  />
                  <label
                    className="add-photo-btn"
                    htmlFor="imageUploadInfoLabel"
                  >
                    upload
                  </label>
                  <div style={{ marginLeft: 20 }}>
                    {data.rating_label?.name || "No file selected"}
                  </div>
                </p>
              </div>
            </>
          )}

          {brand_model?.serial_type !== "None" && (
            <div className="mb-4">
              <label className="form-label">
                {`Enter the ${brand_model?.serial_type} `}
                <i
                  className="bi bi-info-circle"
                  onClick={() =>
                    openDescriptionModal(`
                      This can be the transaction number, case number, batch number, serial number, account number something unique to the item
                    `)
                  }
                ></i>
              </label>
              
              <input
                type="text"
                className="form-control"
                id="serial"
                placeholder="R9JR0E0NKA"
                value={data.unique_identifier}
                onChange={(e) => handleChange("unique_identifier")(e.target.value)}
              />
            </div>
          )}

          {(brand_model?.status === "verified" && Boolean(brand_model?.os_sw_updateable)) && (
            <div className="mb-4">
              <label className="form-label">
                SW Version
              </label>

              <AutocompleteV2
                suggestions={versions}
                labels={["label"]}
                last={{
                  label: "New version",
                  sw_version_id: "",
                }}
                value={data.current_sw_version || ""}
                placeholder={"Select SW Version"}
                primaryKey={"sw_version_id"}
                setValue={handleSwVersionSelect}
                required={false}
              />
            </div>
          )}

          {(brand_model?.item_class !== "One-off service" && brand_model?.item_class !== "Continuous service") && (
            <div className="mb-4">
              <label className="form-label">
                Date created / Manufactured{" "}
                <i
                  className="bi bi-info-circle"
                  onClick={() =>
                    openDescriptionModal(`
                      Give the approximate date or year of manufacturer if you can, otherwise put the date it was purchased new, for software put the date you downloaded or received it
                    `)
                  }
                ></i>
                {/* <em>*</em> */}
              </label>

              <div className="customDatePickerWidth">
                <ReactDatePicker
                  className="form-control datepicker_input"
                  selected={data.mfr_date ? new Date(data.mfr_date) : null}
                  onChange={(date) => handleChange("mfr_date")(convertDateToYearMonthString(date))}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  placeholderText="Select date or enter MM/yyyy"
                />
              </div>
            </div>
          )}

          <div className="mb-4">
            <label className="form-label">
              Intended country{" "}
              <i
                className="bi bi-info-circle"
                onClick={() =>
                  openDescriptionModal(`
                    Products can be different depending on what country they are sold in because of different standards and legal requirements. By default this is the country you are from, check the intended country of sale carefully when you buy from abroad. Many market places are not clear on this.
                  `)
                }
              ></i>
            </label>

            <AutocompleteV2
              suggestions={COUNTRIES}
              placeholder={"Country"}
              required={true}
              labels={["name"]}
              value={data.country || ""}
              primaryKey={"name"}
              setValue={(val) =>
                handleChange("country")(val.name)
              }
            />
          </div>

          {brand_model?.status !== "verified" && (
            <p className="mb-4">
              This is a new model, please go to item unit details, edit and resubmit once this the model is verified
            </p>
          )}

          {errors.length > 0 && (
            <div className="mb-4">
              <ErrorsComponent errors={errors} />
            </div>
          )}

          {Boolean(unit) && (
            <div className="alert mt-5 alert-success">
              Unit is {unit.unit_status.toLowerCase()}
            </div>
          )}

          <div className="mb-4 d-flex flex-row justify-content-center gap-4 flex-wrap">
            <button className="btn btn-yellow" type="submit" disabled={!Boolean(brand_model) || submitting}>
              {submitting ? <LoadIndicator /> : "Submit"}
            </button>
          </div>
        </form>
      </div> 
    </RequireSubmittingUser>
  )
}

export default AddUnit;