import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  first: false,
};

export const submittinRoleDetails = createSlice({
  name: "submitting_role_details",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFirst: (state, action) => {
      state.first = action.payload;
    },
  },
});

export const { setFirst } = submittinRoleDetails.actions;

export const selectFirst = (state) => state.submitting_role_details.first;

export default submittinRoleDetails.reducer;
