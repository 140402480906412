import { Outlet } from "react-router-dom";
import DashboardHeader from "components/dashboard-header";
import "./styles.css";

const Layout = () => {
  return (
    <div className="layout position-relative">
      <DashboardHeader />

      <Outlet />
    </div>
  );
};

export default Layout;
