import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import ServiceBrandComparisonService from "services/ServiceBrandComparisonService";
import { logger, parseQueryString } from "shared/helpers";
import { useMultiSelect } from "shared/useMultiSelect";

export const useServiceBrandController = () => {
    const token = useSelector(selectToken);
    const dispatch = useDispatch();

    const service = new ServiceBrandComparisonService(dispatch, token);

    const [fetching, setFetching] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [report, setReport] = useState({
        t1: [],
        t3: []
    });

    const handleIncludeToggle = (idx) => () => {
        setReport(prev => ({
            ...prev,
            t3: prev.t3.map((e, eIdx) => {
                if (eIdx === idx) {
                    return { ...e, include: !e.include };
                }

                return e;
            })
        }))
    }

    const [queryData, setQueryData] = useState({
        category: null,
        serviceTexts: [],
        serviceTextOperator: "AND"
    });

    const handleQueryDataChange = (key, val) => {
        setQueryData((prev) => ({
            ...prev,
            [key]: val
        }));
    }

    const handleCategorySelect = (val) => {
        if (val.category_id === 1) {
            handleQueryDataChange("category", null);
        } else {
            handleQueryDataChange("category", val);
        }
    };

    const categorySelect = useMultiSelect({
        value: queryData.category?.category_id || "",
        setValue: handleCategorySelect,
    });

    const httpFetchReport = async () => {
        if (!queryData.category || fetching) {
            return;
        }

        const data = {
            category: queryData.category.category_id,
        }
        
        if (queryData.serviceTexts.length > 0) {
            data.service_texts = queryData.serviceTexts.join(",");
            data.service_texts_op = queryData.serviceTextOperator;
        }

        setFetching(true);
        try {
            const res = await service.getReport(parseQueryString(data));

            if (res.data) {
                if (res.data.t3) {
                    res.data.t3 = res.data.t3.map(e => ({...e, include: true}))
                }

                setReport(res.data);
            }
        } catch (e) {
            logger("[ERROR]", e);
        }
        setFetching(false);
    }

    const httpBuyQuality = async (report = []) => {
        const qualityIds = report.map(e => e.quality_id);

        if (qualityIds.length === 0 || !queryData.category) return;

        const QC = report.filter(e => e['sales(n)'] === 0).map((e) => Number(e.price)).reduce((accumulator, val) => accumulator + val, 0);

        try {
            await service.buyReport(qualityIds, QC, queryData.category.category_id)
        } catch (error) {
            logger("[ERROR]", error);
        }
    };

    const httpDownloadReport = useCallback(async (report_id) => {
        setDownloading(true);
        try {
            const {data} = await service.downloadReport(report_id);

            if (data.url) {
                window.open(data.url, "_blank");
            }
            return true;
        } catch (error) {
            console.log(error);
        } finally {
            setDownloading(false);
        }
    }, [downloading]);

    const serviceTextIsDisabled = useMemo(() => {
        if (Boolean(queryData.category)) {
            return !Boolean(queryData.category.end);
        }

        return true;
    }, [queryData.category]);

    useEffect(() => {
        const getData = setTimeout(httpFetchReport, 1500);
        return () => clearTimeout(getData);
    }, [queryData.category?.category_id, queryData.serviceTexts, queryData.serviceTextOperator]);
 
    const reset = () => {
        setReport({
            t1: [],
            t3: []
        });
        setFetching(false);
    }

    return {
        queryData,
        categorySelect,
        report,
        fetching,
        serviceTextIsDisabled,
        downloading,
        reset,
        handleCategorySelect,
        handleQueryDataChange,
        httpBuyQuality,
        handleIncludeToggle,
        httpDownloadReport
    }
}