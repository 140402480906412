import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pending from "./Pending";
import Verified from "./Verified";
import { useUnits } from "hooks/useUnits";
import WarningModal from "components/WarningModal";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

function SubmitQualityInformation() {
  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate();

  const [showWarning, setShowWarning] = useState(false);

  const { refresh, loading, pending, verified, confirming, httpConfirmNoSymptoms } = useUnits();
  useEffect(() => {
    if (!loading) refresh();
  }, []);

  const handleConfirmNoSymptoms = () => {
    setShowWarning(true);
    httpConfirmNoSymptoms();
  }

  return (
    <RequireSubmittingUser>
      <WarningModal 
        show={showWarning}
        handleClose={() => setShowWarning(false)}
        handleOk={() => setShowWarning(false)}
        text={"Checking and updating all active units"}
        hideCancel
      />

      <div id="main-container">
        <div className="container">
          {/**TAB BUTTONS */}
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === 0 ? "active" : ""}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#RegisteredItems"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={() => setActiveTab(0)}
              >
                Registered Items
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === 1 ? "active" : ""}`}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#PendingItems"
                type="button"
                role="tab"
                onClick={() => setActiveTab(1)}
                aria-controls="profile"
                aria-selected="false"
              >
                Pending Items
              </button>
            </li>
          </ul>

          {/**TAB CONTENT */}
          <div className="tab-content" id="myTabContent">
            {/**REGISTERED ITEMS */}
            <div
              className="tab-pane fade show active"
              id="RegisteredItems"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
                <h3>Registered Items</h3>
                <a
                  onClick={() => navigate("/submit-quality-information/register-item")}
                  className="btn btn-yellow"
                >
                  Register New Item
                </a>
              </div>

              <Verified verified={verified} loading={loading} />
            </div>

            {/**PENDING ITEMS */}
            <div
              className="tab-pane fade"
              id="PendingItems"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
                <h3>Pending Items</h3>
              </div>

              <Pending pendingUnits={pending} loading={loading} />
            </div>
          </div>

          {/** */}
          <div className="mt-5 text-center">
            <button 
              className="btn btn-yellow me-3"
              disabled={confirming} 
              onClick={handleConfirmNoSymptoms}
            >
              Confirm No Symptoms
            </button>
            
            <Link
              to="/submit-quality-information/past-use"
              className="btn btn-yellow"
            >
              Past Use Items
            </Link>
          </div>
        </div>
      </div>
    </RequireSubmittingUser>
  );
}

export default SubmitQualityInformation;
