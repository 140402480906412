const { axiosClient } = require("shared/axiosInstance");

class SWApplicationService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async find(id) {
    return this.client.get(`/sw-applications/find/${id}`);
  }
}

export default SWApplicationService;
