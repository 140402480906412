import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import AssetService from "services/AssetService";
import "./styles.css";
import { MAX_FILE_SIZE } from "shared/constants";

const imageexts = ["jpeg", "jpg", "png", "svg"];

function FileDisplayThumb({
  location,
  showSpan = false,
  full = false,
  icon,
  disabled = false,
  onChange,
  hideUploadButton = false,
}) {
  const [fileSelected, setFileSelected] = useState(null);

  const [ext, setExt] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const assetService = new AssetService(dispatch, token);

  const setupFileUrl = async () => {
    if (!location) return;
    try {
      const res = await assetService.getAssetUrl(location);

      setFileUrl(res.data.data);
    } catch (error) {}
  };

  const openAsset = async () => {
    if (!location || disabled) return;
    try {
      const res = await assetService.getAssetUrl(location);

      if (res.data.data) {
        window.open(res.data.data, "_blank");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (location) {
      setupFileUrl();

      const splitUrl = location.split(".");

      let extension = splitUrl[splitUrl.length - 1]?.toLowerCase();

      if (extension && extension.includes("?")) {
        extension = extension.split("?")[0];
      }

      let fileNameSplit = splitUrl[0].split("/");
      fileNameSplit.shift();

      setFileName(fileNameSplit.join(""));

      setExt(extension);
    }
  }, [location]);

  const onFileChange = (event) => {
    const file = event.target.files[0];

    const ext = file.name.split(".").pop();

    const allowedExtensions = ["jpeg", "jpg", "png"];

    if (!allowedExtensions.includes(ext.toLowerCase()))
      return alert("Invalid file type, Select PDF, JPEG, JPG or PNG");

    if (ext.toLowerCase() === "pdf" && file.size > MAX_FILE_SIZE)
      return alert(`Pdf size cannot be more than ${MAX_FILE_SIZE} mb.`);
    else if (file.size > MAX_FILE_SIZE)
      return alert(`Image cannot be greater than ${MAX_FILE_SIZE} mb.`);

    setFileSelected(file);
    onChange(file);
  };

  const displayUrl = useMemo(() => {
    if (onChange && fileSelected) {
      return URL.createObjectURL(fileSelected);
    }

    return fileUrl;
  }, [fileUrl, onChange, fileSelected]);

  useEffect(() => {
    return () => {
      setFileSelected(null);
    };
  }, []);

  // if (!ext || !fileUrl) return <div></div>;

  return (
    <>
      {icon ? (
        <div className="file-display-thumb" onClick={openAsset}>
          {icon}
        </div>
      ) : !location || imageexts.includes(ext) ? (
        <>
          <div className="file-display-thumb" onClick={openAsset}>
            {displayUrl && (
              <img
                src={displayUrl}
                className={`${!full && "img-thumbnail"} me-3 img-fluid ${
                  full && "full-img-thumb"
                }`}
                alt={fileName}
                title=""
                width="60"
              />
            )}

            {showSpan && <a className="mt-2 link">{fileName}</a>}
          </div>

          {onChange && !hideUploadButton && (
            <span className="form">
              <input
                type="file"
                id="imageUploadThumb"
                onChange={onFileChange}
                accept=".jpeg, .jpg, .png"
                hidden
              />
              <label className="add-photo-btn" htmlFor="imageUploadThumb">
                Upload Label
              </label>
            </span>
          )}
        </>
      ) : (
        <div className="file-display-thumb" onClick={openAsset}>
          <a className="link">{`${fileName}.${ext}`}</a>
        </div>
      )}
    </>
  );
}

export default FileDisplayThumb;
