import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ErrorsComponent from "components/Errors";
import LoadIndicator from "components/LoadIndicator";

function ConfirmClose({ show, handleClose, caseId, closeCase }) {
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(false);

  const cleanUp = () => {
    setSaving(false);
    setErrors(false);
  };

  const handleDeleteModalClose = async () => {
    if (!caseId) return;

    setErrors([]);
    setSaving(true);

    try {
      await closeCase(caseId);
    } catch (error) {
    } finally {
      setSaving(false);
      handleClose();
    }
  };

  useEffect(() => {
    return cleanUp;
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Confirm Close Case</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          <div className="px-4">Close this case?</div>

          {errors.length > 0 && (
            <div className="px-4 pt-4">
              <ErrorsComponent errors={errors} />
            </div>
          )}
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "none" }}>
          <button className="btn btn-black me-2" onClick={handleClose}>
            No
          </button>

          <button className="btn btn-yellow" onClick={handleDeleteModalClose}>
            {saving ? <LoadIndicator /> : "Yes"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmClose;
