import { useEffect, useState } from "react";
import "./styles.css";
import LoadIndicator from "../LoadIndicator";
import { useMessageTypeManagement } from "hooks/useMessageTypeManagement";
import FileDisplayThumb from "../FileDisplay";
import { useTransition, animated } from "react-spring";

const SingleMessageView = ({ messageId, handleClose }) => {
  const controller = useMessageTypeManagement();
  const [message, setMessage] = useState(null);

  const cleanUp = () => {
    setMessage(null);
  };

  useEffect(() => {
    return cleanUp;
  }, []);

  useEffect(() => {
    if (messageId) {
      controller
        .httpGetMessageById(messageId)
        .then(setMessage)
        .catch(console.error);
    }
  }, [messageId]);

  const transitions = useTransition(Boolean(message), {
    from: { opacity: 0, x: 200, y: 200 },
    enter: { opacity: 1, x: 0, y: 0 },
  });

  return transitions((style, item) => (
    <animated.div style={style} className="q-single-message-container">
      {!item && <LoadIndicator />}
      {item && (
        <>
          <div className="title">Message</div>

          <div className="my-2">
            {!message ? (
              <LoadIndicator />
            ) : (
              <p className="content">{message.message}</p>
            )}
          </div>

          <div className="title mt-5">Attachements</div>

          <div className="attachments d-flex flex-row flex-wrap my-2">
            {!message && <LoadIndicator />}
            {message && message.attachments.length === 0 && (
              <div>No attachments</div>
            )}

            {message &&
              message.attachments.map((e) => (
                <FileDisplayThumb
                  key={`q-single-message-container-attachment-${e.attachment_id}`}
                  location={e.location}
                />
              ))}
          </div>

          <div className="d-flex flex-row justify-content-center mt-4">
            <button className="btn btn-black" onClick={handleClose}>
              Close
            </button>
          </div>
        </>
      )}
    </animated.div>
  ));
};

export default SingleMessageView;
