import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import AuthenticationService from "services/AuthenticationService";
import { handleError } from "shared/helpers";

function VerifyMail() {
  const [loading, set_loading] = useState(true);
  const [msg, set_msg] = useState(null);
  const access_token = useSelector(selectToken);

  const runVerify = async (token) => {
    set_loading(true);
    set_msg("Loading...");

    try {
      const res = await AuthenticationService.verify_reset_mail(
        {
          token,
        },
        access_token
      );

      set_msg(res.data.message);
    } catch (error) {
      handleError(error, (errs) => set_msg(errs[0]));
    }

    set_loading(false);
  };

  useEffect(() => {
    const hash = new URLSearchParams(window.location.search).get("hash");

    runVerify(hash);
  }, []);

  return (
    <div
      className="registerPage"
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading && (
        <div
          className="spinner-border"
          role="status"
          variant="secondary"
          style={{ marginRight: 10 }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      )}

      {msg && (
        <div
          style={{
            fontSize: 30,
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {msg}
        </div>
      )}
    </div>
  );
}

export default VerifyMail;
