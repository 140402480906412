import AppModal from "components/AppModal";
import LoadIndicator from "components/LoadIndicator";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAppLoadingState,
  setAppLoading,
} from "redux-store/features/appSlice";
import styles from "./styles.module.css";
import CssLoaders from "components/CssLoaders";

const AppLoadingModal = () => {
  const dispatch = useDispatch();
  const loadingState = useSelector(selectAppLoadingState);

  const close = () => dispatch(setAppLoading(null));

  return (
    <AppModal.Container isOpen={Boolean(loadingState)}>
      <AppModal.Content>
        {loadingState?.canClose && (
          <AppModal.Header onClose={close}></AppModal.Header>
        )}

        <div className="d-flex justify-content-center align-items-center">
          <LoadIndicator size="lg" Loader={CssLoaders.Hypnotic} />
        </div>

        {loadingState?.info && (
          <div className={`${styles.info_text} mt-4 text-center`}>
            <strong>{loadingState.info}</strong>
          </div>
        )}
      </AppModal.Content>
    </AppModal.Container>
  );
};

export default {
  Loading: AppLoadingModal,
};
