import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import UserService from "services/UserService";
import { handleError, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "components/Errors";

function ConfirmSuspend({ show, handleClose, suspendMode }) {
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  const [messages, setMessages] = useState([]);
  useShortDisplay(() => setMessages([]), 5000, messages.length > 0);

  const handleSuspend = async () => {
    setLoading(true);
    try {
      const res = await UserService.suspendMyAccount(token, dispatch);

      if (res.data.success) {
        // Log users out in 3 seconds
        setMessages([res.data.message]);

        setTimeout(handleClose, 3000);
      }
    } catch (error) {
      handleError(error, setErrors);
    }
    setLoading(false);
  };

  const getMessage = () => {
    if (suspendMode === "submitting") {
      return "All submitted items will be rendered inactive";
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none" }}
      >
        <Modal.Title>Confirm Suspend</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h5 className="mod-detail">{getMessage()}</h5>

        {errors.length > 0 && (
          <div className="mt-2">
            <ErrorsComponent errors={errors} />
          </div>
        )}

        {messages.length > 0 && (
          <div className="mt-2">
            <ErrorsComponent errors={messages} variant="alert-success" />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="primary" disabled={loading} onClick={handleSuspend}>
          Continue
        </Button>

        <Button variant="secondary" disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmSuspend;
