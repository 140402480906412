import { useEffect, useState } from "react";
import styles from "./styles.module.css";

const WordPillBox = ({ value, setValue, placeHolder, disabled }) => {
    const [input, setInput] = useState("");

    const addPill = (input) => {
        setValue([...value, input]);
        setInput("");
    }

    const handleBlur = () => {
        if (input) {
            addPill(input);
        }
    }

    const removePill = (idx) => () => {
        const newPills = [...value];
        newPills.splice(idx, 1);
        setValue(newPills);
    }

    useEffect(() => {
        if (input && input[input.length - 1] === ",") {
            addPill(input.substring(0, input.length - 1));
        }
    }, [input]);

    return (
        <div className={`${styles.container} form-control`}>
            <div className={styles.pills}>
                {value.map((pill, idx) => (
                    <div key={`pill-${idx}`} className={styles.pill}>
                        <span>{pill}</span>
                        <button className={styles.removePill} onClick={removePill(idx)}>x</button>
                    </div>
                ))}
            </div>

            <input 
                type="text"
                value={input} 
                onChange={(e) => setInput(e.target.value)} 
                onBlur={handleBlur} 
                placeholder={value.length === 0 ? placeHolder : undefined} 
                disabled={disabled}
            />
        </div>
    )
}

export default WordPillBox;