import { usePreferences } from "hooks/usePrefrences";
import { useEffect } from "react";

const UseCounterEst = ({
  mode,
  unit,
  value,
  setValue,
  required = false,
  endCategoryAttributes,
}) => {
  const { getUserDistanceUnit, getUserDistance } = usePreferences();

  useEffect(() => {
    if (!unit || !endCategoryAttributes) return;

    if (
      mode === "edit" &&
      endCategoryAttributes.use_units &&
      endCategoryAttributes.use_units.toLowerCase() === "distance"
    ) {
      setValue(getUserDistance(unit.usage_accumulated));
    } else {
      setValue(unit.usage_accumulated);
    }
  }, [mode, unit, endCategoryAttributes?.use_units]);

  const getUnit = (use_units) => {
    switch (use_units) {
      case "Operational time":
        return "hr";
      case "Distance":
        return getUserDistanceUnit();
      case "Cycles":
        return "Cy";
      case "Uses":
        return "Use";
      default:
        return "";
    }
  };

  if (!unit || !endCategoryAttributes) return null;

  return (
    <>
      {mode === "view" && (
        <p>
          {endCategoryAttributes.use_units?.toLowerCase() === "distance"
            ? getUserDistance(unit.usage_accumulated || 0)
            : unit.usage_accumulated}{" "}
          {getUnit(endCategoryAttributes.use_units)}
        </p>
      )}

      {mode === "edit" && (
        <div className="input-group">
          <input
            type="number"
            className="form-control input-lg"
            value={value}
            min={0.0}
            step={".01"}
            onChange={(e) => setValue(e.target.valueAsNumber)}
            required={required}
          />

          <div className="input-group-prepend">
            <span className="input-group-text" style={{ height: '100%' }}>
              {getUnit(endCategoryAttributes.use_units)}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export { UseCounterEst };
