import { useState } from "react";
import { formatDDMMYYY, isToday } from "shared/helpers";
import LoadIndicator from "components/LoadIndicator";
import "./index.css";

const MobileVersion = ({
  invites = [],
  deleteUserInvite,
  resendUserInvite,
}) => {
  const [resending, setResending] = useState(false);

  const handleResend = (invite_id) => async () => {
    setResending(true);

    await resendUserInvite(invite_id);

    setResending(false);
  };

  return (
    <div className="mobile_invites">
      {invites.map((invite) => {
        return (
          <div key={`invite-row-mobile-${invite.invite_id}`}>
            <div className="info">
              <div className="head">
                <i className="bi bi-envelope"></i> <span>Email/Name</span>
              </div>

              <span>{!Boolean(invite.name) ? invite.email : invite.name}</span>
            </div>

            <div className="info">
              <div className="head">
                <i className="bi bi-calendar"></i> <span>Date</span>
              </div>

              <span>{formatDDMMYYY(invite.created_at)}</span>
            </div>

            <div className="info">
              <div className="head">
                <i className="bi bi-clock"></i> <span>Invite Date</span>
              </div>

              <span>
                {invite.date_sent ? formatDDMMYYY(invite.date_sent) : "-"}
              </span>
            </div>

            <div className="info">
              <div className="head">
                <i className="bi bi-question-circle"></i>{" "}
                <span>User Status</span>
              </div>

              <span>{invite.status}</span>
            </div>

            <div className="actions">
            {(invite.date_sent === null ||
              !isToday(invite.date_sent)) &&
              invite.status.toLowerCase() === "pending" ? (
                <button className="resend" onClick={handleResend(invite.invite_id)}>
                  {resending ? <LoadIndicator /> : invite.name ? "Copy link" : "Resend"}
                </button>
              ) : (
                <div className="resend_empty"></div>
              )}

              {invite.status.toLowerCase() === "rejected" ||
                invite.status.toLowerCase() === "invalid" ||
                invite.status.toLowerCase() === "pending" ? (
                <button className="delete" onClick={deleteUserInvite}>
                  <i className="bi bi-trash"></i>
                </button>
              ) : (
                <div className="delete_empty"></div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileVersion;
