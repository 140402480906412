import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import config from "../../../configs";

const NumberUsedModal = ({ message, isOpen, handleClose, userNumbers }) => {
  const navigate = useNavigate();

  const handleYes = () => {
    navigate("/messages/compose", {
      state: {
        prefilledMailData: {
          receiver: config.ADMIN_MAIL,
          subject: "Phone Conflict",
          text: `Phone conflict between 2 users ${userNumbers[0]} and ${userNumbers[1]}`,
        },
      },
    });
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none" }}
      >
        <Modal.Title>Number Used</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex-column">
          <p>{message}</p>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={handleYes}>
          Yes
        </Button>

        <Button variant="primary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NumberUsedModal;
