import { axiosClient } from "shared/axiosInstance";

class DashboardService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  getPublicMetrics() {
    return axiosClient(this.dispatch, this.token).get("/dashboard/metrics");
  }
}

export default DashboardService;
