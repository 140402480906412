import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DefaultRoute = ({ defaultRoute }) => {
  const navigate = useNavigate();

  const [first, setFirst] = useState(true);
  useEffect(() => {
    if (defaultRoute && first) {
      navigate(defaultRoute);
      setFirst(false);
    }
  }, [defaultRoute, first]);

  return null;
};

export default DefaultRoute;
