import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  addCustomModel,
  initializeModelList,
  selectModelsData,
  setCustomModelList,
} from "redux-store/features/modelsSlice";
import ModelService from "services/ModelService";
import { logger } from "shared/helpers";

export const useModels = (filter = undefined) => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { model_list, initialized_model_list, custom_model_list } =
    useSelector(selectModelsData);

  const fetchModelList = async () => {
    setLoading(true);
    try {
      const res = await ModelService.getModels(token, dispatch);

      if (res.data.data) {
        res.data.data.sort((a, b) => {
          if (!a.brand_model_id) {
            return 1;
          }
          
          return a.model_name_des.toLowerCase().localeCompare(b.model_name_des.toLowerCase());
        });
        dispatch(initializeModelList(res.data.data));
      }
    } catch (error) {
      logger("[ERROR]", error);
    }
    setLoading(false);
  };

  const addToCustom = (model) => {
    const exist = custom_model_list.filter((item) => item.model_id === model.model_id);

    if (exist.length === 0) {
      dispatch(addCustomModel(model));
    }
  }

  const fetchCategoryBrandModels = async (category_id, brand_id) => {
    if (loading || !category_id) return;

    setLoading(true);
    try {
      const res = await ModelService.getFilteredModels(
        token,
        dispatch,
        brand_id,
        category_id
      );

      const models = res.data.data || [];
      models.sort((a, b) => {
        if (!a.brand_model_id) {
          return 1;
        }

        return a.model_name_des.toLowerCase().localeCompare(b.model_name_des.toLowerCase())
      });

      dispatch(setCustomModelList(models));
    } catch (error) {
      logger("ERROR", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!initialized_model_list && token) {
      fetchModelList();
    }
  }, [initialized_model_list, token]);

  useEffect(() => {
    if (filter && filter.category && token) {
      fetchCategoryBrandModels(filter.category, filter.brand);
    }
  }, [token]);

  const refresh = () => {
    if (token) {
      fetchModelList();
    }

    if (filter && filter.category) {
      fetchCategoryBrandModels(filter.category, filter.brand);
    }
  };

  return { all: model_list, custom: custom_model_list, refresh, loading, addToCustom };
};
