import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "services/AuthenticationService";
import { isAllPresent, useForm } from "shared/helpers";
import Header from "components/header";

function ResetPassword() {
  const { form, onChange, err, set_err, handleServerError } = useForm();

  const navigate = useNavigate();

  const [msg, set_msg] = useState(null);
  const [loading, set_loading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.password !== form.password_confirm)
      return set_err(["Password does not match"]);

    if (form.password?.length < 8)
      return set_err(["Password must be at least 8 characters in length"]);

    if (!isAllPresent(form.password))
      return set_err([
        "Password must contain uppercase, lowercase, numeric and special characters",
      ]);

    set_err([]);

    set_loading(true);

    const hash = new URLSearchParams(window.location.search).get("hash");

    try {
      const res = await AuthenticationService.reset_password(
        hash,
        form.password
      );

      set_msg(res.data.message);

      setTimeout(() => {
        navigate("/auth/signin");
      }, 3000);
    } catch (error) {
      handleServerError(error);
    }

    set_loading(false);
  };

  return (
    <>
      <div className="container">
        <div className="loginBg">
          <h1>Enter new password</h1>

          <form id="loginForm" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">
                Password <em className="required">*</em>
              </label>
              <input
                type="password"
                className="form-control"
                id="phone"
                required
                value={form.password}
                onChange={(e) => onChange("password", e.target.value)}
                placeholder="Password"
              />
            </div>
            <div className="mb-4">
              <label className="form-label">
                Confirm password <em className="required">*</em>
              </label>
              <input
                type="password"
                className="form-control"
                id="confirm_password"
                required
                value={form.password_confirm}
                onChange={(e) => onChange("password_confirm", e.target.value)}
                placeholder="Confirm password"
              />
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-yellow">
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
              </button>
              <div className="mt-4">
                <a className="link" onClick={() => navigate("/auth/signin")}>
                  Login Account
                </a>
              </div>
            </div>

            {msg && <div className="success_message">{msg}</div>}
            {err.map((text) => (
              <div className="error_message">{text}</div>
            ))}
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
