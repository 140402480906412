import { axiosClient } from "shared/axiosInstance";

class VerificationHistoryService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  getExistingVerificationHistory(history) {
    return axiosClient(this.dispatch, this.token).get(
      "/verification_history/" + history
    );
  }

  isAnyOpen() {
    return axiosClient(this.dispatch, this.token).get(
      "/verification_history/latest_open"
    );
  }
}

export default VerificationHistoryService;
