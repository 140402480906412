import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./auth";
//import Client from './client';
import Logout from "./logout";
import Home from "pages/main/home";
import VerifyMail from "pages/auth/verifymail";
import { useSelector } from "react-redux";
import { selectUser } from "redux-store/features/authSlice";

import DashboardIndex from "pages/dashboard/index";
import Onboarding from "pages/dashboard/onboarding";
import SubmitRoleDetails from "pages/dashboard/submit-role-details";
import UserVerification from "pages/dashboard/user-verification";
import SubmitQualityInformation from "pages/dashboard/submit-quality-information";
import AddUnit from "pages/dashboard/add-unit";
import AddCategory from "pages/dashboard/add-category";
import AddBrand from "pages/dashboard/add-brand";
import AddCompany from "pages/dashboard/add-company";
import AddModel from "pages/dashboard/add-model";
import ItemDetail from "pages/dashboard/item-detail";
import IdentityInformation from "pages/dashboard/identity";
import MessagingRoutes from "./MessagingRoutes";
import Preferences from "pages/dashboard/preferences";
import UserInvite from "pages/dashboard/user-invite";
import SoftwareVersionRecord from "pages/dashboard/software/SoftwareVersionRecord";
import SoftwareVersionManagement from "pages/dashboard/software/SoftwareVersionManagement";
import DefaultRoute from "./default";
import SelectSymptomPage from "pages/dashboard/select-symptom";
import NewSymptom from "pages/dashboard/new-symptom";
import SymptomManagement from "pages/dashboard/symptom-management";
import AberrationSelect from "pages/dashboard/aberration-select";
import SymptomRecord from "pages/dashboard/symptom-record";
import AberrationManagement from "pages/dashboard/aberration-management";
import UnitQualityRecord from "pages/dashboard/unit-quality-record";
import UnitQualityRecordManagement from "pages/dashboard/unit-quality-record-management";
import QualityRecord from "pages/dashboard/quality-record";
import InstructionsAvailable from "pages/dashboard/instructions-available";
import QualityRecords from "pages/dashboard/quality-records";
import SiteAvailable from "HoC/SiteAvailable";
import PastUse from "pages/dashboard/past-use";
import GetQuality from "pages/get-quality";
import WhatIsQuality from "pages/what-is-quality";
import AddCredit from "pages/add-credit";
import SingleBrandProduct from "pages/single-brand-product";
import ServiceBrandComparison from "pages/service-brand-comparison";
import ModelList from "pages/model-list";
import UnitList from "pages/unit-list";
import ScrollToTop from "components/ScrollToTop";
import RegisterItemScreen from "pages/dashboard/register-item";
import AddUse from "pages/dashboard/add-use";
import SelectUnitPage from "pages/dashboard/select-unit";
import ReviewSalesPage from "pages/dashboard/review-sales";
import CreditPage from "pages/credit";
import ReportPurchasesPage from "pages/credit/report-purchases";
import Reasons from "pages/main/reasons";
import Benefits from "pages/main/benefits";
import LegalInfo from "pages/legal-info";

const AppRoutes = ({ defaultRoute }) => {
  const user = useSelector(selectUser);

  const logggedInRoutes = (
    <>
      <Route index element={<DashboardIndex />} />
      <Route path="onboarding" element={<Onboarding />} />
      <Route path="legal" element={<LegalInfo />} />
      <Route path="user-verification" element={<UserVerification />} />
      <Route path="submit-role-details" element={<SubmitRoleDetails />} />
      <Route
        path="submit-quality-information"
        element={<SubmitQualityInformation />}
      />

      <Route
        path="submit-quality-information/register-item"
        element={<RegisterItemScreen />}
      />

      <Route
        path="submit-quality-information/quality-records"
        element={<QualityRecords />}
      />

      <Route
        path="submit-quality-information/item-detail/:unit_id"
        element={<ItemDetail />}
      />

      <Route
        path="submit-quality-information/review-sales/:unit_id"
        element={<ReviewSalesPage />}
      />

      <Route path="submit-quality-information/past-use" element={<PastUse />} />

      <Route path="submit-quality-information/add-unit" element={<AddUnit />} />
      <Route
        path="submit-quality-information/select-unit"
        element={<SelectUnitPage />}
      />
      <Route path="submit-quality-information/add-use" element={<AddUse />} />
      <Route path="add-category" element={<AddCategory />} />
      <Route path="add-brand" element={<AddBrand />} />
      <Route path="add-company" element={<AddCompany />} />
      <Route path="add-model" element={<AddModel />} />
      <Route path="identity" element={<IdentityInformation />} />
      <Route path="credit" element={<CreditPage />} />
      <Route path="credit/report-purchases" element={<ReportPurchasesPage />} />
      <Route path="messages/*" element={<MessagingRoutes />} />
      <Route path="preferences" element={<Preferences />} />

      <Route path="svr" element={<SoftwareVersionRecord />} />
      <Route path="svm" element={<SoftwareVersionManagement />} />

      <Route path="user-invite" element={<UserInvite />} />
      <Route
        path="instructions-available"
        element={<InstructionsAvailable />}
      />

      {/** SELECT SYMPTOM */}
      <Route
        path="submit-quality-information/select-symptom"
        element={<SelectSymptomPage />}
      />

      {/** UNIT QUALITY RECORD  */}
      <Route
        path="submit-quality-information/unit-quality-record"
        element={<UnitQualityRecord />}
      />
      <Route
        path="submit-quality-information/unit-quality-record/:quality_id"
        element={<UnitQualityRecord />}
      />

      {/* New Symptom */}
      <Route
        path="submit-quality-information/new-symptom"
        element={<NewSymptom />}
      />

      {/** NEW ROUTES */}
      <Route path="get-quality" element={<GetQuality />} />
      <Route path="get-quality/what-is-quality" element={<WhatIsQuality />} />
      <Route path="add-credit" element={<AddCredit />} />
      <Route
        path="get-quality/single-brand-product"
        element={<SingleBrandProduct />}
      />
      <Route
        path="get-quality/service-brand-comparison"
        element={<ServiceBrandComparison />}
      />
      <Route
        path="get-quality/single-brand-product/model-list"
        element={<ModelList />}
      />
      <Route
        path="get-quality/single-brand-product/unit-list"
        element={<UnitList />}
      />
    </>
  );

  return (
    <BrowserRouter>
      <ScrollToTop />
      <DefaultRoute defaultRoute={defaultRoute} />
      <Routes>
        <Route path="/" element={<SiteAvailable />}>
          {user ? logggedInRoutes : <Route index element={<Home />} />}

          <Route path="reasons" element={<Reasons />} />
          <Route path="benefits" element={<Benefits />} />
          <Route path="auth/*" element={<Auth />} />
          <Route path="signout" element={<Logout />} />
          <Route path="verify_reset_mail" element={<VerifyMail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
