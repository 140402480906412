import { axiosClient } from "../shared/axiosInstance";

class PreferenceService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  update(data) {
    return axiosClient(this.dispatch, this.token).patch(
      "/users/preference",
      data
    );
  }
}

export default PreferenceService;
