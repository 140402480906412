import { axiosClient } from "shared/axiosInstance";

class CompanyService {
  static addCompanyToDb(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/company", data);
  }

  static getAvailableCompanies(token, dispatch) {
    return axiosClient(dispatch, token).get("/company");
  }
}

export default CompanyService;
