export const StepThree = ({ onSelectPersonalizedMode }) => {

    return (
        <>
            <p className="mb-4">
                Is this a personalised Service or a customised product where the provider does a small number of jobs one customer at a time and maybe able to identify you from the type of job or the date?
                <br />
                If yes we will take extra care to reduce the information available to protect your anonimity.
            </p>

            <div className="d-flex flex-row gap-4 align-items-center">
                <button className="btn btn-yellow" type="button" onClick={() => onSelectPersonalizedMode(true)}>
                    Yes
                </button>

                <button className="btn btn-yellow" type="button" onClick={() => onSelectPersonalizedMode(false)}>
                    No
                </button>
            </div>
        </>
    )
}