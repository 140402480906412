import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import LoadIndicator from "../LoadIndicator";
import { useModels } from "hooks/useModels";
import styles from "./styles.module.css";

const TableModelSelector = forwardRef(({ category, brand, onModelChange }, ref) => {
    const [filter, setFilter] = useState('');
    const [models, setModels] = useState([]);

    const { custom, addToCustom, refresh, loading } = useModels({ category: category.category_id, brand: brand.brand_id });

    const addModel = (model) => addToCustom(model);

    useImperativeHandle(ref, () => {
        return {
            addModel,
        }
    }, [custom, models]);

    const updateList = () => {
        refresh();
        setFilter('');
    };

    useEffect(() => {
        if (!filter) {
            setModels(custom);
        } else {
            // Search custom for filter
            const result = custom.filter((model) => {
                return model.model_name_des.toLowerCase().includes(filter.toLowerCase()) 
                    || (!Boolean(model.generic_model) && model.model_code?.toLowerCase().includes(filter.toLowerCase()));
            });

            setModels(result);
        }
    }, [filter]);

    useEffect(() => {
        setModels(custom);
    }, [custom]);

    const renderBrandModel = (model) => {
        let title = `${model.model_name_des}`;

        if (!Boolean(model.generic_model)) {
            title += ` - ${model.model_code}`;
        }

        if (typeof model.brand_model_status !== "undefined" && model.brand_model_status !== "verified") {
            title += " (P)";
        };

        if (Boolean(model.generic_model)) {
            title += " (G)";
        }

        return title;
    }

    const handleSelectModel = (model) => () => onModelChange(model);

    return (
        <>
            <div className="mb-2 d-flex flex-row align-items-center flex-wrap gap-2">
                <span>Search filter</span>

                <div>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="Filter"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </div>
            </div>

            <a className="link mb-2 d-block" onClick={updateList}>Update list</a>

            {loading && <LoadIndicator />}

            {!loading && (
                <div className="table-responsive mb-4">
                    <table className="table table-responsive table-bordered table-dark-theme">
                        <thead>
                            <tr>
                                <th scope="col">Model / Service ({models.length})</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                models.map((model) => (
                                    <tr
                                        key={`table-model-selector-${model.brand_model_id}`}
                                        className={styles.model_row}
                                        onClick={handleSelectModel(model)}
                                    >
                                        <td>{renderBrandModel(model)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
});

export {
    TableModelSelector
}