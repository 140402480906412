import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setOtherAddUnitData,
  updateSavedState,
} from "redux-store/features/addUnitSlice";
import { selectToken } from "redux-store/features/authSlice";
import SWApplicationService from "services/SWApplicationService";
import SWVersionService from "services/SWVersionService";
import { useForm } from "shared/helpers";

import styles from "./SoftwareVersionManagement.module.css";
import VersionRow from "./VersionRow";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

function SoftwareVersionManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const [versions, setVersions] = useState([]);

  const swVersionService = new SWVersionService(dispatch, token);
  const swApplicationService = new SWApplicationService(dispatch, token);

  const { form, onChange } = useForm({ name: "", build_code: "" });

  const goBack = () => navigate(-1);

  const [application, setApplication] = useState(null);

  const { state } = useLocation();

  const goToSVR = (item) => {
    if (!application || !item) return;

    if (state.for === "unit_details") {
      navigate(
        `/submit-quality-information/item-detail/${state.editForm?.unit_id}`,
        {
          state: {
            selected: item.sw_version_id,
            for: "select_version",
            editForm: state.editForm,
          },
          replace: true,
        }
      );
    } else if (state.for === "new_unit") {
      dispatch(updateSavedState({ versionInput: item.name }));
      dispatch(setOtherAddUnitData({ current_sw_version: item.sw_version_id }));
      navigate(-1);
    } else {
      navigate("/svr", {
        state: { for: "existing", application, item },
      });
    }
  };

  const handleNewVersion = () => {
    if (!application) return;

    navigate("/svr", {
      state: {
        for: "new",
        secondary_for: state?.for || null,
        editForm: state.editForm,
        application,
        prefilled: form,
      },
      replace: state.for === "unit_details" || state.for === "new_unit",
    });
  };

  const getVersions = async (id) => {
    try {
      const res = await swVersionService.fetchByApplication(id);

      setVersions(res.data.data);
    } catch (error) {}
  };

  const setupApplication = async (id) => {
    try {
      const res = await swApplicationService.find(id);

      const application = res.data.data;

      if (application) {
        setApplication(application);

        getVersions(application.sw_application_id);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (state && state.application) {
      setupApplication(state.application);
    }
  }, [state]);

  return (
    <RequireSubmittingUser>
      <div id="main-container">
        <div className="container">
          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          <h2 className="mb-4 userLevel-Title">Software version management</h2>

          {application && (
            <>
              <div className={`${styles.applicationContainer} mb-4`}>
                <p className={styles.filter}>Application</p>

                <div className="row mb-2">
                  <div className="col-6 col-md-2">
                    <div className={styles.topLabel}>
                      <span>Name:</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col-6 col-md-5`}>
                    {application.name}
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-6 col-md-2">
                    <div className={styles.topLabel}>
                      <span>Details:</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col-6 col-md-5`}>
                    {application.application}
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-6 col-md-2">
                    <div className={styles.topLabel}>
                      <span>Hw Brand:</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col-6 col-md-5`}>
                    {application.brand_name || "Null"}
                  </div>
                </div>
              </div>

              {/* <span className={styles.mInstruction}>Model Instructions</span> */}

              <div className={`${styles.versionListContainer} mb-4`}>
                <div className={styles.versionListHeader}>
                  <h5 className="mb-2 userLevel-Title">
                    Select Software Application
                  </h5>
                </div>

                <div className={`${styles.versionList}`}>
                  <header className="mb-2">
                    <div>
                      <div>
                        <span>Date</span>
                      </div>
                    </div>

                    <div>
                      <div>
                        <span>Name</span>
                      </div>
                    </div>

                    <div>
                      <div>
                        <span>Build code</span>
                      </div>
                    </div>

                    <div>
                      <div>
                        <span>Status</span>
                      </div>
                    </div>
                  </header>

                  <form id="newVersionForm" onSubmit={handleNewVersion}>
                    <div className="data">
                      {[...versions, { sw_version_id: null }].map((version) => {
                        return (
                          <VersionRow
                            key={`version-${version.sw_version_id}`}
                            data={version}
                            onClick={() => goToSVR(version)}
                            form={form}
                            onChange={onChange}
                          />
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>

              <div className={styles.footText}>
                <p>
                  *enter new version in the same format as the previous
                  versions, then click (Create New) to create the record in the
                  subsequent screen. Check instruction link above for
                  information on how to find the version in your unit.
                </p>
              </div>

              {/* close table  */}
              <div className={`${styles.btnContaner} mt-4`}>
                <button className={styles.cancel} onClick={goBack}>
                  Cancel
                </button>

                <button
                  className={styles.new}
                  type="submit"
                  form="newVersionForm"
                >
                  Create new
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </RequireSubmittingUser>
  );
}
export default SoftwareVersionManagement;
