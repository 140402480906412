import { useForm } from "shared/helpers";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { useCallback, useEffect, useState } from "react";
import PaymentService from "services/PaymentService";
import LoadIndicator from "components/LoadIndicator";
import { useNavigate, useSearchParams } from "react-router-dom";
import SuccessModal from "./SuccessModal";
import CanceledModal from "./CanceledModal";
import { FullScreenLoader } from "components/FullScreenLoader";

const AddCredit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [searchParams] = useSearchParams();

  const success = searchParams.get("success")?.toLowerCase() === "true";
  const canceled = searchParams.get("canceled")?.toLowerCase() === "true";

  const service = new PaymentService(token, dispatch);

  const {
    form: addCreditForm,
    onChange,
    reset,
  } = useForm({
    credits: 10,
  });
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [storedText, setStoredText] = useState(null);
  const [allowed, setAllowed] = useState(false);

  const [lastBuyAttempt, setLastBuyAttempt] = useState(null);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!lastBuyAttempt) return;

    const lastBuyAttemptDate = new Date(lastBuyAttempt.last_try + " UTC");
    const lastBuyAttemptTime = lastBuyAttemptDate.getTime();

    const interval = setInterval(() => {
      const now = Date.now();

      const minutesDiff = Math.abs(now - lastBuyAttemptTime) / 60000;

      if (minutesDiff < 5) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [lastBuyAttempt]);

  const handleBuyNow = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        setLoading(true);
        const res = await service.initiateCheckout(addCreditForm);
        if (res.data?.url) {
          reset();
          window.open(res.data.url);
        }
        if (res.data?.attempt) {
          setLastBuyAttempt(res.data.attempt);
        }
      } catch (error) {
        console.log("[ERROR]", error);
      } finally {
        setLoading(false);
      }
    },
    [addCreditForm.credits]
  );

  const fetchStoredText = useCallback(async () => {
    try {
      const res = await service.getCreditStoredText();
      setStoredText(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchLastBuyAttempt = useCallback(async () => {
    try {
      setInitializing(true);
      const res = await service.getCreditLastBuyAttempt();
      setLastBuyAttempt(res.data || null);
    } catch (error) {
      console.log(error);
    } finally {
      setInitializing(false);
    }
  }, []);

  const fetchIsCountryEnabled = useCallback(async () => {
    try {
      setInitializing(true);
      const res = await service.getIsCountryEnabled();
      setAllowed(Boolean(res.data.allowed));
    } catch (error) {
      console.log(error);
    } finally {
      setInitializing(false);
    }
  }, []);

  const closeModal = () =>
    navigate("/credit", {
      replace: true,
    });

  useEffect(() => {
    fetchIsCountryEnabled();
    fetchStoredText();
    fetchLastBuyAttempt();

    return () => setStoredText("");
  }, []);

  return (
    <>
      <SuccessModal show={success} handleClose={closeModal} />

      <CanceledModal show={canceled} handleClose={closeModal} />

      <FullScreenLoader isOpen={initializing} />

      <div id="main-container">
        {!allowed && (
          <div className="container">
            <h5>
              Your country is not registered for payments but we hope to address
              this soon, you can still earn credits if you register to submit
              quality information
            </h5>
          </div>
        )}

        {allowed && (
          <form
            className={`container ${styles.addCredit}`}
            onSubmit={handleBuyNow}
          >
            <h2 className="mb-4 userLevel-Title">Add Credit</h2>

            <h6 className="mb-4">
              Qualy transactions require credits to purchase reports.
            </h6>

            {storedText && (
              <h6 className={`${styles.info}`}>{storedText.text}</h6>
            )}

            <h6 className="mb-4">
              Transactions are handled by Stripe, Qualy does not store your
              financial information.
            </h6>

            <h5 className={`${styles.sub} mb-4`}>
              Enter the amount of credit you want to buy:
            </h5>

            <div className="row mb-3 px-3">
              <div className="col-12 col-lg-7">
                <div className="row mb-2">
                  <div className={`${styles.topLabel} col-6 col-md-3`}>
                    <span>Credits</span>
                  </div>

                  <div className="col-6 col-md-9">
                    <input
                      type="number"
                      className="form-control"
                      value={addCreditForm.credits}
                      onChange={(e) =>
                        onChange("credits", e.target.valueAsNumber)
                      }
                      step={"5"}
                      min={10}
                      max={1000}
                    />
                  </div>
                </div>
              </div>
            </div>

            {disabled && (
              <div className="mb-3">
                <p>You have to wait 5 minutes before another attempt</p>
              </div>
            )}

            <h6 className="mb-4">
              Credits are roughly equivalent to GBP
              <br />
              <br />
              Total will be provided in stripe interface and includes
              transaction fees and taxes in your countries official currency.
              You can only attempt payment one time every 5 minutes.
            </h6>

            <button
              className={`${styles.button}`}
              disabled={disabled || loading}
              type="submit"
            >
              {loading ? <LoadIndicator /> : "Buy Now"}
            </button>
          </form>
        )}
      </div>
    </>
  );
};

export default AddCredit;
