import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  logout,
  selectToken,
  selectUser,
} from "redux-store/features/authSlice";
import AssetService from "services/AssetService";
import UserService from "services/UserService";
import styles from "./styles.module.css";
import {
  selectUnreadMessages,
  setUnreadMesssages,
} from "redux-store/features/unreadMessagesSlice";
import {
  IdentificationIcon,
  SubmitQualityIcon,
  GetQualityIcon,
  HomeIcon,
  SettingsIcon,
  MessageIcon,
  UserAdminIcon,
  ArrowDownIcon,
} from "../SvgIcons";
import NavItem from "./NavItem";
import { LogoutIcon } from "../SvgIcons/LogoutIcon";

function DashboardHeader({}) {
  const user = useSelector(selectUser);

  const unreadMessages = useSelector(selectUnreadMessages);

  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const assetService = new AssetService(dispatch, token);

  const [profileUrl, setProfileUrl] = useState(null);

  const getProfileUrl = async () => {
    try {
      if (user && user.submitting && user.submitting.selfi_photo) {
        const url = user.submitting.selfi_photo;

        const res = await assetService.getAssetUrl(url);

        setProfileUrl(res.data.data);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const httpGetUnreadMessages = useCallback(async () => {
    try {
      const res = await UserService.getUnreadMessageCount(token, dispatch);

      if (res.data && res.data.success) {
        dispatch(setUnreadMesssages(res.data.data));
      }
    } catch (error) {
      console.error(error);
    }
  }, [token, dispatch]);

  useEffect(() => {
    httpGetUnreadMessages();

    if (user && user.submitting && user.submitting.selfi_photo) {
      getProfileUrl();
    }
  }, [user]);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light position-sticky top-0 py-0"
      style={{ zIndex: 5 }}
    >
      <div className="container py-0 align-items-stretch justify-content-between gap-0 gap-md-2">
        <Link className="navbar-brand my-3" to="/">
          <img
            src="/assets/images/qualy_logo.png"
            className={`${styles.logo} d-none d-md-block`}
            alt="QUALY"
            style={{ width: 120, height: 50 }}
          />
          <img
            src="/assets/images/qualy_symbol.png"
            className={`${styles.logo} d-block d-md-none`}
            alt="QUALY"
            style={{ width: 120, height: 50 }}
          />
        </Link>

        <div className="d-flex flex-row mx-5 justify-content-center align-items-stretch gap-2 gap-md-4 flex-wrap order-1 order-sm-0 mx-auto">
          <NavItem Icon={HomeIcon} location={"/"} tooltip="Home" />

          <NavItem
            Icon={GetQualityIcon}
            location={Boolean(user) ? "/get-quality" : "#"}
            relatedRoutes={[
              "/get-quality/what-is-quality",
              "/get-quality/single-brand-product",
            ]}
            tooltip="Get Quality"
          />

          {(!Boolean(user) || Boolean(user.submitting)) && (
            <NavItem
              Icon={SubmitQualityIcon}
              location={Boolean(user) ? "/submit-quality-information" : "#"}
              relatedRoutes={[
                "/add-unit",
                "/add-category",
                "/add-company",
                "/add-brand",
                "/add-model",
              ]}
              tooltip="Submit Quality"
            />
          )}
        </div>

        {Boolean(user) && (
          <div className="dropdown my-auto" style={{ height: "fit-content" }}>
            <button
              className={`d-flex flex-row align-items-center gap-2 dropdown-toggle ${styles.user_profile}`}
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="position-relative">
                <div className={styles.avatar}>
                  <img
                    src={profileUrl || "/assets/images/default-avatar.jpg"}
                    alt="profile"
                  />
                </div>
              </div>

              <span>
                {user?.first_name + " " + user?.last_name} <br />
                <em>ID: </em> {user?.number}
              </span>

              <ArrowDownIcon />
            </button>

            <div
              className={`dropdown-menu dropdown-menu-right ${styles.dropdown_menu}`}
              aria-labelledby="dropdownMenuButton"
            >
              <Link className="d-block px-2 position-relative" to="/identity">
                <div className="d-flex flex-row gap-4 align-items-center px-2 py-3 position-relative">
                  <IdentificationIcon />

                  <span>Identification</span>
                </div>
              </Link>

              <Link
                className="d-block px-2 position-relative"
                to="/preferences"
              >
                <div className="d-flex flex-row gap-4 align-items-center px-2 py-3 position-relative">
                  <SettingsIcon />

                  <span>Preferences</span>
                </div>
              </Link>

              <Link className="d-block px-2 position-relative" to="/messages">
                <div className="d-flex flex-row gap-4 align-items-center px-2 py-3 position-relative">
                  <MessageIcon />

                  <span>Messaging</span>

                  <div className={styles.badge}>
                    {unreadMessages?.length ?? 0}
                  </div>
                </div>
              </Link>

              <Link className="d-block px-2 position-relative" to="/credit">
                <div className="d-flex flex-row gap-4 align-items-center px-2 py-3 position-relative">
                  <i
                    className="bi bi-credit-card-fill"
                    style={{ fontSize: 22 }}
                  ></i>

                  <span>Credit</span>
                </div>
              </Link>

              {Boolean(user) && Boolean(user.submitting) && (
                <Link
                  className="d-block px-2 position-relative"
                  to="/user-invite"
                >
                  <div className="d-flex flex-row gap-4 align-items-center px-2 py-3 position-relative">
                    <UserAdminIcon />

                    <span>User Invite</span>
                  </div>
                </Link>
              )}

              <Link className="d-block px-2 position-relative" to="/signout">
                <div className="d-flex flex-row gap-4 align-items-center px-2 py-3 position-relative">
                  <LogoutIcon />

                  <span>Logout</span>
                </div>
              </Link>
            </div>
          </div>
        )}

        {!Boolean(user) && (
          <div className="flex-row align-items-center gap-2 d-flex">
            <Link className="btn btn-yellow" to={"/auth"}>
              Register
            </Link>

            <Link className="btn btn-yellow" to={"/auth/signin"}>
              Log In
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
}

export default DashboardHeader;
