import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedUnit: null,
  filter: {
    aberration: null,
    category: null,
  },
};

export const symptomSelectionSlice = createSlice({
  name: "symptom_selection",
  initialState,
  reducers: {
    setSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload || null;
    },

    updateFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]:
          typeof action.payload.value === "undefined"
            ? null
            : action.payload.value,
      };
    },

    resetFilter: (state) => {
      state.filter = {
        aberration: null,
        category: null,
      };
    },
  },
});

export const { setSelectedUnit, updateFilter, resetFilter } =
  symptomSelectionSlice.actions;

export const selectSymptomSelectionData = (state) => ({
  ...state.symptom_selection,
});

export default symptomSelectionSlice.reducer;
