const LogoutIcon = ({ color = "#fff" }) => {
    return (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 12.5L22.3333 14.9698M18.3333 2.62074H22.3333C23.0406 2.62074 23.7189 2.88095 24.219 3.34413C24.719 3.80731 25 4.43552 25 5.09056V6.32546L18.3333 2.62074ZM18.3333 22.3793H22.3333C23.0406 22.3793 23.7189 22.119 24.219 21.6559C24.719 21.1927 25 20.5645 25 19.9094V18.6745L18.3333 22.3793ZM2.9 21.6729L10.9 23.8957C11.2982 24.0064 11.7189 24.0294 12.1284 23.9627C12.538 23.896 12.925 23.7415 13.2585 23.5117C13.5921 23.2818 13.8631 22.9828 14.0497 22.6387C14.2363 22.2945 14.3334 21.9147 14.3333 21.5296V3.47036C14.3334 3.08525 14.2363 2.70546 14.0497 2.36133C13.8631 2.01719 13.5921 1.71823 13.2585 1.48834C12.925 1.25845 12.538 1.104 12.1284 1.03731C11.7189 0.970626 11.2982 0.993557 10.9 1.10427L2.9 3.32711C2.35069 3.47982 1.86918 3.79249 1.52692 4.21871C1.18465 4.64494 0.999874 5.16201 1 5.69319V19.3068C0.999874 19.838 1.18465 20.3551 1.52692 20.7813C1.86918 21.2075 2.35069 21.5202 2.9 21.6729V21.6729ZM9.00133 12.5H9H9.00133ZM18.3333 12.5H25H18.3333ZM25 12.5L22.3333 10.0302L25 12.5Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export {
    LogoutIcon
}