import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import {
  selectAllMessageCasesBugTypes,
  selectMessageCases,
  selectMessageCasesBugTypes,
} from "redux-store/features/messageCasesSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMessageCases } from "hooks/useMessageCases";
import LoadIndicator from "components/LoadIndicator";
import { useForm } from "shared/helpers";
import { selectUser } from "redux-store/features/authSlice";
import AttachFiles from "../AttachFiles";
import ConfirmClose from "./modals/ConfirmClose";
import { CaseMessage } from "./CaseMessage";
import SingleMessageView from "components/SingleMessageView";

const MessageCase = () => {
  const navigate = useNavigate();
  const controller = useMessageCases();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const message = searchParams.get("message");

  const user = useSelector(selectUser);
  const { case_id } = useParams();
  const [editSummary, setEditSummary] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [summary, setSummary] = useState("");
  const [savingSmmary, setSavingSummary] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [openCloseConfirm, setOpenCloseConfirm] = useState(null);
  const form = useForm({
    message: "",
    files: [],
  });

  const bugTypes = useSelector(selectMessageCasesBugTypes);
  const allBugTypes = useSelector(selectAllMessageCasesBugTypes);
  const messageCases = useSelector(selectMessageCases);

  const selectedMessageType = useMemo(() => {
    if (!selectedCase?.type || allBugTypes.length === 0) return null;

    return (
      allBugTypes.filter((type) => type.type_id === selectedCase.type)[0] ||
      null
    );
  }, [selectedCase?.type, allBugTypes.length]);

  const goBack = () => navigate(-1);
  const cleanUp = () => {
    setSelectedCase(null);
    setEditSummary(false);
    setSavingSummary(false);
    setSummary("");
    setMessages([]);
    setUsers([]);
    setErrors([]);
    setSaving(false);
    setOpenCloseConfirm(false);
  };

  const handleEditSummaryClick = async () => {
    if (!selectedCase) return;

    if (!editSummary) {
      setEditSummary(true);
      setSummary(selectedCase.title);
    } else {
      if (!summary) return;

      setSavingSummary(true);
      await controller.httpUpdateCase(selectedCase.id, { title: summary });
      setSavingSummary(false);
      setSummary("");
      setEditSummary(false);
    }
  };

  const isMessageFromSupporter = (msg) => {
    if (!msg) return false;

    const messageUser = users.filter((e) => e.user === msg.user)[0];

    if (!messageUser) return false;

    return Boolean(messageUser.supporter);
  }

  const getMessageFrom = (msg) => {
    if (!msg) return "-";

    const messageUser = users.filter((e) => e.user === msg.user)[0];

    if (!messageUser) return "-";

    let from = [];

    if (Boolean(messageUser.supporter)) {
      from.push("Supporter");
    } else {
      from.push("User");
    }

    if (user && user.user_id === messageUser.user) {
      from.push("(You)");
    }

    return from.join(" ");
  };

  const getNewMessageFrom = () => {
    const messageUser = users.filter((e) => e.user === user?.user_id)[0];

    if (!messageUser) return "-";

    return Boolean(messageUser.supporter) ? "Supporter (You)" : "User (You)";
  };

  const handleCloseCase = () => setOpenCloseConfirm(true);
  const closeCloseConfirm = () => setOpenCloseConfirm(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.form.message || !case_id) return;

    setErrors([]);
    setSaving(true);

    try {
      const message = await controller.httpCreateCaseMessage(
        case_id,
        form.form.message,
        form.form.files
      );

      console.log("[message]", message);

      if (message) {
        setMessages((prev) => [...prev, message]);
      }
    } catch (error) {}

    setSaving(false);
    form.reset();
  };

  const shouldAllowReply = useMemo(() => {
    const messageUser = users.filter((e) => e.user == user?.user_id)[0] || null;

    return (
      messageUser &&
      !Boolean(selectedCase?.closed) &&
      (Boolean(selectedMessageType?.user_reply) ||
        Boolean(messageUser.supporter))
    );
  }, [
    user?.user_id,
    users,
    selectedMessageType?.user_reply,
    selectedCase?.closed,
  ]);

  useEffect(() => {
    return cleanUp;
  }, []);

  useEffect(() => {
    if (bugTypes.length === 0) {
      controller.httpGetBugTypes();
    }
  }, [bugTypes.length]);

  useEffect(() => {
    if (allBugTypes.length === 0) {
      controller.httpGetAllBugTypes();
    }
  }, [allBugTypes.length]);

  useEffect(() => {
    const setupCase = async () => {
      let messageCase = messageCases.filter((e) => e.id === +case_id)[0];

      if (!messageCase) {
        messageCase = await controller.httpGetCase(case_id);
      }

      setSelectedCase(messageCase || null);
    };
    if (case_id) {
      setupCase();
    }
  }, [case_id, messageCases]);

  useEffect(() => {
    if (selectedCase?.id) {
      controller.httpGetCaseMessages(selectedCase.id).then(setMessages);
      controller.httpGetCaseUsers(selectedCase.id).then(setUsers);
    }
  }, [selectedCase?.id]);

  const onMessageViewed = (message_id) => {
    setMessages((prev) => prev.map((message) => {
      if (message.message_id == message_id) {
        let readBy = message.read_by;

        if (!readBy) readBy = `${user.user_id}`;
        else {
          readBy += `,${user.user_id}`;
        }

        return { ...message, read_by: readBy }
      }

      return message;
    }))
  }

  return (
    <>
      <ConfirmClose
        show={openCloseConfirm}
        handleClose={closeCloseConfirm}
        caseId={case_id}
        closeCase={controller.httpCloseCase}
      />

      <div id="main-container">
        {!message && <>
          <form className="container" onSubmit={handleSubmit}>
            <div className={`${styles.arrow} mb-4`} onClick={goBack}>
              <img src="/assets/images/ArrowLeft.png" alt="" />
              <span>Go Back</span>
            </div>

            <h2 className="mb-4">Case</h2>

            {!selectedCase && <LoadIndicator size="md" />}

            {selectedCase && (
              <div className="row">
                <div className={`${styles.caseDetails} mb-4 col-12 col-xl-8`}>
                  <div className={`${styles.group}`}>
                    <div>
                      <label>Case</label>
                    </div>

                    <p>{selectedCase.id}</p>
                  </div>

                  <div className={`${styles.group}`}>
                    <div>
                      <label>Type</label>
                    </div>

                    <p>{selectedMessageType?.name}</p>
                  </div>

                  <div className={`${styles.group}`}>
                    <div>
                      <label>Summary</label>
                    </div>

                    {editSummary ? (
                      <input
                        type="text"
                        className="form-control"
                        value={summary}
                        onChange={(e) => setSummary(e.target.value)}
                      />
                    ) : (
                      <p>{selectedCase.title}</p>
                    )}

                    <span>
                      <button
                        type="button"
                        className="btn btn-yellow editButton"
                        onClick={handleEditSummaryClick}
                      >
                        {savingSmmary ? (
                          <div className="mx-2">
                            <LoadIndicator />
                          </div>
                        ) : (
                          <i className="bi bi-pencil mx-2"></i>
                        )}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            )}

            {selectedCase && messages.length > 0 && (
              <div className="table-responsive my-4">
                <table className="table table-bordered table-dark-theme">
                  <thead>
                    <tr>
                      <th>From</th>
                      <th colSpan={3}>Message</th>
                      <th>Sent</th>
                    </tr>
                  </thead>

                  <tbody>
                    {messages.map((msg) => {

                      if (!isMessageFromSupporter(msg) && msg.user !== user?.user_id && msg.to && msg.to !== user?.user_id) {
                        return null;
                      }

                      if (isMessageFromSupporter(msg) && msg.to && msg.to !== user?.user_id) {
                        return null;
                      }

                      return (
                        <CaseMessage 
                          key={`case-messages-${selectedCase.id}-${msg.message_id}`}
                          message={msg}
                          getMessageFrom={getMessageFrom}
                          caseId={selectedCase.id}
                          onMessageViewed={onMessageViewed}
                        />
                      );
                    })}

                    {shouldAllowReply && (
                      <tr>
                        <td>{getNewMessageFrom()}</td>

                        <td colSpan={3}>
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows={2}
                            value={form.form.message}
                            onChange={(e) =>
                              form.onChange("message", e.target.value)
                            }
                            required
                          />

                          <div className="d-flex flex-row justify-content-between align-items-center flex-wrap my-3">
                            <AttachFiles
                              value={form.form.files}
                              onChange={(files) => form.onChange("files", files)}
                            />

                            <button
                              className="btn btn-yellow"
                              disabled={!form.form.message || saving}
                              type="submit"
                            >
                              <i className="bi bi-send"></i>{" "}
                              {saving ? <LoadIndicator /> : "Send"}
                            </button>
                          </div>
                        </td>

                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </form>

          <div className="d-flex flex-row align-items-center justify-content-center">
            {selectedCase && !Boolean(selectedCase.closed) && (
              <button
                className="btn btn-black me-2"
                type="button"
                onClick={handleCloseCase}
              >
                Close
              </button>
            )}

            {/* <button className="btn btn-yellow">Add User</button> */}
          </div>
        </>}

        {message && (
          <div className="container">
            <SingleMessageView
              messageId={Number(message)}
              handleClose={() => navigate(-1)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MessageCase;
