import React, { useState, useEffect } from "react";
import { logout } from "redux-store/features/authSlice";

export const handle401 = (dispatch) => dispatch(logout());

export const formatEnum = (string = "") => {
  while (string.includes("_")) string = string.replace("_", " ");
  while (string.includes("-")) string = string.replace("-", " ");

  return `${string[0]?.toUpperCase()}${string.substring(1)?.toLowerCase()}`;
};

export const useForm = (initialState = {}) => {
  const [form, set_form] = useState({ ...initialState });
  const [err, set_err] = useState([]);

  const onChange = (name, value) =>
    set_form((prev) => ({ ...prev, [name]: value }));

  const validate = (keys) => {
    set_err([]);

    for (const key of keys) {
      if (typeof form[key] === "undefined" || form[key] === null) {
        set_err([key + " is required."]);
        return false;
      }
    }

    return true;
  };

  const reset = () => {
    set_form(initialState);
    set_err([]);
  };

  const handleServerError = (error) => {
    const msg = error.response?.data?.message;
    if (msg) {
      if (msg instanceof String || typeof msg === "string") set_err([msg]);
      else {
        const arr = [];
        Object.keys(msg).map((key) => {
          for (const emsg of msg[key]) arr.push(emsg);
        });

        set_err(arr);
      }
    } else {
      set_err(["Something went wrong"]);
    }
  };

  return { form, onChange, err, reset, validate, handleServerError, set_err };
};

export const useDescriptionModal = () => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const openDescriptionModal = (msg) => {
    setDescription(msg);
    setShowDescriptionModal(true);
  };

  const closeDescriptionModal = () => setShowDescriptionModal(false);

  return {
    showDescriptionModal,
    description,
    openDescriptionModal,
    closeDescriptionModal,
  };
};

export const useShortDisplay = (off, duration = 3000, showing = false) => {
  let timer = null;
  useEffect(() => {
    if (showing) {
      timer = setTimeout(() => {
        off();
      }, duration);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [showing]);
};

export const isAllPresent = (str) => {
  // Regex to check if a string
  // contains uppercase, lowercase
  // special character & numeric value
  var pattern = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
  );

  // If the string is empty
  // then print No
  if (!str || str.length === 0) {
    return false;
  }

  // Print Yes If the string matches
  // with the Regex
  if (pattern.test(str)) {
    return true;
  } else {
    return false;
  }
};

export const formatMMDDYYYY = (date_string) => {
  const date = new Date(date_string);

  if (!date) return;

  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const formatDDMMYYYYDash = (date_string) => {
  const date = new Date(date_string);

  if (!date) return;

  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const logger = (msg, obj) => {
  if (process.env.NODE_ENV === "production") return;
  console.log(msg);

  if (obj) console.log(obj);
};

export const getDecimalYear = (date) => {
  let startOfYear = new Date(date.getFullYear(), 0, 1); // January 1st of the same year
  let endOfYear = new Date(date.getFullYear() + 1, 0, 1); // January 1st of the next year

  let totalMilliseconds = date.getTime() - startOfYear.getTime();
  let yearMilliseconds = endOfYear.getTime() - startOfYear.getTime();

  let decimalYear = date.getFullYear() + totalMilliseconds / yearMilliseconds;
  return decimalYear;
};

export const handleError = (error, setErrors, showWarningModal = undefined) => {
  console.log(error);
  const msg = error.response?.data?.message || error.message;
  if (msg) {
    if (msg instanceof String || typeof msg === "string") {
      if (error.response?.data.showModal && showWarningModal) {
        showWarningModal(msg);
      } else if (setErrors) {
        setErrors([msg]);
      } else {
        return [msg];
      }
    } else {
      const arr = [];
      Object.keys(msg).map((key) => {
        for (const emsg of msg[key]) arr.push(emsg);
      });

      if (setErrors) {
        setErrors(arr);
      } else {
        return arr;
      }
    }
  } else {
    if (setErrors) {
      setErrors(["Something went wrong, check your internet"]);
    } else {
      return ["Something went wrong, check your internet"];
    }
  }
};

export const handleErrorV2 = (error) => {
  const errorObj = error.response?.data || {
    message: "Something went wrong, check your internet",
  };

  if (!errorObj.message) {
    errorObj.message = "Something went wrong, check your internet";
  }

  if (
    !(
      errorObj.message instanceof String || typeof errorObj.message === "string"
    )
  ) {
    const arr = [];
    Object.keys(errorObj.message).map((key) => {
      for (const emsg of errorObj.message.message[key]) arr.push(emsg);
    });

    errorObj.message = arr.join(", ");
  }

  return errorObj;
};

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

/**
 * Paramaters
 * prefilledCategory: array desc: Array of category id on selected in ORDER
 * setDropmatrix: function
 * setCategory: function
 * setCategory: function
 * categories: array
 */
export const setUpCategoryIfPrefilledExists = (
  prefilledCategory = [],
  setDropdownMatrix = () => {},
  setCategory,
  setCategoryInput,
  categories = []
) => {
  if (prefilledCategory.length === 0) return;

  let dropdownMatrix = [categories];
  let currentCategory = categories[0];
  let categoryInput = {};

  for (let i = 0; i < prefilledCategory.length; i++) {
    if (i === 0) {
      categoryInput[0] = "All";
    } else {
      dropdownMatrix.push(currentCategory.children);

      const currentPrefilledCategory = prefilledCategory[i];

      currentCategory = currentCategory.children.filter(
        (category) => category.category_id == currentPrefilledCategory
      )[0];

      if (!currentCategory) return;

      categoryInput[i] = currentCategory.name;
    }
  }

  setDropdownMatrix(dropdownMatrix);
  setCategory(currentCategory);
  setCategoryInput(categoryInput);
};

export const addLeadingZeros = (num, totalLength) => {
  return String(num).padStart(totalLength, "0");
};

export const formatYYYYMMDD = (date_string) => {
  const date = new Date(date_string);

  if (!date) return;

  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const formatDDMMYYY = (date_string, yearLength = 0) => {
  const date = new Date(date_string);

  if (!date) return;

  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return `${day}/${month}/${year.toString().substring(yearLength)}`;
};

export const monthNameAbbrMap = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const formatDateStringToDateNameYear = (date_string, splitter = "-") => {
  const split = date_string.split(splitter);

  return `${Number(split[2])} ${monthNameAbbrMap[Number(split[1]) - 1]} ${
    split[0]
  }`;
};

export const convertDateToYearMonthString = (date = new Date()) => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;

  if (month < 10) month = `0${month}`;

  return `${year}-${month}`;
};

export const getDateFromDateAndTimeString = (date_string, time_string) => {
  console.log(date_string, time_string);
  if (!date_string) return null;

  const date = new Date(date_string);

  if (!time_string) {
    date.setHours(0);
    date.setMinutes(0);
  } else {
    if (time_string.includes(" ")) {
      time_string = time_string.split(" ")[1];
    }

    const time_split = time_string.split(":");

    date.setHours(Number(time_split[0]));
    date.setMinutes(Number(time_split[1]));
  }

  date.setSeconds(0);

  console.log(date);

  return date;
};

export const dateToDbTimestamp = (date = new Date()) => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let minutes = date.getMinutes();
  let hour = date.getHours();

  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (hour < 10) hour = `0${hour}`;

  return `${year}-${month}-${day}  ${hour}:${minutes}`;
};

export const convertDateToYearMonthDayString = (date = new Date()) => {
  if (!date) return null;

  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;

  return `${year}-${month}-${day}`;
};

export const formatDateStringToMonthYear = (date_string, splitter = "-") => {
  if (!date_string) return "";
  const split = date_string.split(splitter);

  return `${monthNameAbbrMap[Number(split[1]) - 1]} ${split[0]}`;
};

export const getDateToUseV2 = (date, time) => {
  new Date().getMinutes();
  if (!date) return null;

  if (typeof date === "string") date = new Date(date);

  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let day = date.getDate();
  if (day < 10) day = "0" + day;

  let hour = 0;
  let minutes = 0;

  console.log("Time", time);

  if (time) {
    if (typeof time === "string") time = new Date(time);

    hour = time.getHours();
    minutes = time.getMinutes();
  }

  if (hour < 10) hour = `0${hour}`;
  if (minutes < 10) minutes = `0${minutes}`;

  return `${year}-${month}-${day} ${hour}:${minutes}:00`;
};

export const getDateToUse = (date_string, time_string) => {
  if (!date_string) return null;
  const date = new Date();

  const date_split = date_string.split("-");
  const time_split = time_string?.split(":");

  const year = Number(date_split[0]);
  let month = Number(date_split[1]) - 1;
  if (month < 10) month = "0" + month;
  let day = Number(date_split[2]);
  if (day < 10) day = "0" + day;

  let hour = 0;
  let minutes = 0;

  if (time_string) {
    hour = Number(time_split[0]);
    minutes = Number(time_split[1]);
  }

  date.setSeconds(0);

  return `${year}-${month}-${day} ${hour}:${minutes}:00`;
};

export const getMonthName = (month) => {
  switch (month) {
    case 0:
      return "January";

    case 1:
      return "February";

    case 2:
      return "March";

    case 3:
      return "April";

    case 4:
      return "May";

    case 5:
      return "June";

    case 6:
      return "July";

    case 7:
      return "August";

    case 8:
      return "September";

    case 9:
      return "October";

    case 10:
      return "November";

    case 11:
      return "December";

    default:
      return "";
  }
};

export const capitalizeFirstLetter = (word = "") => {
  if (word.length === 0) return "";

  return word[0].toUpperCase() + word.substring(1);
};

export const isToday = (date) => {
  date = new Date(date);

  const today = new Date();

  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const isDateSame = (a, b) => {
  a = new Date(a);
  b = new Date(b);

  return (
    a.getDate() == b.getDate() &&
    a.getMonth() == b.getMonth() &&
    a.getFullYear() == b.getFullYear()
  );
};

export const modifyUsesList = (array = []) => {
  const hashMap = new Map();

  for (let value of array) {
    if (hashMap.has(value.unit_id)) {
      const oldUse = hashMap.get(value.unit_id);

      hashMap.delete(value.unit_id);

      hashMap.set(value.unit_id, {
        ...oldUse,
        uses: [...oldUse.uses, value],
      });
    } else {
      hashMap.set(value.unit_id, { ...value, uses: [value] });
    }
  }

  return Array.from(hashMap).map(([key, value]) => {
    return {
      ...value,
    };
  });
};

export function parseQueryString(data) {
  if (typeof data !== "object") {
    throw new Error("Invalid data");
  }

  const queries = [];

  for (const [key, value] of Object.entries(data)) {
    if (value === "" || value === null || typeof value === "undefined") {
      continue;
    }

    if (value instanceof Date) {
      queries.push(`${key}=${value.toISOString()}`);
      continue;
    }

    queries.push(`${key}=${value}`);
  }

  return queries.join("&");
}

export function convertDateTimeString(string) {
  if (!string) {
    return null;
  }

  const split = string.split(" ");

  const dateString = split[0];
  const timeString = split[1];

  const date = new Date(dateString);

  const timeSplit = timeString.split(":");

  const hour = Number(timeSplit[0]);
  const minute = Number(timeSplit[1]);

  date.setHours(hour);
  date.setMinutes(minute);

  return date;
}

export const sanitizeCurrency = (data) => {
  data = Number(data);

  if (!data && typeof data !== "number") {
    return 0.0;
  }

  if (Math.abs(data - Math.round(data)) === 0) {
    return data.toLocaleString() + ".00";
  }

  return data.toLocaleString();
};
