import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import authReducer from "./features/authSlice";
import prefillReducer from "./features/prefillSlice";
import unitsReducer from "./features/unitsSlice";
import addUnitReducer from "./features/addUnitSlice";
import submittinRoleDetailsReducer from "./features/submittingRoleDetails";
import selectedUnitReducer from "./features/selectedUnitSlice";
import softwareVersionManagement from "./features/softwareVersionManagement";
import categoryManagementReducer from "./features/categoryManagement";
import selectSymptomReducer from "./features/symptomSelectionSlice";
import aberrationManagementReducer from "./features/aberrationManagementSlice";
import symptomManagementReducer from "./features/symptomManagementSlice";
import unitQualityRecordReducer from "./features/unitQualityRecordSlice";
import dbVariableReducer from "./features/dbVariableSlice";
import newSymptomReducer from "./features/newSymptomSlice";
import brandsReducer from "./features/brandsSlice";
import modelsReducer from "./features/modelsSlice";
import itemDetailReducer from "./features/itemDetailsSlice";
import addUnitSliceV2 from "./features/addUnitSliceV2";
import messageCasesSlice from "./features/messageCasesSlice";
import messageTypeManagementSlice from "./features/messageTypeManagementSlice";
import unreadMessagesSlice from "./features/unreadMessagesSlice";
import appSlice from "./features/appSlice";
import reportSlice from "./features/reportSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  submitting_role_details: submittinRoleDetailsReducer,
  addUnit: addUnitReducer,
  addUnitV2: addUnitSliceV2,
  prefill: prefillReducer,
  units: unitsReducer,
  selectedUnit: selectedUnitReducer,
  messageCases: messageCasesSlice,
  software_version_management: softwareVersionManagement,
  categoryManagement: categoryManagementReducer,
  symptom_selection: selectSymptomReducer,
  aberration_management: aberrationManagementReducer,
  symptom_management: symptomManagementReducer,
  unit_quality_record: unitQualityRecordReducer,
  db_variables: dbVariableReducer,
  new_symptom: newSymptomReducer,
  brands: brandsReducer,
  models: modelsReducer,
  item_detail: itemDetailReducer,
  message_type_management: messageTypeManagementSlice,
  unreadMessages: unreadMessagesSlice,
  app: appSlice,
  reports: reportSlice,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  blacklist: [
    "submitting_role_details",
    "addUnit",
    "prefill",
    "units",
    "selectedUnit",
    "messageCases",
    "software_version_management",
    "symptom_selection",
    "aberration_management",
    "symptom_management",
    "unit_quality_record",
    "db_variables",
    "new_symptom",
    "brands",
    "models",
    "item_detail",
    "addUnitV2",
    "categoryManagement",
    "message_type_management",
    "unreadMessages",
    "app",
    "reports",
  ], //blacklisting a store attribute name, will not persist that store attribute.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);
