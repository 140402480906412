import React from "react";
import { Button, Modal } from "react-bootstrap";

function ActionModal({
  show,
  text,
  buttonTitle,
  buttonActions,
  action,
  showSecondary = true,
}) {
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        onHide={() => {}}
        keyboard={false}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
        ></Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          {text && <h5 className="mod-detail">{text}</h5>}
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "none" }}>
          {showSecondary && (
            <Button onClick={buttonActions[action.cancel]} variant="secondary">
              {buttonTitle.cancel || "Cancel"}
            </Button>
          )}

          <Button onClick={buttonActions[action.ok]}>
            {buttonTitle.ok || "Ok"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ActionModal;
