import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "redux-store/features/authSlice";

const { useEffect } = require("react");


const RequireSubmittingUser = ({ children }) => {
    const user = useSelector(selectUser);
    const navigate = useNavigate();

    useEffect(() => {
        if (!Boolean(user) || !Boolean(user.submitting)) {
            navigate("/");
        }
    }, [user]);

    return children;
};

export default RequireSubmittingUser;