import { useDispatch, useSelector } from "react-redux";
import {
  selectToken,
  selectUserPreferences,
  updateUserPreferences,
} from "redux-store/features/authSlice";
import PreferenceService from "services/PreferenceService";
import { handleError } from "shared/helpers";

export const usePreferences = () => {
  const preferences = useSelector(selectUserPreferences);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const service = new PreferenceService(token, dispatch);

  const getPayload = (form) => {
    if (!preferences) return {};

    const payload = {};

    for (const key of Object.keys(form)) {
      if (form[key] != null && form[key] != preferences[key]) {
        payload[key] = form[key];
      }
    }

    return payload;
  };

  const updatePreferences = async (form, set_err, setLoading) => {
    const payload = getPayload(form);

    if (Object.keys(payload).length === 0) {
      // Nothing to update
      return;
    }

    setLoading(true);
    try {
      const res = await service.update(payload);

      if (res.data.success) {
        dispatch(updateUserPreferences(res.data.data));
      }
    } catch (e) {
      handleError(e, set_err);
    }
  };

  const getUserDistanceUnit = () => {
    switch (preferences.distance) {
      case "Metric":
        return "Km";

      case "Imperial":
        return "Mi";

      default:
        return "";
    }
  };

  const getDbDistance = (distance = 0) => {
    distance = Number(distance);

    if (isNaN(distance)) return 0;

    if (preferences.distance == "Metric") {
      return distance;
    } else if (preferences.distance == "Imperial") {
      return distance * 1.6093;
    }
  };

  const getUserDistance = (distance = 0) => {
    distance = Number(distance);

    if (isNaN(distance)) return 0.0;

    if (preferences.distance == "Metric") {
      return distance.toFixed(1);
    } else if (preferences.distance == "Imperial") {
      return (distance / 1.6093).toFixed(1);
    }
  };

  const formatDate = (input) => {
    if (!input) return;

    const date = new Date(input);

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.getMonth().toString().padStart(2, "0");
    const year = date.getFullYear();

    switch (preferences.date_format) {
      case "yyyy/mm/dd":
        return `${year}/${month}/${day}`;

      case "dd/mm/yyyy":
        return `${day}/${month}/${year}`;

      case "mm/dd/yyyy":
        return `${month}/${day}/${year}`;
    }
  };

  return {
    preferences,
    updatePreferences,
    getDbDistance,
    getUserDistance,
    getUserDistanceUnit,
    formatDate,
  };
};
