import { FullScreenLoader } from "components/FullScreenLoader";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UnitService from "services/UnitService";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { addLeadingZeros, convertDateToYearMonthDayString, dateToDbTimestamp, getDateFromDateAndTimeString, handleError, isDateSame, useDescriptionModal } from "shared/helpers";
import DescModal from "components/DescModal";
import ReactDatePicker from "react-datepicker";
import CategoryService from "services/CategoryService";
import { UseCounterEst, UseRate } from "../item-detail/components";
import LoadIndicator from "components/LoadIndicator";
import { usePreferences } from "hooks/usePrefrences";
import WarningModal from "components/WarningModal";
import ErrorsComponent from "components/Errors";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const AddUseStep = {
    ONE: "ONE",
    TWO: "TWO"
};

const AddUse = () => {
    const navigate = useNavigate();
    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { getDbDistance } = usePreferences();

    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(AddUseStep.ONE);
    const [errors, setErrors] = useState([]);
    const [unit, setUnit] = useState(null);
    const [attributes, setAttributes] = useState(null);
    const [openUnitWarning, setOpenUnitWarning] = useState(false);
    const [data, setData] = useState({
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        usage_accumulated: null,
        usage_rate: null
    });

    const handleChange = (key) => (val) => {
        setData(prev => ({...prev, [key]: val}));
    }

    const handleSubmit = async (e, skipWrning = false) => {
        e?.preventDefault();

        if (!Boolean(unit)) {
            return;
        }

        try {
            setSubmitting(true);
            setErrors([]);

            const end_category_attribute_response = await CategoryService.getAttributes(token, dispatch, unit.category_id);
            const attributes = end_category_attribute_response.data.data;
            if (!Boolean(attributes)) {
                throw new Error('End category attributes not found.');
            }
            setAttributes(attributes);

            const uask = unit.brand_model_status === "verified" && Boolean(unit.category_verified_date) && attributes.use_units !== "Age" && attributes.use_units !== "Not applicable";
            const urequired = uask && Boolean(unit.transferable);
            const uwarn = uask && !Boolean(unit.transferable);

            if (uask && step === AddUseStep.ONE) {
                setStep(AddUseStep.TWO);
                return;
            } else if (step === AddUseStep.TWO) {
                if (uwarn && !skipWrning) {
                    // open uwarn modal
                    setOpenUnitWarning(true);
                    return;
                } else {
                    if (urequired && !Boolean(data.usage_accumulated)) {
                        throw new Error('Usage meter reading is required');
                    }

                    if (!Boolean(data.usage_rate)) {
                        throw new Error('Usage rate is required');
                    }
                }
            }
            
            const start_date = getDateFromDateAndTimeString(
                data.start_date,
                data.start_time
            );

            const end_date = getDateFromDateAndTimeString(
                data.end_date,
                data.end_time
            );

            if (data.end_date && start_date >= end_date) {
                setErrors(["Start date and time must be less than end date and time"]);
                return;
            }
        
            if (start_date >= new Date()) {
                setErrors(["Start date must be a valid date in the past"]);
                return;
            }

            const payload = {
                unit: unit.unit_id,
                start_date: dateToDbTimestamp(start_date),
                end_date: end_date ? dateToDbTimestamp(end_date) : null,
                uask: uask ? 1 : 0,
            };

            if (typeof data.usage_accumulated === "number") {
                payload.usage_accumulated = getDbDistance(data.usage_accumulated);
            }
             
            if (typeof data.usage_rate !== "undefined") {
                payload.usage_rate = getDbDistance(data.usage_rate);
            }

            await UnitService.addControllerUseAndPrice(token, dispatch, payload);

            navigate("/submit-quality-information");
        } catch (error) {
            console.log('[error]', error);
            handleError(error, setErrors);
        } finally {
            setSubmitting(false);
        }
    }

    const handleWarningOk = () => {
        handleSubmit(null, true);
    }

    const setupAddUse = async (unit_id) => {
        try {
            setLoading(true);
            const response = await UnitService.getUnit(token, dispatch, unit_id);
            setUnit(response.data.data);
        } catch (error) {
            console.log('[error]', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (state?.unit_id) {
            setupAddUse(state.unit_id);
        }
    }, [state]);

    const {
        showDescriptionModal,
        description,
        closeDescriptionModal,
        openDescriptionModal,
      } = useDescriptionModal();

    return (
        <RequireSubmittingUser>
            <FullScreenLoader isOpen={loading} />

            <DescModal
                show={showDescriptionModal}
                handleClose={closeDescriptionModal}
                text={description}
            />

            <WarningModal 
                show={openUnitWarning}
                handleClose={() => setOpenUnitWarning(false)}
                handleOk={handleWarningOk}
                text={"This item is non-transferable. Please confirm the item is brand new, (this type is non-transferable)"}
                negativeLabel="No"
                positiveLabel="Yes"
            />

            <div id="main-container" className={styles.wrapper}>
                <form className="container" onSubmit={handleSubmit}>
                    <h2 className="mb-4 userLevel-Title">Enter Use Information</h2>

                    {step === AddUseStep.ONE && (
                        <>
                            <div className="mb-4">
                                <p className={styles.sub}>Selected Unit</p> 

                                <div className="row mb-2">
                                    <div className="col-6 col-md-2">
                                        <div className={styles.topLabel}>
                                            <span>QUN:</span>
                                        </div>
                                    </div>

                                    <div className={`${styles.topLabelVal} col-6 col-md-5`}>
                                        {unit ? addLeadingZeros(unit.unit_id, 8) : ''}
                                    </div>
                                </div>

                                {(Boolean(unit) && unit.serial_type !== "None") && (
                                    <div className="row mb-2">
                                        <div className="col-6 col-md-2">
                                            <div className={styles.topLabel}>
                                                <span>{unit.serial_type}:</span>
                                            </div>
                                        </div>

                                        <div className={`${styles.topLabelVal} col-6 col-md-5`}>
                                            {unit?.unique_identifier || '-'}
                                        </div>
                                    </div>
                                )}

                                <button
                                    className={`mb-4 btn btn-green`} 
                                    type="button"
                                    onClick={() => navigate(-1)}
                                >
                                    <i className="bi bi-arrow-left"></i>
                                </button>
                            </div>

                            <p className="mb-4">
                                When did you start using this item?
                                <br />
                                Not sure{' '}
                                <i
                                    className="bi bi-info-circle"
                                    onClick={() =>
                                        openDescriptionModal(`
                                            Don't worry if you are not sure, it can be approximate or you can change it later. You will only be able to enter quality records for the period of use.
                                        `)
                                    }
                                ></i>
                            </p>

                            {/** Start Date & Time */}
                            <div className="mb-4">
                                <div className="row mb-2">
                                    <div className="col-6 col-md-2">
                                        <div className={styles.topLabel}>
                                            <span>Start Date:</span>
                                        </div>
                                    </div>

                                    <div className={`col-6 col-md-7`}>
                                        <div className="customDatePickerWidth">
                                            <ReactDatePicker
                                                className="form-control datepicker_input"
                                                selected={data.start_date ? new Date(data.start_date) : null}
                                                onChange={(date) =>
                                                    handleChange('start_date')(convertDateToYearMonthDayString(date))
                                                }
                                                dateFormat="dd/MM/yyyy"
                                                required
                                                placeholderText="Select date or enter dd/MM/yyyy"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {(Boolean(data.end_date) && Boolean(data.start_date) && isDateSame(data.start_date, data.end_date)) && (
                                    <div className="row mb-2">
                                        <div className="col-6 col-md-2">
                                            <div className={styles.topLabel}>
                                                <span>Start Time:</span>
                                            </div>
                                        </div>

                                        <div className={`col-6 col-md-7`}>
                                            <div className="customDatePickerWidth">
                                                <ReactDatePicker
                                                    className="form-control datepicker_input"
                                                    selected={
                                                        data.start_date && data.start_time
                                                        ? getDateFromDateAndTimeString(
                                                            data.start_date,
                                                            data.start_time
                                                            )
                                                        : null
                                                    }
                                                    onChange={(date) =>
                                                        handleChange('start_time')(`${date.getHours()}:${date.getMinutes()}`)
                                                    }
                                                    placeholderText="Select time"
                                                    showTimeSelect
                                                    readOnly={!data.start_date}
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <p className="mb-4">
                                Enter the date you stopped using this or sold it
                                <br />
                                Leave it blank if you are still using it at least occassionally;
                            </p>

                            {/** Finish Date & Time */}
                            <div className="mb-4">
                                <div className="row mb-2">
                                    <div className="col-6 col-md-2">
                                        <div className={styles.topLabel}>
                                            <span>Finish Date:</span>
                                        </div>
                                    </div>

                                    <div className={`col-6 col-md-7`}>
                                        <div className="customDatePickerWidth">
                                            <ReactDatePicker
                                                className="form-control datepicker_input"
                                                selected={data.end_date ? new Date(data.end_date) : null}
                                                onChange={(date) =>
                                                    handleChange('end_date')(convertDateToYearMonthDayString(date))
                                                }
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="Select date or enter dd/MM/yyyy"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {(Boolean(data.end_date) && Boolean(data.start_date) && isDateSame(data.start_date, data.end_date)) && (
                                    <div className="row mb-2">
                                        <div className="col-6 col-md-2">
                                            <div className={styles.topLabel}>
                                                <span>Finish Time:</span>
                                            </div>
                                        </div>

                                        <div className={`col-6 col-md-7`}>
                                            <div className="customDatePickerWidth">
                                                <ReactDatePicker
                                                    className="form-control datepicker_input"
                                                    selected={
                                                        data.end_date && data.end_time
                                                        ? getDateFromDateAndTimeString(
                                                            data.end_date,
                                                            data.end_time
                                                            )
                                                        : null
                                                    }
                                                    onChange={(date) =>
                                                        handleChange('end_time')(`${date.getHours()}:${date.getMinutes()}`)
                                                    }
                                                    placeholderText="Select time"
                                                    showTimeSelect
                                                    readOnly={!data.end_date}
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {step === AddUseStep.TWO && (
                        <>
                            <button
                                className={`mb-4 btn btn-green`} 
                                type="button"
                                onClick={() => setStep(AddUseStep.ONE)}
                            >
                                <i className="bi bi-arrow-left"></i>
                            </button>

                            <p className="mb-4">
                                This item has a use counter
                            </p>

                            <div className="mb-4">
                                <p className="mb-2">Please enter the current reading (eg the odometer reading on a vehicle)</p>

                                <label className="form-label">
                                    Use Counter{" "}
                                    <i
                                        className="bi bi-info-circle"
                                        onClick={() =>
                                        openDescriptionModal(
                                            `The item should have a life counter (eg a Odometer in a car, or operational hours in a plane, enter the reading here in the unit required)`
                                        )
                                        }
                                    ></i>
                                </label>

                                <UseCounterEst
                                    mode={"edit"}
                                    unit={unit}
                                    endCategoryAttributes={attributes}
                                    value={data.usage_accumulated || ""}
                                    setValue={(val) =>
                                        handleChange("usage_accumulated")(val)
                                    }
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <p className="mb-2">
                                    Please enter the approximate use rate (eg how many miles/km you do per year)
                                    <br />
                                    You can change the units to match your estimate
                                </p>

                                <label className="form-label">
                                    Use Rate{" "}
                                    <i
                                        className="bi bi-info-circle"
                                        onClick={() =>
                                            openDescriptionModal(
                                                `Enter the estimate amount of use per week in the units required`
                                            )
                                        }
                                    ></i>
                                </label>

                                <UseRate
                                    mode={"edit"}
                                    unit={unit}
                                    endCategoryAttributes={attributes}
                                    setValue={(val) =>
                                        handleChange("usage_rate")(val)
                                    }
                                    required
                                />
                            </div>

                            <p className="mb-4">You can change the default units in your <a href="/preferences" className="link">Profile preferences</a></p>
                        </>
                    )}

                    {errors.length > 0 && (
                        <div className="mb-4">
                            <ErrorsComponent errors={errors} />
                        </div>
                    )}

                    {step === AddUseStep.ONE && (
                        <button 
                            disabled={!Boolean(data.start_date) || submitting} 
                            type="submit"
                            className="btn btn-yellow"
                        >
                            Next
                        </button>
                    )}

                    {step === AddUseStep.TWO && (
                        <button 
                            disabled={submitting} 
                            type="submit"
                            className="btn btn-yellow"
                        >
                            {submitting ? <LoadIndicator /> : "Submit"}
                        </button>
                    )}
                </form>
            </div>
        </RequireSubmittingUser>
    )
}

export default AddUse;