import { useEffect, useRef } from "react";
import styles from "./styles.module.css";

const ModalContainer = ({ isOpen, children }) => {
  const modalWrapperRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`d-flex flex-column align-items-center justify-content-center ${
          styles.modal_container
        } ${isOpen ? styles.open : ""}`}
      >
        <div
          ref={modalWrapperRef}
          className={`${
            styles.modal_content_wrapper
          } w-100 p-4 animate__animated ${
            isOpen ? "animate__zoomIn" : ""
          } animate__fast`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

const ModalHeader = ({ onClose }) => {
  return (
    <div
      className={`w-full d-flex mb-2 justify-content-end align-items-center ${styles.modal_header}`}
    >
      {onClose && (
        <button type="button" onClick={onClose}>
          <i className="bi bi-x-circle"></i>
        </button>
      )}
    </div>
  );
};

const ModalContent = ({ children }) => {
  return <div className={``}>{children}</div>;
};

export default {
  Container: ModalContainer,
  Content: ModalContent,
  Header: ModalHeader,
};
