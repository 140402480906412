import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import UserService from "services/UserService";
import VerificationHistoryService from "services/VerificationHistoryService";
import { MAX_FILE_SIZE } from "shared/constants";
import {
  handleError
} from "shared/helpers";
import ActionModal from "components/ActionModal";
import ErrorsComponent from "components/Errors";
import FileDisplayThumb from "components/FileDisplay";
import LoadIndicator from "components/LoadIndicator";
import LockTimer from "components/LockTimer";
import DescModal from "../../../components/DescModal";
import styles from "./styles.module.css";
import CollapsableTextContainer from "components/CollapsableTextContainer";
import { fullGuideanceText } from "./data";

function UserVerification() {
  const [searchParams] = useSearchParams();
  const [verification_types, set_verification_types] = useState([]);

  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const [err, set_err] = useState([]);
  const [success, set_success] = useState("");
  const [loading, set_loading] = useState(false);

  const [form, set_form] = useState({});
  const [file_slected, set_file_selected] = useState(null);
  const dispatch = useDispatch();

  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      return alert(`File size cannot be more than ${MAX_FILE_SIZE} mb.`);
    }

    onChange("name", file.name);
    set_file_selected(file);
  };

  const onChange = (name, value) => {
    set_form((prev) => ({ ...prev, [name]: value }));
  };

  const fetchVerificationTypes = async () => {
    try {
      const res = await UserService.getEnabledVeriMethods(token, dispatch);

      onChange("type", res.data.data[0]?.verif_id);
      set_verification_types(res.data.data);
    } catch (error) {}
  };

  const [desc_open, set_desc_open] = useState(false);
  const [message, set_message] = useState("");

  const openDescriptionModal = (text) => {
    set_message(text);
    set_desc_open(true);
  };

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const service = new VerificationHistoryService(token, dispatch);
  const [existingHistory, setExistingHistory] = useState(null);
  const [fetchingExistingHistory, setFetchingExistingHistory] = useState(false);

  const fetchExisting = async (history) => {
    setFetchingExistingHistory(true);
    try {
      const res = await service.getExistingVerificationHistory(history);

      if (res.data.data) {
        setExistingHistory(res.data.data);
      }
    } catch (error) {}
    setFetchingExistingHistory(false);
  };

  const isAnyOpen = async () => {
    setFetchingExistingHistory(true);

    try {
      const res = await service.isAnyOpen();

      if (res.data.data) {
        goBack();
      }
    } catch (error) {}

    setFetchingExistingHistory(false);
  };

  useEffect(() => {
    fetchVerificationTypes();
  }, []);

  useEffect(() => {
    const history = parseInt(searchParams.get("history"));

    if (history && typeof history === "number") {
      fetchExisting(history);
    } else {
      isAnyOpen();
    }
  }, [searchParams]);

  const [showActionModal, setShowActionModal] = useState(false);
  const [text, setText] = useState("");
  const [buttonTitle, setButtonTitle] = useState({});
  const [action, setAction] = useState({});

  const closeActionModal = () => {
    setShowActionModal(false);
    setText("");
    setButtonTitle({});
    setAction({});
  };

  const openActionModal = (text) => {
    setText(text);
    setButtonTitle({ ok: "Ok" });
    setAction({ ok: "ok" });
    setShowActionModal(true);
  };

  const buttonActions = {
    ok: closeActionModal,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!file_slected) return set_err(["Please select Image"]);
    if (!form.type) return set_err(["Please select verification type"]);

    if (!form.verify_text && !file_slected && !existingHistory) {
      openActionModal("You must enter some form of verification");
      return;
    }

    set_err([]);

    set_loading(true);

    try {
      const payload = new FormData();

      for (const key of Object.keys(form))
        if (form[key] !== null) {
          if (typeof form[key] === "string") {
            if (form[key].trim().length === 0) continue;
          }

          payload.append(key, form[key]);
        }

      if (file_slected) payload.append("file", file_slected);

      if (existingHistory)
        payload.append("history_id", existingHistory.history_id);

      let res;

      if (existingHistory) {
        res = await UserService.updateUserVerification(
          payload,
          token,
          dispatch
        );
      } else {
        res = await UserService.submitVerificationDocument(
          payload,
          token,
          dispatch
        );
      }

      navigate("/submit-role-details");
    } catch (error) {
      handleError(error, set_err);
    }

    set_loading(false);
  };

  useEffect(() => {
    if (existingHistory) {
      const data = {
        type: existingHistory.verification_type,
        verify_text: existingHistory.verify_text,
      };

      set_form((prev) => ({ ...prev, ...data }));
    }
  }, [existingHistory]);

  const information = useMemo(() => {
    if (form && form.type && verification_types.length > 0) {
      const verif = verification_types.filter(
        (item) => item.doc_id == form.type
      )[0];

      if (verif) {
        return verif.description || "";
      }
    }

    return "";
  }, [verification_types, form?.type]);

  const getTypeGuidance = useCallback(() => {
    if (user && user.submitting) {
      if (Number(user.submitting.verification_level) === 1) {
        return 'Please select a verification with your name and address from a business or gov\'t body';
      }

      if (Number(user.submitting.verification_level) === 2) {
        return 'Please select a verification with a photo that is from a business or gov\'t body';
      }
    }

    return "";
  }, [user?.submitting?.verification_level]);

  return (
    <>
      <DescModal
        show={desc_open}
        text={message}
        handleClose={() => set_desc_open(false)}
      />

      <ActionModal
        show={showActionModal}
        text={text}
        buttonTitle={buttonTitle}
        buttonActions={buttonActions}
        action={action}
        showSecondary={false}
      />

      <div className="container">
        <div id="main-container">
          <h1 className="title1 text-center centerlabel">
            Submitting User Verification
          </h1>

          {fetchingExistingHistory ? (
            <LoadIndicator />
          ) : (
            <form className="registerForm" onSubmit={handleSubmit}>
              {existingHistory && (
                <LockTimer data={existingHistory} onComplete={() => {}} />
              )}

              <div className="mb-4">
                <label className="form-label">
                  Select the Type of verification:
                </label>
                <select
                  className="form-select"
                  value={form.type || ""}
                  onChange={(e) => onChange("type", e.target.value)}
                  disabled={
                    existingHistory
                      ? existingHistory.initiated_by === "user" &&
                        !existingHistory.uploaded_date
                        ? false
                        : true
                      : false
                  }
                >
                  <option disabled value="">
                    Select type
                  </option>
                  {verification_types.map((item) => (
                    <option value={item.doc_id}>{item.type_name}</option>
                  ))}
                </select>

                <h6 className={`${styles.highlight} mt-2`}>
                  {getTypeGuidance()}
                </h6>
              </div>

              {information && (
                <div className=" mb-4">
                  <label className="form-label">Information</label>

                  <div>{information}</div>
                </div>
              )}

              <div className="mb-4">
                <CollapsableTextContainer 
                  collapsedContent={"Use your phone to take a picture of any document you wish to upload"}
                  fullContent={fullGuideanceText}
                />
              </div>

              {!existingHistory || !existingHistory.removed ? (
                <div className="mb-4">
                  <label className="form-label">
                    Enter Textual Verification{" "}
                    <i
                      className="bi bi-info-circle"
                      onClick={() =>
                        openDescriptionModal(
                          "Eg land line number or National insurance Numer"
                        )
                      }
                    ></i>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder=""
                    readOnly={
                      existingHistory?.process_flow_position === "closed"
                        ? true
                        : false
                    }
                    onChange={(e) => onChange("verify_text", e.target.value)}
                    value={form.verify_text}
                  />
                </div>
              ) : null}

              {!existingHistory || !existingHistory.removed ? (
                <div className="mb-4">
                  <label className="form-label">Document</label>
                  <div className="avatar-upload">
                    {(!existingHistory ||
                      existingHistory.process_flow_position != "closed") && (
                      <div className="avatar-edit">
                        <input
                          type="file"
                          id="imageUpload"
                          onChange={onFileChange}
                          accept=".png, .jpg, .jpeg, application/pdf, application/vnd.ms-excel.doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, .txt"
                          hidden
                        />
                        <label for="imageUpload">Please choose file</label>
                      </div>
                    )}

                    <div className="avatar-preview">
                      {existingHistory &&
                      existingHistory.doc_image &&
                      !file_slected ? (
                        <FileDisplayThumb
                          location={existingHistory.doc_image}
                          full={true}
                          showSpan
                        />
                      ) : file_slected &&
                        !file_slected.type.includes("image") ? (
                        <div>
                          <b>{file_slected.name}</b>
                        </div>
                      ) : (
                        <img
                          id="imagePreview"
                          src={
                            file_slected
                              ? URL.createObjectURL(file_slected)
                              : ""
                          }
                          alt=""
                          className="img-fluid"
                          title=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : null}

              {existingHistory && existingHistory.removed ? (
                <div className="mb-4" style={{ fontWeight: 700, fontSize: 20 }}>
                  No document stored
                </div>
              ) : null}

              {existingHistory?.reason_for_improvement && (
                <div className="mb-4">
                  <label className={`${styles.action_required} form-label`}>ACTION REQUIRED</label>
                  <div className="alert alert-warning">{existingHistory.reason_for_improvement}</div>
                </div>
              )}

              {existingHistory?.reason_for_rejection && (
                <div className="mb-4">
                  <label className="form-label">Reason for rejection</label>
                  <div>{existingHistory.reason_for_rejection}</div>
                </div>
              )}

              {err.length > 0 && <ErrorsComponent errors={err} />}

              {success && (
                <ErrorsComponent errors={[success]} variant="alert-success" />
              )}

              <div className="mt-4 text-center">
                <button
                  type="button"
                  className="btn btn-black me-3"
                  onClick={() => navigate("/submit-role-details")}
                >
                  Cancel
                </button>

                {(!existingHistory ||
                  existingHistory.process_flow_position !== "closed") && (
                  <button type="submit" className="btn btn-yellow">
                    {loading ? <LoadIndicator /> : "Submit"}
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default UserVerification;
