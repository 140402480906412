import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ActionTile({ selected = false, label, icon, changeView, value }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/messages?case=${value}`);
    changeView(value);
  };

  return (
    <div
      className={`messaging-messages-action__tile ${
        selected ? "messaging-messages-action__tile_selected" : undefined
      }`}
      onClick={handleClick}
    >
      <div className="yellow-bar" />

      {icon && <i className={icon} style={{ color: "#fc3" }}></i>}

      <div className="label">{label}</div>
    </div>
  );
}

export default ActionTile;
