import { axiosClient } from "shared/axiosInstance";

class CategoryService {
  static getAvailableCategories(token, dispatch) {
    return axiosClient(dispatch, token).get("/category");
  }

  static addCategory(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/category", data);
  }

  static getParentTree(token, dispatch, category) {
    return axiosClient(dispatch, token).get(
      "/category/parent-tree?category=" + category
    );
  }

  static getAttributes(token, dispatch, category) {
    return axiosClient(dispatch, token).get(
      "/category/attribute?category=" + category
    );
  }
}

export default CategoryService;
