import DataTable from "pages/dashboard/data-table";
import styles from "./styles.module.css";
import { useReportController } from "hooks/useReportController";

const reportColumns = [
  { text: "ID", key: "report_id" },
  { text: "Tdate", key: "t_date_p", type: "date", sortable: true },
  { text: "Price Cr", key: "price_cr", sortable: true },
];

const ReportPurchasesPage = () => {
  const report = useReportController();

  return (
    <div id="main-container">
      <div className="container">
        <h3 className="mb-4 fadeAnim">Report Purchases</h3>

        <div className="card mb-4 fadeAnim">
          <div className="card-body">
            <strong className={styles.text}>
              Select the report below to download
            </strong>

            <div className="mt-4">
              <DataTable
                columns={reportColumns}
                data={report.reports}
                clickAble
                columnDataClass={(data) => (data.pdf ? "link" : undefined)}
                onRowClick={report.onClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPurchasesPage;
