import { useSelector } from "react-redux";
import { selectDbVariablesData } from "redux-store/features/dbVariableSlice";
import Layout from "pages/main/layout";
import SiteUnavailableUi from "pages/under-contruction";

const SiteAvailable = () => {
  const { variables } = useSelector(selectDbVariablesData);

  return (
    <>
      {variables && !Boolean(variables.site_available) && <SiteUnavailableUi />}

      {variables && Boolean(variables.site_available) && <Layout />}
    </>
  );
};

export default SiteAvailable;
