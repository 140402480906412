import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectAddUnitV2Data } from "redux-store/features/addUnitSliceV2";
import SoftwareVersionRecord from "pages/dashboard/software/SoftwareVersionRecord";

const AddSWVersionModal = ({ show, handleClose, httpGetApplication }) => {
  const { selectedBrandModelAttribute } = useSelector(selectAddUnitV2Data);

  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (selectedBrandModelAttribute?.sw_application) {
      httpGetApplication(selectedBrandModelAttribute.sw_application).then(
        (data) => setApplication(data)
      );
    }
  }, [selectedBrandModelAttribute?.sw_application]);

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      fullscreen
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none", backgroundColor: "black" }}
      ></Modal.Header>

      <Modal.Body style={{ backgroundColor: "black", padding: 0 }}>
        <SoftwareVersionRecord
          modalMode="new"
          closeModal={handleClose}
          application={application}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddSWVersionModal;
