import { usePreferences } from "hooks/usePrefrences";
import { useCallback, useEffect, useMemo, useState } from "react";

function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const RateUnit = ({ unit, rate, onRateClick }) => (
  <div className="d-inline-flex flex-row align-items-center">
    {rate !== "%" && (
      <span>
        <button type="button" className="rate-unit me-1 text-capitalize">
          {unit}
        </button>
      </span>
    )}
    {rate !== "%" && <span>/</span>}
    <span>
      <button
        type="button"
        className="rate-unit ms-1 text-capitalize"
        onClick={onRateClick}
      >
        {rate}
      </button>
    </span>
  </div>
);

const UseRate = ({
  mode,
  unit,
  setValue: _setValue,
  required = false,
  endCategoryAttributes,
}) => {
  const validRates = useMemo(() => {
    const validRates = ["wk", "yr", "dy"];

    if (
      !endCategoryAttributes ||
      endCategoryAttributes.use_units.toLowerCase() !== "operational time"
    ) {
      return validRates;
    }

    return [...validRates, "%"];
  }, [endCategoryAttributes]);

  const [rate, setRate] = useState("yr");

  const getYearValue = (value, rate) => {
    value = Number(value);
    switch (rate) {
      case "dy":
        return value * 365.2425;

      case "wk":
        return value * 52.1429;

      case "%":
        return value / 0.01144689;

      case "yr":
        return value;

      default:
        return 0;
    }
  };

  const convertYearValue = (yearValue, newRate) => {
    switch (newRate) {
      case "dy":
        return yearValue / 365.2425;

      case "wk":
        return yearValue / 52.1429;

      case "%":
        return yearValue * 0.01144689;

      case "yr":
        return yearValue;

      default:
        return 0;
    }
  };

  const [value, setValue] = useState(unit?.usage_rate);

  useEffect(() => {
    if (typeof unit?.usage_rate !== "number") {
      setValue(null);
    } else {
      setValue(Number(unit.usage_rate));
    }
  }, [unit?.usage_rate]);

  const onChange = (e) => {
    setValue(e.target.valueAsNumber);

    if (isNaN(e.target.valueAsNumber)) {
      _setValue(null);
    } else {
      _setValue(getYearValue(e.target.valueAsNumber, rate).toFixed(1));
    }
  };

  useEffect(() => {
    setRate("yr");
  }, [mode]);

  useEffect(() => {
    if (mode === "view") return;

    if (isNaN(value)) {
      _setValue(null);
    } else {
      _setValue(getYearValue(value, rate).toFixed(1));
    }
  }, [rate]);

  useEffect(() => {
    if (!unit || !unit.usage_rate || !endCategoryAttributes) return;

    if (mode === "edit") {
      onChange({
        target: {
          valueAsNumber:
            endCategoryAttributes?.use_units?.toLowerCase() === "distance"
              ? getUserDistance(unit.usage_rate)
              : unit.usage_rate,
        },
      });
    } else {
      _setValue(Number(unit.usage_rate));
      setValue(Number(unit.usage_rate));
    }
  }, [mode, unit, endCategoryAttributes?.use_units]);

  const { getUserDistanceUnit, getUserDistance } = usePreferences();

  const getUnit = (use_units) => {
    switch (use_units) {
      case "Operational time":
        return "hr";
      case "Distance":
        return getUserDistanceUnit();
      case "Cycles":
        return "Cy";
      case "Uses":
        return "Use";
      default:
        return "";
    }
  };

  const getNewRate = () => {
    const lastIdx = validRates.length - 1;

    const idx = validRates.indexOf(rate);

    const newIdx = idx === lastIdx ? 0 : idx + 1;

    return validRates[newIdx];
  };

  // when rate is changed update displayed value
  const handleChangeRate = () => {
    const newRate = getNewRate();

    let yearValue = Number(value);
    if (rate !== "yr") yearValue = getYearValue(value, rate);

    let rateValue = yearValue;
    if (newRate !== "yr") rateValue = convertYearValue(yearValue, newRate);

    setRate(newRate);

    if (mode === "view") onChange({ target: { valueAsNumber: rateValue } });
  };

  const getDisplay = useCallback(() => {
    console.log(endCategoryAttributes, value, typeof value);
    if (!endCategoryAttributes || typeof value !== "number") return "Null";

    if (endCategoryAttributes.use_units?.toLowerCase() === "distance") {
      return Number(getUserDistance(value ? Number(value) : 0)).toFixed(1);
    }

    return value;
  }, [endCategoryAttributes?.use_units, value]);

  if (!unit || !endCategoryAttributes) return null;

  return (
    <div
      className={`position-relative ${mode === "edit" ? "w-100" : "w-auto"}`}
    >
      {mode === "view" && (
        <p>
          {getDisplay()}{" "}
          {typeof value === "number" && <RateUnit
            unit={getUnit(endCategoryAttributes.use_units)}
            rate={rate}
            onRateClick={handleChangeRate}
          />}
        </p>
      )}

      {mode === "edit" && (
        <div className="input-group">
          <input
            type="number"
            className="form-control input-lg"
            value={value || ""}
            onChange={onChange}
            step={".01"}
            required={required}
            placeholder="Entry required"
          />

          <div className="input-group-prepend">
            <div className="d-inline-flex flex-column justify-content-center h-100 ms-2">
              <RateUnit
                unit={getUnit(endCategoryAttributes.use_units)}
                rate={rate}
                onRateClick={handleChangeRate}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { UseRate };
