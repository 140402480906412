import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brand_list: [],
  initialized_brand_list: false,
  custom_brand_list: [],
};

export const brandsSlice = createSlice({
  name: "brands",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    initializeBrandList: (state, action) => {
      state.initialized_brand_list = true;
      state.brand_list = action.payload;
    },
    setCustomBrandList: (state, action) => {
      state.custom_brand_list = action.payload;
    },
    addBrandToList: (state, { payload }) => {
      state.brand_list = [payload, ...state.brand_list];
    },
  },
});

export const { initializeBrandList, setCustomBrandList, addBrandToList } =
  brandsSlice.actions;

export const selectBrandsData = (state) => state.brands;

export default brandsSlice.reducer;
