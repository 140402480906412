import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { handleError, logger, useShortDisplay } from "shared/helpers";
import LoadIndicator from "components/LoadIndicator";
import UserService from "services/UserService";
import ErrorsComponent from "components/Errors";

function ChangePasswordModal({ show, handleClose }) {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      setChanged(false);
      setPassword("");
    }
  }, [show]);

  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState("");
  const [secure, setSecure] = useState(true);

  useShortDisplay(() => setErrors([]), 3000, errors.length > 0);

  function isAllPresent(str) {
    // Regex to check if a string
    // contains uppercase, lowercase
    // special character & numeric value
    var pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );

    // If the string is empty
    // then print No
    if (!str || str.length === 0) {
      return false;
    }

    // Print Yes If the string matches
    // with the Regex
    if (pattern.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  const isPasswordInvalid = () => {
    if (password.length < 8)
      return "Password must be at least 8 characters in length";

    if (!isAllPresent(password))
      return "Password must contain uppercase, lowercase, numeric and special characters";

    return null;
  };

  const changePassword = async () => {
    const error = isPasswordInvalid();

    if (error) return setErrors([error]);

    setLoading(true);
    try {
      await UserService.changePassword(token, dispatch, { password });

      setChanged(true);

      setTimeout(() => {
        handleClose();
      }, 1500);
    } catch (error) {
      logger("error", error);
      handleError(error, setErrors);
    }
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none" }}
      >
        <Modal.Title>New Password</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex-column mb-4">
          {!changed ? (
            <div className="d-flex align-items-center mb-4">
              <input
                type={secure ? "password" : "text"}
                value={password}
                className="form-control"
                id="password"
                placeholder="Password"
                autoFocus
                onChange={(e) => setPassword(e.target.value)}
                maxLength={50}
              />

              <div
                onClick={() => setSecure(!secure)}
                style={{ marginLeft: 10, cursor: "pointer" }}
              >
                {!secure ? (
                  <FontAwesomeIcon icon={regular("eye")} size="lg" />
                ) : (
                  <FontAwesomeIcon icon={regular("eye-slash")} size="lg" />
                )}
              </div>
            </div>
          ) : (
            <h3 className="title3">Password changed successfully</h3>
          )}

          {errors.length > 0 && <ErrorsComponent errors={errors} />}
        </div>
      </Modal.Body>

      {!changed && (
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="primary" disabled={loading} onClick={changePassword}>
            {loading ? <LoadIndicator /> : "Submit"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ChangePasswordModal;
