import styles from "./styles.module.css";

const Benefits = () => {
  return (
    <div>
      <section className="homeBanner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 leftHomeBanner">
              <h1>Benefits with Qualy</h1>

              <p>
                If you are spending your money on a new purchase getting
                accurate data before you buy is difficult today, review sites
                are polluted with false reviews usually praising the product or
                service and only around the time of purchase. If you want
                accurate truthful lifetime data you need to pay for it.
              </p>

              <p>
                <b>Guaranteed Data</b> <br /> Qualy is a review site that gives
                guaranteed, truthful and accurate lifetime information about
                products and services that you are interested in, from people
                who have already used that product or service. If the data we
                provide is erroneous we will provide a refund.
              </p>

              <p>
                <b>Not Advertising or seller funded</b> <br /> Unlike other
                sites it isn't funded by the providers themselves or advertisers
                (for example in building quality membership schemes, search
                engines or directory services). Reports on a product or service
                have to be paid for. This means the buyer is the customer and
                must be satisfied.
              </p>

              <p>
                <b>Lifetime results</b> <br /> Unlike other review sites that
                just give an appraisal of the seller or if the product didn't
                work when you received it, Qualy gives you the information on
                the life of the product, even if used by multiple owners.
              </p>

              <p>
                <b>Priced according to your needs</b> <br />
                Data has a variable value, your report will contain data about a
                number of examples from a number of users. You decide how much
                you want to pay and what data you want.
              </p>

              <p>
                <b>Accurate and specific statistics</b> <br />
                When you buy a report it contains all the details of the fault
                records (symptoms). How many and what fault occurred. Faults are
                classified into specific faults decided by experts rather than
                everyone writing their own description. You can see graphs and
                summaries, or import the data and do your own analysis.
              </p>

              <p>
                <b>Strong identity checking</b>
                <br /> Unlike free sites we ensure that people who provide
                quality data are real people, and we can check that they have
                the product or service. It is in our interest to ensure the data
                is of high accuracy because of the guarantee. We use similar
                levels of identity checking that banks are now using.
              </p>

              <p>
                <b>Submitters of Quality Information</b>
                <br /> If you have been invited to join as a submitter, you can
                benefit by receiving credit for quality data you have entered
                every time it is used in a report (up to a limit). You will have
                to put in some details like a serial number, pick the producer,
                a reference for their product or service, when you started and
                finished using the item. Then you have to visit Qualy and update
                the record to say you have had no problems (for products and
                subscriptions), or to enter anything that might be considered
                defective from your point of view, and especially if the product
                or service is no longer useable, or you have stopped using it.
                This can be as regular as you wish but you are more likely to
                receive credit the more often you do this. You maybe asked to
                provide evidence of defects, or even to confirm you have the
                item.
                <br />
                <b className={styles.emphasis}>
                  <i>
                    Like other users you can then use this credit to buy reports
                    on things you might want to buy yourself in future.
                  </i>
                </b>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Benefits;
