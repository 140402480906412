import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unit: null,
};

export const selectedUnitSlice = createSlice({
  name: "selectedUnit",
  initialState,
  reducers: {
    setSelectedUnitInStore: (state, action) => {
      state.unit = action.payload;
    },

    clearSelectedUnitInStore: (state) => {
      state.unit = null;
    },

    editSelectedUnit: (state, action) => {
      state.unit = { ...state.unit, ...action.payload };
    },
  },
});

export const {
  setSelectedUnitInStore,
  clearSelectedUnitInStore,
  editSelectedUnit,
} = selectedUnitSlice.actions;

export const selectSelectedUnitData = (state) => ({
  ...state.selectedUnit,
});

export default selectedUnitSlice.reducer;
