import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialzedPending: false,
  pendingUnits: [],
  initialzedVerified: false,
  verifiedUnits: {
    multiple_use_products: [],
    consumable_products: [],
    single_use_products: [],
    continuous_use_services: [],
    single_use_services: [],
  },
  initialzedPastUse: false,
  pastUseUnits: {
    multiple_use_products: [],
    consumable_products: [],
    single_use_products: [],
    continuous_use_services: [],
    single_use_services: [],
  },
};

export const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    initializePendingUnits: (state, action) => {
      state.initialzedPending = true;
      state.pendingUnits = action.payload;
    },

    initializeVerifiedUnits: (state, action) => {
      state.initialzedVerified = true;

      const multiple_use_products = [];
      const consumable_products = [];
      const single_use_products = [];
      const continuous_use_services = [];
      const single_use_services = [];

      for (const unit of action.payload) {
        unit.price = "C0.10";
        unit.total = "C2.20";

        if (unit.item_class.toLowerCase() === "multiple use product") {
          multiple_use_products.push(unit);
        }

        if (unit.item_class.toLowerCase() === "single use product") {
          single_use_products.push(unit);
        }

        if (unit.item_class.toLowerCase() === "one-off service") {
          single_use_services.push(unit);
        }

        if (unit.item_class.toLowerCase() === "continuous service") {
          continuous_use_services.push(unit);
        }
      }

      state.verifiedUnits = {
        multiple_use_products,
        continuous_use_services,
        single_use_products,
        single_use_services,
        consumable_products,
      };
    },

    initializePastUseUnits: (state, action) => {
      state.initialzedPastUse = true;

      const multiple_use_products = [];
      const consumable_products = [];
      const single_use_products = [];
      const continuous_use_services = [];
      const single_use_services = [];

      for (const unit of action.payload) {
        if (unit.item_class.toLowerCase() === "multiple use product") {
          multiple_use_products.push(unit);
        }

        if (unit.item_class.toLowerCase() === "single use product") {
          single_use_products.push(unit);
        }

        if (unit.item_class.toLowerCase() === "one-off service") {
          single_use_services.push(unit);
        }

        if (unit.item_class.toLowerCase() === "continuous service") {
          continuous_use_services.push(unit);
        }
      }

      state.pastUseUnits = {
        multiple_use_products,
        continuous_use_services,
        single_use_products,
        single_use_services,
        consumable_products,
      };
    },
  },
});

export const {
  initializePendingUnits,
  initializeVerifiedUnits,
  initializePastUseUnits,
} = unitsSlice.actions;

export const selectUnitsData = (state) => ({ ...state.units });

export default unitsSlice.reducer;
