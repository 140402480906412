import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
};

export const categoryManagement = createSlice({
  name: "categoryManagement",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const { setCategories } = categoryManagement.actions;

export const selectCategoryManagementData = (state) => ({
  ...state.categoryManagement,
});

export const selectSelectedCategory = (state) =>
  state.categoryManagement.selectedCategory;

export default categoryManagement.reducer;
