import { Modal, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { logger } from "shared/helpers";
import ModelService from "services/ModelService";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { useMediaQuery } from "usehooks-ts";

function ExistingModelModal({ handleClose, show, categoryId, onChange }) {
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const matches = useMediaQuery("(max-width: 768px)");

  const getModels = async () => {
    setLoading(true);
    try {
      const res = await ModelService.getModelForCategory(
        token,
        dispatch,
        categoryId
      );

      logger(`Models for category ${categoryId}`, res.data);

      setModels(res.data.data);
    } catch (error) {}
    setLoading(false);
  };

  const handleClick = (model) => () => {
    if (typeof onChange === "function") {
      onChange("model_name_des")(model.model_name_des);
      onChange("model_code")(model.model_code);
      onChange("generic_model")(+model.generic_model);
      onChange("item_class")(model.item_class);
      onChange("personalized")(Boolean(model.personalized));
      onChange("serial_type")(model.serial_type);
      handleClose();
    }
  };

  useEffect(() => {
    if (show && categoryId) getModels();
  }, [show, categoryId]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
        size="lg"
        fullscreen={matches}
      >
        <Modal.Header
          closeButton
          closeVariant="black"
          style={{ borderBottom: "none" }}
        ></Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <div>
              <h3 className="card-title mb-4">Model attributes in Category</h3>
              <div className="table-responsive">
                <table className="table table-responsive table-bordered table-dark-theme">
                  <thead>
                    <tr>
                      <th scope="col">Model Description</th>
                      <th scope="col">Generic</th>
                      <th scope="col">Model Code</th>
                      <th scope="col">Serialisation</th>
                      <th scope="col">Item Class</th>
                      <th scope="col">Personal</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {models.map((model) => (
                      <tr key={"model-exist-row-" + model.model_id}>
                        <th scope="row">{model.model_name_des}</th>
                        <td>{Boolean(model.generic_model) ? "Yes" : "No"}</td>
                        <td>{model.model_code}</td>
                        <td>{model.serial_type}</td>
                        <td>{model.item_class}</td>
                        <td>{Boolean(model.personalized) ? "Yes" : "No"}</td>
                        {/* <td>
                          <button onClick={handleClick(model)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-copy"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                              />
                            </svg>
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ExistingModelModal;
