import { multiply } from "lodash";
import { axiosClient } from "../shared/axiosInstance";

class MessageCaseService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  get_bug_types() {
    return axiosClient(this.dispatch, this.token).get(
      "/message-cases/bug-types?site=user"
    );
  }

  get_all_bug_types() {
    return axiosClient(this.dispatch, this.token).get(
      "/message-cases/bug-types/all"
    );
  }

  create_message_case(data) {
    if (!data) return null;
    return axiosClient(this.dispatch, this.token).post("/message-cases", data);
  }

  update_message_case(id, data) {
    if (!data || !id) return null;
    return axiosClient(this.dispatch, this.token).patch(
      `/message-cases/${id}`,
      data
    );
  }

  get_case_by_id(id) {
    if (!id) return null;
    return axiosClient(this.dispatch, this.token).get(`/message-cases/${id}`);
  }

  get_user_cases() {
    return axiosClient(this.dispatch, this.token).get("/message-cases");
  }

  get_case_messages(id) {
    if (!id) return [];
    return axiosClient(this.dispatch, this.token).get(
      `/message-cases/${id}/messages`
    );
  }

  get_case_users(id) {
    if (!id) return [];
    return axiosClient(this.dispatch, this.token).get(
      `/message-cases/${id}/users`
    );
  }

  create_case_message(data) {
    if (!data) return null;
    return axiosClient(this.dispatch, this.token).post(
      "/message-cases/messages",
      data
    );
  }

  close_case(case_id) {
    if (!case_id) return null;
    return axiosClient(this.dispatch, this.token).patch(
      `/message-cases/${case_id}/close`
    );
  }

  get_message_by_id(message_id) {
    if (!message_id) return null;
    return axiosClient(this.dispatch, this.token).get(
      `/message-cases/messages/${message_id}`
    );
  }
}

export default MessageCaseService;
