import InviteRow from "./InviteRow";

const DesktopVersion = ({
  invites = [],
  deleteUserInvite,
  resendUserInvite,
}) => {
  
  return (
    <div className="user-invite__invites mb-4 fadeAnim">
      <header className="mb-3">
        <div>
          <div>
            <i className="bi bi-envelope"></i> <span>Email/Name</span>
          </div>
        </div>

        <div>
          <div>
            <i className="bi bi-calendar"></i> <span>Date</span>
          </div>
        </div>

        <div>
          <div>
            <i className="bi bi-clock"></i> <span>Invite Date</span>
          </div>
        </div>

        <div>
          <div>
            <i className="bi bi-question-circle"></i> <span>User Status</span>
          </div>
        </div>

        <div></div>
      </header>

      <div className="data">
        {invites.map((invite) => {
          return (
            <InviteRow
              key={`invite-row-${invite.invite_id}`}
              data={invite}
              deleteRow={deleteUserInvite}
              resendInvite={resendUserInvite}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DesktopVersion;
