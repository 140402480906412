import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import SwitchAnswer from "components/SwitchAnswer";
import CardTable from "components/CardTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import DateRange from "components/DateRange";
import { useServiceBrandController } from "hooks/useServiceBrandController";
import MultiStepCategorySelect from "components/MultiStepCategorySelect";
import WordPillBox from "components/WordPillBox";
import { COUNTRIES } from "shared/constants";
import { selectUser } from "redux-store/features/authSlice";
import { useSelector } from "react-redux";
import { selectDbVariablesData } from "redux-store/features/dbVariableSlice";
import { FullScreenLoader } from "components/FullScreenLoader";
import WarningModal from "components/WarningModal";
import { socket } from "socket";

const lastday = function (y, m) {
  return new Date(y, m + 1, 0).getDate();
};

const DEFAULT_SCREEN_STATE = {
  bidPrice: "No filter",
  bidPriceVal: 0,
  date_seen_from: null,
  date_seen_to: null,
  date_of_use_from: null,
  date_of_use_to: null,
  evidence_available: "No filter",
  country: "No filter",
  openInsufficientFunds: false,
  openPurchaseConfirmation: false,
  reportDownloadResponse: null,
};

const ServiceBrandComparison = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const user = useSelector(selectUser);
  const { variables } = useSelector(selectDbVariablesData);

  const controller = useServiceBrandController();

  const [screenState, setScreenState] = useState(DEFAULT_SCREEN_STATE);

  const handleScreenStateChange = (key, val) => {
    setScreenState((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const {
    fetching,
    queryData,
    report: baseReport,
    categorySelect,
    serviceTextIsDisabled,
    downloading,
    handleCategorySelect,
    handleIncludeToggle,
  } = controller;

  const goToAddCredit = () => navigate("/add-credit");

  const getHighestPrice = () => {
    let max = 0;

    baseReport.t1.forEach((e) => {
      if (Number(e.price) > max) {
        max = Number(e.price);
      }
    });

    return max;
  };

  useEffect(() => {
    if (screenState.bidPrice === "Set price") {
      handleScreenStateChange("bidPriceVal", getHighestPrice());
    }
  }, [screenState.bidPrice]);

  const report = useMemo(() => {
    let dateSeenFrom = null;
    let dateSeenTo = null;
    let dateUseFrom = null;
    let dateUseTo = null;

    if (screenState.date_seen_from) {
      dateSeenFrom = new Date(screenState.date_seen_from);
      dateSeenFrom.setDate(1);
    }

    if (screenState.date_of_use_from) {
      dateUseFrom = new Date(screenState.date_of_use_from);
      dateUseFrom.setDate(1);
    }

    if (screenState.date_seen_to) {
      dateSeenTo = new Date(screenState.date_seen_to);
      const lastDay = lastday(dateSeenTo.getFullYear(), dateSeenTo.getMonth());
      dateSeenTo.setDate(lastDay);
    }

    if (screenState.date_of_use_to) {
      dateUseTo = new Date(screenState.date_of_use_to);
      const lastDay = lastday(dateUseTo.getFullYear(), dateUseTo.getMonth());
      dateUseTo.setDate(lastDay);
    }

    const result = baseReport.t1.filter((report) => {
      const dateSeen = new Date(report.date_seen);

      const startTime = new Date(report.start_time);
      const endTime = report.end_time ? new Date(report.end_time) : null;

      return (
        (screenState.bidPrice === "No filter" ||
          Number(report.price) <= screenState.bidPriceVal) &&
        (!dateSeenFrom ||
          !dateSeenTo ||
          (dateSeen.getTime() >= dateSeenFrom.getTime() &&
            dateSeen.getTime() < dateSeenTo.getTime())) &&
        (!dateUseFrom ||
          !dateUseTo ||
          !startTime ||
          (startTime.getTime() >= dateUseFrom.getTime() &&
            startTime.getTime() < dateUseTo.getTime() &&
            !endTime) ||
          (startTime.getTime() >= dateUseFrom.getTime() &&
            startTime.getTime() < dateUseTo.getTime()) ||
          (endTimendTime.getTime() >= dateUseFrom.getTime() &&
            endTime.getTime() < dateUseTo.getTime())) &&
        (screenState.evidence_available === "No filter" ||
          (screenState.evidence_available === "Yes"
            ? Boolean(report.evidence)
            : !Boolean(report.evidence))) &&
        (screenState.country === "No filter" ||
          report.country === screenState.country)
      );
    });

    return result;
  }, [
    baseReport,
    screenState.bidPrice,
    screenState.bidPriceVal,
    screenState.date_seen_from,
    screenState.date_seen_to,
    screenState.date_of_use_from,
    screenState.date_of_use_to,
    screenState.evidence_available,
    screenState.country,
  ]);

  const brandCount = useMemo(() => {
    const brands = [];

    report.forEach((e) =>
      !brands.includes(e.brand) ? brands.push(e.brand) : undefined
    );

    return brands.length;
  }, [report]);

  const price_calc = useMemo(() => {
    if (!variables) return "";

    const filteredReport = report.filter((e) => e["sales(n)"] === 0);

    let QC = 0;
    filteredReport.forEach((e) => (QC += Number(e.price)));
    const countN = filteredReport.length;
    const PC = 0; // Path cost
    const GC = QC + PC; // Gross Cost
    const FF = Number(variables.report_comm_fixed);
    const VF = (Number(variables.report_comm_vari) * GC) / 100;
    const TC = GC + FF + VF;

    const AQP = QC / countN;

    return TC;
  }, [report, variables?.report_comm_fixed, variables?.report_comm_vari]);

  useEffect(() => {
    if (user?.country) {
      handleScreenStateChange("country", user.country);
    }
  }, [user?.country]);

  const columns = useMemo(() => {
    return [
      {
        title: "Brand",
        key: "brand",
        colSpan: 3,
      },
      {
        title: "Service",
        getData: (row) => `${row.model_name_des} - ${row.model_code}`,
        colSpan: 3,
      },
      {
        title: "Include",
        getData: (row, index) => (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <input
              className="form-check-input"
              type="checkbox"
              checked={Boolean(row.include)}
              onChange={handleIncludeToggle(index)}
            />
          </div>
        ),
      },
    ];
  }, [handleIncludeToggle]);

  const buyQuality = async () => {
    if (!user) return;

    if (user.balance < price_calc) {
      handleScreenStateChange("openInsufficientFunds", true);
    } else {
      handleScreenStateChange("openPurchaseConfirmation", true);
    }
  };

  const downloadReport = useCallback(async () => {
    if (screenState.reportDownloadResponse?.report_index) {
      const status = await controller.httpDownloadReport(
        screenState.reportDownloadResponse.report_index
      );
      if (status) handleScreenStateChange("reportDownloadResponse", null);
    }
  }, [screenState.reportDownloadResponse?.report_index]);

  useEffect(() => {
    function onTrigger(message) {
      if (message.user === user?.user_id) {
        handleScreenStateChange("reportDownloadResponse", message);
      }
    }
    socket.on("service_comparison_report_download_ready", onTrigger);

    return () => {
      socket.off("service_comparison_report_download_ready", onTrigger);
    };
  }, [socket.connected, user?.user_id]);

  return (
    <>
      <WarningModal
        show={screenState.openInsufficientFunds}
        text={"You don't have enough credit"}
        positiveLabel="Add Credit"
        negativeLabel="Cancel"
        handleOk={goToAddCredit}
        handleClose={() =>
          handleScreenStateChange("openInsufficientFunds", false)
        }
      />

      <WarningModal
        show={screenState.openPurchaseConfirmation}
        text={`Purchase report with ${price_calc} credit?`}
        positiveLabel="Confirm"
        negativeLabel="Cancel"
        handleOk={() => controller.httpBuyQuality(report)}
        handleClose={() =>
          handleScreenStateChange("openPurchaseConfirmation", false)
        }
      />

      <FullScreenLoader isOpen={fetching} />

      <div id="main-container" className={styles.serviceBrandComparison}>
        <div className="container">
          <h2 className="mb-3 userLevel-Title">Service Brand Comparison</h2>

          <div className={`${styles.arrow} mb-5`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span>Get Quality Menu</span>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mb-5">
                <div className={`${styles.subtitle} mb-3`}>
                  Mutiple brand comparison for Single Use services
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Category</span>
                  </div>

                  <div className="col-7">
                    <MultiStepCategorySelect
                      id={"service-brand-comparison-category"}
                      categoryInput={categorySelect.categoryInput}
                      dropdownMatrix={categorySelect.dropdownMatrix}
                      setCategoyInput={categorySelect.setCategoyInput}
                      setDropdownMatrix={categorySelect.setDropdownMatrix}
                      setValue={(val) => handleCategorySelect(val)}
                      verifiedOnly
                    />
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Service Type Search</span>
                  </div>

                  <div className="d-none d-md-block col-7 my-auto">
                    <WordPillBox
                      value={queryData.serviceTexts}
                      setValue={(val) =>
                        controller.handleQueryDataChange("serviceTexts", val)
                      }
                      placeHolder="No Filter"
                      disabled={serviceTextIsDisabled}
                    />
                  </div>

                  <div className="d-none d-md-block col-2 my-auto">
                    <SwitchAnswer
                      rightLabel="OR"
                      leftLabel="AND"
                      value={queryData.serviceTextOperator}
                      handleChange={(val) =>
                        controller.handleQueryDataChange(
                          "serviceTextOperator",
                          val
                        )
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3 d-flex d-md-none">
                  <div className="col-9">
                    <WordPillBox
                      value={queryData.serviceTexts}
                      setValue={(val) =>
                        controller.handleQueryDataChange("serviceTexts", val)
                      }
                      placeHolder="No Filter"
                      disabled={serviceTextIsDisabled}
                    />
                  </div>

                  <div className="col-3">
                    <SwitchAnswer
                      rightLabel="OR"
                      leftLabel="AND"
                      value={queryData.serviceTextOperator}
                      handleChange={(val) =>
                        controller.handleQueryDataChange(
                          "serviceTextOperator",
                          val
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="mb-5">
                <CardTable
                  id={"single-brand-comparison"}
                  customClassName={styles.dataTable}
                  columns={columns}
                  data={baseReport.t3}
                  selectable="include"
                />
              </div>

              <div className="mb-5">
                <div className={`${styles.subtitle} mb-2`}>Filters</div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Symtom bid Price</span>
                  </div>

                  <div className="col-7">
                    <select
                      className="form-select"
                      value={screenState.bidPrice}
                      onChange={(e) =>
                        handleScreenStateChange("bidPrice", e.target.value)
                      }
                    >
                      <option value="No filter">No filter</option>

                      <option value="Set price">Set price</option>
                    </select>

                    {screenState.bidPrice === "Set price" && (
                      <input
                        type="number"
                        className="form-control mt-2"
                        step={"0.01"}
                        min={0}
                        max={99.99}
                        value={screenState.bidPriceVal}
                        onChange={(e) =>
                          handleScreenStateChange(
                            "bidPriceVal",
                            e.target.valueAsNumber
                          )
                        }
                      />
                    )}
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Date of symptom</span>
                  </div>

                  <div className="col-7">
                    <DateRange
                      type="month"
                      from={screenState.date_seen_from}
                      to={screenState.date_seen_to}
                      handleChangeFrom={(val) =>
                        handleScreenStateChange("date_seen_from", val)
                      }
                      handleChangeTo={(val) =>
                        handleScreenStateChange("date_seen_to", val)
                      }
                    />
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Date of use</span>
                  </div>

                  <div className="col-7">
                    <DateRange
                      type="month"
                      from={screenState.date_of_use_from}
                      to={screenState.date_of_use_to}
                      handleChangeFrom={(val) =>
                        handleScreenStateChange("date_of_use_from", val)
                      }
                      handleChangeTo={(val) =>
                        handleScreenStateChange("date_of_use_to", val)
                      }
                    />
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Evidence available</span>
                  </div>

                  <div className="col-7">
                    <select
                      className="form-select"
                      value={screenState.evidence_available}
                      onChange={(e) =>
                        handleScreenStateChange(
                          "evidence_available",
                          e.target.value
                        )
                      }
                    >
                      <option value="No filter">No filter</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                  </div>
                </div>

                <div className="row ps-2 mb-3">
                  <div className={`${styles.topLabel} col-5 col-md-3`}>
                    <span>Country</span>
                  </div>

                  <div className="col-7">
                    <select
                      id="country"
                      className="form-select"
                      value={screenState.country}
                      onChange={(e) =>
                        handleScreenStateChange("country", e.target.value)
                      }
                    >
                      <option value="No filter">No filter</option>
                      {COUNTRIES.map((item, idx) => (
                        <option key={`country-${idx}`} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-auto">
                    <button className={`${styles.searchButton} m-0`}>
                      Search Records
                    </button>
                  </div>
                </div>
              </div>

              <div className="mb-5">
                <div className={`${styles.subtitle} mb-3`}>
                  Records in Report
                </div>

                <div className={`mb-3`}>
                  <div className={`${styles.recordInReport} ps-3`}>
                    <div className="row">
                      <div className="col-3">
                        <span>Brands</span>
                      </div>

                      <div className="col-1">
                        <span className={styles.seperator}></span>
                      </div>

                      <div className="col-8">
                        <span>{brandCount}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`mb-3`}>
                  <div className={`${styles.recordInReport} ps-3`}>
                    <div className="row">
                      <div className="col-3">
                        <span>Quality</span>
                      </div>

                      <div className="col-1">
                        <span className={styles.seperator}></span>
                      </div>

                      <div className="col-8">
                        <span style={{ textDecoration: "none" }}>
                          {report.length}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`mb-3`}>
                  <div className={`${styles.recordInReport} ps-3`}>
                    <div className="row">
                      <div className="col-3">
                        <span>Cost Cr</span>
                      </div>

                      <div className="col-1">
                        <span className={styles.seperator}></span>
                      </div>

                      <div className="col-8">
                        <span style={{ textDecoration: "none" }}>
                          {price_calc}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`d-flex flex-row align-items-center flex-wrap ${styles.actions}`}
              >
                <button
                  type="button"
                  disabled={report.length === 0 || downloading}
                  onClick={buyQuality}
                >
                  Buy
                </button>

                <button disabled={downloading}>Cancel</button>

                <button
                  type="button"
                  disabled={
                    !Boolean(
                      screenState.reportDownloadResponse?.report_index
                    ) || downloading
                  }
                  onClick={downloadReport}
                >
                  Download Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceBrandComparison;
