import { useEffect, useState } from "react";
import styles from "./styles.module.css";

const SwitchAnswer = ({ rightLabel, leftLabel, handleChange, value }) => {
  const [isLeft, setIsLeft] = useState(true);

  useEffect(() => {
    if (value) {
      if (value === rightLabel) {
        setIsLeft(false);
      } else if (value === leftLabel) {
        setIsLeft(true);
      }
    }
  }, [value]);

  const onChange = (label) => () => {
    if (handleChange) {
      handleChange(label);
    } else {
      setIsLeft(label === leftLabel);
    }
  };

  return (
    <>
      <div className={`container p-0 h-100 d-flex flex-column justify-content-center ${styles.switchAnswer}`}>
        <div className="row gx-1">
          <div className="col-6">
            <div
              className={`${styles.switchBadge} ${isLeft ? styles.active : ""}`}
              onClick={onChange(leftLabel)}
            >
              {leftLabel}
            </div>
          </div>

          <div className="col-6">
            <div
              className={`${styles.switchBadge} ${
                !isLeft ? styles.active : ""
              }`}
              onClick={onChange(rightLabel)}
            >
              {rightLabel}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwitchAnswer;
