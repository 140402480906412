import RequireSubmittingUser from "HoC/RequireSubmittingUser";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken } from "redux-store/features/authSlice";
import CompanyService from "services/CompanyService";
import { COUNTRIES } from "shared/constants";
import { formatYYYYMMDD, useForm } from "shared/helpers";
import DescModal from "components/DescModal";
import LoadIndicator from "components/LoadIndicator";

function AddCompany({ closeModal }) {
  const { form, onChange, validate } = useForm({
    type: "Limited",
    country: "Afghanistan",
  });

  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  // Description modal props
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const openDescriptionModal = (msg) => {
    setDescription(msg);
    setShowDescriptionModal(true);
  };

  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const saveCompanyToDb = async (e) => {
    e.preventDefault();

    const isValid = validate(["type", "company_name", "country", "start_date"]);

    if (!isValid) return;

    setSaving(true);

    let address_line1 = "";
    if (form.no) address_line1 += form.no;
    if (form.street) {
      if (address_line1 === "") address_line1 += form.street;
      else address_line1 = address_line1 + " " + form.street;
    }

    try {
      const res = await CompanyService.addCompanyToDb(token, dispatch, {
        ...form,
        address_line1,
      });

      closeModal(res.data.data);
    } catch (error) {}
    setSaving(false);
  };

  useEffect(() => {
    onChange("start_date", formatYYYYMMDD(new Date().toISOString()));
  }, []);

  return (
    <RequireSubmittingUser>
      <DescModal
        show={showDescriptionModal}
        handleClose={() => setShowDescriptionModal(false)}
        text={description}
      />

      <div id="main-container">
        <div className="container">
          <h1 className="page-title mb-4">Add New Company</h1>

          <form className="" onSubmit={saveCompanyToDb}>
            <div className="registerForm ms-0 me-0">
              <div className="mb-4">
                <label className="form-label">
                  Type of Company{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        "Limited companies usually have limited, LLC, PLC, corp or Inc in their full name, if not then the company is likely to be a sole trader/partnership. This is mainly for the purpose of identifiying potential personal information"
                      )
                    }
                  ></i>
                  <em>*</em>
                </label>
                <select
                  value={form.type || ""}
                  onChange={(e) => onChange("type", e.target.value)}
                  className="form-select"
                >
                  <option value={"Limited"}>Limited (private / public)</option>
                  <option value={"Unlimited"}>
                    Unlimited (sole trader / partnership)
                  </option>
                  <option value={"Charity"}>Charity</option>
                  <option value={"Not for profit"}>
                    Not for profit / community interest
                  </option>
                  <option value={"Government"}>Government</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="form-label">
                  Country of Registration{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        "The country the company is formally registered in. Sole traders/Partnerships will not usually be formally registered but will normally be resident in the country they are advertising and working in"
                      )
                    }
                  ></i>
                  <em>*</em>
                </label>
                <select
                  value={form.country}
                  onChange={(e) => onChange("country", e.target.value)}
                  className="form-select"
                >
                  {COUNTRIES.map((item) => (
                    <option
                      key={`country-c ${Math.random()}`}
                      value={item.name}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="form-label">
                  Legal Name of Company{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        `For limited
companies this is the registered name.  For unlimited (sole
trader/partnership) businesses put the name they advertise their
services as this could be a company name or their personal name`
                      )
                    }
                  ></i>
                  <em>*</em>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="company"
                  placeholder="Legal Name Company"
                  name="company"
                  required
                  value={form.company_name || ""}
                  onChange={(e) => onChange("company_name", e.target.value)}
                  maxLength="60"
                />
              </div>

              <div className="mb-4">
                <label className="form-label">
                  Building Number {form.type != "Government" && <em>*</em>}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="building-number"
                  placeholder="Building Number"
                  name="building"
                  value={form.no || ""}
                  onChange={(e) => onChange("no", e.target.value)}
                  maxLength="50"
                  required={form.type != "Government"}
                />
              </div>

              <div className="mb-4">
                <label className="form-label">
                  Street Name {form.type != "Government" && <em>*</em>}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lucid-street"
                  placeholder="Lucid Street"
                  name="street"
                  value={form.street || ""}
                  onChange={(e) => onChange("street", e.target.value)}
                  maxLength="50"
                  required={form.type != "Government"}
                />
              </div>

              <div className="mb-4">
                <label className="form-label">Town</label>
                <input
                  type="text"
                  className="form-control"
                  id="town"
                  placeholder="Town"
                  name="town"
                  value={form.town || ""}
                  onChange={(e) => onChange("town", e.target.value)}
                  maxLength="20"
                />
              </div>

              <div className="mb-4">
                <label className="form-label">State/County/District</label>
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  placeholder="Hertfordshire"
                  name="state"
                  value={form.region || ""}
                  onChange={(e) => onChange("region", e.target.value)}
                  maxLength="20"
                />
              </div>

              <div className="mb-4">
                <label className="form-label">
                  Postal Reference{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal("Post code or zip code")
                    }
                  ></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="postal"
                  placeholder="Postal"
                  name="postal"
                  value={form.postal_ref || ""}
                  onChange={(e) => onChange("postal_ref", e.target.value)}
                  maxLength="10"
                />
              </div>

              <div className="mb-4">
                <label className="form-label">
                  Business Start Date{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(`
                        If you don't know the start date leave it at today's date, longer
running businesses are more secure for consumers
                      `)
                    }
                  ></i>
                  <em>*</em>
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="start_date"
                  name="start_date"
                  value={form.start_date || ""}
                  onChange={(e) => onChange("start_date", e.target.value)}
                  required
                />
              </div>

              <div className="mb-4">
                <label className="form-label">Website</label>
                <input
                  type="text"
                  className="form-control"
                  id="website"
                  placeholder="Website"
                  name="website"
                  maxLength="255"
                  value={form.website || ""}
                  onChange={(e) => onChange("website", e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="form-label">Contact Number</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Contact number"
                  name="phone"
                  value={form.contact_number || ""}
                  onChange={(e) => onChange("contact_number", e.target.value)}
                  maxLength="20"
                />
              </div>

              <div className="mb-4">
                <label className="form-label">Contact Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="website"
                  placeholder="Contact email"
                  name="email"
                  value={form.contact_email || ""}
                  maxLength="255"
                  onChange={(e) => onChange("contact_email", e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="form-label">Note</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="notes"
                  rows="6"
                  placeholder="Note"
                  name="note"
                  value={form.notes || ""}
                  maxLength="200"
                  onChange={(e) => onChange("notes", e.target.value)}
                />
              </div>

              <div className="mt-4 text-center">
                <button
                  type="button"
                  className="btn btn-black me-2"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-yellow"
                  disabled={saving}
                >
                  {saving ? <LoadIndicator /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </RequireSubmittingUser>
  );
}

export default AddCompany;
