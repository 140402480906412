import AppRoutes from "./routes";
import "animate.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  login as setUserDetails,
  selectToken,
} from "redux-store/features/authSlice";
import UserService from "services/UserService";
import "react-datepicker/dist/react-datepicker.css";
import { socket } from "socket";
import DbVariablesService from "services/DbVariablesService";
import {
  selectDbVariablesData,
  setDbVariables,
  updateDbVariable,
} from "redux-store/features/dbVariableSlice";
import GenericModals from "components/GenericModals";

function App({ defaultRoute }) {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const { variables } = useSelector(selectDbVariablesData);

  const variableService = new DbVariablesService(token, dispatch);
  const fetchDbVariables = async () => {
    try {
      const res = await variableService.getDatabaseGlobalVariables();

      dispatch(setDbVariables(res.data.data || null));
    } catch (error) {}
  };

  useEffect(() => {
    if (!variables) {
      fetchDbVariables();
    }
  }, [variables]);

  const updateUserDetails = async () => {
    try {
      const res = await UserService.me(token, dispatch);

      dispatch(setUserDetails(res.data.data));
    } catch (error) {}
  };

  useEffect(() => {
    updateUserDetails();
  }, []);

  useEffect(() => {
    function onConnect() {
      console.log("CONNECTED TO SOCKET");
    }

    function onDisconnect() {
      console.log("DISCONNECTED FROM SOCKET");
    }

    function onMessageEvent({ message }) {
      if (message.type === "site_availability") {
        dispatch(updateDbVariable({ site_available: message.data }));
      }
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("message", onMessageEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("message", onMessageEvent);
    };
  }, []);

  return (
    <>
      <GenericModals.Loading />
      <AppRoutes defaultRoute={defaultRoute} />
    </>
  );
}

export default App;
