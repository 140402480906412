import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken } from "redux-store/features/authSlice";
import InviteUserService from "services/InviteUserService";
import LoadIndicator from "components/LoadIndicator";
import AccountExistModal from "./AccountExistModal";
import WarningModal from "components/WarningModal";
import { logger } from "shared/helpers";

const MoreInformation = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const inviteService = new InviteUserService(token, dispatch);

  const [inviteDetails, setInviteDetails] = useState(null);
  const [existModalOpen, setExistModalOpen] = useState(false);
  const [showNoInviteModal, setShowNoInviteModal] = useState(false);

  const fetchInviteDetails = async (code) => {
    try {
      const res = await inviteService.fetchInviteDetails(code);

      if (!res.data.data) {
        setShowNoInviteModal(true);
      } else if (res.data.data?.status?.toLowerCase() === "rejected") {
        navigate("/");
      } else if (res.data.data.user_id !== null) {
        setExistModalOpen(true);
      } else {
        setInviteDetails(res.data.data);
      }
    } catch (e) {
      logger("Error fetch invite details", e);

      navigate("/");
    }
  };

  const navigate = useNavigate();

  const goToSignUp = () => {
    if (inviteDetails) navigate(`/auth/signup?c=${inviteDetails.invite_code}`);
  };
  const [rejecting, setRejecting] = useState(false);
  const rejectInvite = async () => {
    if (!inviteDetails) return;

    setRejecting(true);

    try {
      await inviteService.rejectUserInvite(inviteDetails.invite_id);
      navigate("/");
    } catch (error) {}

    setRejecting(false);
  };

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("c");

    if (!code) navigate("/");

    fetchInviteDetails(code);
  }, []);

  return (
    <>
      <AccountExistModal
        show={existModalOpen}
        handleClose={() => setExistModalOpen(false)}
      />

      <WarningModal
        show={showNoInviteModal}
        text={
          "Sorry, this invite is no longer valid, you may sign up as a basic user"
        }
        handleOk={() => {
          setShowNoInviteModal(false);
          navigate("/");
        }}
        handleClose={() => {}}
        hideCancel
      />

      <div className="registerPage">
        <section className="mt-5 mb-5">
          <div className="container">
            <h1 className="title1">More information</h1>

            <p>
              Congratulations, you have been invited to join Qualy as a
              submitting user. This means you are part of a trusted global
              community sharing information about products and services you have
              used. Information you enter can be purchased by others and you
              receive credit that can then be used to get quality reports on
              things you might buy.
            </p>

            <h2 className="title3 mb-3 mt-5">
              Is this just another review site?
            </h2>

            <p>
              Qualy is different to all other review sites, when you register a
              product it's quality is tracked for as long as you are using using
              it, not just when you buy it. This gives a much better picture of
              how long products will last, how many are performing well or not
              and how what type of problems you might encounter. Services, such
              as the seller of a product can also be recorded as well as any
              defects (for example: failing to provide a legally required refund
              or repair). Anything you buy, and somethings you don't can be
              entered and potentially may be of interest to buyers. Whether its
              a new TV, Phone, Plumber, builder or Internet service you can put
              in your experience or buy others. Reports can contain hundreds or
              1000s of reviewers records depending on how much you want to spend
              and what is available.
              <br />
              Because of the way we attract users and our robust identity
              confirmation our information is more valuable than typical review
              sites, or trade sites that are funded by the traders themselves,
              or by advertisers. If you want guaranteed information quality you
              have to be the customer.
            </p>

            <h2 className="title3 mb-3 mt-5">How much can I make?</h2>

            <p>
              As a private user you can't make money, however you can earn
              credit that can be used to buy reports on Qualy so that you don't
              waste money on products and service that don't do what they claim.
              The amount of credit you earn is decided in a market place, you
              decide how much you want to sell for and buyer decide how much
              they are prepared to pay. Or if all that is too complicated you
              can just go with the market rate.
            </p>

            <h2 className="title3 mb-3 mt-5">What about privacy?</h2>

            <p>
              You can check our full privacy policy here, but briefly our
              business model is in transaction commission and not in advertising
              or selling your personal data. Qualy is not a completely free site
              funded by advertsing or by sellers of products and services. Your
              data is not used normally, and where we might do this it is under
              your express permission and direct benefit.
            </p>

            <div className="d-flex align-items-center mt-5">
              <button
                type="button"
                className="btn btn-yellow me-3"
                onClick={goToSignUp}
              >
                Sign me up
              </button>

              <button
                type="button"
                className="btn btn-black me-2"
                onClick={rejectInvite}
                disabled={rejecting}
              >
                {rejecting ? <LoadIndicator /> : "Sorry, not interested"}
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MoreInformation;
