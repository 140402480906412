export const StepOne = ({ onSelectModelType, openExistingModelModal }) => {

    return (
        <>
            <p className="mb-2">
                Please enter as much information as you can below, if you are not sure our professionals will finish the details. As a minimum we need model description name.
                <br />
                Check out the other Items for this category to help you;
            </p>    

            <button className="mb-2 btn btn-yellow" type="button" onClick={openExistingModelModal}>
                View Existing Models
            </button>

            <p className="mb-4 d-block">
                Is this is a product or service?
            </p>

            <p className="mb-4 d-block">
                Product: A physical item you keep or use, or software you buy and keep as long as you want. This can be a custom product like a house or significant part of a building. <a className="link" href="#">More examples.</a>
            </p>


            <p className="mb-4 d-block">
                Service: A service provided to you once or on a regular basis. It maybe purely information, legal processing, communicatons, repairs and maintenance. Examples are a mobile phone service contract, a plumbing repair, vehicle servicing. <a className="link" href="#">More examples.</a>
            </p>

            <div className="d-flex flex-row gap-4 align-items-center">
                <button className="btn btn-yellow" type="button" onClick={() => onSelectModelType('Product')}>
                    Product
                </button>

                <button className="btn btn-yellow" type="button" onClick={() => onSelectModelType('Service')}>
                    Service
                </button>
            </div>
        </>
    );
}