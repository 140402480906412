import { useEffect, useState } from "react";
import styles from "./styles.module.css";

const CollapsableTextContainer = ({
    collapsedContent,
    fullContent
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleCollapse = () => setIsCollapsed(prev => !prev);

    const renderText = (content = "") => {
        let paragraphs = content.split("new-line");
        paragraphs = paragraphs.map(e => e.trim());

        return (
            <>
                {paragraphs.map((e, index) => (
                    <>
                        <p className={styles.content}>
                            {e}
                        </p>

                        {index !== paragraphs.length - 1 && (
                            <><br /> <br /></>
                        )}
                    </>
                ))}
            </>
        )
    };

    useEffect(() => {
        return () => setIsCollapsed(true);
    }, []);

    return (
        <div>
            {renderText(isCollapsed ? collapsedContent : fullContent)}

            <span className="link" onClick={toggleCollapse}> ...{isCollapsed ? "more" : "less"}</span>
        </div>
    );
};

export default CollapsableTextContainer;