const { axiosClient } = require("shared/axiosInstance");

class SymptomService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchAll() {
    return this.client.get("/symptoms");
  }

  async insert(data) {
    return this.client.post("/symptoms", data);
  }

  async get_quality_records(id) {
    return this.client.get("/symptoms/quality-records?symptom_id=" + id);
  }

  async get_category_symptom_records(id) {
    return this.client.get(
      "/symptoms/category-symptom-records?symptom_id=" + id
    );
  }
}

export default SymptomService;
