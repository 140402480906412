import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const GetQuality = () => {
  return (
    <div id="main-container" className={styles.get_quality_container}>
      <div className="container">
        <h2 className="mb-4 userLevel-Title">Get Quality</h2>

        <p className={`${styles.subtitle} mb-4`}>
          Get Guaranteed quality information in a detailed report
        </p>

        <div className={`${styles.topLabel} mb-4`}>
          <span>Select report</span>
        </div>

        <Link to="/get-quality/single-brand-product" className={"mb-4"}>
          <button>Single brand product report</button>
        </Link>

        <Link to="/get-quality/service-brand-comparison" className={"mb-4"}>
          <button>Service brand comparison report</button>
        </Link>

        <Link to="/get-quality/what-is-quality" className={styles.what_is_quality}>
          What is Quality?
        </Link>
      </div>
    </div>
  );
};

export default GetQuality;
