import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import AuthenticationService from "services/AuthenticationService";
import { useDispatch } from "react-redux";
import { logout, verifyPhone } from "redux-store/features/authSlice";
import LoadIndicator from "./LoadIndicator";
import CustomPhoneInput from "./CustomPhoneInput";

function VerifyPhoneModal({ show, handleClose, phone: phone_number, email }) {
  const [code, set_code] = useState("");

  const [sending, set_sending] = useState(false);

  const [code_sent, set_code_sent] = useState(false);
  const [verifying, set_verifying] = useState(false);

  const [phone, setPhone] = useState(phone_number);

  const [err, set_err] = useState("");
  const [msg, set_msg] = useState(
    "To continue using your account please verify your phone number. Please click the verify button to verify phone now."
  );

  const [hide_verify, set_hide_verify] = useState(false);

  const sendSmsCode = async () => {
    set_err(null);

    set_verifying(true);

    try {
      const res = await AuthenticationService.verify_phone(phone, email);

      set_msg(res.data.message);

      set_code_sent(true);
    } catch (e) {
      const emsg = e.response?.data?.message
        ? e.response.data.message
        : "Something went wrong";
      set_err(emsg);
    }

    set_verifying(false);
  };

  const dispatch = useDispatch();

  const verifyCode = async () => {
    set_verifying(true);
    set_err(null);

    try {
      const res = await AuthenticationService.verify_code({
        phone_number: phone,
        code,
        email,
      });

      set_msg(res.data.message);

      set_hide_verify(true);

      handleClose(true);

      // reload();

      dispatch(verifyPhone());
    } catch (e) {
      set_hide_verify(false);

      const emsg = e.response?.data?.message
        ? e.response.data.message
        : "Something went wrong";
      set_err(emsg);
    }

    set_verifying(false);
  };

  const sendOrVerify = () => {
    if (code_sent) {
      verifyCode();
    } else {
      sendSmsCode();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Verify Phone</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            {sending ? (
              <LoadIndicator />
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "3em",
                    fontSize: 18,
                    textAlign: "center",
                  }}
                >
                  {msg}
                </div>
                <div className="col-md-6 mb-4">
                  {code_sent ? (
                    <input
                      type="text"
                      className="form-control"
                      id="code"
                      placeholder="Code"
                      disabled={hide_verify || !code_sent}
                      name="name"
                      value={code}
                      onChange={(e) => set_code(e.target.value)}
                    />
                  ) : (
                    <CustomPhoneInput
                      country={"gb"}
                      value={phone || ""}
                      onChange={(phone) => {
                        setPhone(phone);
                      }}
                      disableDropDown={false}
                    />
                  )}

                  <div
                    className="link"
                    style={{ marginTop: 5 }}
                    hidden={!code_sent}
                    onClick={sendSmsCode}
                  >
                    Resend
                  </div>
                </div>
              </>
            )}

            {err && (
              <div
                className="error_message"
                style={{ marginBottom: 0, marginTop: 5 }}
              >
                {err}
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            Cancel
          </Button>

          {!hide_verify && (
            <Button variant="primary" onClick={sendOrVerify}>
              {verifying ? (
                <LoadIndicator />
              ) : code_sent ? (
                "Verify"
              ) : (
                "Send Code"
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VerifyPhoneModal;
