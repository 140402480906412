import React, { useEffect, useState } from "react";
import "./index.css";
import DashboardService from "services/DashboardService";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { logger } from "shared/helpers";
import { Link } from "react-router-dom";
import { USER_TYPE } from "shared/constants";
import PublicService from "services/PublicService";

const DEFAULT_METRICS = {
  quality: 0,
  brand_models: 0,
};

const Home = (props) => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const service = new DashboardService(token, dispatch);
  const [metrics, setMetrics] = useState(DEFAULT_METRICS);
  const [contact, setContact] = useState("");

  const fetchPublicMetrics = async () => {
    try {
      const { data } = await service.getPublicMetrics();
      setMetrics(data);
    } catch (error) {
      logger("[fetchPublicMetrics]", error);
    }
  };

  const fetchStoredText = async () => {
    try {
      const { data } = await PublicService.getStoredTextById(10);
      if (typeof data === "object") {
        setContact(data.text);
      }
    } catch (error) {
      logger("[fetchPublicMetrics]", error);
    }
  };

  useEffect(() => {
    fetchPublicMetrics();
    fetchStoredText();

    return () => {
      setContact("");
      setMetrics(DEFAULT_METRICS);
    };
  }, []);

  return (
    <React.Fragment>
      <div>
        <section className="homeBanner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 leftHomeBanner">
                <h1>For Private Users</h1>
                <p>
                  Qualy is a review site that pays users to record their
                  experiences through the life of the products or services.
                  Customers then buy reports ensuring data is guaranteed.
                </p>
                <p>
                  Our aim is to encourage the sales of better products and
                  services while eliminating fake reviews
                </p>
                <p>
                  <Link className="d-block link" to="/reasons">
                    Reasons to use Qualy before you buy anything
                  </Link>

                  <Link className="d-block link" to="/benefits">
                    Benfits with Qualy
                  </Link>
                </p>
                {/* <a href="#" className="btn btn-yellow">
                  More information
                </a> */}
              </div>

              <div className="col-lg-6 col-md-12 rightHomeBanner">
                {/* <img
                  src="assets/images/team.jpg"
                  alt=""
                  title=""
                  className="img-fluid"
                /> */}
              </div>
            </div>
          </div>
        </section>

        <section className="section CommercialUsers">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                {/* <img
                  src="assets/images/team.jpg"
                  alt=""
                  title=""
                  className="img-fluid"
                /> */}
              </div>
              <div className="col-lg-6 col-md-12">
                <h2>For Commercial Users</h2>
                <p>
                  We also need Professional Contractors for all categories and
                  company representatives for the goods and services we hold
                  data on
                </p>

                <Link
                  to={`/auth?type=${USER_TYPE.COMMERCIAL}`}
                  className="btn btn-yellow"
                >
                  More information
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="section counterRow">
          <div className="container">
            <table className="table table-bordered">
              <tr>
                <th>Quality records:</th>
                <td>{metrics.quality}</td>
              </tr>
              <tr>
                <th>Items:</th>
                <td>{metrics.brand_models}</td>
              </tr>
            </table>

            <div className="d-flex flex-row gap-4 mt-4">
              <Link
                className="d-block link"
                to="/non_commercial_privacy.pdf"
                target="_blank"
              >
                Legal
              </Link>

              <Link
                className="d-block link"
                href="#"
                data-bs-target="#contactModal"
                data-bs-toggle="modal"
              >
                Contact
              </Link>
            </div>
          </div>
        </section>

        <div className="modal fade" id="contactModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Contact</h4>

                <button
                  className="btn-close"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <div className="modal-body">
                <p className="pre-line">{contact}</p>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-yellow"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
