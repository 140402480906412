import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  types: [],
};

export const messageTypeManagement = createSlice({
  name: "message_type_management",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setMessageTypes: (state, action) => {
      state.types = action.payload;
    },
    updateMessageType: (state, action) => {
      state.types = state.types.map((e) => {
        if (e.type_id === action.payload.type_id) {
          return { ...e, ...action.payload };
        }

        return e;
      });
    },

    addMessageType: (state, action) => {
      state.types = [...state.types, action.payload];
    },
  },
});

export const { setMessageTypes, updateMessageType, addMessageType } =
  messageTypeManagement.actions;

export const selectMessageTypes = (state) =>
  state.message_type_management.types;

export default messageTypeManagement.reducer;
