import { axiosClient } from "shared/axiosInstance";

class ReportService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  async getUserReports() {
    return axiosClient(this.dispatch, this.token).get("/report");
  }
}

export default ReportService;
