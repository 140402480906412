import React from "react";
import { Spinner } from "react-bootstrap";

function LoadIndicator({ size = "sm", Loader }) {
  return Loader ? (
    <Loader />
  ) : (
    <Spinner
      as="span"
      animation="border"
      size={size}
      role="status"
      aria-hidden="true"
    />
  );
}

export default LoadIndicator;
