import { Button } from "react-bootstrap";
import React from "react";
import { Modal } from "react-bootstrap";

function SuccessModal({ show, credits, handleClose }) {
  return (
    <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
    >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title>Success</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <h4>You have successfully added credits to your account.</h4>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "none" }}>
            <Button
                variant="primary"
                onClick={handleClose}
            >
                Ok
            </Button>
        </Modal.Footer>
    </Modal>
  );
}

export default SuccessModal;
