import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import UserService from "services/UserService";
import LoadIndicator from "../LoadIndicator";

const VerficationtypesSelector = ({ onSwitchClick, selected = [] }) => {
  const [verify_list, set_verify_list] = useState([]);
  const [fetching, set_fetching] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  useEffect(() => {
    console.log("Selected", selected);
  }, [selected]);

  const fetchVerifyList = async () => {
    set_fetching(true);
    try {
      const res = await UserService.fetchVerifyList(dispatch, token);

      set_verify_list(res.data.data);
    } catch (e) {}
    set_fetching(false);
  };

  useEffect(() => {
    fetchVerifyList();

    return () => {
      set_verify_list([]);
    };
  }, []);

  if (fetching) return <LoadIndicator />;

  return (
    <ul className="infolist selectMethod">
      {verify_list.map((item) => (
        <li key={"verify-list-" + item.doc_id}>
          {item.type_name}
          <label className="switch">
            <input
              type="checkbox"
              onChange={(e) => onSwitchClick(item.doc_id)}
              checked={selected.includes(item.doc_id)}
              disabled={item.mandatory === "mandatory"}
            />
            <span className="slider round"></span>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default VerficationtypesSelector;
