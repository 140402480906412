import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "view",
  form: {
    unique_identifier: null,
    current_sw_version: null,
    usage_rate: null,
    usage_accumulated: null
  },
};

export const itemDetailSlice = createSlice({
  name: "item_detail",
  initialState,
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    onChange: (state, action) => {
      state.form = {
        ...state.form,
        [action.payload.key]: action.payload.value,
      };
    },
    resetForm: (state) => {
      state.form = {};
    },
  },
});

export const { setMode, onChange, resetForm } = itemDetailSlice.actions;

export const selectItemDetailData = (state) => ({
  ...state.item_detail,
});

export default itemDetailSlice.reducer;
