const { axiosClient } = require("shared/axiosInstance");

class ModelListService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchModelList(models, filters) {
    return this.client.post("/model-list", {
      models,
      filters,
    });
  }

  async fetchModelListWithUnitCount(models, filters) {
    return this.client.post("/model-list/with-unit-count", {
      models,
      filters,
    });
  }
}

export default ModelListService;
