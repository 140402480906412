import React from "react";
import styles from "./QualityRecord.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const MobileVersion = () => {
  return (
    <>
      <div id="main-container">
        <div className="container">
          <h2 className="mb-4 userLevel-Title">UNIT QUALITY RECORD</h2>

          {/** SYMPTOM FILTER */}
          <div className="mb-4">
            <div className={`${styles.rowCard} d-flex mb-2 row `}>
              <div className={`${styles.val} ${styles.key} col-3`}>
                <div>Item</div>
              </div>
              <div className={`${styles.val} col-9`}>
                <div className={styles.spacer}></div>
                Galaxy J6 SM-J600FN
              </div>
            </div>

            <div className={`${styles.rowCard} d-flex mb-2 row `}>
              <div className={`${styles.val} ${styles.key} col-3`}>
                <div>Use Period</div>
              </div>
              <div className={`${styles.val} col-9`}>
                <div className={styles.spacer}></div>
                00221122 4/5/2018 - ongoing
              </div>
            </div>

            <div className={`${styles.rowCard} d-flex mb-2 row `}>
              <div className={`${styles.val} ${styles.key} col-3`}>
                <div>Aberration</div>
              </div>
              <div className={`${styles.val} col-9`}>
                <div className={styles.spacer}></div>
                Reduced Performance
              </div>
            </div>

            <div className={`${styles.rowCard} d-flex mb-2 row `}>
              <div className={`${styles.val} ${styles.key} col-3`}>
                <div>Description</div>
              </div>
              <div className={`${styles.val} col-9`}>
                <div className={styles.spacer}></div>A feature or function has
                reduced performance
              </div>
            </div>

            <div className={`${styles.rowCard} d-flex mb-2 row `}>
              <div className={`${styles.val} ${styles.key} col-3`}>
                <div>Symptom</div>
              </div>
              <div className={`${styles.val} col-9`}>
                <div className={styles.spacer}></div> Battery Life .50% of
                Original
              </div>
            </div>

            <div className="row mb-4 ">
              <div className="col-7 mx-auto col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptomEmpty}></div>
                  </div>
                  <button
                    className={`${styles.btn} ${styles.changeSymptomBtn}`}
                  >
                    Change Symptom
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12 col-md-7">
              <div className="row">
                <div className="col-12 mb-2 mb-md-0 col-md-auto">
                  <div className={styles.bottomLabelRecord}>
                    <span>User Description</span>
                  </div>
                </div>

                <div className={`${styles.topLabelVal} col`}>
                  <input
                    type="text"
                    className={`${styles.userdescinput} form-control`}
                  />
                </div>
              </div>
            </div>
          </div>

          {/** ENTER SYMPTOM IN YOUR WORD */}
          <div className="mb-4">
            <p className={`${styles.filter} mb-4 h6 `}>
              Enter or Check the Data Below
            </p>

            <div className="mb-4">
              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-4 mb-2 mb-md-0">
                      <div className={styles.bottomLabelRecord}>
                        <span>Severity*</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      <select
                        name="severity"
                        className="form-select w-full"
                        id="severity"
                      >
                        <option value="Minor">Minor</option>
                        <option value="Major">Major</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-4 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.bottomLabelRecord}>
                        <span>Use Counter*</span>
                      </div>
                    </div>
                    <div
                      className={`${styles.topLabelVal} ${styles.customLabelVal} col`}
                    >
                      <div className={styles.customInputView}>
                        <span>200,000</span>
                        <span className={styles.customInputViewUnit}>
                          Miles
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-4 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.bottomLabelRecord}>
                        <span>First Seen*</span>
                      </div>
                    </div>
                    <div className={`${styles.topLabelVal} col`}>
                      <div className={styles.bottomValueRecord}>
                        <span>10/8/2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-4 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.bottomLabelRecord}>
                        <span>Repeatability*</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      <select
                        name="repeatability"
                        className="form-select w-full"
                        id="repeatability"
                      >
                        <option value="Easily Repeatable">
                          Easily Repeatable
                        </option>
                        <option value="Hardly Repeatable">
                          Hardly Repeatable
                        </option>
                        <option value="Not Repeatable">Not Repeatable</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-4 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.bottomLabelRecord}>
                        <span>SW Version*</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      <div className={styles.bottomValueRecord}>
                        <span>J600FNXXSACVBI</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-4 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.bottomLabelRecord}>
                        <span>Repaired Date</span>
                      </div>
                    </div>

                    {/* <div className={`${styles.topLabelVal} col`}>
                      Battery Life .50% of Original
                    </div> */}
                    <input
                      className={`${styles.dateInput} form-control`}
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-4 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.bottomLabelRecord}>
                        <span>Evidence Available</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      <select
                        name="evidence"
                        className="form-select w-full"
                        id="evidence"
                      >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className={styles.info}>
              All of the above except required date must be set according to the
              time of the fault condition when first seen
            </p>
          </div>

          <div className={`${styles.actions} d-grid flex-row mb-2`}>
            <button className={`${styles.cancel} grid-col-1`}>Back</button>

            <button className={styles.btn}>Submit</button>
            <button className={styles.reportbtn}>Report Error</button>
            <button className={styles.requestassistance}>
              Request Assistance
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileVersion;
