import React, { useEffect, useState } from "react";
import DataTable from "pages/dashboard/data-table";

function PagedDataTable({
  list = [],
  query = "",
  numPerPage = 5,
  filterKeys = [],
  columns,
  onRowClick,
  loading,
}) {
  const [filtered, setFiltered] = useState([]);
  const [listChunk, setListChunk] = useState([]);

  const [page, setPage] = useState(0);
  const nextPage = () => {
    if (page + 1 < listChunk.length) setPage((prev) => prev + 1);
  };
  const prevPage = () => {
    if (page - 1 >= 0) setPage((prev) => prev - 1);
  };

  useEffect(() => {
    setFiltered(list);
  }, [list]);

  useEffect(() => {
    if (filtered.length > 0) {
      const res = [];

      for (let i = 0; i < filtered.length; i += numPerPage) {
        const chunk = filtered.slice(i, i + numPerPage);
        res.push(chunk);
      }

      setListChunk(res);
    }
  }, [filtered]);

  useEffect(() => {
    if (query != "") {
      const result = list.filter((item) => {
        for (let i = 0; i < filterKeys.length; i++) {
          if (
            item[filterKeys[i]]
              ?.toString()
              ?.toLowerCase()
              ?.includes(query.toLowerCase())
          )
            return true;

          return false;
        }
      });

      setFiltered(result);
    } else setFiltered(list);
  }, [query]);

  if (loading) return <div></div>;

  return (
    <div>
      <DataTable
        columns={columns}
        onRowClick={onRowClick}
        data={filtered}
        clickAble
      />
    </div>
  );
}

export default PagedDataTable;
