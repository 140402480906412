import { Link } from "react-router-dom";

export const Personal = () => {
  return (
    <>
      <h2 className="title3 mb-3 mt-5">Welcome to user registration!</h2>
      <p>
        Qualy has significant benefits over other sites but this means we have
        to ensure the data we collect is accurate.{" "}
      </p>
      <p>
        A common term used in IT circles is "rubbish in ~ rubbish out". This
        means that signing up with Qualy takes a bit longer and there will be
        further requirements in future.{" "}
      </p>
      <p>
        We hope you appreciate that this investment is worth it and provides you
        with{" "}
        <a
          href="#"
          className="infolink"
          data-bs-toggle="modal"
          data-bs-target="#benefitsModal"
        >
          Benefits
        </a>
        .
      </p>
      <strong className="d-block mb-3 mt-5">
        In order to complete the initial signup you will need the following{" "}
        <a
          href="#"
          className="infolink"
          data-bs-toggle="modal"
          data-bs-target="#identityVerificationModal"
        >
          Why
        </a>
      </strong>

      <ul className="infolist">
        <li>
          A smart phone registered in the country where you reside with a
          reasonable quality camera and a service connection
        </li>
        <li>An Email address of your own</li>
        <li>Your personal details</li>
      </ul>
      <strong className="d-block mb-3 mt-5">
        Furthermore most people who want to provide their experience data will
        need{" "}
        <a
          href="#"
          className="infolink"
          data-bs-toggle="modal"
          data-bs-target="#identityVerificationModal"
        >
          Why
        </a>
      </strong>

      <ul className="infolist mb-5">
        <li>Photographic ID</li>
        <li>Evidence of their address</li>
        <li>A unique photo</li>
        <li>
          Other means of unique identification such as social security number
        </li>
      </ul>

      <div className="mt-4">
        <Link to="/auth/signup">
          <div href="" className="btn btn-yellow">
            Okay, lets do it
          </div>
        </Link>
      </div>

      {/*Investment Benefits */}
      <div className="modal fade" id="benefitsModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Investment Benefits</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <h2 className="title3 mb-3">
                Benefits of Qualy over other sites!
              </h2>
              <strong className="d-block mb-2">
                Qualy is like a review site but with a number of key differences
              </strong>
              <ul className="infolist">
                <li>
                  Strong authentication of submitting users as individuals.
                </li>
                <li>
                  On-going audits of submitting users and the data they enter
                </li>
                <li>
                  Reporting on the life of products and services rather than the
                  initial purchase and user experience.
                </li>
                <li>
                  Professional involvement in the structure of the site and all
                  the entries.
                </li>
                <li>
                  Fair exchange of effort between users and professionals,
                  between buyers and sellers, managed by a system of credits.
                </li>
                <li>
                  Your personal data is not the commodity, the experience data
                  you provide is anonymous.
                </li>
                <li>
                  The site is entirely impartial and 3rd party. The brands do
                  not have any control over the data you provide and what is
                  available, however they can question it and ask for audits.
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-yellow"
                data-bs-dismiss="modal"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Identity Verification */}
      <div className="modal fade" id="identityVerificationModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Identity Verification</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <h2 className="title3 mb-3">
                Why we need identity verification.
              </h2>
              <p>
                Most other sites just want your attention and clicks as they are
                primarily advertising platforms. You fund the site by buying the
                products. It therefore does not matter as much if the users are
                fake and the inputs they provide are also fake. At Qualy, data
                integrity is our priority. In order to do this e we collect the
                following;
              </p>
              <p>
                <b>Mobile phone</b> – As a means to provide photographic
                evidence of submitted data, users identity and uniqueness and as
                a dual method of authentication.
              </p>
              <p>
                <b>Email Address</b> – as a means to feedback information to you
                about status changes, security checks, and other information
                useful to you.
              </p>
              <p>
                <b>Photographic ID</b> – Fake mobile phones and email addresses
                are easy to setup, so we need a government authenticated
                photographic ID.
              </p>
              <p>
                <b>Address ID</b> – We need, for example, bills with your name
                and address. This allows us to know what country you are in, and
                that you are an individual living in a house.
              </p>
              <p>
                In future we may implement new methods of verification that are
                convenient and able to detect fake users.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-yellow"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
