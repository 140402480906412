import { MAX_FILE_SIZE } from "shared/constants";
import styles from "./styles.module.css";

const AttachFiles = ({ value = [], onChange }) => {
  const onFileChange = (event) => {
    const { length, ...rest } = event.target.files;
    const files = Object.values(rest);

    let error = false;
    for (const file of files) {
      if (file.size > MAX_FILE_SIZE) {
        error = true;
        return alert(`File cannot be greater than ${MAX_FILE_SIZE} mb.`);
      }
    }

    if (!error) onChange(files);
  };

  const clearSelection = () => onChange([]);

  return (
    <div className="d-flex flex-row align-items-center">
      <span className="d-inline-block">
        <input
          type="file"
          id="messagesAttachFiles"
          onChange={onFileChange}
          accept=".png, .jpg, .jpeg, application/pdf, application/vnd.ms-excel.doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, .txt"
          hidden
          name="files[]"
          multiple
        />

        <label className="btn btn-yellow" htmlFor="messagesAttachFiles">
          Attach Files
        </label>

        <span className="d-inline-block ms-2">
          {value.length} file(s) selected
        </span>

        {value.length > 0 && (
          <span
            className={`d-inline-block ms-4 ${styles.closeButton}`}
            onClick={clearSelection}
          >
            <i class="bi bi-x-lg"></i>
          </span>
        )}
      </span>
    </div>
  );
};

export default AttachFiles;
