import { useEffect, useState } from "react";
import LoadIndicator from "components/LoadIndicator";

export const StepFour = ({
  data,
  handleChange,
  openDescriptionModal,
  submitModelForm,
  openExistingModelModal
}) => {
    const [loading, setLoading] = useState(false);

    const { 
      model_name_des,
      model_code,
      generic_model,
      serial_type 
    } = data;

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        await submitModelForm();
        setLoading(false);
    } 

    useEffect(() => {
      if (typeof generic_model === "number" && Boolean(generic_model)) {
        handleChange("model_code")("GENERIC");
      } else if (model_code === "GENERIC") {
        handleChange("model_code")("");
      }
    }, [generic_model]);

    return (
        <form onSubmit={handleSubmit}>
            <p className="mb-2">
                Please Enter the Model or Service name and a model code if relevant.
            </p>

            <button className="mb-4 btn btn-yellow" type="button" onClick={openExistingModelModal}>
                View Existing Models
            </button>

            <div className="mb-4">
                <label className="form-label">
                  Model / Service Name{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(`
                        Use the textual description of a product, any textual series names or numbers, and some detail about what the product is. You can use (View Existing Models) to see the entries used for other models
                    `)
                    }
                  ></i>{" "}
                  <em>*</em>
                </label>

                <input
                    type="text"
                    className="form-control"
                    id="model_name_des"
                    placeholder="Galaxy Tab A7 lite"
                    name="model_name_des"
                    value={model_name_des || ""}
                    onChange={(e) => handleChange("model_name_des")(e.target.value)}
                    maxLength="50"
                    required
                />
            </div>

            <div className="mb-4">
                <label className="form-label">
                  Model Code{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(`
                      This is the more accurate less user friendly model code for a product, in some cases this isn't used, for generic products this will always be Generic.
                    `)
                    }
                  ></i>
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="model_code"
                  placeholder="ST-220M"
                  name="model_code"
                  value={model_code || ""}
                  onChange={(e) => handleChange("model_code")(e.target.value)}
                  maxLength="30"
                  disabled={typeof generic_model === "number" && generic_model}
                />
            </div>

            <p className="mb-4">
                If the item is generic then please select yes below. Generic means a product or service that does not have a specific model name unique to the brand, and is commonly referred to by the same name. For example a "Claw Hammer", or "House sale processing", or "Retail Purchase"
            </p>

            <div className="mb-4">
                <label className="form-label">
                  Generic Item?{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(`
                        A generic item is a product or service that does not use a specific model code and is common across an industry, examples are "House Selling" from a solicitor, or "Claw hammer" from an unbranded box in a Shop. If unsure select 'No' and we will check it
                    `)
                    }
                  ></i>
                </label>

                <select
                  className="form-select"
                  value={typeof generic_model === "number" ? generic_model : ""}
                  onChange={(e) =>
                    handleChange("generic_model")(parseInt(e.target.value))
                  }
                >
                    <option value="" selected disabled>Select</option>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                </select>
            </div>

            <p className="mb-4">
                What kind of serialisation does the item have? This is a unique reference for each unit or task. It could be an account number or job number. This helps to verify you have used the item.
            </p>

            <div className="mb-4">
                <label className="form-label">
                  Serialisation Type{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(`
                        Select if the product or service has a specific code that represents each unit uniquely, for example a case number or account number for a service is also a serial number. Batch numbers are commonly used for consumable materials
                    `)
                    }
                  ></i>
                </label>

                <select
                  className="form-select"
                  required
                  value={serial_type || "None"}
                  onChange={(e) => handleChange("serial_type")(e.target.value)}
                >
                  <option value={"Serial number"}>Serial Number</option>
                  <option value={"Batch number"}>Batch Number</option>
                  <option value={"Chassis number"}>Chassis Number</option>
                  <option value={"Account number"}>Account Number</option>
                  <option value={"Case number"}>Case Number</option>
                  <option value={"Transaction number"}>
                    Transaction Number
                  </option>
                  <option value={"Job number"}>Job Number</option>
                  <option value={"Location gps"}>Location GPS</option>
                  <option value={"Location url"}>Location URL</option>
                  <option value={"None"}>None</option>
                </select>
            </div>

            <p className="mb-4">
                Note these will be checked by a professional, so don't worry too much if its not right. They may need you to upload picture of the information label so do this if you can to save time.
            </p>

            <button className="btn btn-yellow" type="submit" disabled={loading}>
                {loading ? <LoadIndicator /> : 'Submit'}
            </button>
        </form>
    );
}