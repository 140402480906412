import { axiosClient } from "shared/axiosInstance";

class ModelService {
  static getFilteredModels(token, dispatch, brand, category) {
    const querys = [];

    if (category) {
      querys.push(`category=${category}`);
    }

    if (brand) {
      querys.push(`brand=${brand}`);
    }
    return axiosClient(dispatch, token).get(
      `/model?${querys.join('&')}`
    );
  }

  static getModels(token, dispatch) {
    return axiosClient(dispatch, token).get("/model/all");
  }

  static addModelToDb(token, dispatch, payload) {
    return axiosClient(dispatch, token).post("/model", payload);
  }

  static createGenericModelBrandModel(token, dispatch, payload) {
    return axiosClient(dispatch, token).post("/model/brand_model_generic", payload);
  }

  static getModelForCategory(token, dispatch, category) {
    return axiosClient(dispatch, token).get(`/model?category=${category}`);
  }

  static getOverlapAndSerial(token, dispatch, model) {
    return axiosClient(dispatch, token).get("/model/attribute?model=" + model);
  }
}

export default ModelService;
