import { formatDDMMYYYYDash } from "shared/helpers";
import styles from "./style.module.css";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "redux-store/features/authSlice";
import { useNavigate } from "react-router-dom";
import { useMessageTypeManagement } from "hooks/useMessageTypeManagement";
import { useIntersection } from "hooks/useIntersection";
import { markAsRead } from "redux-store/features/unreadMessagesSlice";

export function CaseMessage ({ caseId, message, getMessageFrom, onMessageViewed }) {
    const controller = useMessageTypeManagement();
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ref = useRef(null);
    const isVisible = useIntersection(ref, "0px");

    const readBy = useMemo(() => {
        if (message && typeof message.read_by == "string") {
            return message.read_by.split(",").map(e => +e);
        }

        return [];
    }, [message?.read_by]);

    const goToSingleMessage = () => {
        navigate(`/messages/case/${caseId}?message=${message.message_id}`);
    }

    const isUnread = useMemo(() => {
        if (readBy.length > 0 && readBy.includes(user?.user_id)) {
            return false;
        }

        if (message?.user === user?.user_id) {
            return false;
        }

        return true;
    }, [readBy, message]);

    const onVisible = useCallback(() => {
        if (isUnread) {
            controller
                .httpGetMessageById(message.message_id)
                .then(() => {
                    dispatch(markAsRead(message.message_id));
                    onMessageViewed(message.message_id);
                })
                .catch(console.error);
        }
    }, [isUnread]);

    useEffect(() => {
        if (isVisible) {
            onVisible()
        }
    }, [onVisible, isVisible])

    if (!message) return null;

    return (
        <tr
            className={`${ isUnread ? styles.unreadMessage : styles.message }`}
            onClick={goToSingleMessage}
            ref={ref}
        >
            <td className={styles.fromColumn}>{getMessageFrom(message)}</td>
            <td colSpan={3} className={styles.messageColumn}>
                {message.message}
            </td>
            <td className={styles.dateColumn}>{formatDDMMYYYYDash(message.created_at)}</td>
        </tr>
    )
}