import { Button } from "react-bootstrap";
import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import LoadIndicator from "../LoadIndicator";

function WarningModal({
  show,
  handleClose,
  handleOk,
  text,
  hideCancel = false,
  positiveLabel = "Ok",
  negativeLabel = "Cancel",
}) {
  const warningData = useMemo(() => {
    if (typeof text === "string") {
      return {
        text,
        handleOk,
      };
    } else {
      return {
        text: text.text,
        handleOk: text.ok,
        okText: text.okText,
      };
    }
  }, [text, handleClose, handleOk]);

  const [loading, setLoading] = useState(false);
  const onOk = async () => {
    setLoading(true);
    if (warningData.handleOk) await warningData.handleOk();
    handleClose();
    setLoading(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          <h5 className="mod-detail">{warningData.text}</h5>
        </Modal.Body>

        <Modal.Footer>
          {!hideCancel && (
            <Button variant="secondary" onClick={handleClose}>
              {negativeLabel}
            </Button>
          )}

          <Button variant="primary" onClick={onOk}>
            {loading ? <LoadIndicator /> : warningData.okText || positiveLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WarningModal;
