import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function AccountExistModal({ show, handleClose }) {
  const navigate = useNavigate();

  const handleOk = () => {
    handleClose();
    navigate("/auth/signin");
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        onHide={() => {}}
        keyboard={false}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
        ></Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          <h5 className="mod-detail">
            A basic account already exists for this invitation, please sign in
            normally and go to to your Identification Information and select (+)
            symbol to signup for a submitting user account
          </h5>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "none" }}>
          <Button onClick={handleOk}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AccountExistModal;
