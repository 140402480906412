import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  model_list: [],
  initialized_model_list: false,
  custom_model_list: [],
};

export const modelsSlice = createSlice({
  name: "models",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    initializeModelList: (state, action) => {
      state.initialized_model_list = true;
      state.model_list = action.payload;
    },
    setCustomModelList: (state, action) => {
      state.custom_model_list = action.payload;
    },
    addCustomModel: (state, action) => {
      state.custom_model_list = [action.payload, ...state.custom_model_list];
    },
  },
});

export const { initializeModelList, setCustomModelList, addCustomModel } =
  modelsSlice.actions;

export const selectModelsData = (state) => state.models;

export default modelsSlice.reducer;
