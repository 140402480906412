import React, { useEffect, useState } from "react";
import { addLeadingZeros, formatDDMMYYYYDash } from "shared/helpers";

const getColumnVal = (key, item) => {
  if (typeof key === "string") {
    return item[key];
  }

  if (Array.isArray(key)) {
    let val = "";

    key.forEach((e) => {
      val += item[e.key];

      if (e.suffix) {
        val += e.suffix;
      }
    });

    val = val.trim();

    if (val[val.length - 1] === ",") {
      val = val.substring(0, val.length - 1);
    }

    return val || "-";
  }

  if (typeof key === "function") {
    return key(item);
  }

  return "-";
};

function DataTable({
  data,
  columns,
  clickAble,
  onRowClick,
  onAddClick,
  columnDataClass = () => undefined,
}) {
  const [sortedData, setSortedData] = useState([]);
  const [cols, setCols] = useState(columns);

  useEffect(() => {
    setCols(columns);
  }, [columns]);

  const sortByColumn = (col) => {
    if (col.sortable) {
      const accending = !Boolean(col.accending);

      const sortedCopy = [...sortedData];

      sortedCopy.sort((a, b) => {
        if (col.type && col.type === "date") {
          let aDate = new Date(a[col.key]);
          let bDate = new Date(b[col.key]);

          if (aDate < bDate) return accending ? -1 : 1;

          if (aDate > bDate) return accending ? 1 : -1;

          return 0;
        } else {
          let valA = col.data ? col.data(a) : getColumnVal(col.key, a);
          let valB = col.data ? col.data(b) : getColumnVal(col.key, b);

          if (!isNaN(Number(valA)) && !isNaN(Number(valB))) {
            valA = Number(valA);
            valB = Number(valB);
          }

          if (valA < valB) {
            return accending ? -1 : 1;
          }

          if (valA > valB) {
            return accending ? 1 : -1;
          }

          return 0;
        }
      });

      setSortedData(sortedCopy);

      setCols((prev) => {
        const copy = [...prev];

        copy.map((item) => {
          if (item.key === col.key) {
            item.accending = accending;
            item.arrow = accending
              ? "bi-arrow-up-short"
              : "bi-arrow-down-short";
            item.sorted = true;
          } else {
            item.sorted = false;
            item.accending = false;
          }

          return item;
        });

        return copy;
      });
    }
  };

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  return (
    <div className="table-responsive">
      {data && data.length > 0 && (
        <table className="table table-responsive table-dark-theme">
          <thead>
            <tr>
              {cols.map((col, index) => {
                if (typeof col.show === "undefined" || col.show)
                  return (
                    <th
                      scope="col"
                      key={"col-p-" + index}
                      style={{
                        cursor: col.sortable ? "pointer" : "default",
                      }}
                      onClick={() => sortByColumn(col)}
                    >
                      {col.text}
                      {col.sorted && <i className={`bi ${col.arrow}`}></i>}
                    </th>
                  );
              })}
            </tr>
          </thead>

          <tbody>
            {sortedData.map((item, index) => {
              return (
                <tr
                  key={"data-row-p-" + index}
                  style={{
                    cursor: clickAble ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (clickAble) onRowClick(item);
                  }}
                >
                  <td
                    scope="row"
                    className={columnDataClass(item)}
                    key={"data-p-" + 0}
                  >
                    {item[columns[0].key]
                      ? columns[0].padded
                        ? addLeadingZeros(item[columns[0].key], 8)
                        : item[columns[0].key]
                      : columns[0].data
                      ? columns[0].data(item)
                      : index + 1}
                  </td>

                  {columns.map((col, indx) => {
                    if (typeof col.show === "undefined" || col.show) {
                      if (indx > 0) {
                        if (col.add)
                          return (
                            <td
                              key={"data-p-" + indx}
                              className={columnDataClass(item)}
                            >
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onAddClick(item);
                                }}
                                disabled={col.disabled}
                                className="btn btn-circle btn-success"
                              >
                                <i className="bi bi-plus"></i>
                              </button>
                            </td>
                          );

                        if (col.type && col.type === "date")
                          return (
                            <td
                              key={"data-p-" + indx}
                              className={columnDataClass(item)}
                            >
                              {formatDDMMYYYYDash(item[col.key])}
                            </td>
                          );

                        return (
                          <td
                            key={"data-p-" + indx}
                            className={columnDataClass(item)}
                          >
                            {col.data
                              ? col.data(item)
                              : getColumnVal(col.key, item)}
                          </td>
                        );
                      }
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {data.length === 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 18,
            fontWeight: "600",
          }}
        >
          No record found
        </div>
      )}
    </div>
  );
}

export default DataTable;
