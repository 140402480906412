import React, { useEffect, useState } from "react";
import { useTransition, animated } from "react-spring";

function ErrorsComponent({ errors, variant = "alert-danger" }) {
  if (!errors) return <div></div>;

  const [showing, setShowing] = useState(false);

  useEffect(() => {
    if (errors instanceof Array) {
      setShowing(errors.length > 0);
    } else {
      setShowing(errors != "" && errors != null);
    }
  }, [errors]);

  const transition = useTransition(showing, {
    from: { x: -100, y: 0, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1 },
    leave: { x: -100, y: 0, opacity: 0 },
  });

  if (errors instanceof Array) {
    return transition((style, item) =>
      item ? (
        <animated.div style={style}>
          {errors.map((err, idx) => (
            <div className={`alert ${variant}`} key={"error-" + idx}>
              {typeof err === "string" ? err : "Something went wrong"}
            </div>
          ))}
        </animated.div>
      ) : null
    );
  } else {
    return (
      <div className={`alert ${variant}`}>
        {typeof errors === "string" ? errors : "Something went wrong"}
      </div>
    );
  }
}

export default ErrorsComponent;
