import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  preferences: null,
  buyCreditAttempt: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      if (action.payload.token) {
        state.token = action.payload.token;
      }
      state.preferences = action.payload.preferences;
    },

    logout: (state) => {
      state.user = null;
      state.token = null;
      state.preferences = null;
    },

    verifyPhone: (state) => {
      state.user = {
        ...state.user,
        phone_verified: 1,
      };
    },

    updateProfilePic: (state, action) => {
      state.user = {
        ...state.user,
        submitting: { ...state.user.submitting, selfi_photo: action.payload },
      };
    },

    updateUserEmail: (state, action) => {
      state.user = {
        ...state.user,
        email: action.payload,
      };
    },

    updateUserMobile: (state, action) => {
      console.log("mobile", action.payload);
      state.user = {
        ...state.user,
        mobile: action.payload,
        phone_verified: 1,
      };
    },

    updateUserData: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },

    updateUserPreferences: (state, action) => {
      state.preferences = {
        ...state.preferences,
        ...action.payload,
      };
    },

    updateBuyCreditAttempt: (state) => {
      state.buyCreditAttempt = Date.now();
    }
  },
});

export const {
  login,
  logout,
  verifyPhone,
  updateProfilePic,
  updateUserEmail,
  updateUserMobile,
  updateUserData,
  updateUserPreferences,
  updateBuyCreditAttempt
} = authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
export const selectUserPreferences = (state) => state.auth.preferences;
export const selectBuyCreditAttempt = (state) => state.auth.buyCreditAttempt;

export default authSlice.reducer;
