import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FullScreenLoader } from "components/FullScreenLoader";
import styles from "./styles.module.css";
import BrandModelService from "services/BrandModelService";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import CategoryService from "services/CategoryService";
import PagedDataTable from "components/PagedDataTable";
import UnitService from "services/UnitService";
import { addLeadingZeros, handleError, handleErrorV2, modifyUsesList } from "shared/helpers";
import ErrorsComponent from "components/Errors";
import { useAddUnit } from "../add-unit/hooks/useAddUnit";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const SelectUnitPageStep = {
    ONE: "ONE",
    TWO: "TWO"
}

const getUnitUseStatus = (unit) => {
    for (const use of unit.uses) {
      if (use.controller_type == "current_controller") {
        return "In use";
      }
  
      if (use.controller_type === "pending_new_controller") {
        return "Pending";
      }
    }
  
    return "Available";
};

const defaultColumns = [
    {
      text: "Unit Id",
      key: "unit_id",
      padded: true,
    },
    {
      text: "Serial Type",
      key: "unique_identifier",
      show: true,
    },
    {
      text: "Use",
      key: "controller_type_display",
      show: true,
      data: getUnitUseStatus,
    },
];

const SelectUnitPage = () => {
    const navigate = useNavigate();
    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const { state } = useLocation();

    const {httpFetchBrandModelUnits} = useAddUnit();

    const bmService = new BrandModelService(token, dispatch);

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(SelectUnitPageStep.ONE);
    const [brand_model, setBrandModel] = useState(null);
    const [searching, setSearching] = useState(false);
    const [errors, setErrors] = useState([]);
    const [units, setUnits] = useState([]);
    const [filteredUnits, setFilteredUnits] = useState([]);
    const [columns, setColumns] = useState(defaultColumns);
    const [selectedUnit, setSelectedUnit] = useState(null);

    const [unit_id, setUnitID] = useState("");
    const [identifier, setIdentifier] = useState("");

    const httpFetchUnits = async () => {
        if (loading) return;
    
        setLoading(true);
        const units = await httpFetchBrandModelUnits(
          brand_model.brand_model_id
        );
        setUnits(modifyUsesList(units));
        setLoading(false);
    };

    const httpFindPrivateUnit = async (mode) => {
        try {
            setSearching(true);
            setErrors([]);
            setSelectedUnit(null);
            const { data } = await UnitService.findPrivateUnit(
                token,
                dispatch,
                brand_model.brand_model_id,
                mode === 'unit_id' ? unit_id.trim() : '',
                mode === 'identifier' ? identifier.trim() : ''
            );
        
            if (data.data) {
                setSelectedUnit(data.data);
            } else {
                throw new Error('No match found')
            }
        } catch (error) {
            handleError(error, setErrors);
        } finally {
            setSearching(false);
        }
    };

    const goToAddUnit = () => {
        navigate(`/submit-quality-information/add-unit`, {
            state: {
                brand_model_id: brand_model.brand_model_id
            }
        });
    }

    const setupBrandModel = async (brand_model_id) => {
        setLoading(true);
        try {
            const brand_model_response = await bmService.getByID(brand_model_id);
            const brand_model = brand_model_response.data.data;
            setBrandModel(brand_model);
        } catch (error) {
          console.log('[error]', error);
        } finally {
          setLoading(false);
        }
    };

    const onRowClick = (data) => {
        setSelectedUnit({ ...data, uses: undefined });
    }

    const filterAvailableUnits = (e) => {
        const query = e.target.value.trim().toLowerCase();

        if (!query) {
            setFilteredUnits(units);
        } else {
            console.log("Search for", query, "in", units);

            const result = units.filter((unit) => {
                if (unit.unit_id == query || unit.unique_identifier?.includes(query)) {
                    return true;
                } 

                return false;
            });

            console.log("result", result);

            setFilteredUnits(result);
        }
    }

    useEffect(() => {
        if (state?.brand_model_id) {
            setupBrandModel(state.brand_model_id);
        }
    }, [state]);

    useEffect(() => {
        if (!brand_model) return;

        if (!Boolean(brand_model.prevent_use_overlap)) {
            setStep(SelectUnitPageStep.TWO);
        }

        if (brand_model?.serial_type) {
            if (brand_model.serial_type === "None") {
                setColumns((prev) => prev.filter(e => e.text !== "Serial Type"));
            } else {
                setColumns(prev => prev.map((item) => {
                    if (item.text == "Serial Type") {
                        return { ...item, text: brand_model.serial_type };
                    }

                    return item;
                }));
            }
        }
    }, [brand_model]);

    useEffect(() => {
        if (step === SelectUnitPageStep.TWO && Boolean(brand_model) && !(Boolean(brand_model.prevent_use_overlap) && Boolean(brand_model.personalized))) {
            httpFetchUnits();
        }
    }, [brand_model, step]);

    useEffect(() => {
        if (!Boolean(selectedUnit)) return;

        const timeout = setTimeout(() => navigate('/submit-quality-information/add-use', {
            state: {
              unit_id: selectedUnit.unit_id
            }
        }), 2000);

        return () => clearTimeout(timeout);
    }, [selectedUnit]);

    useEffect(() => {
        setFilteredUnits(units);
    }, [units]);

    useEffect(() => {
        console.log(filteredUnits)
    }, [filteredUnits]);

    return (
        <RequireSubmittingUser>
            <FullScreenLoader isOpen={loading} />

            <div id="main-container" className={styles.wrapper}>
                <div className="container">
                    <h2 className="mb-4 userLevel-Title">Select Existing Unit</h2>

                    {step === SelectUnitPageStep.ONE && (
                        <>
                            <div className="mb-4">
                                <div className="row mb-2">
                                    <div className="col-6 col-md-2">
                                        <div className={styles.topLabel}>
                                            <span>Item:</span>
                                        </div>
                                    </div>

                                    <div className={`${styles.topLabelVal} col-6 col-md-5`}>
                                        {brand_model?.brand} {brand_model?.model_name_des}
                                    </div>
                                </div>
                            </div>

                            <p className="mb-4">
                                Did you buy this brand new?
                            </p>

                            <div className="d-flex flex-row gap-2">
                                <button 
                                    className="btn btn-yellow"
                                    disabled={!Boolean(brand_model)}
                                    onClick={goToAddUnit}
                                >
                                    Yes
                                </button>
                                
                                <button 
                                    className="btn btn-black"
                                    disabled={!Boolean(brand_model)}
                                    onClick={() => setStep(SelectUnitPageStep.TWO)}
                                >
                                    No
                                </button>
                            </div>
                        </>
                    )}

                    {step === SelectUnitPageStep.TWO && (
                        <>
                            <p className="mb-4">
                                Select your unit or add a new unit:
                            </p>

                            {!(Boolean(brand_model?.prevent_use_overlap) && Boolean(brand_model?.personalized)) && <div className="mb-4">
                                <div className="row mb-2">
                                    <div className="col-6 col-md-4">
                                        <div className={styles.topLabel}>
                                            <span>Total units found:</span>
                                        </div>
                                    </div>

                                    <div className={`${styles.topLabelVal} col-6 col-md-7`}>
                                        {filteredUnits.length}
                                    </div>
                                </div>
                            </div>}

                            {Boolean(brand_model?.prevent_use_overlap) && Boolean(brand_model?.personalized) ? (
                                <>
                                    <div className="mb-4 row">
                                        <div className="col-6">
                                            <label className="form-label">Unit ID</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Search Unit ID"
                                                value={unit_id}
                                                onChange={(e) => setUnitID(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-6 mt-auto">
                                            <button 
                                                type="button" 
                                                className="btn btn-yellow"
                                                onClick={() => httpFindPrivateUnit('unit_id')}
                                                disabled={searching || !unit_id}
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>

                                    {(brand_model?.serial_uniqueness !== "none" && brand_model?.serial_uniqueness !== "batch") && (
                                        <div className="mb-4 row">
                                            <div className="col-6">
                                                <label className="form-label">{brand_model?.serial_type}</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder={`Search ${brand_model?.serial_type}`}
                                                    value={identifier}
                                                    onChange={(e) => setIdentifier(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-6 mt-auto">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-yellow"
                                                    onClick={() => httpFindPrivateUnit('identifier')}
                                                    disabled={searching || !identifier}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="row mb-4">
                                        <div className="col-12 col-md-4">
                                            <label className="form-label">
                                                Filter
                                            </label>

                                            <input 
                                                type="text" 
                                                className="form-control"
                                                placeholder="Search"
                                                onChange={filterAvailableUnits}
                                            />
                                        </div>
                                    </div>

                                    <PagedDataTable
                                        list={filteredUnits}
                                        columns={columns}
                                        onRowClick={onRowClick}
                                        loading={loading}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {errors.length > 0 && (
                        <div className="mb-4">
                            <ErrorsComponent errors={errors} />
                        </div>
                    )}

                    {selectedUnit && (
                        <div className="alert bg-gray-800" role="alert">
                            <b>Selected Unit:</b>{" "}
                            {`${addLeadingZeros(selectedUnit.unit_id, 8)} ${
                            (selectedUnit.serial_type === "None" ||
                            selectedUnit.serial_type === "Batch number")
                                ? ""
                                : selectedUnit.unique_identifier || ''
                            }`}
                        </div>
                    )}

                    {step === SelectUnitPageStep.TWO && !Boolean(selectedUnit) && (
                        <button 
                            className="btn btn-yellow"
                            type="button"
                            onClick={goToAddUnit}
                        >
                            Create new unit
                        </button>
                    )}
                </div>
            </div>
        </RequireSubmittingUser>
    )
}

export default SelectUnitPage;