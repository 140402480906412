import AppModal from "components/AppModal";
import LoadIndicator from "../LoadIndicator";

export const FullScreenLoader = ({ isOpen }) => {
  return (
    <AppModal.Container isOpen={isOpen}>
      <LoadIndicator />
    </AppModal.Container>
  );
};
