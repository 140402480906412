const SubmitQualityIcon = ({ color = "#fff" }) => {

    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.25 23.75H20C20.5967 23.75 21.169 23.5129 21.591 23.091C22.0129 22.669 22.25 22.0967 22.25 21.5V7.4375L16.0625 1.25H6.5C5.90326 1.25 5.33097 1.48705 4.90901 1.90901C4.48705 2.33097 4.25 2.90326 4.25 3.5V8" stroke={color} stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.875 19.25L13.25 15.875L9.875 12.5M15.5 1.25V8H22.25L15.5 1.25ZM2 15.875H13.25H2Z" stroke={color} stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export {
    SubmitQualityIcon
}