import React, { useState } from "react";
import { formatDDMMYYY, isToday } from "shared/helpers";
import LoadIndicator from "components/LoadIndicator";
import "./InviteRow.css";

const InviteRow = ({ data, deleteRow, resendInvite }) => {
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    setDeleting(true);
    await deleteRow(data.invite_id);
    setDeleting(false);
  };

  const [resending, setResending] = useState(false);
  const handleResend = async () => {
    setResending(true);

    await resendInvite(data.invite_id);

    setResending(false);
  };

  return (
    <div className="user-invite-wrapper">
      <div className="user-invite-data">
        <div className="email">
          <div>
            <span>{!Boolean(data.name) ? data.email : data.name}</span>
          </div>
        </div>

        <div className="date">
          <div>
            <span>{formatDDMMYYY(data.created_at)}</span>
          </div>
        </div>

        <div className="invite-date">
          <div>
            <span>{data.date_sent ? formatDDMMYYY(data.date_sent) : "-"}</span>
          </div>
        </div>

        <div className="status">
          <div>
            <span>{data.status}</span>
          </div>
        </div>
      </div>

      <div className="user-invite-actions">
        {(data.date_sent === null ||
          !isToday(data.date_sent) || Boolean(data.name)) &&
        data.status.toLowerCase() === "pending" ? (
          <button className="resend" onClick={handleResend}>
            {resending ? <LoadIndicator /> : data.name ? <i className="bi bi-link"></i> : "Resend"}
          </button>
        ) : (
          <div className="resend_empty"></div>
        )}

        {data.status.toLowerCase() === "rejected" ||
        data.status.toLowerCase() === "invalid" ||
        data.status.toLowerCase() === "pending" ? (
          <button className="delete" onClick={onDelete}>
            <i className="bi bi-trash"></i>
          </button>
        ) : (
          <div className="delete_empty"></div>
        )}
      </div>
    </div>
  );
};

export default InviteRow;
