import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";

function DeleteModal({ show, handleClose }) {

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4>Delete Quality Record?</h4>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "none" }}>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={() => handleClose(true)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
