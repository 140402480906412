import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  previousCategory: null,
  category: null,
  brand: null,
  model: null,
  country: "United Kingdom",
  mfr_date: null,
  unique_identifier: null,
  unit: null,
  current_sw_version: null,
  start_date: null,
  start_time: null,
  end_date: null,
  end_time: null,
  savedState: null,
};

export const addUnitSlice = createSlice({
  name: "addUnit",
  initialState,
  reducers: {
    setSavedState: (state, action) => {
      state.savedState = action.payload;
    },

    updateSavedState: (state, action) => {
      state.savedState = { ...state.savedState, ...action.payload };
    },

    clearSavedState: (state) => {
      state.savedState = null;
    },

    setCategoryInStore: (state, action) => {
      state.previousCategory = state.category;
      state.category = action.payload;
    },

    setBrandInStore: (state, action) => {
      state.brand = action.payload;
    },

    setModelInStore: (state, action) => {
      state.model = action.payload;
    },

    setUnit: (state, action) => {
      state.unit = action.payload;
    },

    setOtherAddUnitData: (state, action) => {
      // if (action.payload.country) state.country = action.payload.country;
      // if (action.payload.mfr_date) state.mfr_date = action.payload.mfr_date;
      // if (typeof action.payload.unique_identifier === "string")
      //   state.unique_identifier = action.payload.unique_identifier;
      // if (action.payload.start_date)
      //   state.start_date = action.payload.start_date;
      // if (action.payload.end_date) state.end_date = action.payload.end_date;
      // if (action.payload.start_time)
      //   state.start_time = action.payload.start_time;
      // if (action.payload.end_time) state.end_time = action.payload.end_time;
      // if (action.payload.current_sw_version) {
      //   state.current_sw_version = action.payload.current_sw_version;
      // }
      // if (action.payload.unit_id) state.unit = action.payload.unit_id;
      // state.usage_accumulated = action.payload.usage_accumulated;
      // state.usage_rate = action.payload.usage_rate;
      Object.keys(action.payload).forEach(
        (key) => (state[key] = action.payload[key])
      );
    },

    clearAddUnitStore: (state) => {
      state.category = null;
      state.brand = null;
      state.model = null;
      state.unit = null;
      state.unique_identifier = null;
      state.country = "United Kingdom";
      state.mfr_date = null;
      state.start_date = null;
      state.start_time = null;
      state.end_date = null;
      state.end_time = null;
      state.usage_accumulated = null;
      state.usage_rate = null;
    },
  },
});

export const {
  setCategoryInStore,
  setBrandInStore,
  setModelInStore,
  clearAddUnitStore,
  setOtherAddUnitData,
  setUnit,
  setSavedState,
  updateSavedState,
  clearSavedState,
} = addUnitSlice.actions;

export const selectAddUnitData = (state) => ({
  ...state.addUnit,
});

export default addUnitSlice.reducer;
