import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DesktopVersion from "./desktop";
import MobileVersion from "./mobile";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUnitQualityRecordData,
  setOriginalCategoryAttributes,
  setSelectedAberration,
  setSelectedSymptom,
  setSelectedUnit,
  setSelectedQuality,
} from "redux-store/features/unitQualityRecordSlice";
import { getDecimalYear, useForm } from "shared/helpers";
import CategoryService from "services/CategoryService";
import { selectToken } from "redux-store/features/authSlice";
import {
  selectDbVariablesData,
  setDbVariables,
} from "redux-store/features/dbVariableSlice";
import DbVariablesService from "services/DbVariablesService";
import SWVersionService from "services/SWVersionService";
import QualityService from "services/QualityService";
import { round } from "lodash";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";
import DeleteModal from "./DeleteModal";

const UnitQualityRecord = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const { quality_id } = useParams();

  const goBack = () => navigate(-1);

  const {
    originalCategoryAttributes,
    selectedUnit,
    selectedSymptom,
    selectedQuality,
  } = useSelector(selectUnitQualityRecordData);
  const { variables } = useSelector(selectDbVariablesData);

  const [swVersion, setSwVersion] = useState(null);
  const mediaQuery = window.matchMedia("(max-width: 760px)");
  const [showMobileVersion, setShowMobileVersion] = useState(
    mediaQuery.matches
  );

  const { form, onChange } = useForm({ description: "", evidence: 0 });

  const fetchCategoryAttributes = async (id) => {
    if (!id) return;

    try {
      const res = await CategoryService.getAttributes(token, dispatch, id);

      dispatch(setOriginalCategoryAttributes(res.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchQualityRecordData = async (id) => {
    try {
      const response = await qualityService.fetchQualityData(id);

      dispatch(setSelectedUnit(response.data.unit));
      dispatch(setSelectedAberration(response.data.aberration));
      dispatch(setSelectedSymptom(response.data.symptom));

      if (response.data.quality) {
        onChange("description", response.data.quality.description);
        onChange("severity", response.data.quality.severity);
        onChange("repaired", response.data.quality.repaired);
        onChange("use_counter", response.data.quality.use_amount);
        onChange("first_seen", response.data.quality.date_seen);
        // onChange("repeatability", response.data.quality.repeatability);
        onChange("evidence", +response.data.quality.evidence);
        dispatch(setSelectedQuality(response.data.quality));
      }

      if (response.data.unit) {
        fetchCategoryAttributes(response.data.unit.end_category);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (
      Boolean(state) &&
      (state.from === "select_symptom" || state.from === "new_symptom")
    ) {
      dispatch(setSelectedUnit(state.data?.unit));
      dispatch(setSelectedAberration(state.data?.aberration));
      dispatch(setSelectedSymptom(state.data?.symptom));

      onChange("description", state.data?.description);

      fetchCategoryAttributes(state.data?.original_category);

      if (!state.data.quality) {
        onChange("severity", state.data?.symptom.default_severity);
        onChange("repaired", null);
      } else {
        dispatch(setSelectedQuality(state.data.quality));
      }
    } else if (quality_id) {
      fetchQualityRecordData(quality_id);
    }
  }, [state, quality_id]);

  const estimatedUseCounter = useMemo(() => {
    if (
      selectedUnit &&
      originalCategoryAttributes &&
      originalCategoryAttributes.use_units != "Not applicable" &&
      originalCategoryAttributes.use_units != "Age"
    ) {
      const date_seen = getDecimalYear(
        !selectedQuality || !selectedQuality.date_seen
          ? new Date()
          : new Date(selectedQuality.date_seen)
      );

      console.log("date_seen", date_seen);

      const usage_updated = getDecimalYear(
        new Date(selectedUnit.usage_updated)
      );

      console.log("usage_updated", usage_updated);

      const use_counter =
        (selectedUnit.usage_accumulated || 0) +
        (selectedUnit.usage_rate || 0) * Math.abs(date_seen - usage_updated);

      return round(use_counter, 3);
    } else {
      return "";
    }
  }, [originalCategoryAttributes, selectedUnit, selectedQuality]);

  const variableService = new DbVariablesService(token, dispatch);
  const fetchDbVariables = async () => {
    try {
      const res = await variableService.getDatabaseGlobalVariables();

      dispatch(setDbVariables(res.data.data || null));
    } catch (error) {}
  };
  useEffect(() => {
    if (!variables) {
      fetchDbVariables();
    }
  }, [variables]);

  useEffect(() => {
    if (typeof estimatedUseCounter === "number")
      onChange("use_counter", estimatedUseCounter);
  }, [estimatedUseCounter]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowMobileVersion(mediaQuery.matches);
    });
  }, [mediaQuery.matches]);

  const swVersionService = new SWVersionService(dispatch, token);
  const fetchSwVersion = async (id) => {
    try {
      const res = await swVersionService.fetchById(id);

      setSwVersion(res.data.data || null);

      onChange("sw_version", res.data.data?.sw_version_id);
    } catch (error) {}
  };
  useEffect(() => {
    if (
      selectedUnit &&
      selectedUnit.current_sw_version &&
      selectedUnit.os_sw_updateable === 1
    ) {
      fetchSwVersion(selectedUnit.current_sw_version);
    }
  }, [selectedUnit]);

  const SALES = useMemo(() => {
    if (selectedQuality && selectedQuality["sales(n)"] > 0) {
      return true;
    }

    return false;
  }, [selectedQuality]);

  const getMaxDate = () => {
    const today = new Date();
    const date_string = today.toISOString().split("T")[0]; // Format as 'yyyy-mm-dd'

    if (selectedUnit && selectedUnit.end_time && variables) {
      const endTime = new Date(selectedUnit.end_time);
      endTime.setDate(endTime.getDate() + variables.symptom_delay);

      if (endTime.getTime() > today) {
        return date_string;
      } else {
        return endTime.toISOString().split("T")[0];
      }
    } else {
      return date_string;
    }
  };

  const getMaxDateRepairedDate = () => {
    const today = new Date();
    // today.setDate(today.getDate() - 1); // Subtract 1 day
    const date_string = today.toISOString().split("T")[0]; // Format as 'yyyy-mm-dd'

    if (
      selectedUnit &&
      selectedUnit.usage_active === 0 &&
      selectedUnit.usage_updated
    ) {
      const endTime = new Date(selectedUnit.usage_updated + "Z");
      endTime.setDate(endTime.getDate() - 1);

      if (endTime.getTime() > today) {
        return date_string;
      } else {
        return endTime.toISOString().split("T")[0];
      }
    } else {
      return date_string;
    }
  };

  const onSwVersionClick = () => {
    navigate("/svm");
  };

  const submitDisabled = useMemo(() => {
    if (!SALES && !form.severity) return true;

    if (
      selectedUnit &&
      originalCategoryAttributes &&
      originalCategoryAttributes.use_units != "Not applicable" &&
      originalCategoryAttributes.use_units != "Age" &&
      !SALES &&
      isNaN(form.use_counter)
    )
      return true;

    if (!SALES && !form.first_seen) return true;

    if (
      selectedUnit &&
      selectedUnit.os_sw_updateable === 1 &&
      !SALES &&
      !form.sw_version
    )
      return true;

    if (!form.description) {
      return true;
    }

    return false;
  }, [form, selectedUnit, originalCategoryAttributes]);

  const qualityService = new QualityService(dispatch, token);
  const createQuantityRecord = async (payload) => {
    onChange("loading", true);
    try {
      const uask =
        selectedUnit.brand_model_status === "verified" &&
        Boolean(selectedUnit.category_verified_date) &&
        originalCategoryAttributes.use_units !== "Age" &&
        originalCategoryAttributes.use_units !== "Not applicable";

      await qualityService.insertQuality({ ...payload, uask: uask ? 1 : 0 });

      if (state.from === "select_symptom") navigate(-2);
      else if (state.from === "new_symptom") navigate(-3);
    } catch (error) {}
    onChange("loading", false);
  };

  const updateQualityRecord = async (payload) => {
    try {
      onChange("loading", true);
      const uask =
        selectedUnit.brand_model_status === "verified" &&
        Boolean(selectedUnit.category_verified_date) &&
        originalCategoryAttributes.use_units !== "Age" &&
        originalCategoryAttributes.use_units !== "Not applicable";
      await qualityService.updateQuality({ ...payload, uask: uask ? 1 : 0 });
      navigate(-1);
    } catch (error) {
    } finally {
      onChange("loading", false);
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (submitDisabled || form.loading || !selectedUnit || !selectedSymptom)
      return;

    if (!selectedQuality) {
      const payload = {
        ...form,
        controller_use: selectedUnit.controller_use_id,
        unit: selectedUnit.unit_id,
        symptom: selectedSymptom.symptom_id,
        original_category: state.data.original_category || unit,
        filter_category: state.data.filter_category,
      };

      createQuantityRecord(payload);
    } else {
      const payload = {
        // repeatability: form.repeatability,
        use_amount: form.use_counter,
        quality_id: selectedQuality.quality_id,
        repaired: form.repaired,
        evidence: Number(form.evidence),
        severity: form.severity,
        date_seen: form.first_seen,
        description: form.description,
      };

      updateQualityRecord(payload);
    }
  };

  const [deleting, setDeleting] = useState(false);
  const [deleteProps, setDeleteProps] = useState(null);
  const shouldDeleteProceed = () =>
    new Promise((res) => {
      setDeleteProps({
        handleClose: res,
      });
    });
  const handleDelete = async () => {
    try {
      if (!quality_id) return;

      const canDelete = await shouldDeleteProceed();
      setDeleteProps(null);

      if (canDelete) {
        setDeleting(true);

        await qualityService.deleteQuality(quality_id);
        navigate(-1);
      }
    } catch (e) {
      console.log("ERROR", e);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <RequireSubmittingUser>
      <DeleteModal show={deleteProps !== null} {...deleteProps} />

      {showMobileVersion ? (
        <MobileVersion />
      ) : (
        <DesktopVersion
          SALES={SALES}
          form={form}
          onChange={onChange}
          estimatedUseCounter={estimatedUseCounter}
          getMaxDate={getMaxDate}
          getMaxDateRepairedDate={getMaxDateRepairedDate}
          swVersion={swVersion}
          onSwVersionClick={onSwVersionClick}
          goBack={goBack}
          submitDisabled={submitDisabled}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          useStart={selectedUnit?.start_time}
        />
      )}
    </RequireSubmittingUser>
  );
};

export default UnitQualityRecord;
