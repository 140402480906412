import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "services/AuthenticationService";
import { useForm } from "shared/helpers";
import Header from "components/header";

function ForgotPassword() {
  const [loading, set_loading] = useState(false);
  const [msg, set_msg] = useState(null);

  const { form, onChange, validate, err, handleServerError } = useForm();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const valid = validate(["identity"]);
    if (!valid) return;

    set_loading(true);
    set_msg(null);

    try {
      const res = await AuthenticationService.forgot_password(form.identity);

      set_msg(res.data.message);
    } catch (error) {
      handleServerError(error);
    }

    set_loading(false);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="container">
        <div className="loginBg">
          <h1>Forgot Password</h1>

          <form id="loginForm" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">
                Phone Number/Email <em className="required">*</em>
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                // required
                value={form.identity}
                onChange={(e) => onChange("identity", e.target.value)}
                placeholder=""
              />
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-yellow">
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
              </button>
              <div className="mt-4">
                <a className="link" onClick={() => navigate("/auth/signin")}>
                  Login Account
                </a>
              </div>
            </div>

            {msg && <div className="success_message">{msg}</div>}
            {err.map((text) => (
              <div className="error_message">{text}</div>
            ))}
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
