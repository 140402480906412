import styles from "./styles.module.css";

const CardTable = ({
    id, columns, data = [], customClassName = "", selectable
}) => {

    if (columns.length === 0) {
        return null;
    }

    return (
        <div className={styles.cardTableContainer}>
            <table className={`${styles.table} ${customClassName}`}>
                <thead>
                    <tr>
                        {
                            columns.map((column, idx) => (
                                <th key={`${id}-th-${idx}`} className={styles.th} colSpan={column.colSpan}>
                                    <div>
                                        {column.title}
                                    </div>
                                </th>
                            ))
                        }
                    </tr>
                </thead>

                <tbody>
                    {
                        data.map((item, idx) => (
                            <tr className={`${styles.tr} ${Boolean(selectable && item[selectable]) ? styles.selected : ''}`} key={`${id}-tr-${idx}`}>
                                {
                                    columns.map((column, colIdx) => (
                                        <td className={styles.td} key={`${id}-td-${idx}-${colIdx}`} colSpan={column.colSpan}>
                                            <div>
                                                {column.getData ? column.getData(item, idx) : item[column.key]}
                                            </div>
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
                
            </table>

            {
                data.length === 0 && (
                    <h6>No data</h6>
                )
            }
        </div>
    )
}

export default CardTable;