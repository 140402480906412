import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // originalCategoryAttributes: null,
  selectedUnit: null,
  selectedAberration: null,
  // selectedSymptom: null,
};

export const newSymptomSlice = createSlice({
  name: "new_symptom",
  initialState,
  reducers: {
    setSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload || null;
    },
    setSelectedAberration: (state, action) => {
      state.selectedAberration = action.payload || null;
    },
    // setSelectedSymptom: (state, action) => {
    //   state.selectedSymptom = action.payload || null;
    // },
    // setOriginalCategoryAttributes: (state, action) => {
    //   state.originalCategoryAttributes = action.payload || null;
    // },
  },
});

export const { setSelectedAberration, setSelectedUnit } =
  newSymptomSlice.actions;

export const selectnewSymptomData = (state) => ({
  ...state.new_symptom,
});

export default newSymptomSlice.reducer;
