import React, { useEffect } from "react";
import DashboardHeader from "components/dashboard-header";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import styles from "./SoftwareVersionRecord.module.css";
import { useState } from "react";
import { formatDDMMYYYYDash, logger, useForm } from "shared/helpers";
import { useSelector } from "react-redux";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import { useDispatch } from "react-redux";
import { addSWVersion } from "redux-store/features/softwareVersionManagement";
import SWVersionService from "services/SWVersionService";
import {
  setOtherAddUnitData,
  updateSavedState,
} from "redux-store/features/addUnitSlice";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

function SoftwareVersionRecord({
  closeModal,
  modalMode,
  application: modalApplication,
}) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const { state } = useLocation();

  const [application, setApplication] = useState(null);

  const swVersionService = new SWVersionService(dispatch, token);

  const { form, onChange, handleServerError, validate } = useForm({
    name: "",
    build_code: "",
    notes: "",
    released_date: null,
  });

  const [editBtn, setEditBtn] = useState(false);

  const createSWVersion = async () => {
    if (!application?.sw_application_id) return;

    try {
      const res = await swVersionService.create({
        name: form.name,
        released_date: form.released_date,
        build_code: form.build_code,
        notes: form.notes,
        application_id: application.sw_application_id,
      });

      dispatch(addSWVersion(res.data.data));

      if (state?.secondary_for === "unit_details" && state?.editForm) {
        navigate(
          `/submit-quality-information/item-detail/${state?.editForm?.unit_id}`,
          {
            state: {
              selected: res.data.data.sw_version_id,
              for: "select_version",
              editForm: state.editForm,
            },
            replace: true,
          }
        );
      } else if (state?.secondary_for === "new_unit") {
        dispatch(updateSavedState({ versionInput: res.data.data.name }));
        dispatch(
          setOtherAddUnitData({
            current_sw_version: res.data.data.sw_version_id,
          })
        );
        navigate(-1);
      } else if (modalMode === "new") {
        closeModal(res.data.data);
      } else {
        navigate(-1);
      }
    } catch (error) {
      handleServerError(error);
    }
  };

  const [saving, setSaving] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate(["build_code", "name"])) return;

    setSaving(true);
    await createSWVersion();
    setSaving(false);
  };

  useEffect(() => {
    if (state) {
      setEditBtn(state?.for === "new");
      setApplication(state.application);

      if (state.prefilled) {
        Object.keys(state.prefilled).forEach((key) =>
          onChange(key, state.prefilled[key])
        );
      }

      if (state.item) {
        Object.keys(state.item).forEach((key) =>
          onChange(key, state.item[key])
        );
      }
    }
  }, [state]);

  useEffect(() => {
    setEditBtn(modalMode);

    if (modalApplication) setApplication(modalApplication);
  }, [modalMode, modalApplication]);

  return (
    <RequireSubmittingUser>
      <div id="main-container">
        <div className="container">
          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          <div className={`${styles.heading} mb-4`}>
            <h2 className="mb-4">Software version record</h2>

            <p className={styles.filter}>Application</p>

            <div className={styles.selectWrapper}>
              <div>
                <label>Name:</label>
              </div>

              <span>{application?.name || "Null"}</span>
            </div>

            <div className={styles.selectWrapper}>
              <div>
                <label>Details:</label>
              </div>

              <span>{application?.application || "Null"}</span>
            </div>

            <div className={styles.selectWrapper}>
              <div>
                <label>Hw Brand:</label>
              </div>

              <span>{application?.brand_name || "Null"}</span>
            </div>

            {/* <p className={styles.uses}>Model Instructions</p> */}

            {state?.for === "existing" && form.sw_version_id && (
              <div className={styles.alNum}>
                {/* <LockTimer data={form} onComplete={() => navigate(-1)} /> */}

                <div className={styles.topBtn}>
                  {/* <button
                    onClick={editHandler}
                    disabled={form.locked_by && form.locked_by !== user.user_id}
                  >
                    {editBtn ? "Close" : "Edit"}
                  </button> */}
                </div>
              </div>
            )}
          </div>
          {/*  */}

          {/* a row withoiut button*/}
          <form onSubmit={handleSubmit} id="softwareVersionRecordForm">
            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>SW Version ID</span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.sw_version_id || "Null"}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Application ID</span>
              </div>

              <div className={styles.rowValue}>
                <span>{application?.sw_application_id || "Null"}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Name</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <span>{form.name || "Null"}</span>
                ) : (
                  <input
                    className="form-control"
                    maxLength={30}
                    value={form.name}
                    onChange={(e) => onChange("name", e.target.value)}
                    required
                  />
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Build Code</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <span>{form.build_code || "Null"}</span>
                ) : (
                  <input
                    className="form-control"
                    maxLength={100}
                    value={form.build_code}
                    onChange={(e) => onChange("build_code", e.target.value)}
                    required
                  />
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Release Note</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <span>{form.notes || "Null"}</span>
                ) : (
                  <input
                    className="form-control"
                    maxLength={100}
                    value={form.notes}
                    onChange={(e) => onChange("notes", e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Release Date</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <span>
                    {form.released_date
                      ? formatDDMMYYYYDash(form.released_date)
                      : "Null"}
                  </span>
                ) : (
                  <ReactDatePicker
                    className="form-control datepicker_input"
                    selected={
                      form.released_date ? new Date(form.released_date) : null
                    }
                    onChange={(date) =>
                      onChange("released_date", date.toISOString())
                    }
                    dateFormat="dd/MM/yyyy"
                    required
                    placeholderText="Select date or enter dd/MM/yyyy"
                  />
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Created By </span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.created_by_number || "Null"}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Created At</span>
              </div>

              <div className={styles.rowValue}>
                <span>
                  {form.created_at
                    ? formatDDMMYYYYDash(form.created_at)
                    : "Null"}
                </span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Approved By</span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.approved_by_number || "Null"}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Approved At</span>
              </div>

              <div className={styles.rowValue}>
                <span>
                  {form.approved_at
                    ? formatDDMMYYYYDash(form.approved_at)
                    : "Null"}
                </span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Status</span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.status || "Null"}</span>
              </div>
            </div>
          </form>

          <div className={styles.btnContainer}>
            {(state?.for === "new" || modalMode === "new") && (
              <div className={styles.btnRight}>
                <button
                  className={styles.submit}
                  type="submit"
                  form="softwareVersionRecordForm"
                  disabled={saving}
                >
                  Submit
                </button>
              </div>
            )}
          </div>

          <div className={styles.mobileBtn}>
            {(state?.for === "new" || modalMode === "new") && (
              <div className={styles.btnTop}>
                <button
                  className={styles.submit}
                  type="submit"
                  form="softwareVersionRecordForm"
                  disabled={saving}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </RequireSubmittingUser>
  );
}

export default SoftwareVersionRecord;
