import { useState, useEffect } from 'react';

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update window width
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    // Event listener to update window width on resize
    window.addEventListener('resize', updateWindowWidth);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  return windowWidth;
};

export default useWindowWidth;
