import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import UserService from "services/UserService";
import { COUNTRIES } from "shared/constants";
import { handleError } from "shared/helpers";
import CustomPhoneInput from "components/CustomPhoneInput";
import ErrorsComponent from "components/Errors";
import LoadIndicator from "components/LoadIndicator";

const ChangeMobileModal = ({ show, handleClose }) => {
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (user) {
      const country = COUNTRIES.filter(
        (item) => item.name.toLowerCase() === user.country?.toLowerCase()
      )[0];

      if (country) {
        setCountryCode(country.code2.toLowerCase());
      }
    }
  }, [user]);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      setPhoneNumber("");
      setErrors([]);
      setLoading(false);
    }
  }, [show]);

  const changeUserMobile = async () => {
    setLoading(true);
    setErrors([]);

    const mobile = phoneNumber === dialCode ? "" : phoneNumber;

    try {
      const res = await UserService.requestChangeMobile(token, dispatch, {
        mobile,
      });

      handleClose({
        success: true,
        message: res.data?.message,
        number,
      });
    } catch (error) {
      handleError(error, setErrors);

      const response = error.response?.data;

      if (response && response.type === "number_used_resolution") {
        setErrors([]);
        setPhoneNumber("");
        handleClose({ error: response });
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose({})}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none" }}
      >
        <Modal.Title>New Mobile</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex-column mb-4">
          <CustomPhoneInput
            country={countryCode}
            value={phoneNumber}
            onChange={(phone) => {
              setPhoneNumber(phone);
            }}
            onDialCodeChange={setDialCode}
          />

          {errors.length > 0 && (
            <div className="mt-4">
              <ErrorsComponent errors={errors} />
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={() => handleClose({})}>
          Cancel
        </Button>

        <Button variant="primary" disabled={loading} onClick={changeUserMobile}>
          {loading ? <LoadIndicator /> : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeMobileModal;
