import { Modal } from "react-bootstrap";
import AddCategory from "pages/dashboard/add-category";

const AddCategoryModal = ({ show, handleClose, parent }) => {
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      fullscreen
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none", backgroundColor: "black" }}
      ></Modal.Header>

      <Modal.Body style={{ backgroundColor: "black", padding: 0 }}>
        <AddCategory closeModal={handleClose} parent={parent} />
      </Modal.Body>
    </Modal>
  );
};

export default AddCategoryModal;
