import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import CardTable from "components/CardTable";
import { useModelListController } from "hooks/useModelListController";
import LoadIndicator from "components/LoadIndicator";

const columns = [
  {
    title: "Model Name",
    key: "model_name_des",
  },
  {
    title: "Model Code",
    key: "model_code",
  },
  {
    title: "Qr",
    key: "qr",
  },
];

const ModelList = () => {
  const {
    fetching,
    list: data,
    brand,
    triggerFetch,
    reset,
  } = useModelListController();

  useEffect(() => {
    return reset;
  }, []);

  return (
    <div id="main-container" className={styles.modelList}>
      <div className="container">
        <h2 className="mb-4 userLevel-Title">Model List</h2>

        <div className="row">
          <div className="col-12">
            <div className="mb-4">
              <div className="row ps-2 mb-3">
                <div className={`${styles.topLabel} col-4 col-md-2`}>
                  <span>Brand</span>
                </div>

                <div className={`${styles.label} col my-auto`}>
                  <span>{brand}</span>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <a href="#" className={styles.refresh} onClick={triggerFetch}>
                Refresh
              </a>
            </div>

            <div className="mb-4">
              <div className={`${styles.subtitle} mb-3`}>
                Close or Select original browser tab to return
              </div>

              {fetching ? (
                <LoadIndicator />
              ) : (
                <CardTable
                  id={"model-list"}
                  customClassName={styles.dataTable}
                  columns={columns}
                  data={data}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelList;
