import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  convertDateToYearMonthDayString,
  convertDateToYearMonthString,
  dateToDbTimestamp,
  formatDateStringToDateNameYear,
  formatDateStringToMonthYear,
  getDateFromDateAndTimeString,
  handleError,
  logger,
  useDescriptionModal,
  useShortDisplay,
} from "shared/helpers";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import DescModal from "components/DescModal";
import DeleteModal from "./DeleteModal";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import UnitService from "services/UnitService";
import LoadIndicator from "components/LoadIndicator";
import ErrorsComponent from "components/Errors";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileDisplayThumb from "components/FileDisplay";
import "./styles.css";
import { UseCounterEst, UseRate } from "./components";
import { COUNTRIES } from "shared/constants";
import { useBrands } from "hooks/useBrands";
import Autocomplete from "components/Autocomplete";
import { useModels } from "hooks/useModels";
import ReactDatePicker from "react-datepicker";
import WarningModal from "components/WarningModal";
import {
  selectItemDetailData,
  setMode as setModeInStore,
  onChange,
  resetForm,
} from "redux-store/features/itemDetailsSlice";
import AutocompleteV2 from "components/AutocompleteV2";
import { useSwVersions } from "hooks/useSwVersions";
import CategoryService from "services/CategoryService";
import { usePreferences } from "hooks/usePrefrences";
import { useUnits } from "hooks/useUnits";
import AnimateHeight from "react-animate-height";
import BrandModelService from "services/BrandModelService";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

function ItemDetail() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const brandModelService = new BrandModelService(token, dispatch);
  const { unit_id } = useParams();

  const [unit, setUnit] = useState(null);
  const [unitUses, setUnitUses] = useState([]);
  const [qavp, setQavp] = useState(null);
  const [rating_label, set_rating_label] = useState(null);
  const [expandState, setExpandState] = useState({
    user_details: false,
    pricing: false,
    use_information: false
  });

  const toggleExpandState = (key) => () => {
    setExpandState(prev => ({
      ...prev,
      [key]: !prev[key]
    }))
  }

  const fetchBrandModelQavp = async (brand_model) => {
    if (!brand_model) return;

    try {
      const res = await brandModelService.getQavp(brand_model);
      setQavp(res.data);
    } catch (error) {
      logger("[ERROR]", error);
    }
  }

  const fetchUnitUses = useCallback(async () => {
    try {
      if (!unit_id) return;
      const { data } = await UnitService.getUnitUses(token, dispatch, unit_id);

      setUnitUses(data.data || []);
    } catch (error) {}
  }, [unit_id]);

  const fetchUnit = useCallback(async () => {
    try {
      if (!unit_id) return;
      const response = await UnitService.getUnit(token, dispatch, unit_id);

      setUnit(response.data?.data);
    } catch (error) {}
  }, [unit_id]);

  useEffect(() => {
    if (unit_id) fetchUnit().then(fetchUnitUses);
  }, [unit_id]);

  useEffect(() => {
    if (unit?.brand_model) {
      fetchBrandModelQavp(unit.brand_model);
    }
  }, [unit?.brand_model])

  const [modelText, setModelText] = useState("");
  // const editForm = useForm({});
  const { mode, form } = useSelector(selectItemDetailData);
  const setMode = (mode) => dispatch(setModeInStore(mode));

  const handleFormChange = (e) => {
    dispatch(onChange({ key: e.target.name, value: e.target.value }));
  };

  const { custom: brands } = useBrands({
    category: unit?.category_id,
  });

  const { custom: models, all: allModels } = useModels({
    category: unit?.category_id,
    brand: form.brand_id || unit?.brand_id,
  });

  const { all: all_versions, loading: loading_versions } = useSwVersions();
  const versions = useMemo(() => {
    if (!unit || !unit.sw_application || all_versions?.length === 0) return [];

    return all_versions.filter((e) => e.application_id === unit.sw_application);
  }, [all_versions, unit]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behaviuor: "smooth" });
  };

  useEffect(() => {
    setMode("view");
    dispatch(resetForm());
    return () => {
      setUnit(null);
    };
  }, []);

  const getControllerUseDisplayTime = (date_string) => {
    return `
      ${date_string
        .split(" ")[1]
        .substring(0, 5)} ${formatDateStringToDateNameYear(
      date_string.split(" ")[0]
    )}`;
  };

  const {
    showDescriptionModal,
    description,
    closeDescriptionModal,
    openDescriptionModal,
  } = useDescriptionModal();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  const deleteItem = async (options) => {
    if (!unit || deleting) return;

    setDeleting(true);
    try {
      await UnitService.deleteUnit(token, dispatch, {
        ...options,
        unit_id: unit.unit_id,
        controller_use_id: unit.controller_use_id
      });
      console.log("Go back");
      navigate("/submit-quality-information");
    } catch (error) {
      handleError(error, setErrors);
    }
    setDeleting(false);
  };

  const handleDeleteModalClose = (options = null) => {
    if (options) deleteItem(options);

    setShowDeleteModal(false);
  };

  const getSwClassName = () => {
    const classes = ["item-details__sw-version"];

    if (unit) {
      classes.push(`item-details__sw-version-${unit.sw_status?.toLowerCase()}`);
    }

    if (!unit || !unit.sw_version_code || !unit.sw_version_name) {
      classes.push("item-details__sw-version-empty");
    }

    return classes.join(" ");
  };

  const displayVersionName = () => {
    if (!unit || !unit.sw_version_code || !unit.sw_version_name) return "Null";

    return `${unit.sw_version_code}, ${unit.sw_version_name}`;
  };

  const { state } = useLocation();

  const goToQualityRecords = () => {
    if (!unit) {
      return;
    }

    navigate("/submit-quality-information/quality-records", {
      state: { unit },
    });
  };

  const goToEditMode = () => {
    scrollToTop();

    if (!unit || mode === "edit") {
      setMode("view");
      dispatch(resetForm());
    } else {
      setMode("edit");
      dispatch(onChange({ key: "usage_rate", value: unit.usage_rate }));
      dispatch(onChange({ key: "usage_active", value: unit.usage_active }));
      dispatch(
        onChange({ key: "usage_accumulated", value: unit.usage_accumulated })
      );
      dispatch(onChange({ key: "item_class", value: unit.item_class }));
      dispatch(onChange({ key: "brand_id", value: unit.brand_id }));
      dispatch(onChange({ key: "model_id", value: unit.model_id }));
      dispatch(
        onChange({ key: "unique_identifier", value: unit.unique_identifier })
      );
      dispatch(onChange({ key: "data_sale_type", value: unit.data_sale_type }));
      dispatch(onChange({ key: "price", value: unit.price }));
      dispatch(onChange({ key: "mfr_date", value: unit.mfr_date }));

      dispatch(onChange({ key: "country", value: unit.country }));
      dispatch(onChange({ key: "use_id", value: unit.controller_use_id }));
      dispatch(
        onChange({ key: "start_date", value: unit.start_time.split(" ")[0] })
      );
      dispatch(
        onChange({ key: "start_time", value: unit.start_time.split(" ")[1] })
      );
      dispatch(
        onChange({ key: "current_sw_version", value: unit.current_sw_version })
      );

      if (unit.end_time) {
        dispatch(
          onChange({ key: "end_date", value: unit.end_time.split(" ")[0] })
        );
        dispatch(
          onChange({ key: "end_time", value: unit.end_time.split(" ")[1] })
        );
      }

      setModelText(unit.model_name_des);
    }
  };

  const [warning, setWarning] = useState("");

  const changeUsageStatus = () => {
    if (mode === "edit") {
      const val = form?.usage_active === 0 ? 1 : 0;
      dispatch(onChange({ key: "usage_active", value: val }));
    }
  };

  const handleNewUse = () => {
    if (!unit) return;

    navigate("/submit-quality-information/add-use", {
      state: {
        unit_id: unit.unit_id
      }
    });
  };

  useEffect(() => {
    if (form.model_id && !modelText) {
      const model = allModels.filter((e) => e.model_id === form.model_id)[0];
      if (model) {
        setModelText(model.model_name_des);
      }
    }
  }, [modelText, form.model_id]);

  const { getDbDistance } = usePreferences();

  const { refresh } = useUnits();

  const [endCategoryAttributes, setEndCategoryAttributes] = useState(null);

  const uask = useMemo(() => {
    if (!unit || !endCategoryAttributes) return null;

    return (
      unit.brand_model_status == "verified" &&
      Boolean(unit.category_verified_date) &&
      endCategoryAttributes.use_units !== "Age" &&
      endCategoryAttributes.use_units !== "Not applicable"
    );
  }, [unit, endCategoryAttributes]);

  const [saving, setSaving] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (saving) return;

    const start_date = getDateFromDateAndTimeString(
      form.start_date,
      form.start_time
    );

    const end_date = getDateFromDateAndTimeString(form.end_date, form.end_time);

    if (form.end_date && start_date >= end_date) {
      setErrors("Start date and time must be less than end date and time");
      return;
    }

    if (start_date >= new Date()) {
      setErrors("Start date must be a valid date in the past");
      return;
    }

    setSaving(true);

    try {
      let data = {
        ...form,
        unit_id: unit.unit_id,
        start_date: dateToDbTimestamp(start_date),
        end_date: end_date ? dateToDbTimestamp(end_date) : null,
        uask: Boolean(uask)
      };

      if (data.usage_accumulated && endCategoryAttributes) {
        data.usage_accumulated =
          endCategoryAttributes.use_units?.toLowerCase() === "distance"
            ? getDbDistance(data.usage_accumulated)
            : data.usage_accumulated;
      }

      if (data.usage_rate && endCategoryAttributes) {
        data.usage_rate =
          endCategoryAttributes.use_units?.toLowerCase() === "distance"
            ? getDbDistance(data.usage_rate)
            : data.usage_rate;
      }

      if (rating_label) {
        const formData = new FormData();

        Object.keys(data).forEach((key) => formData.append(key, data[key]));

        formData.append("rating_label", rating_label);

        data = formData;
      }

      const res = await UnitService.updateUnit(token, dispatch, data);
      setUnit(res.data.data);
      fetchUnitUses();

      refresh();
      setMode("view");
    } catch (error) {
      handleError(error, setErrors, setWarning);
    }

    setSaving(false);
  };

  const fetchEndCategoryAttributes = async () => {
    if (!unit.category_id) return;

    try {
      const res = await CategoryService.getAttributes(
        token,
        dispatch,
        unit.category_id
      );

      if (res.data.success) {
        setEndCategoryAttributes(res.data.data);
      }
    } catch (error) {
      console.log("[ERROR]", error);
    }
  };

  const handleReviewSales = () => {
    navigate(`/submit-quality-information/review-sales/${unit.unit_id}`);
  }

  useEffect(() => {
    if (unit && unit.category_id) {
      fetchEndCategoryAttributes();
    }
  }, [unit?.category_id]);

  useEffect(() => {
    if (mode !== "edit") return;

    if (unit?.start_time) {
      dispatch(
        onChange({ key: "start_date", value: unit.start_time.split(" ")[0] })
      );
      dispatch(
        onChange({ key: "start_time", value: unit.start_time.split(" ")[1] })
      );
    }

    if (unit?.end_time) {
      dispatch(
        onChange({ key: "end_date", value: unit.end_time.split(" ")[0] })
      );
      dispatch(
        onChange({ key: "end_time", value: unit.end_time.split(" ")[1] })
      );
    }
  }, [unit?.start_time, unit?.end_time]);

  useEffect(() => {
    if (unit?.controller_use_id) {
      const use =
        unitUses.filter(
          (e) => e.controller_use_id == unit.controller_use_id
        )[0] || null;

      if (Boolean(use) && Boolean(unit)) {
        setUnit((prev) => {
          console.log("newData", { ...prev, ...use });
          return {
            ...prev,
            ...use,
          };
        });
      }
    }
  }, [unit?.controller_use_id]);

  const onSwVersionChange = (val) => {
    if (val.sw_version_id) {
      dispatch(
        onChange({
          key: "current_sw_version",
          value: val.sw_version_id,
        })
      );
    } else {
      if (unit?.sw_application)
        navigate("/svm", {
          state: {
            application: unit.sw_application,
            for: "unit_details",
            editForm: { ...form, unit_id: unit.unit_id },
          },
        });
    }
  };

  useEffect(() => {
    if (state && state.for === "select_version" && state.selected) {
      let passedFormData = state.editForm || {};

      if (typeof passedFormData !== "object") passedFormData = {};

      Object.keys(passedFormData).forEach((key) => {
        if (key !== "unit_id") {
          dispatch(onChange({ key, value: passedFormData[key] }));
        }
      });

      dispatch(
        onChange({
          key: "current_sw_version",
          value: +state.selected,
        })
      );

      setMode("edit");

      // clear state
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const getActionMessage = () => {
    if (unit) {
      if (unit.brand_model_status === "awaiting_verification") {
        return "Qualy is checking this new entry, please check again later for any new requirements";
      }

      if (unit.unit_status === "Pending") {
        return "You need to complete the required entries below";
      }

      if (unit.brand_model_status === "queried") {
        return "Qualy has a query about this item, please check your messages"
      }
    }

    return "";
  };

  const getMaxPrice = () => {
    if (qavp && Number(qavp.qavp) !== 0) {
      const maxMarketPrice = Number(qavp.qavp) * 3;
  
      if (maxMarketPrice <= 99.999) {
        return maxMarketPrice;
      }
    }
    return 99.999;
  }

  useEffect(() => {
    if (form.data_sale_type === 'market' && qavp) {
      dispatch(onChange({ key: "price", value: Number(qavp.qavp) }));
    }
  }, [form.data_sale_type]);

  return (
    <RequireSubmittingUser>
      <WarningModal
        show={warning}
        text={warning}
        handleOk={() => setWarning("")}
        handleClose={() => setWarning("")}
        hideCancel
      />

      <DescModal
        show={showDescriptionModal}
        handleClose={closeDescriptionModal}
        text={description}
      />

      <DeleteModal
        show={showDeleteModal}
        handleClose={handleDeleteModalClose}
      />

      <div id="main-container">
        <div className="container item-details">
          <h1 className="page-title mb-4">Item Unit Details</h1>

          {!unit ? (
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="infoContentCenter card mb-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-5">
                      {unit.unit_status !== "Complete" && (
                        <div className="d-flex mb-2">
                          <label className="form-label me-3">Action:</label>

                          <p>
                            {getActionMessage()}
                          </p>
                        </div>
                      )}

                      {mode === "view" && (
                        <div className="d-flex mb-2">
                          <label className="form-label me-3">Brand Model:</label>

                          <p className="d-flex flex-row p-0">
                            <span
                              className={`item-details__brand-${unit.brand_status
                                ?.replace(" ", "-")
                                ?.toLowerCase()}`}
                            >
                              {unit.brand}
                            </span>

                            &nbsp;

                            <span
                              className={`item-details__model-${unit.brand_model_status}`}
                            >
                              {unit.model_name_des}{" "}
                              {unit.model_code ? ", " + unit.model_code : ""}
                            </span>

                            &nbsp;

                            {unit.brand_model_status === "verified" &&
                            unit.model_instructions && (
                              <span className="item-details__model__instructions">
                                <OverlayTrigger
                                  key={"model-instruction"}
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-model-instruction">
                                      Model Instructions
                                    </Tooltip>
                                  }
                                >
                                  <FileDisplayThumb
                                    location={unit.model_instructions}
                                    icon={
                                      <i className="bi bi-file-earmark-text-fill"></i>
                                    }
                                  />
                                </OverlayTrigger>
                              </span>
                            )}
                          </p>
                        </div>
                      )}

                      {mode === "edit" && (
                        <>
                          <div className="d-flex mb-2">
                            <label className="form-label me-3">Brand:</label>
                            
                            <select
                              name="brand_id"
                              id="brand_id"
                              onChange={handleFormChange}
                              className="form-select"
                              value={form.brand_id || ""}
                              disabled={
                                unit.brand_status === "Verified" ||
                                unit.brand_created_by != user.user_id
                              }
                            >
                              <option value="" selected disabled>
                                Select Brand
                              </option>
                              {brands.map((brand) => (
                                <option
                                  key={
                                    brand.brand_id + "-item-detail-brand-option"
                                  }
                                  value={brand.brand_id}
                                >
                                  {brand.brand}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="d-flex mb-2">
                            <label className="form-label me-3">Model:</label>

                            <div className="w-100">
                              <Autocomplete
                                input={modelText}
                                setInput={setModelText}
                                suggestions={models}
                                placeholder="Samsung"
                                primaryKey="model_id"
                                labelKey="model_name_des"
                                setValue={(model) =>
                                  dispatch(
                                    onChange({
                                      key: "model_id",
                                      value: model.model_id,
                                    })
                                  )
                                }
                                disabled={unit.brand_model_status === "verified" || unit.b_m_created_by !== user?.user_id}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <hr className="spacer" />

                    {/** UNIT DETAILS */}
                    <div className="mb-5">
                      <div className="d-flex mb-2">
                        <label className="form-label me-3">
                          Unit Details
                        </label>

                        <button type="button" className="btn btn-green" onClick={toggleExpandState("user_details")}>
                          {expandState.user_details ? "Collapse" : "Expand"}
                        </button>
                      </div>

                      <AnimateHeight
                        id="user-details"
                        duration={300}
                        height={expandState.user_details ? "auto" : 0}
                      >
                        {/** UNIT DETAILS */}
                        <div className="d-flex mb-2">
                          <label
                            className={`form-label me-3 ${
                              unit.serial_type !== "None" &&
                              !Boolean(unit.unique_identifier)
                                ? "item-details__unit-identification__error"
                                : ""
                            }`}
                          >
                            Unit Details:
                          </label>

                          <div
                            className={"w-100 item-details__unit-identification"}
                          >
                            <span>
                              {`QUN#${unit.unit_id}`}{" "}
                              <span className={unit.unit_status?.toLowerCase()}>
                                ({unit.unit_status})
                              </span>
                            </span>

                            <span>
                              <div className="d-flex flex-row align-items-center">
                                {unit.serial_type !== "None" && (
                                  <div>{`${unit.serial_type} ${unit.unique_identifier}`}</div>
                                )}

                                <OverlayTrigger
                                  key={"information-label"}
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-information-label">
                                      Information Label
                                    </Tooltip>
                                  }
                                >
                                  <FileDisplayThumb
                                    location={unit.rating_label}
                                    disabled={mode === "edit"}
                                    onChange={mode === "edit" && set_rating_label}
                                    hideUploadButton={
                                      !(
                                        (!Boolean(unit.rating_label) ||
                                          (unit.controller_type ===
                                            "current_controller" &&
                                            unit.created_by === user?.user_id)) &&
                                        unit.item_class
                                          ?.toLowerCase()
                                          .includes("product")
                                      )
                                    }
                                  />
                                </OverlayTrigger>
                              </div>
                            </span>

                            {unit.serial_type !== "None" && mode === "edit" && (
                              <input
                                type="text"
                                className="form-control mt-2 mb-2 animate__animated animate__fadeIn"
                                value={form.unique_identifier || ""}
                                name="unique_identifier"
                                onChange={handleFormChange}
                              />
                            )}
                          </div>
                        </div>

                        {/** PRODUCED DATE */}
                        {(unit.item_class !== "One-off service" && unit.item_class !== "Continuous service") && (
                          <div className="d-flex mb-2">
                            <label
                              className={`form-label me-3 ${
                                !Boolean(unit.mfr_date)
                                  ? "item-details__mfr-date__error"
                                  : ""
                              }`}
                            >
                              Produced Date:
                            </label>

                            {mode === "view" && (
                              <p>{formatDateStringToMonthYear(unit.mfr_date)}</p>
                            )}

                            {mode === "edit" && (
                              <div className="customDatePickerWidth" style={{ flex: 1 }}>
                                <ReactDatePicker
                                  className="form-control"
                                  selected={
                                    form.mfr_date ? new Date(form.mfr_date) : null
                                  }
                                  onChange={(date) =>
                                    dispatch(
                                      onChange({
                                        key: "mfr_date",
                                        value: convertDateToYearMonthString(date),
                                      })
                                    )
                                  }
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  required={false}
                                  placeholderText="Select date or enter MM/yyyy"
                                />
                              </div>
                            )}
                          </div>
                        )}

                        {/** COUNTRY */}
                        <div className="d-flex mb-2">
                          <label className="form-label me-3">
                            Intended Country:
                          </label>
                          {mode === "view" && <p>{unit.country}</p>}
                          {mode === "edit" && (
                            <div className="w-100">
                              <AutocompleteV2
                                suggestions={COUNTRIES}
                                placeholder="Country"
                                required={true}
                                disabled={unit.unit_status !== "Pending"}
                                // input={countryInput}
                                value={form.country || ""}
                                labels={["name"]}
                                primaryKey="name"
                                setValue={(value) => {
                                  dispatch(
                                    onChange({
                                      key: "country",
                                      value: value.name,
                                    })
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>

                        {/** SW VERSION */}
                        {unit.brand_model_status == "verified" &&
                        unit.os_sw_updateable === 1 ? (
                          <div className="d-flex mb-2">
                            <label className="form-label me-3">SW Version:</label>

                            {mode === "view" && (
                              <p className={getSwClassName()}>
                                {displayVersionName()}
                              </p>
                            )}

                            {mode === "edit" && (
                              <div className="w-100">
                                <AutocompleteV2
                                  suggestions={versions}
                                  last={{
                                    name: "New version",
                                    sw_version_id: "",
                                    isLast: true,
                                  }}
                                  disabled={loading_versions}
                                  primaryKey="sw_version_id"
                                  value={form.current_sw_version || ""}
                                  labels={[
                                    "name",
                                    "build_code",
                                    { label: "released_date", date: true },
                                  ]}
                                  sortKey="name"
                                  setValue={onSwVersionChange}
                                  required={false}
                                />
                              </div>
                            )}
                          </div>
                        ) : null}
                      </AnimateHeight>
                    </div>
                    
                    <hr className="spacer" />

                    {/** PRICING */}
                    <div className="mb-5">
                      <div className="d-flex mb-2">
                        <label className="form-label me-3">
                          Pricing
                        </label>

                        <button type="button" className="btn btn-green" onClick={toggleExpandState("pricing")}>
                          {expandState.pricing ? "Collapse" : "Expand"}
                        </button>
                      </div>

                      <AnimateHeight
                        id="pricing"
                        duration={300}
                        height={expandState.pricing ? "auto" : 0}
                      >
                        {/** QUALITY PRICE */}
                        <div className="d-flex mb-2">
                          <label className="form-label me-3">
                            Quality Price: <i
                              className="bi bi-info-circle"
                              style={{ marginLeft: 5 }}
                              onClick={() =>
                                openDescriptionModal(
                                  "This is the credit you get each time your quality record appears in a report"
                                )
                              }
                            ></i>
                          </label>

                          {mode === "view" && (
                            <p>
                              <i className="bi bi-credit-card-fill"></i>{" "}
                              {unit.price}{" "}
                              <span className="badge bg-primary">
                                {unit.data_sale_type} price
                              </span>
                            </p>
                          )}

                          {mode === "edit" && (
                            <div className="w-100">
                              <input
                                value={form.price}
                                onChange={handleFormChange}
                                name="price"
                                type="number"
                                className="form-control"
                                placeholder="10.000"
                                step="0.001"
                                min={0}
                                max={getMaxPrice()}
                                disabled={form.data_sale_type === "market" || unit.brand_model_status !== "verified"}
                                required
                              />

                              <label className="mt-2 custom-control teleport-switch d-block">
                                <span className="teleport-switch-control-description">
                                  Custom Price
                                </span>
                                <input
                                  type="checkbox"
                                  className="teleport-switch-control-input"
                                  checked={
                                    form.data_sale_type === "market"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    dispatch(
                                      onChange({
                                        key: "data_sale_type",
                                        value: e.target.checked
                                          ? "market"
                                          : "custom",
                                      })
                                    )
                                  }
                                />
                                <span className="teleport-switch-control-indicator"></span>
                                <span className="teleport-switch-control-description">
                                  Market Price
                                </span>
                              </label>
                            </div>
                          )}
                        </div>

                        {/** MARKET PRICE */}
                        <div className="d-flex mb-2">
                          <label className="form-label me-3">Market Price:</label>
                          <p>
                            <i className="bi bi-credit-card-fill"></i> {unit?.brand_model_status !== "verified" ? "Pending" : qavp?.qavp || "N/A"}
                          </p>
                        </div>

                        <button 
                          className="btn btn-yellow mb-2" 
                          type="button"
                          disabled={!Boolean(unit)}
                          onClick={handleReviewSales}
                        >
                          Review Sales
                        </button>
                      </AnimateHeight>
                    </div>

                    <hr className="spacer" />

                    {/** USE */}
                    <div className="mb-5">
                      <div className="d-flex mb-2">
                        <label className="form-label me-3">
                          Use Information
                        </label>

                        <button type="button" className="btn btn-green" onClick={toggleExpandState("use_information")}>
                          {expandState.use_information ? "Collapse" : "Expand"}
                        </button>
                      </div>

                      <AnimateHeight
                        id="pricing"
                        duration={300}
                        height={expandState.use_information ? "auto" : 0}
                      >
                        <div className="d-flex mb-2">
                          <label className="form-label me-3">Use ID:</label>
                          <div className="d-flex flex-row align-items-center w-100">
                            {mode === "edit" && <p>{unit.controller_use_id}</p>}
                            {mode === "view" && (
                              <select
                                name="use_id"
                                className="form-select"
                                value={unit.controller_use_id}
                                onChange={(e) =>
                                  setUnit((prev) => ({
                                    ...prev,
                                    controller_use_id: +e.target.value,
                                  }))
                                }
                                required
                              >
                                <option value="" selected disabled>
                                  Select Use
                                </option>
                                {unitUses.map(({ controller_use_id }) => (
                                  <option
                                    key={`item-detail-unit-${unit_id}-use-${controller_use_id}`}
                                    value={controller_use_id}
                                  >
                                    {controller_use_id}
                                  </option>
                                ))}
                              </select>
                            )}

                            {mode === "view" && (
                              <button
                                className="btn btn-yellow ms-4"
                                onClick={handleNewUse}
                                type="button"
                              >
                                New
                              </button>
                            )}
                          </div>
                        </div>

                        {/** START DATE & TIME */}
                        <div className="d-flex mb-2">
                          <label className="form-label me-3">Start Time:</label>

                          {mode === "view" && (
                            <p>{getControllerUseDisplayTime(unit.start_time)}</p>
                          )}

                          {mode === "edit" && (
                            <div className="d-flex flex-row flex-wrap w-100 mb-2" style={{ gap: 16 }}> 
                              <div className="customDatePickerWidth" style={{ flex: 1 }}>
                                <ReactDatePicker
                                  className="form-control datepicker_input"
                                  selected={
                                    form.start_date
                                      ? new Date(form.start_date)
                                      : null
                                  }
                                  onChange={(date) =>
                                    dispatch(
                                      onChange({
                                        key: "start_date",
                                        value:
                                          convertDateToYearMonthDayString(date),
                                      })
                                    )
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  required
                                  placeholderText="Select date or enter dd/MM/yyyy"
                                />
                              </div>

                              <div className="customDatePickerWidth" style={{ flex: 1 }}>
                                <ReactDatePicker
                                  id="start-time"
                                  className="form-control datepicker_input"
                                  selected={
                                    form.start_date && form.start_time
                                      ? getDateFromDateAndTimeString(
                                          form.start_date,
                                          form.start_time
                                        )
                                      : null
                                  }
                                  placeholderText="Select time"
                                  onChange={(date) =>
                                    dispatch(
                                      onChange({
                                        key: "start_time",
                                        value: `${date.getHours()}:${date.getMinutes()}`,
                                      })
                                    )
                                  }
                                  showTimeSelect
                                  readOnly={!form.start_date}
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        {unit && uask && endCategoryAttributes && (
                          <div className="use-counter d-flex mb-2">
                            <label className="form-label me-3">
                              Use Counter:
                            </label>

                            <UseCounterEst
                              value={form.usage_accumulated || ""}
                              endCategoryAttributes={endCategoryAttributes}
                              setValue={(value) =>
                                dispatch(
                                  onChange({ key: "usage_accumulated", value })
                                )
                              }
                              unit={unit}
                              mode={mode}
                            />
                          </div>
                        )}

                        {unit && uask && endCategoryAttributes && (
                          <div className="use-counter d-flex mb-2">
                            <label className={`form-label me-3 ${unit.usage_rate === null ? 'bg-red' : ''}`}>Use Rate:</label>

                            <UseRate
                              setValue={(value) =>
                                dispatch(onChange({ key: "usage_rate", value }))
                              }
                              unit={unit}
                              mode={mode}
                              endCategoryAttributes={endCategoryAttributes}
                              required
                            />
                          </div>
                        )}

                        {unit && uask && endCategoryAttributes && !Boolean(unit.end_time) && (
                          <div className="use-counter d-flex mb-3">
                            <label className="form-label me-3"></label>
                            <button
                              className={`
                                btn 

                                ${
                                  mode === "view" &&
                                  (unit?.usage_active
                                    ? "btn-green"
                                    : "btn-danger")
                                }

                                ${
                                  mode === "edit" &&
                                  (form?.usage_active
                                    ? "btn-green"
                                    : "btn-danger")
                                }

                                me-2`}
                              onClick={changeUsageStatus}
                              disabled={mode === "view"}
                            >
                              {mode === "view" && (
                                <>{unit?.usage_active ? "Active" : "Inactive"}</>
                              )}

                              {mode === "edit" && (
                                <>{form?.usage_active ? "Active" : "Inactive"}</>
                              )}
                            </button>
                          </div>
                        )}

                        <div className="d-flex mb-2">
                          <label className="form-label me-3">End Time:</label>

                          {mode === "view" && (
                            <p>
                              (
                              {unit.end_time
                                ? getControllerUseDisplayTime(unit.end_time)
                                : `In use`}
                              )
                            </p>
                          )}

                          {mode === "edit" && (
                            <div className="d-flex flex-row w-100 mb-2" style={{ gap: 16 }}>
                              <div className="customDatePickerWidth" style={{ flex: 1 }}>
                                <ReactDatePicker
                                  className="form-control datepicker_input"
                                  selected={
                                    form.end_date ? new Date(form.end_date) : null
                                  }
                                  onChange={(date) =>
                                    dispatch(
                                      onChange({
                                        key: "end_date",
                                        value:
                                          convertDateToYearMonthDayString(date),
                                      })
                                    )
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Select date or enter dd/MM/yyyy"
                                />
                              </div>

                              <div className="customDatePickerWidth" style={{ flex: 1 }}>
                                <ReactDatePicker
                                  id="start-time"
                                  className="form-control datepicker_input"
                                  selected={
                                    form.end_date && form.end_time
                                      ? getDateFromDateAndTimeString(
                                          form.end_date,
                                          form.end_time
                                        )
                                      : null
                                  }
                                  placeholderText="Select time"
                                  onChange={(date) =>
                                    dispatch(
                                      onChange({
                                        key: "end_time",
                                        value: `${date.getHours()}:${date.getMinutes()}`,
                                      })
                                    )
                                  }
                                  showTimeSelect
                                  readOnly={!form.end_date}
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </AnimateHeight>
                    </div>
                  </div>
                </div>
              </div>

              {errors.length > 0 && <ErrorsComponent errors={errors} />}

              <div className="mt-4 text-center">
                <a className="btn btn-black me-2" onClick={goToEditMode}>
                  <i className="bi bi-pencil"></i> Edit
                </a>

                <a
                  href="#"
                  className="btn btn-danger me-2"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <i className="bi bi-trash3"></i>
                  {deleting ? <LoadIndicator /> : "Delete"}
                </a>

                {/* <a className="btn btn-yellow">
                  <i className="bi bi-plus-circle"></i> Add Vendor Record
                </a>
                <i
                  className="bi bi-info-circle"
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    openDescriptionModal(
                      "You can add these as a service based on your purchase"
                    )
                  }
                ></i> */}

                {mode === "edit" && (
                  <button
                    type="submit"
                    className="btn btn-yellow ms-2"
                    disabled={saving}
                  >
                    {saving ? <LoadIndicator /> : "Submit"}
                  </button>
                )}

                <a className="btn btn-yellow ms-2" onClick={goToQualityRecords}>
                  Quality Records
                </a>
              </div>
            </form>
          )}
        </div>
      </div>
    </RequireSubmittingUser>
  );
}

export default ItemDetail;
