import { axiosClient } from "shared/axiosInstance";

class BrandService {
  static getBrandsForCategory(token, dispatch, category) {
    return axiosClient(dispatch, token).get(
      "/brand_model/brands?category=" + category
    );
  }

  static getBrandRecords(token, dispatch) {
    return axiosClient(dispatch, token).get("/brand_model");
  }

  static getAllBrandModelRecords(token, dispatch) {
    return axiosClient(dispatch, token).get("/brand_model/all");
  }

  static addBrandToDb(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/brand", data);
  }

  static getAllBrands(token, dispatch, max, filter) {
    let url = "/brand";

    let params = [];

    if (max) {
      params.push(`max=${max}`);
    } 

    if (filter) {
      params.push(`filter=${filter}`);
    }

    if (params.length > 0) {
      url += "?" + params.join("&");
    }

    return axiosClient(dispatch, token).get(encodeURI(url));
  }
}

export default BrandService;
