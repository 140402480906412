import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  setMessageTypes,
} from "redux-store/features/messageTypeManagementSlice";
import MessageCaseService from "services/MessageService";

export const useMessageTypeManagement = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const service = new MessageCaseService(token, dispatch);

  const httpGetMessageTypes = useCallback(async () => {
    try {
      const res = await service.get_all_bug_types();
      dispatch(setMessageTypes(res.data || []));
    } catch (error) {}
  }, [service, dispatch]);

  const httpGetCasesByType = useCallback(
    async (type_id) => {
      try {
        const res = await service.get_cases_by_type(type_id);

        if (res && res.data) {
          return res.data;
        }
      } catch (error) {
        console.log(error);
      }

      return [];
    },
    [service]
  );

  const httpGetMessageById = useCallback(
    async (message_id) => {
      try {
        const res = await service.get_message_by_id(message_id);

        if (res && res.data) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }

      return null;
    },
    [service]
  );

  return {
    httpGetMessageTypes,
    httpGetCasesByType,
    httpGetMessageById,
  };
};
