import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  selectCategoryManagementData,
  setCategories,
} from "redux-store/features/categoryManagement";
import CategoryService from "services/CategoryService";
import { setUpCategoryIfPrefilledExists } from "./helpers";

export const useMultiSelect = (props = {}) => {
  const { preSelected, setValue, noFilter = false, value = null, skipToEnd = false, skip = null } = props;

  const { categories } = useSelector(selectCategoryManagementData);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const [selectedCategoryTree, setSelectedCategoryTree] = useState([]);
  const [categoryInput, setCategoyInput] = useState({ 0: "All" });
  const [dropdownMatrix, setDropdownMatrix] = useState([]);
  const userInteraction = useRef(false);

  useEffect(() => {
    if (
      dropdownMatrix &&
      dropdownMatrix.length === 1 &&
      dropdownMatrix[0].length > 0
    ) {
      setDropdownMatrix((prev) => [...prev, prev[0][0].children]);
      setCategoyInput((prev) => ({ ...prev, 1: null }));
    }
  }, [dropdownMatrix]);

  const getCategories = async () => {
    try {
      const res = await CategoryService.getAvailableCategories(token, dispatch);

      dispatch(setCategories(res.data.data));
    } catch (e) {}
  };

  const fetchCategoryTree = async (category) => {
    try {
      const res = await CategoryService.getParentTree(
        token,
        dispatch,
        category
      );

      if (value === null) setSelectedCategoryTree([...res.data.data, category]);

      return res.data.data;
    } catch (e) {}

    return null;
  };

  useEffect(() => {
    if (categories.length === 0) getCategories();
  }, []);

  useEffect(() => {
    setDropdownMatrix([categories]);
  }, [categories]);

  useEffect(() => {
    if (preSelected) {
      fetchCategoryTree(preSelected).then((data) => {
        if (data && skip && skipToEnd && !userInteraction.current) {
          skip(data.length);
        }
      });
    } else if (noFilter && !preSelected) {
      setValue(null);
      setCategoyInput((prev) => ({ ...prev, 0: "No Filter" }));
    }
  }, [preSelected]);

  useEffect(() => {
    if (value === null) return;

    if (value === "") {
      setCategoyInput({ 0: "All" });
      setDropdownMatrix([categories]);
    } else {
      fetchCategoryTree(value).then((data) => {
        if (data && !userInteraction.current) {
          setUpCategoryIfPrefilledExists(
            [...data, value],
            setDropdownMatrix,
            (category) => {},
            setCategoyInput,
            categories
          );

          if (skip && skipToEnd) {
            skip(data.length);
          }
        }
      });
    }
  }, [value]);

  useEffect(() => {
    if (categories.length > 0 && selectedCategoryTree.length > 0) {
      setUpCategoryIfPrefilledExists(
        selectedCategoryTree,
        setDropdownMatrix,
        (category) => setValue(category),
        setCategoyInput,
        categories,
        noFilter
      );
    } else setDropdownMatrix([categories]);
  }, [selectedCategoryTree]);

  const reset = () => {
    setCategoyInput({ 0: "All" });
    setValue(null);
    setDropdownMatrix([categories]);
  };

  const handleChangeDropdownMatrix = (matrix) => {
    userInteraction.current = true;
    setDropdownMatrix(matrix);
  };

  useEffect(() => {
    return () => {
      userInteraction.current = false;
    };
  }, []);

  return {
    dropdownMatrix,
    categoryInput,
    setCategoyInput,
    setDropdownMatrix: handleChangeDropdownMatrix,
    reset,
    userInteraction,
    getCategories,
  };
};
