import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectToken } from "redux-store/features/authSlice";
import UnitService from "services/UnitService";
import { FullScreenLoader } from "components/FullScreenLoader";
import styles from "./styles.module.css";
import { logger } from "shared/helpers";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const ReviewSalesPage = () => {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const { unit_id } = useParams();
    const [unit, setUnit] = useState(null);

    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState(null);

    const fetchUnit = useCallback(async () => {
        try {
          if (!unit_id) return;
          const response = await UnitService.getUnit(token, dispatch, unit_id);
    
          setUnit(response.data?.data);
        } catch (error) {}
    }, [unit_id]);

    useEffect(() => {
        fetchUnit();
    }, [unit_id]);

    const httpGetReviewSalesData = useCallback(async () => {
        try {
            setFetching(true);
            const response = await UnitService.getReviewSalesData(token, dispatch, unit.brand_model);
            setData(response.data);
        } catch (error) {
            logger("Error", error);
        } finally {
            setFetching(false);
        }
    }, [unit?.brand_model]);

    useEffect(() => {
        console.log("unit", unit);
        if (unit && unit.unit_id) {
            httpGetReviewSalesData();
        }
    }, [unit?.unit_id]);

    return (
        <RequireSubmittingUser>
            <FullScreenLoader isOpen={!Boolean(unit) || fetching} />

            <div id="main-container" className={styles.review_sales_page}>
                <div className="container">
                    <h1 className="page-title mb-4">Review Sales</h1>

                    <p>Compare your sales with others for this item</p>

                    <p className={styles.brand_model}>{unit?.brand} {unit?.model_name_des}</p>

                    <div className="table-responsive">
                        <table className="table table-responsive table-bordered table-dark-theme user-summary-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Sales</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td scope="col">Yours</td>
                                    <td>{data?.yours || '0'}</td>
                                </tr>

                                <tr>
                                    <td scope="col">All</td>
                                    <td>{data?.all || '0'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>Note this is the total quality records sold, and does not include free records.</p>
                </div>
            </div>
        </RequireSubmittingUser>
    )
}

export default ReviewSalesPage;