import AnimatedLetters from "components/AnimatedLetters";
import "./styles.css";
import { useEffect, useState } from "react";

const title = "This site is currently down for maintenance".split("");

const SiteUnavailableUi = () => {
  const [letterClass, setLetterClass] = useState("text-animate");

  useEffect(() => {
    setTimeout(() => setLetterClass("text-animate-hover"), 4000);
  }, []);

  return (
    <div className="qualy-site-unavailable-ui">
      <div className="qualy-site-unavailable-ui__content container py-5">
        <div>
          <img src="/assets/images/favicon.png" />
        </div>

        <div className="row mt-100">
          <div className="col-12 col-md-6 my-auto">
            <h1 className="">
              <AnimatedLetters
                letterClass={letterClass}
                strArray={title}
                idx={1}
              />
            </h1>

            <p className="mx-auto mt-2">
              We apologize for any inconveniencies caused. We're almost done
            </p>
          </div>

          <div className="col-12 col-md-6 my-auto">
            <img className="unavailable" src="/assets/images/unavailable.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteUnavailableUi;
