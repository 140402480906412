import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import BrandService from "services/BrandService";

import styles from "./styles.module.css";
import LoadIndicator from "../LoadIndicator";

const maxBrands = 40;

const TableBrandSelector = forwardRef(({ onBrandChange }, ref) => {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);

    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('');
    const [maxExceeded, setMaxExceeded] = useState(false);

    const addBrand = (brand) => {
        setBrands(prev => [...prev, brand]);
    }

    useImperativeHandle(ref, () => {
        return {
            addBrand
        }
    }, [brands]);

    const fetchBrands = async (filter) => {
        try {
            setLoading(true);
            setMaxExceeded(false);
            const res = await BrandService.getAllBrands(token, dispatch, maxBrands, filter);
            const brands = res.data.data || [];
            brands.sort((a, b) => a.brand.toLowerCase().localeCompare(b.brand.toLowerCase()));
            setBrands(brands);
        } catch (error) {
            console.log('error', error);
            const errorCode = error.response?.data?.error;
            if (errorCode === "MaxExceeded") {
                setMaxExceeded(true);
            }
        } finally {
            setLoading(false);
        }
    };

    const updateList = () => fetchBrands(filter);

    const onBrandSelected = (brand) => () => onBrandChange(brand);

    useEffect(() => {
        const timeout = setTimeout(() => fetchBrands(filter), 1000);

        return () => clearTimeout(timeout);
    }, [filter]);

    return (
        <>
            {maxExceeded && (
                <b className="mb-2 d-block">
                    Too many brands to list, use filter to reduce results
                </b>
            )}

            <div className="mb-2 d-flex flex-row align-items-center flex-wrap gap-2">
                <span>Search filter</span>

                <div>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="Filter"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </div>
            </div>

            <a className="link mb-2 d-block" onClick={updateList}>Update list</a>

            {loading && <LoadIndicator />}

            {!loading && (
                <div className="table-responsive mb-4">
                    <table className="table table-responsive table-bordered table-dark-theme">
                        <thead>
                            <tr>
                                <th scope="col">Brand / Provider ({brands.length})</th>
                                <th scope="col">Owner Company</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                brands.map((brand) => (
                                    <tr 
                                        key={`table-brand-selector-${brand.brand_id}`}
                                        onClick={onBrandSelected(brand)}
                                        className={styles.category_row}
                                    >
                                        <td>{brand.brand}</td>
                                        <td>{brand.company_name ?? "-"}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
});

export {
    TableBrandSelector
}