import { Route, Routes, useNavigate } from "react-router-dom";
//import AuthLayout from "components/layouts/auth";

import SignupStart from "pages/auth/signup-start";
import Signup from "pages/auth/signup";
import Signin from "pages/auth/signin";
import ForgotUsername from "pages/auth/forgotusername";
import { useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { useEffect } from "react";
import ForgotPassword from "pages/auth/forgotpassword";
import ResetPassword from "pages/auth/resetpassword";
import MoreInformation from "pages/auth/more-information";

const Auth = (props) => {
  const token = useSelector(selectToken);

  const navigate = useNavigate();

  useEffect(() => {
    if (token) navigate("/");
  }, [token]);
  return (
    <Routes>
      <Route index element={<SignupStart />} />
      <Route path="signin" element={<Signin />} />
      <Route path="signup" element={<Signup />} />
      <Route path="forgot-username" element={<ForgotUsername />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="more-information" element={<MoreInformation />} />
    </Routes>
  );
};

export default Auth;
