import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { selectCategoryManagementData, setCategories } from "redux-store/features/categoryManagement";
import CategoryService from "services/CategoryService";
import LoadIndicator from "../LoadIndicator";
import styles from "./styles.module.css";

const TableCategorySelector = forwardRef(({ setCategory }, ref) => {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const { categories } = useSelector(selectCategoryManagementData);

    const [tree, setTree] = useState([]);

    const getCategories = async () => {
        try {
            const res = await CategoryService.getAvailableCategories(token, dispatch);
        
            dispatch(setCategories(res.data.data));

            setTree([res.data.data[0]])
        } catch (e) {}
    };

    useEffect(() => {
        if (categories.length === 0) getCategories();
    }, []);

    useEffect(() => {
        setTree([categories[0]]);
    }, [categories]);

    const activeParentCategory = useMemo(() => {
        if (tree.length === 0 || !Boolean(tree[tree.length - 1])) return null;

        let result = {...tree[tree.length - 1]};
        if (Array.isArray(result.children)) {
            let children = [...result.children];
            children.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            result.children = children;
        }

        return result;
    }, [tree]);

    const title = useMemo(() => {
        if (tree.length <= 1) {
            return "Top Categories";
        }

        return tree[tree.length - 1].name;
    }, [tree, activeParentCategory]);

    const renderCategoryName = useCallback((category) => {

        return (
            <span>
                {Boolean(activeParentCategory?.category_id !== 1) && (
                    <i className="bi bi-arrow-left"></i>
                )} 
                {category.name}
                {!Boolean(category.end) && (
                    <i className="bi bi-arrow-right"></i>
                )}
            </span>
        );
    }, [activeParentCategory?.category_id]);

    const renderCategoryDescription = useCallback((category) => {

        return (
            <span>
                {category.description}
            </span>
        );
    }, []);

    const onCategorySelected = (category) => () => {
        setCategory(category);

        if (!Boolean(category.end) && Array.isArray(category.children)) {
            setTree(prev => [...prev, category]);
        }
    }

    const goBack = () => {
        if (tree.length <= 1) {
            return;
        }

        let result = [...tree];
        result.splice(result.length - 1, 1);

        setTree(result);

        if (result.length > 1) {
            setCategory(result[result.length - 1]);
        } else {
            setCategory(null);
        }
    };

    const addChildCategory = (child) => {
        if (tree.length > 0) {
            let treeClone = [...tree];
            const parentIndex = treeClone.length - 1;

            treeClone = treeClone.map((item, idx) => {
                if (idx === parentIndex && Boolean(item.allow_end_category)) {
                    const children = [...item.children, child];

                    return {
                        ...item,
                        children,
                    }
                }

                return item;
            });

            let categoryIndex = parentIndex;
            while(categoryIndex > 0) {
                categoryIndex--;
                treeClone = treeClone.map((item, idx) => {
                    if (idx === categoryIndex) {
                        const children = item.children.map((child) => {
                            if (child.category_id === treeClone[categoryIndex + 1].category_id) {
                                return treeClone[categoryIndex + 1];
                            }

                            return child;
                        });
    
                        return {
                            ...item,
                            children,
                        };
                    }
    
                    return item;
                });
            }

            setTree(treeClone);
        }
    }

    useImperativeHandle(ref, () => {
        return {
            goBack,
            addChildCategory,
            tree
        }
    }, [tree]);

    if (categories.length === 0 || !Boolean(activeParentCategory)) {
        return <LoadIndicator />;
    }

    return (
        <>
            <h4 className="mb-2">{title}</h4>

            <div className="table-responsive">
                <table className="table table-responsive table-bordered table-dark-theme text-start">
                    <thead>
                        <tr>
                            <th scope="col">Category Name</th>
                            <th scope="col">Description</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            activeParentCategory.children
                                .map((category) => (
                                <tr 
                                    key={`table-category-selector-${category.category_id}`}
                                    onClick={onCategorySelected(category)}
                                    className={styles.category_row}
                                >
                                    <td>{renderCategoryName(category)}</td>
                                    <td>{renderCategoryDescription(category)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
});

export {
    TableCategorySelector
};