import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PublicService from "services/PublicService";
import { logger } from "shared/helpers";

const LegalInfo = () => {
  const [contact, setContact] = useState("");

  const fetchStoredText = async () => {
    try {
      const { data } = await PublicService.getStoredTextById(10);
      if (typeof data === "object") {
        setContact(data.text);
      }
    } catch (error) {
      logger("[fetchStoredText]", error);
    }
  };

  useEffect(() => {
    fetchStoredText();

    return () => {
      setContact("");
    };
  }, []);

  return (
    <div id="main-container">
      <div className="container">
        <h3 className="mb-4 fadeAnim">Legal Info</h3>

        <div className="card mb-4 fadeAnim">
          <div className="card-body">
            <div className="mb-2">
              <Link className="link" to="/basic_user_t_c.pdf" target="_blank">
                Basic User Terms and Conditions
              </Link>
            </div>

            <div className="mb-2">
              <Link className="link" to="/sub_user_t_c.pdf" target="_blank">
                Submitting User Terms and Conditions
              </Link>
            </div>

            <div className="mb-2">
              <Link
                className="link"
                to="/non_commercial_privacy.pdf"
                target="_blank"
              >
                Non-commercial User Privacy policy
              </Link>
            </div>

            <div className="mt-4">
              <b>Contact Information</b>

              <p className="pre-line">{contact}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalInfo;
