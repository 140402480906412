import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import UkPhoneInput from "../UkPhoneInput";

const CustomPhoneInput = ({
  value,
  onChange,
  country,
  disabled,
  disableDropDown = true,
  id,
  onDialCodeChange,
}) => {
  const handleChange = (rawValue, data) => {
    onChange(rawValue, true);
    onDialCodeChange && onDialCodeChange(data.dialCode);
  };

  const getNumbersOnly = (value) => {
    return value.replace(/[^0-9\.]+/g, "") || "";
  };

  const isValidPhoneNumber = (value, dialCode) => {
    const inputNumber = getNumbersOnly(value);

    let isValid = true;
    // when a dial code is present, the next number cannot be a 0
    if (
      dialCode &&
      inputNumber.length > dialCode.length &&
      inputNumber[dialCode.length] === "0"
    )
      isValid = false;

    return isValid;
  };

  const handleIsValid = (value, country) => {
    const isValid = isValidPhoneNumber(value, country.dialCode);
    return isValid;
  };

  // country.toLowerCase() === "gb" ? (
  //   <UkPhoneInput
  //     id={id}
  //     value={value}
  //     handleChange={(phone) => onChange(phone, true)}
  //     disabled={disabled}
  //     placeholder="Phone Number"
  //   />
  // ) :
  return (
    <PhoneInput
      // containerClass="PhoneInput"
      disabled={disabled}
      country={country.toLowerCase()}
      disableDropdown={disableDropDown}
      countryCodeEditable={disableDropDown ? false : true}
      isValid={handleIsValid}
      placeholder="Phone Number"
      inputProps={{
        name: "phone",
        required: true,
        id,
      }}
      value={value}
      onChange={handleChange}
      containerStyle={{ width: "100%" }}
      inputStyle={{
        backgroundColor: "transparent",
        width: "100%",
      }}
      dropdownStyle={{
        color: "black",
      }}
    />
  );
};

export default CustomPhoneInput;
