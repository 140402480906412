import { axiosClient } from "shared/axiosInstance";

class BrandModelService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  async getBMByBrandAndModel(brand, model) {
    return axiosClient(this.dispatch, this.token).get(
      `/brand_model/by-brand-and-model?brand=${brand}&model=${model}`
    );
  }

  async getAttribute(id) {
    return axiosClient(this.dispatch, this.token).get(
      "/brand_model_attributes?id=" + id
    );
  }

  async getByID(id) {
    return axiosClient(this.dispatch, this.token).get(
      `/brand_model/single/by-id/${id}`
    )
  }

  async getQavp(brand_model) {
    return axiosClient(this.dispatch, this.token).get(
      `/brand_model/qavp?brand_model=${brand_model}`
    );
  }
}

export default BrandModelService;
