import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUser } from "redux-store/features/authSlice";
import AuthenticationService from "services/AuthenticationService";
import { handleError, useShortDisplay } from "shared/helpers";
import LoadIndicator from "components/LoadIndicator";

function ConfirmMobileModal({ show, handleClose, phoneNumber }) {
  const user = useSelector(selectUser);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 3000, errors.length > 0);

  const confirmCode = async () => {
    setLoading(true);
    try {
      const res = await AuthenticationService.verify_code({
        email: user.email,
        phone_number: phoneNumber,
        code,
      });

      handleClose(true);
    } catch (error) {
      handleError(error, setErrors);
    }
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none" }}
      >
        <Modal.Title>Confirm Mobile</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex-column mb-4">
          <input
            type="text"
            className="form-control"
            id="code"
            placeholder="Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={() => handleClose(false)}>
          Cancel
        </Button>

        <Button variant="primary" disabled={loading} onClick={confirmCode}>
          {loading ? <LoadIndicator /> : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmMobileModal;
