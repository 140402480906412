import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { selectToken } from "redux-store/features/authSlice";
import ModelListService from "services/ModelListService";
import { logger } from "shared/helpers";

export const useModelListController = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const models = searchParams.get("models");
  const brand = searchParams.get("brand");

  const country = searchParams.get("country");
  const bid_price = searchParams.get("bidPrice");
  const bid_price_val = +searchParams.get("bidPriceVal");
  const evidence = searchParams.get("evidence_available");
  const date_seen_from = searchParams.get("date_seen_from") || undefined;
  const date_seen_to = searchParams.get("date_seen_to") || undefined;
  const date_of_production_from =
    searchParams.get("date_of_production_from") || undefined;
  const date_of_production_to =
    searchParams.get("date_of_production_to") || undefined;

  const service = new ModelListService(dispatch, token);

  const [list, setList] = useState([]);
  const [fetching, setFetching] = useState(false);

  const httpFetchModelList = useCallback(
    async (models) => {
      setFetching(true);
      try {
        const res = await service.fetchModelList(models, {
          country,
          bid_price: bid_price == "No filter" ? undefined : bid_price_val,
          evidence:
            evidence == "No filter" ? undefined : evidence == "Yes" ? 1 : 0,
          date_seen_to,
          date_seen_from,
          date_of_production_from,
          date_of_production_to,
        });
        if (res.data && Array.isArray(res.data)) {
          setList(res.data);
        }
      } catch (error) {
        logger("[error]", error);
      }
      setFetching(false);
    },
    [setFetching]
  );

  const triggerFetch = () => {
    if (token && models && !fetching) {
      httpFetchModelList(models.split(","));
    }
  };

  useEffect(() => {
    triggerFetch();
  }, [token, models]);

  const reset = () => {
    setFetching(false);
    setList([]);
  };

  return {
    fetching,
    list,
    brand,
    triggerFetch,
    reset,
  };
};
