const { axiosClient } = require("shared/axiosInstance");

class SWVersionService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchByApplicationForDropdown(id) {
    return this.client.get(
      `/sw-versions/by-application-dropdown?application=${id}`
    );
  }

  async fetchAll() {
    return this.client.get(`/sw-versions`);
  }

  async fetchByApplication(id) {
    return this.client.get(`/sw-versions/by-application?application=${id}`);
  }

  async fetchById(id) {
    return this.client.get("/sw-versions?id=" + id);
  }

  async create(data) {
    return this.client.post("/sw-versions", { ...data, from: "user" });
  }
}

export default SWVersionService;
