import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  selectToken,
  selectUser,
  updateUserData,
  updateUserEmail,
  updateUserMobile,
  verifyPhone,
} from "redux-store/features/authSlice";
import AuthenticationService from "services/AuthenticationService";
import UserService from "services/UserService";
import { COUNTRIES } from "shared/constants";
import {
  formatEnum,
  getMonthName,
  handleError,
  logger,
  useShortDisplay,
} from "shared/helpers";
import AnimatedContaner from "components/AnimatedContaner";
import Autocomplete from "components/Autocomplete";
import CustomSelect from "components/CustomSelect";
import ErrorsComponent from "components/Errors";
import LoadIndicator from "components/LoadIndicator";
import ChangeEmailModal from "./ChangeEmailModal";
import ChangeMobileModal from "./ChangeMobileModal";
import ChangePasswordModal from "./ChangePasswordModal";
import ConfirmMobileModal from "./ConfirmMobileModal";
import ActionModal from "../../../components/ActionModal";
import ConfirmDelete from "./ConfirmDelete";
import ConfirmSuspend from "./ConfirmSuspend";
import InviteUserService from "services/InviteUserService";
import DbVariablesService from "services/DbVariablesService";
import CustomPhoneInput from "components/CustomPhoneInput";
import NumberUsedModal from "./NumberUsedModal";
import ConfirmVerifyPhoneModal from "./ConfirmVerifyMobileModal";
import WarningModal from "components/WarningModal";

function IdentityInformation() {
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [countryCode, setCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [propertyNumber, setPropertyNumber] = useState(null);
  const [town, setTown] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const [type, setType] = useState("");

  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(1850);

  const [gender, setGender] = useState("");
  const [accountType, setAccountType] = useState("");
  const [postcode, setPostCode] = useState("");

  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (country) {
      const countryResult = COUNTRIES.filter(
        (item) => item.name.toLowerCase() === country.toLowerCase()
      )[0];

      if (countryResult) {
        setCountryCode(countryResult.code2.toLowerCase());
      }
    }
  }, [country]);

  const [first, setFirst] = useState(true);

  useEffect(() => {
    setPhoneNumber(user?.mobile);
  }, [user?.mobile]);

  useEffect(() => {
    if (first) {
      // scrollToTop();

      setPhoneNumber(user?.mobile);
      setFirstName(user?.first_name);
      setMiddleName(user?.middle_name);
      setLastName(user?.last_name);
      setPropertyNumber(user?.property_number);

      setAddressLine1(user?.road_name);
      setTown(user?.town);
      setState(user?.state);
      setCountry(user?.country);

      setCompanyName(user?.company_name);
      setPostCode(user?.postcode);

      setType(user?.type);

      const dob = user?.date_of_birth?.split("-");

      if (dob) {
        setDay(Number(dob[2]));
        setMonth(Number(dob[1]));
        setYear(Number(dob[0]));
      }

      setGender(user?.gender);
      setAccountType(user?.user_type);

      setFirst(false);
    }
  }, [editMode, user?.user_id, first]);

  useEffect(() => {
    if (country) {
      const states = COUNTRIES.filter((item) => item.name === country)[0]
        ?.states;

      if (states) {
        const stateInCountry = states.filter((item) => item.name === state);

        if (stateInCountry.length === 0) setState("");
      } else setState("");
    }
  }, [country]);

  const getUserName = () => {
    if (user) {
      let name = user?.first_name;

      if (user?.middle_name) name += ` ${user?.middle_name}`;

      name += ` ${user?.last_name}`;

      return name;
    } else return "";
  };

  const getAddress = () => {
    if (user) {
      let address = user?.property_number + ", " + user?.road_name;

      if (user?.town) address += `, ${user?.town}`;

      if (user?.state) address += `, ${user?.state}`;

      if (user?.postcode) address += ` ${user?.postcode}`;

      address += ` ${user?.country}`;

      return address;
    } else return "";
  };

  const capitalizeFirst = (string = "") => {
    return string[0]?.toUpperCase() + string.substring(1);
  };

  const getBirthDate = () => {
    if (user) {
      const dob = user.date_of_birth.split("-");

      return `${Number(dob[2])} ${getMonthName(Number(dob[1]) - 1)} ${Number(
        dob[0]
      )}`;
    }

    return "";
  };

  const handleCancel = () => {
    if (editMode) setEditMode(false);
    else navigate("/");
  };

  const getPayload = () => {
    const data = {};

    if (phoneNumber != user.mobile && phoneVerified)
      data.mobile = phoneNumber === dialCode ? "" : phoneNumber;

    if (propertyNumber != user.property_number)
      data.property_number = propertyNumber;
    if (firstName != user.first_name) data.first_name = firstName;
    if (middleName != user.middle_name) data.middle_name = middleName;
    if (lastName != user.last_name) data.last_name = lastName;
    if (companyName != user.company_name) data.company_name = companyName;

    let dob = user.date_of_birth.split("-");
    if (Number(day) != Number(dob[2])) dob[2] = day;
    if (Number(month) != Number(dob[1])) dob[1] = month;
    if (Number(year) != Number(dob[0])) dob[0] = year;
    dob = dob.join("-");
    if (dob != user.date_of_birth) data.date_of_birth = dob;

    if (addressLine1 != user.road_name) data.road_name = addressLine1;
    if (state != user.state) data.state = state;
    if (town != user.town) data.town = town;
    if (country != user.country) data.country = country;

    if (gender != user.gender) data.gender = gender.toLowerCase();
    if (accountType != user.user_type) data.user_type = accountType;
    if (type != user.type) data.type = type.toLowerCase();

    if (postcode != user.postcode) data.postcode = postcode;

    return data;
  };

  const getAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const validateAge = () => {
    const birthDate = new Date();
    birthDate.setMonth(month);
    birthDate.setDate(day);
    birthDate.setFullYear(year);

    const age = getAge(birthDate);

    if (age < 16) return "Minimum age is 16";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!type) return setErrors(["Please select your address type"]);
    if (!gender) return setErrors(["Please select your gender."]);

    try {
      const error = validateAge();

      if (error) return setErrors([error]);

      setLoading(true);

      const payload = getPayload();

      const res = await UserService.updateUser(token, dispatch, payload);

      dispatch(updateUserData(res.data.data));
      setEditMode(false);
      scrollToTop();
    } catch (error) {
      logger("Error", error);
      handleError(error, setErrors);
    }
    setLoading(false);
  };

  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const openChangeEmailModal = () => {
    setShowChangeEmailModal(true);
  };

  const closeChangeEmailModal = (email) => {
    setShowChangeEmailModal(false);

    if (email) {
      dispatch(updateUserEmail(email));
    }
  };

  const [showChangeMobileModal, setShowChangeMobileModal] = useState(null);
  const openChangeMobileModal = async () =>
    new Promise((resolve, reject) => {
      setShowChangeMobileModal({
        handleClose: (data) => {
          setShowChangeMobileModal(null);
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        },
      });
    });

  const [showMoibleDisputeModal, setShowMobileDisputeModal] = useState(null);
  const sendMobileDisputeMessage = async ({ message, userNumbers }) =>
    new Promise((resolve) => {
      setShowMobileDisputeModal({
        message,
        userNumbers,
        handleClose: () => {
          setShowMobileDisputeModal(null);
          resolve();
        },
      });
    });

  const [showVerifyChangeNumberModal, setShowVerifyChangeNumberModal] =
    useState(null);

  const verifyChangeNumber = async ({ message, number }) =>
    new Promise((resolve) => {
      setShowVerifyChangeNumberModal({
        message,
        number,
        handleClose: () => {
          setShowVerifyChangeNumberModal(null);
          resolve();
        },
      });
    });

  const [warning, setWarning] = useState("");

  const [changingMobile, setChangingMobile] = useState(false);
  const changeMobile = async (now = false) => {
    setChangingMobile(true);
    try {
      // request the change of mobile
      const { success, error, message, number } = !now
        ? await openChangeMobileModal()
        : {
            ...(
              await UserService.requestChangeMobile(token, dispatch, {
                mobile: phoneNumber === dialCode ? "" : phoneNumber,
              })
            ).data,
            number: phoneNumber === dialCode ? "" : phoneNumber,
          };

      if (error && error.type === "number_used_resolution") {
        await sendMobileDisputeMessage(error);
      }

      if (success) {
        await verifyChangeNumber({ message, number });
      }
    } catch (error) {
      handleError(error, (errs) => setWarning(errs[0]));
    }
    setChangingMobile(false);
  };

  const topRef = useRef(null);
  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const bottom = useRef(null);
  const scrollToBottom = () => {
    bottom.current?.scrollIntoView({ behavior: "auto" });
  };

  const [showConfirmMobileModal, setShowConfirmMobileModal] = useState(false);

  const closeConfirmMobileModal = (success) => {
    setShowConfirmMobileModal(false);

    if (success) {
      setPhoneVerified(success);
      dispatch(updateUserMobile(phoneNumber === dialCode ? "" : phoneNumber));
      dispatch(verifyPhone());
    }
  };

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const navigate = useNavigate();
  const goToSubmittingUserDetails = () => navigate("/submit-role-details");

  useEffect(() => {
    if (errors.length > 0) {
      scrollToBottom();
    }
  }, [errors]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const closeDeleteModal = () => setShowDeleteModal(false);
  const openDeleteModal = () => setShowDeleteModal(true);

  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [suspendMode, setSuspendMode] = useState("");
  const openSuspendModal = () => setShowSuspendModal(true);
  const closeSuspendModal = () => setShowSuspendModal(false);

  const handleSuspendSubmittingUser = () => {
    setSuspendMode("submitting");
    openSuspendModal(true);
  };

  const handleSuspend = () => {
    if (user.submitting) {
      handleSuspendSubmittingUser();
    }
  };

  const inviteService = new InviteUserService(token, dispatch);
  const dbVariablesService = new DbVariablesService(token, dispatch);

  const wasSubmittingUserInvited = async () => {
    try {
      const res = await inviteService.wasUserInvited();

      return res.data.data;
    } catch (error) {
      return null;
    }
  };

  const getDBVariablesInviteOnly = async () => {
    try {
      const res = await dbVariablesService.getDatabaseGlobalVariables();

      if (!res.data.data) return false;

      return Boolean(res.data.data.invite_only);
    } catch (error) {
      return false;
    }
  };

  const [loadingSubUserStatus, setLoadingSubUserStatus] = useState(false);

  const [submittingUserFound, setSubmittingUserFound] = useState(false);
  const [showSubmittingUserLabel, setShowSubmittingUserLabel] = useState(false);

  const getSubmittingUserStatusDisplayData = async () => {
    setLoadingSubUserStatus(true);

    const N1 = Boolean(user.submitting);
    const N2 = await wasSubmittingUserInvited();
    const N3 = await getDBVariablesInviteOnly();
    const N4 = Boolean(user.phone_verified);
    const N5 = Boolean(user.email_verified);
    const N6 = N2 && !N2.date_sent;

    const LABEL = (!N3 && N5 && N4) || (N3 && N5 && N4 && N2 && !N6) || N1;

    setSubmittingUserFound(N1);
    setShowSubmittingUserLabel(LABEL);

    setLoadingSubUserStatus(false);
  };

  useEffect(() => {
    getSubmittingUserStatusDisplayData();
  }, []);

  const getSubmittingUserStatusDisplay = () => {
    if (loadingSubUserStatus) {
      return <LoadIndicator />;
    }

    if (!showSubmittingUserLabel) {
      return null;
    }

    return (
      <tr>
        <td>Submitting User</td>
        <td>
          {submittingUserFound ? (
            <a className="link" onClick={goToSubmittingUserDetails}>
              <span
                style={{
                  background:
                    user?.submitting?.status === "on-hold"
                      ? "#e04626"
                      : "transparent",
                }}
              >
                {formatEnum(user?.submitting.status || "")}
              </span>
              , Level {user?.submitting.verification_level}
            </a>
          ) : (
            <div className="d-flex align-items-center">
              <p style={{ margin: 0 }}>Not found</p>

              <a
                onClick={(e) => {
                  navigate("/onboarding");
                }}
                className="btn btn-circle btn-success btnclickable mx-2"
              >
                <i className="bi bi-plus"></i>
              </a>
            </div>
          )}
        </td>
      </tr>
    );
  };

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
  };

  const states = useMemo(() => {
    if (!country) return [];

    return (
      COUNTRIES[
        COUNTRIES.findIndex(
          (item) => item.name.toLowerCase() == country.toLowerCase()
        )
      ].states?.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }) || []
    );
  }, [country]);

  return (
    <>
      <ConfirmSuspend
        show={showSuspendModal}
        handleClose={closeSuspendModal}
        suspendMode={suspendMode}
      />

      <WarningModal
        show={warning}
        text={warning}
        handleOk={() => setWarning("")}
        handleClose={() => setWarning("")}
        hideCancel
      />

      {/* <ActionModal
        show={showActionModal}
        text={actionModalText}
        buttonTitle={buttonTitle}
        action={action}
        buttonActions={buttonActions}
      /> */}

      <ChangeEmailModal
        show={showChangeEmailModal}
        handleClose={closeChangeEmailModal}
      />

      <ChangeMobileModal
        show={showChangeMobileModal !== null}
        handleClose={showChangeMobileModal?.handleClose}
      />

      <NumberUsedModal
        isOpen={showMoibleDisputeModal !== null}
        handleClose={showMoibleDisputeModal?.handleClose}
        message={showMoibleDisputeModal?.message}
        userNumbers={showMoibleDisputeModal?.userNumbers}
      />

      <ConfirmVerifyPhoneModal
        isOpen={showVerifyChangeNumberModal !== null}
        handleClose={showVerifyChangeNumberModal?.handleClose}
        message={showVerifyChangeNumberModal?.message}
        number={showVerifyChangeNumberModal?.number}
      />

      <ConfirmMobileModal
        phoneNumber={phoneNumber}
        show={showConfirmMobileModal}
        handleClose={closeConfirmMobileModal}
      />

      <ChangePasswordModal
        show={showChangePasswordModal}
        handleClose={() => setShowChangePasswordModal(false)}
      />

      <ConfirmDelete show={showDeleteModal} handleClose={closeDeleteModal} />

      <div ref={topRef} />

      <div id="main-container">
        <div className="container">
          <h3 className="mb-4 fadeAnim">Identification Information</h3>

          <form onSubmit={handleSubmit} autocomplete="off">
            <div className="card mb-4 fadeAnim">
              <div className="card-body">
                {/** USER NUMBER */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">User Number</label>
                  </div>

                  <div className="col-md-9">
                    <p>{user?.number || ""}</p>
                  </div>
                </div>

                {/** Name */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Name</label>
                  </div>

                  <div className="col-md-9">
                    {!editMode && <p>{getUserName()}</p>}
                    <AnimatedContaner show={editMode}>
                      <label className="form-label">First name</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        maxLength={20}
                        required
                      />

                      <label className="form-label">Middle name</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        placeholder="Middle Name"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                        maxLength={20}
                      />

                      <label className="form-label">Last name</label>
                      <input
                        type="text"
                        className="form-control mb-4"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        maxLength={20}
                        required
                      />
                    </AnimatedContaner>
                  </div>
                </div>

                {/** Company name */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Company name</label>
                  </div>

                  <div className="col-md-9">
                    {!editMode && <p>{user?.company_name || "Null"}</p>}

                    <AnimatedContaner show={editMode}>
                      <input
                        type="text"
                        className="form-control mb-4"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        maxLength={50}
                      />
                    </AnimatedContaner>
                  </div>
                </div>

                {/** ADDRESS */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Address</label>
                  </div>

                  <div className="col-md-9">
                    {!editMode && <p>{getAddress()}</p>}

                    <AnimatedContaner show={editMode}>
                      <div className="row mb-4">
                        <div className="col-md-6 mb-4">
                          <label className="form-label">
                            House/dwelling number
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="House Number"
                            value={propertyNumber}
                            onChange={(e) => setPropertyNumber(e.target.value)}
                            min={0}
                            required
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="form-label">Street name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address Line 1"
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                            maxLength="70"
                            required
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="form-label">Town</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Town"
                            value={town}
                            onChange={(e) => setTown(e.target.value)}
                            maxLength="20"
                            required
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="form-label">Postal Reference</label>
                          <input
                            type="text"
                            className="form-control"
                            value={postcode}
                            onChange={(e) => setPostCode(e.target.value)}
                            placeholder="Post Code"
                            maxLength="10"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label">
                            County/State/District
                          </label>

                          <Autocomplete
                            suggestions={states}
                            input={state}
                            setInput={(input) => setState(input)}
                            placeholder="Please select state"
                            labelKey={"name"}
                            primaryKey="code"
                            disabled={!country}
                            setValue={(value) => {}}
                            required={false}
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="form-label">Country</label>
                          <select
                            className="form-select"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            {COUNTRIES.map((item) => (
                              <option
                                key={`countrynkn ${Math.random()}`}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </AnimatedContaner>
                  </div>
                </div>

                {/** ADDRESS TYPE */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Address Type</label>
                  </div>

                  <div className="col-md-9">
                    {!editMode && (
                      <p>{user?.type ? capitalizeFirst(user?.type) : ""} </p>
                    )}

                    <AnimatedContaner show={editMode}>
                      <CustomSelect
                        suggestions={["unit", "flat", "house", "premises"]}
                        value={type}
                        onChange={(val) => setType(val)}
                      />
                    </AnimatedContaner>
                  </div>
                </div>

                {/** EMAIL */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Email</label>
                  </div>

                  <div className="col-md-9">
                    <div className="d-flex justify-content-start align-items-baseline">
                      <p>{user?.email}</p>

                      <h5 className="mx-4">
                          {user?.email_verified === 1
                            ? "(Verified)"
                            : "(Unverified)"}
                        </h5>

                      {!editMode && (
                        <button
                          type="button"
                          className="btn btn-yellow"
                          onClick={openChangeEmailModal}
                        >
                          Change email
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {/** MOBILE */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Mobile</label>
                  </div>

                  <div className="col-md-9">
                    {editMode ? (
                      <div className="d-flex justify-content-start align-items-baseline">
                        <CustomPhoneInput
                          country={countryCode}
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          onDialCodeChange={setDialCode}
                          disabled={user?.phone_verified === 1}
                        />

                        <h5 className="mx-4">
                          {user?.phone_verified === 1
                            ? "(Verified)"
                            : "(Unverified)"}
                        </h5>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-start align-items-baseline">
                        <p>+{phoneNumber}</p>

                        <h5 className="mx-4">
                          {user?.phone_verified === 1
                            ? "(Verified)"
                            : "(Unverified)"}
                        </h5>

                        {user?.phone_verified == 1 ? (
                          <button
                            className="btn btn-yellow"
                            type="button"
                            onClick={() => changeMobile()}
                            disabled={changingMobile}
                          >
                            Change mobile
                          </button>
                        ) : (
                          <button
                            className="btn btn-yellow"
                            type="button"
                            disabled={changingMobile}
                            onClick={() => changeMobile(true)}
                          >
                            {changingMobile ? <LoadIndicator /> : "Verify now"}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/** DATE OF BIRTH */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Date of birth</label>
                  </div>

                  <div className="col-md-9">
                    {!editMode && <p>{getBirthDate()}</p>}
                    <AnimatedContaner show={editMode}>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="form-label">Day</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Day"
                            min={1}
                            max={31}
                            value={day}
                            readOnly
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="form-label">Month</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Month"
                            min={1}
                            max={12}
                            value={month}
                            readOnly
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="form-label">Year</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Year"
                            min={1850}
                            max={2999}
                            value={year}
                            readOnly
                          />
                        </div>
                      </div>
                    </AnimatedContaner>
                  </div>
                </div>

                {/** Gender */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Gender</label>
                  </div>

                  <div className="col-md-9">
                    {!editMode && (
                      <p>{user?.gender ? capitalizeFirst(user?.gender) : ""}</p>
                    )}

                    <AnimatedContaner show={editMode}>
                      <CustomSelect
                        suggestions={["male", "female", "other"]}
                        value={gender}
                        onChange={setGender}
                      />
                    </AnimatedContaner>
                  </div>
                </div>

                {/** ACCOUNT TYPE */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Account Type</label>
                  </div>

                  <div className="col-md-9">
                    {!editMode && (
                      <p>
                        {user?.user_type
                          ? capitalizeFirst(user?.user_type)
                          : ""}
                      </p>
                    )}

                    <AnimatedContaner show={editMode}>
                      <CustomSelect
                        suggestions={["commercial", "personal"]}
                        value={accountType}
                        onChange={setAccountType}
                      />
                    </AnimatedContaner>
                  </div>
                </div>

                {/** ACCOUNT STATUS */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Account Status</label>
                  </div>

                  <div className="col-md-9">
                    <p>{user?.status ? capitalizeFirst(user?.status) : ""}</p>
                  </div>
                </div>

                {/** PASSWORD */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label-x">Password</label>
                  </div>

                  <div className="col-md-9">
                    <button
                      type="button"
                      className="btn btn-yellow"
                      onClick={() => setShowChangePasswordModal(true)}
                    >
                      Change password
                    </button>
                  </div>
                </div>

                {/** ROLES */}
                <table className="table table-bordered table-responsive table-dark-theme mb-4">
                  <thead>
                    <tr>
                      <th scope="col">Roles</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>{getSubmittingUserStatusDisplay()}</tbody>
                </table>

                {/** ERRORS */}
                <div ref={bottom} />
                {errors.length > 0 && <ErrorsComponent errors={errors} />}

                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-yellow me-3"
                    disabled={editMode}
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    <i className="bi bi-pencil"></i> Edit
                  </button>

                  {!editMode && (
                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      onClick={openDeleteModal}
                    >
                      Delete Account
                    </button>
                  )}

                  {!editMode && (
                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      onClick={handleSuspend}
                    >
                      Suspend Account
                    </button>
                  )}

                  <Link className="link d-block" to="/legal">
                    Legal Info
                  </Link>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-black me-2"
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-yellow"
                disabled={loading || !user || !editMode}
              >
                {loading ? <LoadIndicator /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default IdentityInformation;
