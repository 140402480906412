import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingState: null,
  errorState: null,
  canceledSubSignup: false,
};

const setAppLoadingAction = (state, action) => {
  state.loadingState = action.payload;
};

const setCanceledSubSignupAction = (state, action) => {
  state.canceledSubSignup = action.payload;
};

const reducers = {
  setAppLoading: setAppLoadingAction,
  setCanceledSubSignup: setCanceledSubSignupAction,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers,
});

export const { setAppLoading, setCanceledSubSignup } = appSlice.actions;

export const selectAppLoadingState = (state) => state.app.loadingState;
export const selectCanceledSubSignupState = (state) =>
  state.app.canceledSubSignup;

export default appSlice.reducer;
