import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  logout,
  selectToken,
  selectUser,
} from "redux-store/features/authSlice";
import VerifyPhoneModal from "components/VerifyPhoneModal";
import UserService from "../../services/UserService";
import DataTable from "./data-table";
import { selectCanceledSubSignupState } from "redux-store/features/appSlice";

function DashboardIndex() {
  const navigate = useNavigate();

  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const canceledSubSignup = useSelector(selectCanceledSubSignupState);
  const dispatch = useDispatch();
  const [loading, set_loading] = useState(false);

  const [show_verify, set_show_verify] = useState(false);

  const openVerify = () => {
    set_show_verify(true);
  };

  const closeVerify = () => {
    set_show_verify(false);
  };

  const should_onboard = async () => {
    if (!user?.submitting) return;

    set_loading(true);
    try {
      const res = await UserService.isVerificationMethodSubmitted(
        token,
        dispatch
      );

      if (!res.data.submitted && !canceledSubSignup) navigate("/onboarding");
      else if (user.phone_verified === 0) openVerify();
    } catch (error) {}
    set_loading(false);
  };

  const getStatus = (level) => {
    switch (level) {
      case 0:
        return "Phone Verification Pending";
      case 1:
        return "Normal";
      default:
        return "Normal";
    }
  };

  useEffect(() => {
    should_onboard();
  }, [canceledSubSignup]);

  return (
    <>
      <VerifyPhoneModal
        show={show_verify}
        handleClose={closeVerify}
        phone={user?.mobile}
        email={user?.email}
      />

      <div className="adminPage ProfilePage">
        <div id="main-container">
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <div className="container">
              <h3>News</h3>
              <p className="m-0 p-0">
                This week: Language options now available, currently populating
              </p>

              <p className="m-0 p-0">
                Last week: User vouching now available for those who don't have
                evidence docs in their name.
              </p>

              <div className="card mt-4 mb-4">
                <div className="card-body">
                  <h3
                    className="card-title"
                    data-bs-toggle="tooltip"
                    title="Searches with no data"
                    data-bs-placement="top"
                  >
                    Roles
                  </h3>

                  <DataTable
                    data={[
                      {
                        id: 1,
                        type: !user?.submitting ? "Common User" : "Submitting",
                        status: getStatus(
                          user?.submitting?.verification_level ||
                            user?.phone_verified ||
                            0
                        ),
                      },
                    ]}
                    columns={[
                      {
                        text: "#",
                        key: "id",
                      },
                      {
                        text: "Type",
                        key: "type",
                        sortable: true,
                      },
                      {
                        text: "Status",
                        key: "status",
                        sortable: true,
                      },
                    ]}
                  />
                  {/* <div className="table-responsive">
                      <table className="table table-responsive table-dark-theme">
                        <thead>
                          <tr>
                            <th scope="col">
                              # <i className="bi bi-arrow-down-short"></i>
                            </th>
                            <th scope="col">
                              Type <i className="bi bi-arrow-down-short"></i>
                            </th>
                            <th scope="col">
                              Status <i className="bi bi-arrow-down-short"></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {roles.map(({ type, status }, index) => (
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>{type}</td>
                              <td>{status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div> */}
                </div>
              </div>

              {/** */}

              <div className="card mb-4">
                <div className="card-body">
                  <h3
                    className="card-title"
                    data-bs-toggle="tooltip"
                    title="Searches with no data"
                    data-bs-placement="top"
                  >
                    Data Shortage Top 5
                  </h3>
                  <div className="table-responsive">
                    <table className="table table-responsive table-dark-theme">
                      <thead>
                        <tr>
                          <th scope="col">
                            # <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Category <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Records <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Searches <i className="bi bi-arrow-down-short"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/** */}

              <div className="card mb-4">
                <div className="card-body">
                  <h3 className="card-title">Your Last Searches</h3>
                  <div className="table-responsive">
                    <table className="table table-responsive table-dark-theme">
                      <thead>
                        <tr>
                          <th scope="col">
                            # <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Category <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Brand <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Model <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th
                            scope="col"
                            data-bs-toggle="tooltip"
                            title="New records since last search"
                            data-bs-placement="top"
                          >
                            New Records{" "}
                            <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Action <i className="bi bi-arrow-down-short"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/** */}

              <div className="card mb-4">
                <div className="card-body">
                  <h3
                    className="card-title"
                    data-bs-toggle="tooltip"
                    title="Records Generating Revenue"
                    data-bs-placement="top"
                  >
                    Active Registered Products
                  </h3>
                  <div className="table-responsive">
                    <table className="table table-responsive table-dark-theme">
                      <thead>
                        <tr>
                          <th scope="col">
                            # <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Brand <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Product Model{" "}
                            <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Record Pricing{" "}
                            <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th
                            scope="col"
                            data-bs-toggle="tooltip"
                            title="Pricing type Market or Fixed"
                            data-bs-placement="top"
                          >
                            PT <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th
                            scope="col"
                            data-bs-toggle="tooltip"
                            title="Last date no defect record updated"
                            data-bs-placement="top"
                          >
                            Last <i className="bi bi-arrow-down-short"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/** */}

              <div className="card mb-4">
                <div className="card-body">
                  <h3
                    className="card-title"
                    data-bs-toggle="tooltip"
                    title="Records Generating Revenue"
                    data-bs-placement="top"
                  >
                    Active Registered Services
                  </h3>

                  <div className="table-responsive">
                    <table className="table table-dark-theme">
                      <thead>
                        <tr>
                          <th scope="col">
                            # <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Brand <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">
                            Product Model{" "}
                            <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th scope="col">Record Pricing</th>
                          <th
                            scope="col"
                            data-bs-toggle="tooltip"
                            title="Pricing type Market or Fixed"
                            data-bs-placement="top"
                          >
                            PT <i className="bi bi-arrow-down-short"></i>
                          </th>
                          <th
                            scope="col"
                            data-bs-toggle="tooltip"
                            title="Last date no defect record updated"
                            data-bs-placement="top"
                          >
                            Last <i className="bi bi-arrow-down-short"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/** */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardIndex;
