import styles from "./styles.module.css";

const DateRange = ({ from = null, to = null, handleChangeFrom, handleChangeTo, type="date", required }) => {
  return (
    <div
      className={`d-flex flex-column align-items-center flex-md-row ${styles.dateRange}`}
    >
      {/* <div className={`${styles.rangeItem} d-flex flex-row align-items-center`}>
        <div>{">"}</div>

        <div>No filter</div>
      </div> */}

      <input 
        type={type} 
        className="form-control" 
        value={from || ''}
        max={to || undefined}
        onChange={(e) => handleChangeFrom(e.target.value)}
        required={required}
      />

      <div className={`${styles.seperator} d-none d-md-block`}>-</div>

      {/* <div className={`${styles.rangeItem} d-flex flex-row align-items-center`}>
        <div>{"<"}</div>

        <div>No filter</div>
      </div> */}

      <input 
        type={type} 
        className="form-control"
        value={to || ''}
        min={from || undefined}
        onChange={(e) => handleChangeTo(e.target.value)}
        required={required}
      />
    </div>
  );
};

export default DateRange;
