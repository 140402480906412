import { axiosClient } from "shared/axiosInstance";

class AuthenticationService {
  constructor() {}

  static getDbVariables() {
    return axiosClient().get("/auth/db-variables");
  }

  static cancelEmailVerification(email) {
    return axiosClient().post("/auth/cancel-email-verification", { email });
  }

  /**
   *
   */
  static signup(data) {
    return axiosClient().post("/auth/signup", data);
  }

  static isEmailDomainValid(email) {
    return axiosClient().get(
      `/auth/email-domain-status?domain=${email.split("@")[1]}`
    );
  }

  static verify_mail(data) {
    return axiosClient().post("/auth/confirm/email", data);
  }

  static verify_reset_mail(data, token) {
    return axiosClient().post("/auth/confirm/email_reset", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static send_user_no(data) {
    return axiosClient().post("/auth/remember/user_no", data);
  }

  static verify_phone(phone, email) {
    return axiosClient().get(`/auth/confirm/phone/${phone}/${email}`);
  }

  static verify_code(data) {
    return axiosClient().post("/auth/confirm/phone_code", data);
  }

  static complete_signup(data) {
    return axiosClient().post("/auth/update_user", data);
  }

  static is_email_verified(email) {
    return axiosClient().get(
      `/auth/confirm/email_verification_status/${email}`
    );
  }

  static signin(data) {
    return axiosClient().post("/auth/signin", data);
  }

  static forgot_password(identity) {
    return axiosClient().get(`/auth/reset/password/${identity}`);
  }

  static reset_password(token, password) {
    return axiosClient().put(`/auth/reset/password/${token}`, { password });
  }

  static are_details_uniqe(email, phone = "") {
    return axiosClient().get(
      `/auth/are_details_unique?email=${email}${phone ? `&phone=${phone}` : ""}`
    );
  }
}

export default AuthenticationService;
