import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import UserService from "services/UserService";
import { handleError, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "components/Errors";
import LoadIndicator from "components/LoadIndicator";

let timer = null;

function ChangeEmailModal({ show, handleClose }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 3000, errors.length > 0);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const [emailReset, setEmailReset] = useState(null);
  const [msg, setMsg] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(
    "Waiting for verification"
  );

  useEffect(() => {
    if (!show) {
      setEmail("");
      setEmailVerified(false);
      setEmailReset(null);
      setMsg("");
    }
  }, [show]);

  useEffect(() => {
    if (emailReset && !emailVerified) {
      timer = setInterval(() => {
        // Check reset status
        checkResetStatus();
      }, 2000);
    } else if (emailReset && emailVerified) {
      if (timer) clearInterval(timer);
      handleClose(email);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [emailReset, emailVerified]);

  const sendEmailChangeMail = async () => {
    setLoading(true);
    try {
      const res = await UserService.requestChangeMail(token, dispatch, {
        email,
      });

      setEmailReset(res.data.data);
      setMsg(res.data.message);
    } catch (error) {
      handleError(error, setErrors);
    }
    setLoading(false);
  };

  const checkResetStatus = async () => {
    try {
      const res = await UserService.checkResetStatus(
        token,
        dispatch,
        emailReset.id
      );

      const verified = Boolean(res.data.data);

      setEmailVerified(verified);
    } catch (error) {}
  };

  const handleCancel = () => {
    if (!emailReset) {
      handleClose(null);
    } else {
      setEmailReset(null);
      UserService.cancelRequestChangeMail(token, dispatch, email);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setMsg("");
        setEmailReset(null);
        setEmailVerified(false);
        if (emailReset) {
          UserService.cancelRequestChangeMail(token, dispatch, email);
        }
        handleClose(null);
      }}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none" }}
      >
        <Modal.Title>New Email</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex-column mb-4">
          {!emailReset ? (
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="johndoe@qualy.co.uk"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          ) : (
            <h3 className="title3">{msg}</h3>
          )}
        </div>

        {emailReset && (
          <div className="mb-4">
            <h3 className="title3">{verificationStatus}</h3>
          </div>
        )}

        {errors.length > 0 && (
          <div className="mb-4">
            <ErrorsComponent errors={errors} />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>

        {!emailReset && (
          <Button
            variant="primary"
            disabled={loading}
            onClick={sendEmailChangeMail}
          >
            {loading ? <LoadIndicator /> : "Verify now"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ChangeEmailModal;
