const { axiosClient } = require("shared/axiosInstance");

class AberrationService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchAll() {
    return this.client.get("/aberrations");
  }
  async getAberrationUseCount(aberration_id) {
    return this.client.get(
      "/aberrations/quality-record-count?aberration_id=" + aberration_id
    );
  }
}

export default AberrationService;
