import React from "react";
import styles from "./InstructionAvailable.module.css";
const InstructionsAvailable = () => {
  return (
    <>
      <div id="main-container">
        <div className="container">
          <h2 className="mb-4 userLevel-Title">
            UNIT QUALITY RECORD MANAGEMENT
          </h2>

          <div className={`${styles.arrow} mb-4`}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span className="">Quality and Symptom Management</span>
          </div>

          {/** SYMPTOM FILTER */}
          <div className="mb-4">
            <div className="row mb-2">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Quality Unit ID</span>
                    </div>
                  </div>

                  <div
                    className={`${styles.topLabelVal} ${styles.customLabelVal} col`}
                  >
                    <div className={styles.customInputView}>
                      <span>987654321</span>
                    </div>
                  </div>
                </div>
              </div>
              <button className={styles.btn}>Expand Category</button>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Serial no</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>123456789</div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Item Class</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    Reusable Product
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>End Category</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    Smart Phones
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Model </span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    Samsung Galaxy J6 SM-J600FN
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Current Controller </span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>123456789</div>
                </div>
              </div>
            </div>
          </div>

          {/** ENTER SYMPTOM IN YOUR WORD */}
          <div className="mb-4">
            <p className={`${styles.filter} mb-4 `}>Quality History</p>

            <div className="mb-2 row">
              <div className={`${styles.tableHead} col-2`}>
                <div className={styles.th}>Aberration</div>
              </div>
              <div className={`${styles.tableHead} col-2`}>
                <div className={styles.th}>Symptom</div>
              </div>
              <div className={`${styles.tableHead} col-2`}>
                <div className={styles.th}>Severity</div>
              </div>
              <div className={`${styles.tableHead} col-2`}>
                <div className={styles.th}>Date Seen</div>
              </div>
              <div className={`${styles.tableHead} col-2`}>
                <div className={styles.th}>Repaired</div>
              </div>
              <div className={`${styles.tableHead} col-2`}>
                <div className={styles.th}>Owner</div>
              </div>
            </div>

            <div className={`${styles.rowCard} d-flex mb-2 row `}>
              {/* Aberration */}
              <div className={`${styles.val} col-2`}>Missing Function</div>
              {/* Symptom */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
                Will not Activate
              </div>
              {/* Severity */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
                Major
              </div>

              {/* Date Seen */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
                2/3/2020
              </div>
              {/* Repaired */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
                Null
              </div>
              {/* Owner */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
                1234567890
              </div>
            </div>
            <div className={`${styles.rowCard} d-flex mb-2 row `}>
              {/* Aberration */}
              <div className={`${styles.val} col-2`}></div>
              {/* Symptom */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
              {/* Severity */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
                Minor
              </div>

              {/* Date Seen */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
              {/* Repaired */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
              {/* Owner */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
            </div>
            <div className={`${styles.rowCard} d-flex mb-2 row `}>
              {/* Aberration */}
              <div className={`${styles.val} col-2`}></div>
              {/* Symptom */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
              {/* Severity */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>

              {/* Date Seen */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
              {/* Repaired */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
              {/* Owner */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
            </div>
            <div className={`${styles.rowCard} d-flex mb-2 row `}>
              {/* Aberration */}
              <div className={`${styles.val} col-2`}></div>
              {/* Symptom */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
              {/* Severity */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>

              {/* Date Seen */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
              {/* Repaired */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
              {/* Owner */}
              <div className={`${styles.val} col-2`}>
                <div className={styles.spacer}></div>
              </div>
            </div>
          </div>

          <div className={`${styles.actions} d-flex flex-row`}>
            <button className={styles.cancel}>Back</button>

            <button className={styles.btn}>Submit</button>
            <button className={styles.reportbtn}>Report Error</button>
            <button className={styles.requestassistance}>
              Request Assistance
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructionsAvailable;
