import { axiosClient } from "shared/axiosInstance";

class UserService {
  constructor() {}

  static me(token, dispatch) {
    return axiosClient(dispatch, token).get("/users/me");
  }

  static createSubmittingProfile(token, dispatch) {
    return axiosClient(dispatch, token).post("/users/submitting_profile");
  }

  static updateVerificationMethods(data, token, dispatch) {
    return axiosClient(dispatch, token).post(
      "/users/settings/verification_methods",
      data
    );
  }

  static updateUser(token, dispatch, data) {
    return axiosClient(dispatch, token).patch("/users", data);
  }

  static fetchVerifyList(dispatch, token) {
    return axiosClient(dispatch, token).get("/users/settings/verify_list");
  }

  static submitVerificationDocument(data, token, dispatch) {
    return axiosClient(dispatch, token).post(
      "/verification_history/create",
      data
    );
  }

  static updateUserVerification(data, token, dispatch) {
    return axiosClient(dispatch, token).post(
      "/verification_history/update",
      data
    );
  }

  static isVerificationMethodSubmitted(token, dispatch) {
    return axiosClient(dispatch).get(
      "/users/settings/verification_methods_status",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static deleteMyAccount(token, dispatch) {
    return axiosClient(dispatch, token).delete("/users/me");
  }

  static suspendMyAccount(token, dispatch) {
    return axiosClient(dispatch, token).post("/users/suspend");
  }

  static getEnabledVeriMethods(token, dispatch) {
    return axiosClient(dispatch, token).get(
      "/users/settings/verification_methods"
    );
  }

  static fetchSubmittingUserDetails(token) {
    return axiosClient().get("/users/submitting_role_details", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static fetchRolesAndStatus(token, dispatch) {
    return axiosClient(dispatch, token).get("/users/roles");
  }

  static updateProfilePic(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/users/settings/avatar", data);
  }

  static requestChangeMail(token, dispatch, data) {
    return axiosClient(dispatch, token).post(
      "/users/request_email_change",
      data
    );
  }

  static cancelRequestChangeMail(token, dispatch, email) {
    return axiosClient(dispatch, token).delete(
      `/users/request_email_change?email=${email}`
    );
  }

  static requestChangeMobile(token, dispatch, data) {
    return axiosClient(dispatch, token).post(
      "/users/request_mobile_change",
      data
    );
  }

  static verifyChangeMobile(token, dispatch, data) {
    return axiosClient(dispatch, token).post(
      "/users/verify_mobile_change",
      data
    );
  }

  static checkResetStatus(token, dispatch, resetId) {
    return axiosClient(dispatch, token).get(
      "/users/email_reset_status?reset_id=" + resetId
    );
  }

  static changePassword(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/users/change_password", data);
  }

  static removeTestUser() {
    return axiosClient().delete("/users/remove-test-user");
  }

  static setTestInvite({ email }) {
    return axiosClient().post("/auth/invite/test", { email });
  }

  static getUnreadMessageCount(token, dispatch) {
    return axiosClient(dispatch, token).get('/messages/unread');
  }
}

export default UserService;
