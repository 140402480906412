import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  logout,
  selectToken,
  selectUser,
  updateUserData,
} from "redux-store/features/authSlice";
import UserService from "services/UserService";
import DashboardHeader from "components/dashboard-header";
import LoadIndicator from "components/LoadIndicator";
import ErrorsComponent from "components/Errors";
import { setCanceledSubSignup } from "redux-store/features/appSlice";

function Onboarding() {
  const navigate = useNavigate();

  const [err, set_err] = useState([]);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const [loading, set_loading] = useState(false);
  const [fetching, set_fetching] = useState(false);
  const [verify_list, set_verify_list] = useState([]);

  const fetchVerifyList = async () => {
    set_fetching(true);
    try {
      const res = await UserService.fetchVerifyList(dispatch, token);

      set_verify_list(res.data.data);
    } catch (e) {}
    set_fetching(false);
  };

  const createSubmittingProfile = async () => {
    try {
      const res = await UserService.createSubmittingProfile(token, dispatch);

      dispatch(updateUserData({ ...user, submitting: res.data.data }));
    } catch (error) {}
  };

  const submitVerificationMethods = async (verification_methods) => {
    set_err([]);
    set_loading(true);

    try {
      if (verification_methods.length < 2) {
        set_loading(false);
        return set_err(["Select at least 2 document"]);
      }

      const res = await UserService.updateVerificationMethods(
        { verification_methods },
        token
      );

      await createSubmittingProfile();

      set_step(1);
    } catch (error) {
      const msg = error.response?.data?.message;
      if (msg) {
        if (msg instanceof String || typeof msg === "string") set_err([msg]);
        else {
          const arr = [];
          Object.keys(msg).map((key) => {
            for (const emsg of msg[key]) arr.push(emsg);
          });

          set_err(arr);
        }
      } else {
        set_err(["Something went wrong, check your internet"]);
      }
    }
    set_loading(false);
  };

  const [step, set_step] = useState(0);

  useEffect(() => {
    fetchVerifyList();
    return () => set_verify_list([]);
  }, []);

  return (
    <>
      <div id="main-container">
        <div className="container">
          <div className="submitRoleContent mb-5">
            {fetching ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <div className="container">
                <h1 className="title1 mb-4">{`Submitting Role Sign Up ${
                  step + 1
                }/2`}</h1>
                {step === 0 && (
                  <StepOne
                    err={err}
                    submitVerificationMethods={submitVerificationMethods}
                    loading={loading}
                    verify_list={verify_list}
                    token={token}
                    dispatch={dispatch}
                  />
                )}

                {step === 1 && (
                  <StepTwo
                    navigateToUserDetails={() => navigate("/user-verification")}
                    navigateToSumbitQuality={() =>
                      navigate("/submit-quality-information")
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Onboarding;

const StepOne = ({
  err,
  submitVerificationMethods,
  loading,
  verify_list,
  token,
  dispatch,
}) => {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [verification_methods, set_verification_methods] = useState([]);

  const fetchVerificationTypes = async () => {
    try {
      const res = await UserService.getEnabledVeriMethods(token, dispatch);

      if (res.data.data instanceof Array) {
        res.data.data.forEach((item) => onSwitchClick(item.doc_id));
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchVerificationTypes();
  }, []);

  const onSwitchClick = (doc_id) => {
    set_verification_methods((prev) => {
      const d = [...prev];
      if (d.includes(doc_id)) {
        return d.filter((item) => item !== doc_id);
      } else {
        d.push(doc_id);
        return d;
      }
    });
  };

  const onCancel = useCallback(() => {
    dispatch(setCanceledSubSignup(true));
    navigate(-1);
  }, []);

  // Select mandatory and disable
  useEffect(() => {
    if (verify_list.length > 0) {
      for (let idx in verify_list) {
        if (verify_list[idx].mandatory === "mandatory") {
          onSwitchClick(verify_list[idx].doc_id);
        }
      }
    }
  }, [verify_list]);

  return (
    <form>
      <p>
        You will soon be able to submit and sell your quality experiences, as a
        submitting user you must must proceed to the next level of verification
        within 3 months of starting your submitting role.
      </p>

      <strong className="d-block mb-3 mt-3">
        The minimum requirement for this is
      </strong>

      <ul className="infolist">
        {" "}
        <li>A unique Selfi Photo</li>{" "}
        <li>
          Image(s) of recognised ID document(s) to confirm picture and address.
        </li>{" "}
        <li>A list of other verification documents you have</li>{" "}
      </ul>

      <p>
        If you do not provide these within 3 months your submitting account will
        be placed on hold until you do. This means any quality data you have in
        your account will not be sellable.
      </p>

      <p>
        Please confirm which verification methods you can do below. These maybe
        requested at random in future at a limited frequency, and we will only
        keep copies of any documents temporarily, see{" "}
        <a
          href="/non_commercial_privacy.pdf"
          target="_blank"
          className="infolink"
        >
          privacy policy
        </a>{" "}
        for ful details.
      </p>

      <strong className="d-block mb-3 mt-5">Verfication Methods</strong>

      <ul className="infolist selectMethod">
        {verify_list.map((item) => (
          <li key={"verify-list-onboard-" + item.doc_id}>
            {item.type_name}
            <label className="switch">
              <input
                type="checkbox"
                onChange={(e) => onSwitchClick(item.doc_id)}
                checked={verification_methods.includes(item.doc_id)}
                disabled={item.mandatory === "mandatory"}
              />
              <span className="slider round"></span>
            </label>
          </li>
        ))}
      </ul>

      <p>
        Additionally you may also be contacted directly by post, voice, video or
        at your address for a limited in-person visit with prior arrangement.
        More details in{" "}
        <a
          href="/non_commercial_privacy.pdf"
          target="_blank"
          className="infolink"
        >
          privacy policy
        </a>
        .
      </p>

      <p>
        <input
          className="form-check-input"
          type="checkbox"
          checked={agree}
          onClick={() => setAgree((prev) => !prev)}
        />{" "}
        I agree to{" "}
        <a href="/sub_user_t_c.pdf" target="_blank" className="infolink">
          Submitting User Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          href="/non_commercial_privacy.pdf"
          target="_blank"
          className="infolink"
        >
          Privacy policy
        </a>
      </p>

      <div className="d-flex align-items-center gap-4">
        <button
          type="button"
          onClick={() => submitVerificationMethods(verification_methods)}
          className="btn btn-yellow"
          disabled={!agree}
        >
          {loading ? <LoadIndicator /> : "Agree and Submit"}
        </button>

        <button type="button" className="btn btn-black" onClick={onCancel}>
          Cancel
        </button>
      </div>

      {err.length > 0 && <ErrorsComponent errors={err} />}
    </form>
  );
};

const StepTwo = ({ navigateToUserDetails, navigateToSumbitQuality }) => {
  return (
    <>
      <p>
        You can now submit your experiences and go straight to{" "}
        <a className="infolink" onClick={navigateToSumbitQuality}>
          Product/Service Registration
        </a>
        , but you must upload document(s) that confirms your address aswell as a
        unique photo within the next 3 months.
      </p>
      <p>Or if you have the documents now you can get it out of the way now.</p>
      <a className="btn btn-yellow" onClick={navigateToUserDetails}>
        Start next verification step
      </a>
    </>
  );
};
