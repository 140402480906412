import React from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { logout } from "redux-store/features/authSlice";

const Logout = () => {
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(logout());
  };

  logoutUser();
  localStorage.clear();

  return <Navigate to="/" />;
};

export default Logout;
