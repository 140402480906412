import { useSelector } from "react-redux";
import { selectUser } from "redux-store/features/authSlice";
import styles from "./styles.module.css";
import { sanitizeCurrency } from "shared/helpers";
import { Link } from "react-router-dom";

const CreditPage = () => {
  const user = useSelector(selectUser);

  return (
    <div id="main-container">
      <div className="container">
        <h3 className="mb-4 fadeAnim">Credit</h3>

        <div className="card mb-4 fadeAnim">
          <div className="card-body">
            {/** USER ID */}
            <div className="row mb-4">
              <div className="col-12">
                <label className="form-label-x">User ID</label>
              </div>

              <div className="col-12">
                <p className={styles.val}>{user?.number || ""}</p>
              </div>
            </div>

            {/** USER NAME */}
            <div className="row mb-4">
              <div className="col-12">
                <label className="form-label-x">User Name</label>
              </div>

              <div className="col-12">
                <p className={styles.val}>
                  {user?.first_name || ""} {user?.last_name || ""}
                </p>
              </div>
            </div>

            {/** CURRENT CREDIT BALANCE */}
            <div className="row mb-4">
              <div className="col-12">
                <label className="form-label-x">Current Credit Balance</label>
              </div>

              <div className="col-12">
                <p className={styles.val}>
                  <i className="bi bi-credit-card-fill"></i>{" "}
                  {sanitizeCurrency(user?.balance)}
                </p>
                <Link to="/add-credit" className="d-block link">
                  Add Credit
                </Link>
              </div>
            </div>

            <a href="#" className="d-block mb-2 link">
              Credit Purchases
            </a>

            <Link to="/credit/report-purchases" className="d-block mb-2 link">
              Report Purchases
            </Link>

            <a href="#" className="d-block mb-2 link">
              Sales
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditPage;
