import React, { useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "../data-table";
import useWindowWidth from "hooks/useWindowWidth";
import { addLeadingZeros } from "shared/helpers";

const defaultUnitColumns = [
  {
    text: "QUN",
    key: "unit_id",
    padded: true,
  },
  {
    text: "Item",
    data: (item) => `${item.brand} - ${item.model_name_des} - ${item.model_code}`,
    sortable: true
  },
  {
    text: "Submitted",
    key: "submitted",
    sortable: true,
    type: "date",
  },
  {
    text: "Defect",
    add: true,
    onClick: () => {},
  },
]

function Pending({ pendingUnits, loading }) {
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  const unitColumns = useMemo(() => {
    if (windowWidth <= 768) {
      const columns = [...defaultUnitColumns];
      columns[0] = { ...defaultUnitColumns[0], key: undefined, data: (item) => `...${addLeadingZeros(item.unit_id, 8).substring(6)}` };
      return columns;
    }

    return defaultUnitColumns;
  }, [defaultUnitColumns, windowWidth]);

  const onRowClick = (rowData) => {
    navigate(`/submit-quality-information/item-detail/${rowData.unit_id}`);
  };

  return (
    <div>
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <div>
          <div className="card mb-4">
            <div className="card-body">
              <DataTable
                data={pendingUnits}
                columns={unitColumns}
                clickAble
                onRowClick={onRowClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pending;
