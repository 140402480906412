import React from "react";
import styles from "./QualityRecord.module.css";
import { useSelector } from "react-redux";
import { selectUnitQualityRecordData } from "redux-store/features/unitQualityRecordSlice";
import { usePreferences } from "hooks/usePrefrences";

const DesktopVersion = ({
  SALES,
  form,
  onChange,
  estimatedUseCounter,
  getMaxDate,
  swVersion,
  onSwVersionClick,
  getMaxDateRepairedDate,
  goBack,
  submitDisabled,
  handleSubmit,
  handleDelete,
  useStart = undefined,
}) => {
  const {
    selectedUnit,
    selectedAberration,
    selectedSymptom,
    originalCategoryAttributes,
  } = useSelector(selectUnitQualityRecordData);

  const getItemName = () => {
    if (!selectedUnit) {
      return "";
    }

    return `${selectedUnit.brand}, ${selectedUnit.model_name_des}, ${selectedUnit.model_code}`;
  };

  const showRepairedInput = () => {
    onChange("repaired", "");
  };

  const { getUserDistanceUnit } = usePreferences();

  const getUnit = (use_units) => {
    switch (use_units) {
      case "Operational time":
        return "hr";
      case "Distance":
        return getUserDistanceUnit();
      case "Cycles":
        return "Cy";
      case "Uses":
        return "Use";
      default:
        return "";
    }
  };

  return (
    <>
      <div id="main-container">
        <form onSubmit={handleSubmit} className="container">
          <h2 className="mb-4 userLevel-Title">Unit Quality Record</h2>

          {/** SYMPTOM FILTER */}
          <div className="mb-5">
            <div className="row mb-2">
              <div className="col-12 col-md-7">
                <div className="row">
                  {/* <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Item</span>
                    </div>
                  </div> */}

                  <div className={`${styles.topLabelVal} col`}>
                    {getItemName()}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Use Period</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedUnit?.controller_use_id || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Defect Class</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedAberration?.aberration}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Description</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedAberration?.description || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Symptom</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedSymptom?.description || "Null"}
                  </div>
                </div>
              </div>
            </div>

            {SALES && (
              <div className="row mb-4">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptomEmpty}></div>
                    </div>
                    <button
                      className={`${styles.btn} ${styles.changeSymptomBtn}`}
                    >
                      Change Symptom
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="row mb-3">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>User's Description</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <input
                      type="text"
                      value={form.description || ""}
                      onChange={(e) => onChange("description", e.target.value)}
                      className={`${styles.userdescinput} form-control`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/** ENTER SYMPTOM IN YOUR WORD */}
          <div className="mb-4">
            <p className={`${styles.filter} mb-4 `}>
              Enter or Check the Data Below
            </p>

            <div className="mb-4">
              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.bottomLabelRecord}>
                        <span>Severity*</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      <select
                        name="severity"
                        className="form-select w-full"
                        id="severity"
                        value={form.severity || ""}
                        onChange={(e) => onChange("severity", e.target.value)}
                        disabled={SALES}
                      >
                        <option value="" selected disabled>
                          Select Severity
                        </option>
                        <option value="Minor">Minor</option>
                        <option value="Major">Major</option>
                        <option value="Critical">Critical</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {originalCategoryAttributes &&
                originalCategoryAttributes.use_units != "Not applicable" &&
                originalCategoryAttributes.use_units != "Age" && (
                  <div className="row mb-2">
                    <div className="col-12 col-md-7">
                      <div className="row">
                        <div className="col-12 mb-2 mb-md-0 col-md-auto">
                          <div className={styles.bottomLabelRecord}>
                            <span>Use Counter*</span>
                          </div>
                        </div>
                        <div className={`${styles.topLabelVal} col d-flex`}>
                          <input
                            className="form-control"
                            type="number"
                            style={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                            value={
                              typeof form.use_counter !== "number"
                                ? ""
                                : form.use_counter
                            }
                            disabled={SALES}
                            min={0}
                            onChange={(e) =>
                              onChange("use_counter", e.target.valueAsNumber)
                            }
                          />

                          <div className="d-inline-block input-group-prepend">
                            <span
                              className="input-group-text"
                              style={{ height: "100%" }}
                            >
                              {getUnit(originalCategoryAttributes?.use_units)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.topLabelVal} col`}>
                      estimated - {estimatedUseCounter}
                    </div>
                  </div>
                )}

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.bottomLabelRecord}>
                        <span>First Seen*</span>
                      </div>
                    </div>
                    <div className={`${styles.topLabelVal} col`}>
                      <input
                        type="date"
                        className="form-control"
                        min={
                          useStart
                            ? new Date(useStart).toISOString().split("T")[0]
                            : undefined
                        }
                        max={getMaxDate()}
                        disabled={SALES}
                        value={form.first_seen || ""}
                        onChange={(e) => onChange("first_seen", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {selectedUnit && selectedUnit.os_sw_updateable === 1 && (
                <div className="row mb-2">
                  <div className="col-12 col-md-7">
                    <div className="row">
                      <div className="col-12 mb-2 mb-md-0 col-md-auto">
                        <div className={styles.bottomLabelRecord}>
                          <span>SW Version*</span>
                        </div>
                      </div>

                      <div className={`${styles.topLabelVal} col`}>
                        <div className={styles.bottomValueRecord}>
                          <span
                            className={styles.link}
                            onClick={onSwVersionClick}
                          >
                            {swVersion?.name} {swVersion?.build_code}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {originalCategoryAttributes &&
                originalCategoryAttributes.repairable === 1 && (
                  <div className="row mb-2">
                    <div className="col-12 col-md-7">
                      <div className="row">
                        <div className="col-12 mb-2 mb-md-0 col-md-auto">
                          <div className={styles.bottomLabelRecord}>
                            <span>Repaired Date</span>
                          </div>
                        </div>

                        <div className={`${styles.topLabelVal} col`}>
                          {form.repaired === null ? (
                            <span onClick={showRepairedInput}>
                              Not Repaired
                            </span>
                          ) : (
                            <input
                              value={form.repaired || ""}
                              max={getMaxDateRepairedDate()}
                              onChange={(e) =>
                                onChange("repaired", e.target.value)
                              }
                              className={`form-control`}
                              type="date"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.bottomLabelRecord}>
                        <span>Evidence Available</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      <select
                        name="evidence"
                        className="form-select w-full"
                        id="evidence"
                        value={
                          typeof form.evidence !== "number" ? "" : form.evidence
                        }
                        onChange={(e) => onChange("evidence", +e.target.value)}
                        required
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value={0}>No</option>
                        <option value={1}>Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p className={styles.info}>
              All of the above except required date must be set according to the
              time of the fault condition when first seen
            </p>
          </div>

          <div className={`${styles.actions} d-flex flex-row`}>
            <button
              className={styles.cancel}
              onClick={goBack}
              disabled={form.loading}
            >
              Back
            </button>

            <button
              className={styles.btn}
              disabled={submitDisabled || form.loading}
              type="submit"
            >
              Submit
            </button>

            {SALES ? (
              <button disabled className={styles.reportbtn}>
                Report Error
              </button>
            ) : (
              <button
                type="button"
                onClick={handleDelete}
                className={styles.reportbtn}
              >
                Delete
              </button>
            )}

            <button disabled className={styles.requestassistance}>
              Request Assistance
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DesktopVersion;
