import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { selectCategoryManagementData, setCategories } from "redux-store/features/categoryManagement";
import CategoryService from "services/CategoryService";
import styles from "./styles.module.css";

const CollapsedCategoryTreeViewer = forwardRef(({ value, onChange, onSkipChange }, ref) => {

    const { categories } = useSelector(selectCategoryManagementData);
    const token = useSelector(selectToken);
    const dispatch = useDispatch();

    const [activeIndex, setActiveIndex] = useState(0);
    const [tree, setTree] = useState([]);

    const getCategories = async () => {
        try {
            const res = await CategoryService.getAvailableCategories(token, dispatch);

            dispatch(setCategories(res.data.data));
        } catch (e) {}
    };
    
    const fetchCategoryTree = async (category) => {
        try {
            const res = await CategoryService.getParentTree(
                token,
                dispatch,
                category
            );
        
            return [...res.data.data, category];
        } catch (e) {}

        return null;
    };
    
    useEffect(() => {
        if (categories.length === 0) getCategories();
    }, []);

    useEffect(() => {
        if (value && tree.length === 0) {
            fetchCategoryTree(value).then((tree) => {
                if (tree) {
                    setTree(tree);
                    setActiveIndex(tree.length - 1);
                }
            })
        }
    }, [value, tree.length]);

    useEffect(() => {
        if (onSkipChange && typeof onSkipChange === "function") {
            onSkipChange(activeIndex);
        }
    }, [onSkipChange, activeIndex]);

    useImperativeHandle(ref, () => {
        return {
            expand() {
                if (activeIndex > 0) {
                    const newIndex = activeIndex - 1;
                    setActiveIndex (newIndex);
                    return newIndex;
                }

                return 0;
            },
            reset() {
                const lastIndex = tree.length - 1;
                setActiveIndex(lastIndex);
                return lastIndex;
            }
        };
    }, [activeIndex, tree.length]);

    const category = useMemo(() => {
        if (tree.length > 0 && categories.length > 0) {
            let currentIndex = 0;
            let currentCategory = categories[0];

            while (currentIndex < activeIndex && Boolean(currentCategory)) {
                currentIndex++;
                const id = tree[currentIndex];

                if (!currentCategory.children || !Array.isArray(currentCategory.children)) {
                    break;
                }

                currentCategory = currentCategory.children.filter((category) => category.category_id === id)[0] || null;
            }

            return currentCategory;
        }

        return null;
    }, [activeIndex, categories, tree]);
    
    const positionIndicator = useMemo(() => {
        if (tree.length === 0) return "";

        if (activeIndex === 0) return "O-";
        else if (activeIndex === tree.length - 1) return "-O";
        else return "-";
    }, [tree, activeIndex]);

    useEffect(() => {
        onChange(category);
    }, [category?.category_id]);

    if (!category) {
        return null;
    }

    return (
        <div className={`d-flex flex-wrap align-items-center ${styles.container}`}> 
            <span>{positionIndicator}</span>
            <input className={`form-control ${styles.input}`} value={category.name} readOnly />
        </div>
    );
});

export default CollapsedCategoryTreeViewer;