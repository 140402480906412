import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./NewSymptom.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectnewSymptomData,
  setSelectedAberration,
  setSelectedUnit,
} from "redux-store/features/newSymptomSlice";
import {
  initializeAberrationList,
  selectAberrationManagementData,
} from "redux-store/features/aberrationManagementSlice";
import AberrationService from "services/AberrationService";
import { selectToken } from "redux-store/features/authSlice";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "shared/helpers";
import SymptomService from "services/SymptomService";
import { addSymptom } from "redux-store/features/symptomManagementSlice";
import RequireSubmittingUser from "HoC/RequireSubmittingUser";

const NewSymptom = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const token = useSelector(selectToken);

  const aberrationService = new AberrationService(dispatch, token);
  const symptomService = new SymptomService(dispatch, token);

  const goBack = () => navigate(-1);

  const { initialized } = useSelector(selectAberrationManagementData);
  const { selectedAberration, selectedUnit } =
    useSelector(selectnewSymptomData);

  const fetchAberrations = async () => {
    try {
      const res = await aberrationService.fetchAll();

      dispatch(initializeAberrationList(res.data.data || []));
    } catch (error) {}
  };
  useEffect(() => {
    if (!initialized) fetchAberrations();
  }, [initialized]);

  const { form, onChange } = useForm({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.loading || !selectedAberration || !selectedUnit) return;

    onChange("loading", true);

    try {
      const payload = {
        ...form,
        aberration: selectedAberration.aberration_id,
        end_category: selectedUnit.category_id,
      };

      const res = await symptomService.insert(payload);

      if (res.data.data) {
        dispatch(addSymptom(res.data.data));
      }

      navigate("/submit-quality-information/unit-quality-record", {
        state: {
          from: "new_symptom",
          data: {
            original_category: state.original_category,
            filter_category: state.filter_category,
            symptom: res.data.data,
            unit: selectedUnit,
            aberration: selectedAberration,
            description: form?.description,
          },
        },
      });
    } catch (error) {
      console.log("error");
    } finally {
      onChange("loading", false);
    }
  };

  useEffect(() => {
    dispatch(setSelectedAberration(state?.aberration));
    dispatch(setSelectedUnit(state?.unit));
    onChange("description", state?.description);
  }, [state]);

  return (
    <RequireSubmittingUser>
      <div id="main-container">
        <form className="container" onSubmit={handleSubmit}>
          <h2 className="mb-4 userLevel-Title">New Symptom</h2>

          {/** SYMPTOM FILTER */}
          <div className="mb-4">
            {/* <p className={styles.filter}>Symptom Filter:</p> */}

            <div className="row mb-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Defect Class</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedAberration?.aberration || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Description</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedAberration?.description || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>End Category</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <OverlayTrigger
                      key={"cat-des"}
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-cat-des">
                          {selectedUnit?.category_description}
                        </Tooltip>
                      }
                    >
                      <span>{selectedUnit?.category_name || "Null"}</span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>The Symptom in your own words</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <textarea
                      rows="3"
                      className="form-control"
                      value={form.description || ""}
                      onChange={(e) => onChange("description", e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>
                        Include the following in the symptom description
                      </span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedAberration?.guidance || "Null"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 className="mb-4">
              Include any assumptions or conditions in Additional Information
            </h3>
            <div className="mb-4">
              <div className="row mb-4">
                <div className="col-12 ">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabel}>
                        <span>
                          Symptom Details <em>*</em>
                        </span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      <textarea
                        rows="1"
                        className="form-control"
                        value={form.symptom_description || ""}
                        required
                        onChange={(e) =>
                          onChange("symptom_description", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12 ">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabel}>
                        <span>Additional Information</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      <textarea
                        rows="1"
                        className="form-control"
                        value={form.additional_info || ""}
                        onChange={(e) =>
                          onChange("additional_info", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.actions} d-flex flex-row`}>
            <button className={styles.cancel} onClick={goBack}>
              Back
            </button>

            <button
              className={styles.new}
              type="submit"
              disabled={form.loading || !selectedUnit || !selectedAberration}
            >
              Submit for Review
            </button>
          </div>
        </form>
      </div>
    </RequireSubmittingUser>
  );
};

export default NewSymptom;
