import { useCallback } from "react";

export const StepTwo = ({ modelType, onSelectModelType }) => {

    const renderContent = useCallback((modelType) => {
        switch (modelType) {
            case "Product":
                return (
                    <>
                        <h4 className="mb-4">Product</h4>

                        <p className="mb-4">
                            Is this a product you can keep and use many times like a car, TV, paint or a building material, or is it a consumable/single use item like washing materials, fuel or a non-rechargeable battery and you use it once. <a className="link" href="#">More Examples.</a>
                        </p>

                        <div className="d-flex flex-row gap-4 align-items-center">
                            <button className="btn btn-yellow" type="button" onClick={() => onSelectModelType('Multiple use product')}>
                                Multiple Use
                            </button>

                            <button className="btn btn-yellow" type="button" onClick={() => onSelectModelType('Single use product')}>
                                Single Use
                            </button>
                        </div>
                    </>
                );

            case "Service":
                return (
                    <>
                        <h4 className="mb-4">Service</h4>

                        <p className="mb-4">
                            Is this a regular service you subscribe to like a phone service contract, or is it a one-off event like a repair. <a href="#" className="link">More examples.</a>
                        </p>

                        <div className="d-flex flex-row gap-4 align-items-center">
                            <button className="btn btn-yellow" type="button" onClick={() => onSelectModelType('Continuous service')}>
                                Regular Subscription
                            </button>

                            <button className="btn btn-yellow" type="button" onClick={() => onSelectModelType('One-off service')}>
                                One-off Service
                            </button>
                        </div>
                    </>
                );
        
            default:
                break;
        }
    }, [onSelectModelType]);

    if (!Boolean(modelType)) return null;

    return renderContent(modelType);
}