import { formatDDMMYYYYDash } from "shared/helpers";
import styles from "./VersionRow.module.css";

const VersionRow = ({ data, onClick, form, onChange }) => {
  if (!data.sw_version_id) {
    return (
      <div className={`${styles.versionRow}  mb-2`}>
        <div className={`${styles.versionRowData} ${styles.newRow}`}>
          <div className={styles.versionRowDataCol}>
            <div>
              <span>Null</span>
            </div>
          </div>

          <div className={styles.versionRowDataCol}>
            <div>
              <input
                className={`${styles.newVersionInput}`}
                type="text"
                placeholder="Enter short name"
                maxLength={30}
                value={form.name}
                onChange={(e) => onChange("name", e.target.value)}
                required
              />
            </div>
          </div>

          <div className={styles.versionRowDataCol}>
            <div>
              <input
                className={`${styles.newVersionInput}`}
                type="text"
                placeholder="Enter build code"
                maxLength={100}
                value={form.build_code}
                onChange={(e) => onChange("build_code", e.target.value)}
                required
              />
            </div>
          </div>

          <div className={styles.versionRowDataCol}>
            <div>
              <span>Null</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.versionRow} mb-2`} onClick={onClick}>
      <div
        className={`${styles.versionRowData} ${
          data.status === "Pending" ? styles.newRow : undefined
        }`}
      >
        <div className={styles.versionRowDataCol}>
          <div>
            <span>
              {data.released_date
                ? formatDDMMYYYYDash(data.released_date)
                : "Null"}
            </span>
          </div>
        </div>

        <div className={styles.versionRowDataCol}>
          <div>
            <span>{data.name}</span>
          </div>
        </div>

        <div className={styles.versionRowDataCol}>
          <div>
            <span>{data.build_code}</span>
          </div>
        </div>

        <div className={styles.versionRowDataCol}>
          <div>
            <span>{data.status}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VersionRow;
